<ng-scrollbar track="vertical" visibility="hover">
    <div class="default-preferences" [formGroup]="formGroup">

        <ng-container *ngIf="!showPreferencesGroup">

            <rdc-apps-header header="Defaults & preferences" title="Frequent use"></rdc-apps-header>

            <div class="form-group" formArrayName="defaultAirports">
                <label class="default-preferences-label">Default airports</label>

                <div class="default-preferences-wrapper">
                    <ng-container *ngFor="let airportControl of defaultAirportsArray.controls; let airportIndex = index">
                        <rdc-apps-default-preferences-item
                            [index]="airportIndex"
                            [controls]="airportControl"
                            (editPreference)="onEdit($event, GROUP_TYPE.Airport)"
                            (deletePreference)="onDelete($event, GROUP_TYPE.Airport)"
                        ></rdc-apps-default-preferences-item>
                    </ng-container>

                    <button rdcAppsButton (click)="onAddAirport()">Add group</button>
                </div>
            </div>

            <div class="form-group" formArrayName="defaultAirlines">
                <label class="default-preferences-label">Default airlines</label>
                <div class="default-preferences-wrapper">
                    <ng-container *ngFor="let airlineControl of defaultAirlinesArray.controls; let airlineIndex = index">
                        <rdc-apps-default-preferences-item
                            [index]="airlineIndex"
                            [controls]="airlineControl"
                            (editPreference)="onEdit($event, GROUP_TYPE.Airline)"
                            (deletePreference)="onDelete($event, GROUP_TYPE.Airline)"
                        ></rdc-apps-default-preferences-item>
                    </ng-container>
                    <button rdcAppsButton (click)="onAddAirline()">Add group</button>
                </div>
            </div>

            <rdc-apps-header title="Units & measurements" [marginTop]="true"></rdc-apps-header>

            <div class="form-group">
                <label>Distance units</label>
                <rdc-apps-select
                    controlName="distanceUnits"
                    [controlGroup]="formGroup"
                    [options]="dataPointsDropDownData?.distanceUnits || []"
                ></rdc-apps-select>
            </div>

            <div class="form-group">
                <label>Number format</label>
                <div class="form-group-radio">
                    <div>
                        <label class="rdc-checkbox"
                               rdcAppsTooltip
                               tooltipPosition="above"
                               [tooltipTemplate]="commas">
                            <input
                                formControlName="tableOutput"
                                name="tableOutput"
                                type="radio"
                                [value]="'decimals'"
                            >
                            <span>Decimal comma</span>
                        </label>
                    </div>
                    <div>
                        <label class="rdc-checkbox"
                               rdcAppsTooltip
                               tooltipPosition="above"
                               [tooltipTemplate]="points">
                            <input
                                formControlName="tableOutput"
                                name="tableOutput"
                                type="radio"
                                [value]="'commas'"
                            >
                            <span>Decimal point</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label>Currency</label>
                <rdc-apps-select
                    placeholder="Select currency"
                    controlName="currency"
                    [controlGroup]="formGroup"
                    [filterable]="true"
                    [options]="dataPointsDropDownData?.currency || []"
                ></rdc-apps-select>
            </div>

            <rdc-apps-header title="Useful defaults" [marginTop]="true"></rdc-apps-header>

            <div class="form-group">
                <label class="form-group-ex-label">Output names:</label>

                <div class="form-group-ex">

                    <rdc-apps-display-codes
                        [form]="form"
                        [emitEvent]="true"
                        [showLabel]="false"
                        controlName="defaultPreferences"
                    ></rdc-apps-display-codes>

                    <span>Example: {{ outputExample }}</span>
                </div>

            </div>

            <div class="form-group">
                <label>Profit reporting level</label>

                <rdc-apps-select
                    controlName="reportingLevel"
                    [controlGroup]="formGroup"
                    [options]="(dataPointsDropDownData?.reportingLevel | displayOrder)">
                </rdc-apps-select>
            </div>

            <div class="form-group">
                <label>Directionality</label>

                <rdc-apps-select
                    controlName="directionality"
                    [controlGroup]="formGroup"
                    [options]="dataPointsDropDownData?.directionality || []">
                </rdc-apps-select>
            </div>

            <div class="default-preferences-toggles">
                <div class="default-preferences-taxes">
                    <label class="rdc-checkbox">
                        <input type="checkbox" formControlName="governmentTaxes">
                        <span>Include government taxes within fare data</span>
                    </label>
                </div>

                <div class="default-preferences-taxes">
                    <label class="rdc-checkbox">
                        <input type="checkbox" formControlName="airportCharges">
                        <span>Include airport charges within profit data</span>
                    </label>
                </div>
            </div>

        </ng-container>

        <rdc-apps-default-preferences-group
            *ngIf="showPreferencesGroup"
            [form]="formGroup"
            [index]="index"
            [editMode]="editMode"
            [groupType]="groupType"
            [givenFormGroupName]="givenFormGroupName"
            [autocompleteResults]="autocompleteResults"
            (cancel)="onCancel()"
            (addGroup)="onAddGroup($event)"
            (autocomplete)="onAutoComplete($event, groupType)"
        ></rdc-apps-default-preferences-group>

    </div>
</ng-scrollbar>

<ng-template #commas>
    <div class="text-tooltip">
        Example: <span class="tooltip">1.234,56</span>
    </div>
</ng-template>
<ng-template #points>
    <div class="text-tooltip">
        Example: <span class="tooltip">1,234.56</span>
    </div>
</ng-template>
