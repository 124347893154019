import { Pipe, PipeTransform } from '@angular/core';
import { ɵFormGroupValue } from '@angular/forms';
import {
    ApexStudy,
    ChartStudyQueryRequest,
    QueryRequestFilterList
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { StudyTemplateOptions } from 'rdc-apex-store';

import { TemplateHelper } from './TemplateHelper';

@Pipe({
    standalone: true,
    name: 'PrependFilterPipe',
})
export class PrependFilterPipe implements PipeTransform {

    transform(str: string, chartRequest: ɵFormGroupValue<ApexStudy>): string {

        const filters = TemplateHelper.getFiltersFromString(str);

        const key = filters.find((filter) => {

            const isXaxis = chartRequest.queryRequest?.chartProperties?.type === filter;

            const isSeries = chartRequest.queryRequest?.chartProperties?.seriesDefinitions?.some(
                ({ type }) => type === filter
            );

            return (!isXaxis && !isSeries);
        });

        switch (true) {
            case key?.includes('origin'): {
                return 'from';
            }
            case key?.includes('destination'): {
                return 'to';
            }
            default: {
                return 'for';
            }
        }
    }

}

@Pipe({
    standalone: true,
    name: 'PrependSeriesPipe',
})
export class PrependSeriesPipe implements PipeTransform {

    transform(str: string, requestChartProps: ɵFormGroupValue<ChartStudyQueryRequest>): string {

        if (str.includes('seriesRange')) {
            return 'for';
        }

        if (str.includes('timeperiod')) {

            if (requestChartProps?.filters?.timePeriod?.specific?.type === 'months') {
                return 'between';
            }

            return 'for';
        }

        if (!str.includes('comparison')) {
            return '';
        }

        const indexes = str.match(/\d/g) || [ '0' ];

        const comparisonIndex = Number(indexes[0]);

        switch ((requestChartProps.chartProperties?.seriesDefinitions || [])[comparisonIndex]?.type) {
            case 'origin': {
                return 'from';
            }
            case 'destination': {
                return 'to';
            }
            default: {
                return 'for';
            }
        }
    }

}


@Pipe({
    standalone: true,
    name: 'shouldShowButton',
})
export class ShouldShowButton implements PipeTransform {

    transform(
        str: string,
        templateOptions: StudyTemplateOptions,
        queryRequest: ɵFormGroupValue<ChartStudyQueryRequest>,
        valueMap: Map<string, string | undefined>,
    ): {
            add: boolean;
            remove: boolean;
        } {

        if (str.includes('filters')) {

            const controls = TemplateHelper.getFiltersFromString(str);

            const indexes = str.match(/\d/g) || [ '0' ];

            const filterIndex = Number(indexes[0]);

            if (isNaN(filterIndex)) {
                return { add: false, remove: false };
            }

            const notAllAndValidLen = controls.some((ctrl) => {

                const hasLimit = templateOptions.filterLimits?.find(
                    (filLim) => filLim.filter.includes(ctrl));

                let limit = hasLimit ? (hasLimit.limit || 5) : 5;

                limit = (limit > 5) ? 5 : limit;

                const { selected = [], type = '' }: ɵFormGroupValue<QueryRequestFilterList> = (queryRequest.filters as never)[ctrl];

                if (limit === 1 && selected.length <= 1) {
                    return false;
                }

                return ![ 'all', null ].includes(type) && (selected.length < limit);
            });

            const moreThanOne = controls.some((ctrl) => {
                const { selected = [] }: ɵFormGroupValue<QueryRequestFilterList> = (queryRequest.filters as never)[ctrl];

                return selected.length > 1;
            });

            const items = Array.from(valueMap.keys()).filter((key) => key.includes(str));

            const lastKey = items[items.length - 1];

            return { add: (notAllAndValidLen && !!valueMap.get(lastKey)), remove: moreThanOne };
        }

        if (str.includes('comparison')) {

            const indexes = str.match(/\d/g) || [ '0' ];

            const seriesIndex = Number(indexes[1]);

            const hasLimit = templateOptions.comparisonLimits?.find(
                (filLim) => filLim.index === seriesIndex);

            let limit = hasLimit? (hasLimit.limit || 4) : 4;

            limit = (limit > 4) ? 4 : limit; // cannot be more than 4

            if (limit === 1 || !queryRequest.chartProperties?.series) {
                return { add: false, remove: false };
            }

            const seriesCount = queryRequest.chartProperties.series.length;

            const hasMoreThanOne = seriesCount > 1;

            const isLessThanLimit = seriesCount < limit;

            const items = Array.from(valueMap.keys()).filter((key) => key.includes(str));

            const lastKey = items[items.length - 1];

            return {
                remove: hasMoreThanOne,
                add: (isLessThanLimit && !!valueMap.get(lastKey)), // and last in series
            };
        }

        return { add: false, remove: false };
    }
}


@Pipe({
    standalone: true,
    name: 'shouldShowButtonIfSeries',
})
export class ShouldShowButtonIfSeries implements PipeTransform {

    transform(str: string): boolean {

        if (str.includes('comparison') && str.includes('series')) {
            return false;
        }

        return true;
    }
}


@Pipe({
    standalone: true,
    name: 'isSingleFilter',
})
export class SingleFilterItemPipe implements PipeTransform {

    transform(key: string, templateOptions: StudyTemplateOptions): boolean {

        const filters = TemplateHelper.getFiltersFromString(key);

        const limit = templateOptions.filterLimits?.find((filLim) => filters.some((fil) => filLim.filter.includes(fil)));

        if (!limit) {
            return false;
        }

        return limit.limit === 1;
    }
}
