import { map, withLatestFrom } from 'rxjs';

import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { appLoaded, AppLoadStatus } from "@rdc-apps/rdc-shared/src/lib/data-access/store/app-loading";

import {
    getFiltersDateRange,
    loadFiltersDateRangeFailure,
    loadFiltersDateRangeSuccess
} from './filters-date-range.actions';
import * as FiltersDateRangeActions from './filters-date-range.actions';
import { getLoadedDateRange } from './filters-date-range.selectors';
import { FiltersDateRangeService } from './filters-date-range.service';
import { APP_DATE_RANGE } from '../../app-loading-keys';



@Injectable()
export class FiltersDateRangeEffects {

    setLoading$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getFiltersDateRange),
            fetch({
                run: () => this.store$.dispatch(AppLoadStatus.loading(APP_DATE_RANGE))
            })
        )
    );

    setLoaded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                loadFiltersDateRangeSuccess,
                loadFiltersDateRangeFailure,
            ),
            fetch({
                run: () => this.store$.dispatch(appLoaded({ key: APP_DATE_RANGE }))
            })
        )
    );

    get$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FiltersDateRangeActions.getFiltersDateRange),
            withLatestFrom(this.store$.select(getLoadedDateRange)) as never,
            fetch({
                run: (action, dates) => {

                    if (dates) {
                        return {
                            type: loadFiltersDateRangeSuccess.type,
                            filtersDateRange: dates,
                        };
                    }

                    return this.filterDateRangeService.get()
                        .pipe(
                            map((dateRanges) => ({
                                type: loadFiltersDateRangeSuccess.type,
                                filtersDateRange: dateRanges,
                            }))
                        );
                },
                onError: () => FiltersDateRangeActions.loadFiltersDateRangeFailure({
                    error: 'Error loading date ranges',
                }),
            })
        )
    );

    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store,
        private readonly filterDateRangeService: FiltersDateRangeService
    ) {}
}
