import { map } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';

import * as AutocompleteActions from './autocomplete.actions';
import { loadAutocompleteSuccess } from './autocomplete.actions';
import { AutocompleteService } from './autocomplete.service';


@Injectable()
export class AutocompleteEffects {

    autocomplete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AutocompleteActions.autocomplete),
            fetch({
                run: (props) => {
                    if(!props.query) {
                        return {
                            type: loadAutocompleteSuccess.type,
                            results: [],
                        }
                    }

                    return this.autocompleteService
                        .get(props.filter, props.query)
                        .pipe(
                            map((results) => ({
                                type: loadAutocompleteSuccess.type,
                                results,
                            }))
                        );
                },
                onError: (action, errorResp: HttpErrorResponse) => {
                    if (errorResp.status === 404) {
                        // we don't care about 404's, this just means no results
                        return AutocompleteActions.loadAutocompleteSuccess({
                            results: [],
                        });
                    }

                    // create a string of all the errors
                    return AutocompleteActions.loadAutocompleteFailure({
                        error: Object.keys(errorResp.error.errors)
                            .map((key) => errorResp.error.errors[key])
                            .join(', '),
                    });
                },
            })
        )
    );

    multiAutocomplete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AutocompleteActions.multiAutocomplete),
            fetch({
                run: (props) => {
                    if(!props.query) {
                        return {
                            type: loadAutocompleteSuccess.type,
                            results: [],
                        }
                    }

                    return this.autocompleteService
                        .post(props.filters, props.query, props.limit ? props.limit : 10)
                        .pipe(
                            map((results) => ({
                                type: loadAutocompleteSuccess.type,
                                results,
                            }))
                        );
                },
                onError: (action, errorResp: HttpErrorResponse) => {
                    if (errorResp.status === 404) {
                        // we don't care about 404's, this just means no results
                        return AutocompleteActions.loadAutocompleteSuccess({
                            results: [],
                        });
                    }

                    // create a string of all the errors
                    return AutocompleteActions.loadAutocompleteFailure({
                        error: Object.keys(errorResp.error.errors)
                            .map((key) => errorResp.error.errors[key])
                            .join(', '),
                    });
                },
            })
        )
    );

    constructor(
        private readonly actions$: Actions,
        private readonly autocompleteService: AutocompleteService
    ) {}
}
