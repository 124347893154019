import { PresetColor } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';

/**
 * Interface for the 'DefaultPreferences' data
 */
// eslint-disable-next-line no-shadow
export enum PreferencesGroupType {
    Airport = 'Airport',
    Airline = 'Airline'
}

export interface DefaultPreferencesGroup {
    groups: PreferencesGroup[];
}

export interface CloudDefaultsSelection {
    originAirport: PreferencesSelection;
    airlineId: PreferencesSelection;
}
export interface DefaultPreferencesEntity {
    userAirportGroups?: PreferencesGroup[];
    userAirlineGroups?: PreferencesGroup[];
    unitsMeasurements?: UserPreferences;
}

export interface PreferencesGroup {
    groupId?: string;
    name: string;
    selections: PreferencesSelection[];
    isDefaultGroup: boolean;
}

export interface PreferencesSelection {
    id: number;
    label: string;
    iataCode: string;
    icaoCode: string;
    threeLetterCode: string;
}

export interface DeletePreferenceGroup {
    group: PreferencesGroup;
    index: number;
}

export interface UserPreferences {
    distanceUnits?: string;
    currency?: string;
    reportingLevel?: string;
    tableOutput?: string;
    directionality?: string;
    governmentTaxes?: boolean;
    airportCharges?: boolean;
    includeOnlyCompleteData?: boolean;
    presetColours?: PresetColor[];
    mapColours?: {
        landColour: string,
        seaColour: string,
        borderColour: string,
        unservedColour: string,
        servedColour: string,
    };
    iataCodes?: boolean;
    shouldDisplayNames?: boolean;
}

export interface MapColourPreset {
    landColour: string;
    seaColour: string;
    borderColour: string;
    unservedColour: string;
    servedColour: string;
}
