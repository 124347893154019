import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { QueryBuilderTimePeriodOptionsComponent } from "apex-shared-ui";
import { SelectAutocompleteModule } from "shared-ui";

@Component({
    selector: 'rdc-apps-template-query-time-period',
    templateUrl: './template-query-time-period.component.html',
    styleUrls: [ './template-query-time-period.component.scss' ],
    imports: [ CommonModule, ReactiveFormsModule, SelectAutocompleteModule ]
})
export class TemplateQueryTimePeriodComponent extends QueryBuilderTimePeriodOptionsComponent {

}
