import { CommonModule } from "@angular/common";
import { Component, Input } from '@angular/core';

@Component({
    standalone: true,
    selector: 'rdc-apps-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: [ './progress-bar.component.scss' ],
    imports: [ CommonModule ]
})
export class ProgressBarComponent {

    @Input() title!: string;

}
