<div class="submit-button-container">

    <div class="submit-button-alignment">
        <div class="submit-button-position" [class.no-top-bar]="noTopBar">
            <div class="submit-button-cont-inner">
                <div class="submit-button-outer"
                    [ngClass]="{ querying: queryState === queryStates.QUERYING }"
                    [class.disabled]="invalid"
                >
                    <div
                        rdcAppsTooltip
                        tooltipText="Cancel run"
                        [tooltipDisabled]="queryState !== queryStates.QUERYING"
                    >
                        <button
                            class="submit-button"
                            type="submit"
                            [disabled]="invalid"
                            (click)="this.formSubmit.emit()"
                        >
                            <ng-container [ngSwitch]="true">

                                <rdc-apps-icon class="submit-button-icon" *ngSwitchCase="[queryStates.QUERYING].includes(queryState)" size="xxl">
                                    close
                                </rdc-apps-icon>

                                <rdc-apps-icon
                                    *ngSwitchCase="[queryStates.HAS_RESULTS,  queryStates.EXPORTING,  queryStates.EXPORT_OK].includes(queryState)"
                                    class="refresh-icon submit-button-icon"
                                    rdcIcon="n-icon-refresh"
                                    [size]="queryState === queryStates.QUERYING ? 'lg' : 'xl'"
                                    [fill]="true"
                                ></rdc-apps-icon>

                                <rdc-apps-icon
                                    class="submit-button-icon"
                                    *ngSwitchDefault
                                    [size]="queryState === queryStates.QUERYING ? 'xl' : 'xxl'"
                                    [fill]="true"
                                >
                                    play_arrow
                                </rdc-apps-icon>

                            </ng-container>

                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
