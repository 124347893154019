import { DialogModule } from '@angular/cdk/dialog';
import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FeatureFlagEnabledPipe } from "feature-flag";
import { NgScrollbarModule } from 'ngx-scrollbar';
import { RdcButtonDirective, RdcMenuItemDirective, TooltipDirective } from "shared-directives";
import {
    BreadcrumbComponent,
    ExpandableSidebarComponent,
    IconComponent, LoadingOverlayComponent,
    ProgressBarComponent,
    SidebarComponent,
    SidebarItemComponent,
} from "shared-ui";

import { ApexLayoutComponent } from './apex-layout.component';
import { HasCloudAccessPipe } from "./has-cloud-access.pipe";
import { CloudItemComponent } from "../cloud-item/cloud-item.component";
import { QueryBuilderQuickLauncherModule } from "../query-builder-quick-launcher/query-builder-quick-launcher.module";


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SidebarComponent,
        RdcMenuItemDirective,
        CdkMenuModule,
        IconComponent,
        TooltipDirective,
        DialogModule,
        QueryBuilderQuickLauncherModule,
        RdcButtonDirective,
        ReactiveFormsModule,
        SidebarItemComponent,
        ProgressBarComponent,
        ExpandableSidebarComponent,
        LoadingOverlayComponent,
        NgScrollbarModule,
        CloudItemComponent,
        FeatureFlagEnabledPipe,
        HasCloudAccessPipe,
        BreadcrumbComponent,
    ],
    providers: [
        FeatureFlagEnabledPipe,
        HasCloudAccessPipe,
    ],
    declarations: [
        ApexLayoutComponent,
    ],
    exports: [
        ApexLayoutComponent,
    ],
})
export class ApexLayoutModule {}
