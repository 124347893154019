import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/header';
import { TagManagementItemModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/tag-management-item';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { RdcButtonDirective } from "shared-directives";
import { PanelComponent } from "shared-ui";

import { TagManagementComponent } from './tag-management.component';



@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        HeaderModule,
        TagManagementItemModule,
        RdcButtonDirective,
        PanelComponent,
        NgScrollbarModule,
    ],
    declarations: [ TagManagementComponent ],
    exports: [
        TagManagementComponent,
    ],
})
export class TagManagementModule {}
