import { Observable, of } from 'rxjs';

import { Dialog, DIALOG_DATA } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TabItemModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/tab-item';
import { TabsModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/tabs';
import { getIsAppLoading } from '@rdc-apps/rdc-shared/src/lib/data-access/store/app-loading';
import { CompactSavedQueriesMenuComponent } from "apex-shared-ui";
import { TemplatedChartBuilderComponent } from 'home-ui';
import { NgScrollbar } from 'ngx-scrollbar';
import {
    APP_STUDY_TEMPLATES,
    fetchStudyTemplates,
    getStudyTemplates, StudyTemplate
} from 'rdc-apex-store';
import { RdcButtonDirective, RdcDialogItemDirective, RdcIconButtonDirective, TooltipDirective } from "shared-directives";
import { IconComponent, LoadingOverlayComponent } from "shared-ui";

import {
    QueryBuilderQuickLauncherComponent
} from "../query-builder-quick-launcher/query-builder-quick-launcher.component";


@Component({
    selector: 'rdc-apps-chart-builder-quick-launcher',
    templateUrl: './chart-builder-quick-launcher.component.html',
    styleUrls: [ './chart-builder-quick-launcher.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
    imports: [
        CommonModule,
        IconComponent,
        RdcButtonDirective,
        TabsModule,
        TabItemModule,
        CompactSavedQueriesMenuComponent,
        RdcDialogItemDirective,
        NgScrollbar,
        NgOptimizedImage,
        LoadingOverlayComponent,
        TooltipDirective,
        RdcIconButtonDirective,
    ]
})
export class ChartBuilderQuickLauncherComponent extends QueryBuilderQuickLauncherComponent {

    constructor(
        private dialog: Dialog,
        private overlay: Overlay,
        router: Router,
        store$: Store,
        @Inject(DIALOG_DATA) data: {
            title: string;
            queryCategory: string;
            queryTypes: string[];
        }
    ) {
        super(router, store$, data);

        store$.dispatch(fetchStudyTemplates());

        this.loadingTemplates$ = store$.select(getIsAppLoading(APP_STUDY_TEMPLATES));

        this.templates$ = store$.select(getStudyTemplates(...data.queryTypes));
    }

    templates$: Observable<StudyTemplate[]> = of([]);

    loadingTemplates$: Observable<boolean> = of(true);

    loadTemplate(template: StudyTemplate): void {
        this.dialog.open(TemplatedChartBuilderComponent, {
            width: '750px',
            panelClass: [ 'rdc-dialog' ],
            positionStrategy: this.overlay
                .position()
                .global()
                .centerHorizontally()
                .top('150px'),
            data: {
                template,
                source: 'sidenav'
            },
        });
    }
}
