import { Directive, ElementRef, HostBinding, Input, OnChanges, Renderer2 } from '@angular/core';

@Directive({
    selector: '[rdcAppsButton]',
    standalone: true,
})
export class RdcButtonDirective implements OnChanges {

    @Input() display: null | undefined | 'compact' | 'large' = null;

    @Input() colour: 'default' | 'primary' | 'secondary' | 'accent' | 'alternative' = 'default';

    @Input() version: null | 'pill' | 'pill-alt' | 'pill-hollow' | 'pill-clear' = null;

    @Input() disabled: boolean | null | undefined = false;

    @HostBinding('class.rdc-button') button = true;

    @HostBinding('class') get classList(): string {
        const classes: string[] = [];

        [ this.colour, this.display, this.version ].forEach((value) => {
            if (value) {
                classes.push(`btn-${value}`);
            }
        });

        return classes.join(' ');
    }

    constructor(
        protected elementRef: ElementRef,
        protected renderer: Renderer2
    ) {
        if (this.disabled) {
            this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', '');
        }
    }

    ngOnChanges(): void {
        if (this.disabled) {
            this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', '');
        } else {
            this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');
        }
    }

}
