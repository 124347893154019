import { CommonModule } from "@angular/common";
import { Component, HostBinding, Input } from '@angular/core';

@Component({
    standalone: true,
    selector: 'rdc-apps-panel',
    templateUrl: './panel.component.html',
    styleUrls: [ './panel.component.scss' ],
    imports: [ CommonModule ]
})
export class PanelComponent {

    @HostBinding('class.primary') @Input() primary = false;
    @HostBinding('class.accent') @Input() accent = false;
    @HostBinding('class.dark') @Input() dark = false;
    @HostBinding('class.help') @Input() help = false;
    @HostBinding('class.warning') @Input() warning = false;
    @HostBinding('class.error') @Input() error = false;
    @HostBinding('class.noPadding') @Input() noPadding = false;

}
