<rdc-apps-stepper #cdkStepper [linear]="isLinear">
    <cdk-step>
        <div class="onboarding-container welcome">

            <div class="image-bg">
                <img alt="Apex onboarding image" [attr.src]="IMAGE_SRC" />
            </div>

            <section class="copy">
                <h3>Welcome to Apex-NG</h3>
                <p>We are going to take you through a few short steps to get your account ready.</p>
            </section>

            <div class="actions">
                <button
                    type="button"
                    class="rdc-button btn-primary"
                    cdkStepperNext
                    [disabled]="(userDetailsLoaded$ | async) !== true"
                >
                    Get started
                </button>
            </div>
        </div>
    </cdk-step>
    <form [formGroup]="form">
        <div>
            <cdk-step [stepControl]="formDetails">
                <div class="onboarding-container" formGroupName="details">

                    <header class="onboarding-header">
                        <h3>Your personal details</h3>
                        <p>Please confirm or amend your personal information here.</p>
                    </header>

                    <div class="onboarding-wrapper">

                        <rdc-apps-panel *ngIf="showValidation && formDetails.invalid" [error]="true">
                            Please complete all required fields before continuing
                        </rdc-apps-panel>

                        <rdc-apps-validated-control
                            controlName="firstName"
                            [control]="formDetails.get('firstName')"
                            [validationMessages]="validationMessages"
                            [showValidation]="showValidation"
                        >
                            <div class="form-group">
                                <label>First name</label>
                                <input type="text" placeholder="First name" formControlName="firstName">
                            </div>
                        </rdc-apps-validated-control>
                    </div>
                    <div class="onboarding-wrapper">
                        <rdc-apps-validated-control
                            controlName="lastName"
                            [control]="formDetails.get('lastName')"
                            [validationMessages]="validationMessages"
                            [showValidation]="showValidation"
                        >
                            <div class="form-group">
                                <label>Last name</label>
                                <input type="text" placeholder="Last name" formControlName="lastName">
                            </div>
                        </rdc-apps-validated-control>
                    </div>
                    <div class="onboarding-wrapper">
                        <div class="form-group">
                            <label>Organisation</label>
                            <input
                                type="text"
                                placeholder="Organisation"
                                formControlName="organisation"
                                [ngClass]="{ 'disabled': formDetails.get('organisation')?.disabled }"
                            >
                        </div>
                    </div>

                    <div class="onboarding-wrapper">
                        <div class="form-group">
                            <label>Job title</label>
                            <input
                                type="text"
                                placeholder="Enter job title (optional)"
                                formControlName="jobTitle"
                            >
                        </div>
                    </div>

                    <div class="actions">
                        <button
                            class="rdc-button btn-primary"
                            [disabled]="saving"
                            (click)="onSaveUserDetails()"
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </cdk-step>

            <cdk-step [stepControl]="formPreferences">
                <div class="onboarding-container" formGroupName="preferences">

                    <header class="onboarding-header">
                        <h3>Your preferences</h3>
                        <p>Set your preferences here. You can update these later if you need to.</p>
                    </header>

                    <div class="onboarding-wrapper">

                        <rdc-apps-panel *ngIf="showValidation && formPreferences.invalid" [error]="true">
                            Please complete all required fields before continuing
                        </rdc-apps-panel>

                        <div class="form-group baseline">
                            <label>Set default airport
                                <span class="tooltip">
                                    <rdc-apps-icon
                                       size="xxs"
                                       rdcAppsTooltip
                                       tooltipPosition="right"
                                       [tooltipOptions]="{ maxWidth: '250px' }"
                                       [tooltipText]="AIRPORT_TOOLTIP"
                                    >
                                        info
                                    </rdc-apps-icon>
                                </span>
                            </label>
                            <div class="wrap">
                                <div class="onboarding-component-wrapper">
                                    <rdc-apps-autocomplete
                                        placeholder="Search airports"
                                        [options]="autocompleteResults$ | async"
                                        [showValidation]="false"
                                        [maskSelected]="formPreferences.controls['homeAirport'].value"
                                        (autocomplete)="onAutocomplete($event)"
                                        (valueSelected)="onSelect($event)"
                                        (clearSelected)="onRemove()">
                                    </rdc-apps-autocomplete>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="onboarding-wrapper">
                        <rdc-apps-validated-control
                            controlName="units"
                            [control]="formPreferences.get('units')"
                            [validationMessages]="validationMessages"
                            [showValidation]="showValidation"
                            [showToolTip]="false"
                        >
                            <div class="form-group">
                                <label>Distance units</label>
                                <div class="onboarding-component-wrapper">
                                    <rdc-apps-select
                                        controlName="units"
                                        [controlGroup]="formPreferences"
                                        [options]="(dataPointsDropDownData$ | async)?.distanceUnits || []">
                                    </rdc-apps-select>
                                </div>
                            </div>
                        </rdc-apps-validated-control>
                    </div>

                    <div class="onboarding-wrapper">
                        <div class="form-group">
                            <label>Number format</label>
                            <div class="form-radio">
                                <div>
                                    <label class="rdc-checkbox">
                                        <input formControlName="tableOutput" name="tableOutput" type="radio" [value]="'Commas'" checked>
                                        <span class="max-width">Commas 1,234.56</span>
                                    </label>
                                </div>
                                <div>
                                    <label class="rdc-checkbox">
                                        <input formControlName="tableOutput" name="tableOutput" type="radio" [value]="'Decimals'">
                                        <span>Decimals 1.234,56</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="onboarding-wrapper">
                        <rdc-apps-validated-control
                            controlName="units"
                            [control]="formPreferences.get('currency')"
                            [validationMessages]="validationMessages"
                            [showValidation]="showValidation"
                            [showToolTip]="false"
                        >
                            <div class="form-group">
                                <label>Currency</label>
                                <div class="onboarding-component-wrapper">
                                    <rdc-apps-select
                                        controlName="currency"
                                        placeholder="Select currency"
                                        [controlGroup]="formPreferences"
                                        [filterable]="true"
                                        [options]="(dataPointsDropDownData$ | async)?.currency || []">
                                    </rdc-apps-select>
                                </div>
                            </div>
                        </rdc-apps-validated-control>
                    </div>

                    <div class="onboarding-wrapper">
                        <rdc-apps-validated-control
                            controlName="directionality"
                            [control]="formPreferences.get('directionality')"
                            [validationMessages]="validationMessages"
                            [showValidation]="showValidation"
                            [showToolTip]="false"
                        >
                            <div class="form-group">
                                <label>Directionality</label>
                                <div class="onboarding-component-wrapper">
                                    <rdc-apps-select
                                        controlName="directionality"
                                        [controlGroup]="formPreferences"
                                        [options]="(dataPointsDropDownData$ | async)?.directionality || []">
                                    </rdc-apps-select>
                                </div>
                            </div>
                        </rdc-apps-validated-control>
                    </div>

                    <div class="onboarding-wrapper">
                        <rdc-apps-validated-control
                            controlName="reportingLevel"
                            [control]="formPreferences.get('reportingLevel')"
                            [validationMessages]="validationMessages"
                            [showValidation]="showValidation"
                            [showToolTip]="false"
                        >
                            <div class="form-group">
                                <label>Reporting Level</label>
                                <div class="onboarding-component-wrapper">
                                    <rdc-apps-select
                                        controlName="reportingLevel"
                                        [controlGroup]="formPreferences"
                                        [options]="((dataPointsDropDownData$ | async)?.reportingLevel | displayOrder) || []">
                                    </rdc-apps-select>
                                </div>
                            </div>
                        </rdc-apps-validated-control>
                    </div>

                    <div class="actions">
                        <button
                            class="rdc-button"
                            cdkStepperPrevious
                        >
                            Back
                        </button>
                        <button
                            class="rdc-button btn-primary"
                            [disabled]="saving"
                            (click)="onSaveUserPreferences()"
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </cdk-step>
        </div>
        <cdk-step>
            <div class="onboarding-container">
                <header class="onboarding-header">
                    <h3>See Apex-NG</h3>
                    <p>
                        Everything looks good! You're all set to get started with Apex.
                    </p>
<!--                    <p>-->
<!--                        Everything looks good. Next why not try out our tutorials-->
<!--                        find out what you can do within the new Apex?-->
<!--                    </p>-->
                </header>
                <div class="onboarding-wrapper slim">
                    <!-- PLACEHOLDER CONTENT -->
<!--                    <a class="tutorial-link"><span>Learn how to make similar queries to legacy Apex</span><rdc-apps-icon size="xxs">arrow_forward_ios</rdc-apps-icon></a>-->
<!--                    <a class="tutorial-link"><span>Learn how to make similar queries to legacy Apex</span><rdc-apps-icon size="xxs">arrow_forward_ios</rdc-apps-icon></a>-->
<!--                    <a class="tutorial-link"><span>Learn how to make similar queries to legacy Apex</span><rdc-apps-icon size="xxs">arrow_forward_ios</rdc-apps-icon></a>-->
<!--                    <a class="tutorial-link"><span>Learn how to make similar queries to legacy Apex</span><rdc-apps-icon size="xxs">arrow_forward_ios</rdc-apps-icon></a>-->
                    <!-- PLACEHOLDER CONTENT -->
                </div>
                <div class="actions">
                    <button
                        class="rdc-button"
                        cdkStepperPrevious
                    >
                        Back
                    </button>
                    <button
                        class="rdc-button btn-primary"
                        cdkStepperNext
                        (click)="onSeeApex()"
                    >
                        See new Apex
                    </button>
                </div>
            </div>
        </cdk-step>
    </form>
</rdc-apps-stepper>

