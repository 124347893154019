import { CommonModule } from '@angular/common';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { AppEnvironment, appEnvironment } from '@rdc-apps/rdc-apex/src/lib/shared/environment';
import { RdcButtonDirective, RdcDialogItemDirective, RdcIconButtonDirective, TooltipDirective } from "shared-directives";
import { IconComponent } from "shared-ui";

@Component({
    standalone: true,
    selector: 'rdc-apps-app-drawer',
    templateUrl: './app-drawer.component.html',
    styleUrls: [ './app-drawer.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
    imports: [ CommonModule, RdcButtonDirective, IconComponent, RdcDialogItemDirective, TooltipDirective, RdcIconButtonDirective ],
})
export class AppDrawerComponent {

    constructor(
        @Inject(appEnvironment) public env: AppEnvironment
    ) {}
}
