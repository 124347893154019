import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/header';
import { RdcButtonDirective } from "shared-directives";
import { IconComponent, ValidatedControlComponent } from "shared-ui";

import { AccountComponent } from './account.component';


@NgModule({
    imports: [
        CommonModule,
        ValidatedControlComponent,
        ReactiveFormsModule,
        HeaderModule,
        RdcButtonDirective,
        IconComponent,
    ],
    declarations: [ AccountComponent ],
    exports: [
        AccountComponent,
    ],
})
export class AccountModule {}
