import { CommonModule } from "@angular/common";
import {
    ChangeDetectorRef,
    Component,
    ComponentRef,
    EmbeddedViewRef, inject, Renderer2,
    TemplateRef, Type,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { ToastType } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

@Component({
    standalone: true,
    selector: 'rdc-apps-toast',
    templateUrl: './toast.component.html',
    styleUrls: [ './toast.component.scss' ],
    encapsulation: ViewEncapsulation.None,
    imports: [ CommonModule ],
})
export class ToastComponent {

    @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef | undefined;

    @ViewChild('defaultToast', { static: true }) defaultToast: TemplateRef<unknown> | undefined;

    missingElementsError = 'container or defaultToast elements missing in template!';

    constructor(private renderer: Renderer2) {}

    closing = false;

    cdr = inject(ChangeDetectorRef);

    simpleToast(
        type: ToastType,
        body: string,
        timeout: number | false
    ): EmbeddedViewRef<unknown> {
        this.closing = false;

        if (!this.container || !this.defaultToast) {
            throw new Error(this.missingElementsError);
        }

        const templateRef = this.container.createEmbeddedView(this.defaultToast, { type, body });

        // default time in this instance as user cannot close otherwise
        if (timeout) {
            this.applyTimeout(templateRef, timeout || 5000);
        }

        return templateRef;
    }

    toastComponent<T>(
        type: ToastType,
        component: Type<T>,
        timeout: number | false
    ): ComponentRef<T> {
        this.closing = false;

        if (!this.container || !this.defaultToast) {
            throw new Error(this.missingElementsError);
        }

        const componentRef = this.container.createComponent(component);

        this.renderer.addClass(componentRef.location.nativeElement, type);

        this.applyTimeout(componentRef, timeout);

        return componentRef;
    }

    toastTemplate<T>(
        type: ToastType,
        template: TemplateRef<T>,
        timeout: number | false
    ): EmbeddedViewRef<T> {
        this.closing = false;

        if (!this.container || !this.defaultToast) {
            throw new Error(this.missingElementsError);
        }

        const templateRef = this.container.createEmbeddedView(template);

        this.cdr.detectChanges();

        this.renderer.addClass(templateRef.rootNodes[0], type);

        this.applyTimeout(templateRef, timeout);

        return templateRef;
    }

    private applyTimeout<T>(ref: EmbeddedViewRef<T> | ComponentRef<T>, timeout: number | false): void {
        if (!timeout) {
            return;
        }
        setTimeout(() => {
            // apply the closing animation
            this.closing = true;

            setTimeout(() => {
                // destroy after the closing animation
                ref.destroy();
            },250);

        }, timeout);
    }

}
