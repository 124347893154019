import { Pipe, PipeTransform } from "@angular/core";
import { UserDetails } from 'rdc-apex-store';

@Pipe({ name: 'hasCloudAccess', standalone: true })
export class HasCloudAccessPipe implements PipeTransform {

    transform(userDetails: UserDetails | undefined | null, ...clouds: string[]): boolean {

        if(!userDetails) {
            return false;
        }

        return clouds.every((cloud) => userDetails.organisation.activeCloudCodes.includes(cloud));
    }
}
