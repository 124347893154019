import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { RepoItem, ToastType } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { ToastService } from "@rdc-apps/rdc-shared/src/lib/utilities";
import {
    AutocompleteOption
,
    PreferencesGroup,
    PreferencesGroupType
} from "rdc-apex-store";


@Component({
    selector: 'rdc-apps-default-preferences-group',
    templateUrl: './default-preferences-group.component.html',
    styleUrls: [ './default-preferences-group.component.scss' ],
})
export class DefaultPreferencesGroupComponent implements OnInit {

    @Input() form!: FormGroup;
    @Input() givenFormGroupName!: string;
    @Input() autocompleteResults: AutocompleteOption[] = [];
    @Input() index!: number;
    @Input() editMode!: boolean;
    @Input() groupType!: string;

    @Output() cancel = new EventEmitter<void>();
    @Output() addGroup = new EventEmitter<PreferencesGroup>();
    @Output() autocomplete = new EventEmitter<string>();

    validationMessages = {
        required: 'This field is required',
    };

    showValidation = false;
    preferencesGroupRawValue!: PreferencesGroup[];

    readonly GROUP_TYPE = PreferencesGroupType;

    get groupsArray(): FormArray {
        return this.form.get(`${this.givenFormGroupName}.${this.index}.selections`) as FormArray;
    }

    get preferenceFormGroup(): FormGroup {
        return this.form.get(`${this.givenFormGroupName}.${this.index}`) as FormGroup;
    }

    constructor(private toast: ToastService) {}

    ngOnInit(): void {
        this.preferencesGroupRawValue = JSON.parse(JSON.stringify(this.form.get(`${this.givenFormGroupName}`)?.getRawValue()));
    }

    onAutoComplete(query: string): void {
        this.autocomplete.emit(query);
    }

    onSelect(item: Partial<RepoItem<unknown>>): void {
        const group = new FormGroup({
            id: new FormControl(item.id),
            label: new FormControl(item.label),
            iataCode: new FormControl(item['iataCode']),
            icaoCode: new FormControl(item['icaoCode']),
        });

        if (this.groupsArray.value.some((val: { id: string | number }): boolean => val.id === item.id)) {
            this.toast.simpleToast(ToastType.WARN, `${this.groupType} already added`, 5000);

            return;
        } else {
            this.groupsArray.push(group);
        }
    }

    onCancel(): void {
        if (this.editMode) {
            this.groupsArray.clear();

            this.preferencesGroupRawValue.forEach((preferenceGroup, preferenceIndex): void => {
                if (this.index === preferenceIndex) {
                    preferenceGroup.selections.forEach((preferenceSelection): void => {
                        const group = new FormGroup({
                            id: new FormControl(preferenceSelection.id),
                            label: new FormControl(preferenceSelection.label),
                            iataCode: new FormControl(preferenceSelection.iataCode),
                            icaoCode: new FormControl(preferenceSelection.icaoCode),
                        });

                        this.groupsArray.push(group);
                    });

                    this.preferenceFormGroup.patchValue(preferenceGroup);
                }
            });
        }

        this.cancel.emit();
    }

    onDeleteGroupItem(index: number): void {
        this.groupsArray.removeAt(index);
    }

    onAddGroup(): void {
        if (this.editMode) {
            if (!this.groupsArray.length) {
                this.preferenceFormGroup.get(`${this.groupType.toLowerCase()}s`)?.setErrors({ required: true });
                this.showValidation = true;

                return;
            }

            if (!this.preferenceFormGroup.get('name')?.value) {
                this.preferenceFormGroup.get('name')?.setErrors({ required: true });
                this.showValidation = true;

                return;
            }
        }

        if (this.preferenceFormGroup.invalid) {

            this.showValidation = true;

            return;
        }

        this.showValidation = false;

        this.addGroup.emit(this.preferenceFormGroup.value);
    }

}
