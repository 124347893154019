import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { RdcButtonDirective, RdcIconButtonDirective } from "shared-directives";
import { IconComponent, ValidatedControlComponent } from "shared-ui";

import { TagManagementItemComponent } from './tag-management-item.component';


@NgModule({
    imports: [ CommonModule, RdcButtonDirective, IconComponent, ReactiveFormsModule, ValidatedControlComponent, ColorPickerModule, RdcIconButtonDirective ],
    declarations: [ TagManagementItemComponent ],
    exports: [
        TagManagementItemComponent,
    ],
})
export class TagManagementItemModule {}
