<ng-container *ngFor="let filter of autocompleteTypeFilters">

    <section class="rdc-template-filter-container" *ngIf="!disabledControls.includes(filter) && showFilterControls.includes(filter)">

        <div class="rdc-template-filter" [class.locked]="locked">

            <label class="text-hidden rdc-template-filter-type" *ngIf="!locked">
                <span>{{ filter | titlecase }}</span>
                <rdc-apps-select
                    controlName="type"
                    [maskSelected]="disabledControls.includes(filter) ? { label: 'Defined by series' } : null"
                    [disabled]="disabledControls.includes(filter) || displayFilterIndex > 0"
                    [markAsTouched]="false"
                    [options]="optionsForFilter.get(filter) || []"
                    [controlGroup]="filtersFormGroup(filter)"
                    (valueSelected)="onFilterTypeChange(filter)">
                </rdc-apps-select>
            </label>

            <ng-container *ngIf="!['all', null].includes(filtersFormGroup(filter, 'type')?.value)">

                <label class="text-hidden rdc-template-filter-selection">
                    <span>{{ filter | titlecase }}</span>

                    <ng-container *ngIf="filterGroupSelected(filter)[displayFilterIndex] else selectOptions">
                        <button class="rdc-select-value-button" rdcAppsButton (click)="onRemoveSelected(filter, displayFilterIndex)">
                            <span class="template-filter-selected-label">{{ filterGroupSelected(filter)[displayFilterIndex].label }}</span>
                            <rdc-apps-icon size="sm" class="cancel">cancel</rdc-apps-icon>
                        </button>
                    </ng-container>

                    <ng-template #selectOptions>

                        <ng-container [ngTemplateOutlet]="dropDownFilters.get(filtersFormGroup(filter).value.type) ? selectable : autoCompletable"></ng-container>
                        <ng-template #selectable>
                            <rdc-apps-select
                                placeholder="Add filter"
                                [options]="dropDownFilters.get(filtersFormGroup(filter).value.type) || []"
                                (valueSelected)="onSelect(filter, $event)">
                            </rdc-apps-select>
                        </ng-template>
                        <ng-template #autoCompletable>
                            <rdc-apps-autocomplete
                                placeholder="Select {{ filter }}"
                                [options]="autoCompleteResults$ | async"
                                [showValidation]="false"
                                [focusOnEvent]="{ focusEmitter: optionChangedEvent, identifier: filter }"
                                (autocomplete)="onAutocomplete(filtersFormGroup(filter, 'type').value, $event)"
                                (valueSelected)="onUserSelect(filter, $event)">
                            </rdc-apps-autocomplete>
                        </ng-template>

                    </ng-template>

                </label>

            </ng-container>

        </div>

    </section>

</ng-container>
