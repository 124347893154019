import { Observable, takeUntil } from 'rxjs';

import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { HeaderModule } from "@rdc-apps/rdc-apex/src/lib/settings/ui/header";
import { Clouds } from '@rdc-apps/rdc-apex/src/lib/shared/constants';
import { RepoItem, ToastType } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { RdcComponentUtils, ToastService } from '@rdc-apps/rdc-shared/src/lib/utilities';
import { getUserDetailsData ,
    createCloudDefaultsFailure,
    getCloudDefaults,
    getCloudOriginAirline,
    getCloudOriginAirport,
    PreferencesSelection
,
    AutocompleteDispatch,
    AutocompleteOption
} from 'rdc-apex-store';
import { IconComponent, SelectAutocompleteModule } from "shared-ui";

@Component({
    selector: 'rdc-apps-cloud-management',
    standalone: true,
    imports: [
        CommonModule,
        HeaderModule,
        RouterLink,
        IconComponent,
        SelectAutocompleteModule,
        ReactiveFormsModule,
    ],
    templateUrl: './cloud-management.component.html',
    styleUrl: './cloud-management.component.scss',
})
export class CloudManagementComponent
    extends RdcComponentUtils
    implements OnInit
{
    @Input() form!: FormGroup;
    @Input() formGroup!: FormGroup;
    @Input() autocompleteResults: AutocompleteOption[] = [];

    @Output() autocomplete = new EventEmitter<AutocompleteDispatch>();

    cloudOriginAirport$!: Observable<PreferencesSelection | undefined>;
    cloudOriginAirline$!: Observable<PreferencesSelection | undefined>;
    userAccessableCloud: string[] = [];
    dialogRef = inject(DialogRef);
    clouds = Clouds;

    constructor(
        private store$: Store,
        private toastService: ToastService,
        actions: Actions
    ) {
        super(actions);
        this.store$.dispatch(getCloudDefaults());

        this.cloudOriginAirport$ = this.store$.select(getCloudOriginAirport);
        this.cloudOriginAirline$ = this.store$.select(getCloudOriginAirline);
    }

    ngOnInit(): void {
        // TODO: replace with has-cloud-access pipe
        this.store$
            .select(getUserDetailsData('userDetailsState'))
            .subscribe(
                (userData) =>
                    (this.userAccessableCloud =
                        userData?.organisation.activeCloudCodes || [])
            );

        this.onActions([ createCloudDefaultsFailure ], () => {
            this.formGroup.get('cloudDefaultAirport')?.reset();

            this.toastService.simpleToast(
                ToastType.ERROR,
                'Unable to update default airport',
                5000
            );
        });

        this.cloudOriginAirport$
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((originAirport) =>
                this.formGroup.patchValue({
                    cloudDefaultAirport: originAirport,
                })
            );

        this.cloudOriginAirline$
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((originAirline) =>
                this.formGroup.patchValue({
                    cloudDefaultAirline: originAirline,
                })
            );
    }

    onAutoComplete(query: string, type: string): void {
        this.autocomplete.emit({
            query,
            filter: type.toLowerCase(),
        });
    }

    onUpdateCloudDefaultAirline(
        originAirline: Partial<RepoItem<unknown>>
    ): void {
        this.formGroup.patchValue({ cloudDefaultAirline: originAirline });
    }

    onUpdateCloudDefaultAirport(
        originAirport: Partial<RepoItem<unknown>>
    ): void {
        this.formGroup.patchValue({ cloudDefaultAirport: originAirport });
    }

    onClearDefault(controlName: string) {
        this.formGroup.get(controlName)?.reset();
        this.formGroup.get(controlName)?.markAsTouched();
    }

    closeCloudDialog() {
        this.dialogRef.close();
    }
}
