import { takeUntil } from "rxjs";

import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { HeaderModule } from "@rdc-apps/rdc-apex/src/lib/settings/ui/header";
import { mapHighQuality } from "@rdc-apps/rdc-shared/src/lib/constants";
import { RdcComponentUtils } from "@rdc-apps/rdc-shared/src/lib/utilities";
import * as Highcharts from "highcharts/highmaps";
import { ColorPickerModule, ColorPickerService } from "ngx-color-picker";
import {
    getMapPresetColours, updateMapColours, updateMapColourSuccess,
} from "rdc-apex-store";
import { RdcButtonDirective, TooltipDirective } from "shared-directives";
import { LoadingOverlayComponent } from "shared-ui";

@Component({
    selector: 'rdc-apps-map-colours',
    templateUrl: './map-colours.component.html',
    styleUrl: './map-colours.component.scss',
    imports: [
        CommonModule,
        HeaderModule,
        RdcButtonDirective,
        FormsModule,
        ReactiveFormsModule,
        ColorPickerModule,
        LoadingOverlayComponent,
        TooltipDirective
    ],
    providers: [ ColorPickerService ]
})
export class MapColoursComponent extends RdcComponentUtils implements OnInit, OnDestroy, AfterViewInit {

    @Input() mapColourFormGroup!: FormGroup | any;

    @ViewChild('landColour', { static: true }) landColour!: TemplateRef<HTMLDivElement>
    @ViewChild('seaColour', { static: true }) seaColour!: TemplateRef<HTMLDivElement>
    @ViewChild('borderColour', { static: true }) borderColour!: TemplateRef<HTMLDivElement>
    @ViewChild('servedColour', { static: true }) servedColour!: TemplateRef<HTMLDivElement>
    @ViewChild('unservedColour', { static: true }) unservedColour!: TemplateRef<HTMLDivElement>;

    keys = [
        { label: 'Land', type: 'landColour' },
        { label: 'Sea', type: 'seaColour' },
        { label: 'Borders', type: 'borderColour' },
        { label: 'Routes', type: 'servedColour' },
        { label: 'Custom', type: 'unservedColour' },
    ];

    resetting = false;

    private store$ = inject(Store);

    private settingsMap!: Highcharts.MapChart;

    tooltipTemplates!: Record<string, TemplateRef<HTMLDivElement>>;

    constructor(actions: Actions) {
        super(actions);
    }

    ngOnInit(): void {
        this.store$.select(getMapPresetColours('defaultPreferencesState')).subscribe((colours) => {
            this.mapColourFormGroup.patchValue(colours);
        });

        this.tooltipTemplates = {
            landColour: this.landColour,
            seaColour: this.seaColour,
            borderColour: this.borderColour,
            unservedColour: this.unservedColour,
            servedColour: this.servedColour,
        }
    }

    ngAfterViewInit() {
        this.map();

        this.mapColourFormGroup.controls.landColour.valueChanges
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((colour: string) => {
            this.settingsMap.series[0].update({ nullColor: colour } as never);
        });

        this.mapColourFormGroup.controls.borderColour.valueChanges
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((colour: string) => {
            this.settingsMap.series[0].update({ borderColor: colour } as never);
        });

        this.mapColourFormGroup.controls.seaColour.valueChanges
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((colour: string) => {
            this.settingsMap.update({ chart: { backgroundColor: colour } });
        });

        this.mapColourFormGroup.controls.servedColour.valueChanges
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((colour: string) => {
            this.settingsMap.series[1].data[0].update({ color: colour });

            this.settingsMap.series[2].data[2].update({ color: colour });
        });

        this.mapColourFormGroup.controls.unservedColour.valueChanges
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((colour: string) => {
            this.settingsMap.series[1].data[1].update({ color: colour });

            this.settingsMap.series[2].data[0].update({ color: colour });
            this.settingsMap.series[2].data[1].update({ color: colour });
        });

        this.onActions([ updateMapColourSuccess ],() => {
            this.resetting = false;
        });
    }

    onReset(): void {
        this.resetting = true;

        this.store$.dispatch(updateMapColours({ mapColours: {
            landColour: '#DEF2DE',
            seaColour: '#E2F7FD',
            borderColour: '#C7DECF',
            unservedColour: '#DA5555',
            servedColour: '#4D66F2',
        } }));
    }

    private map(): void {

        const { landColour, seaColour, borderColour, unservedColour, servedColour } = this.mapColourFormGroup.controls;
        // eslint-disable-next-line
        this.settingsMap = Highcharts.mapChart('settings-colour-map', {
            chart: {
                map: mapHighQuality,
                spacingBottom: 0,
                borderWidth: 0,
                borderColor: '',
                backgroundColor: seaColour.value,
                spacing: [ 0, 0, 0, 0 ],
                animation: false,
            },
            legend: {
                enabled: false,
            },
            title: {
                text: undefined,
            },
            exporting: {
                enabled: false,
            },
            mapNavigation: {
                enabled: false,
                enableButtons: false,
                enableDoubleClickZoom: false,
                enableMouseWheelZoom: false,
                enableTouchZoom: false,
                enableDoubleClickZoomTo: false
            },
            plotOptions: {
                series: {
                    states: {
                        inactive: {
                            opacity: 1
                        },
                        hover: {
                            enabled: false,
                        }
                    }
                }
            },
            tooltip: {
                enabled: false,
            },
            accessibility: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            mapView: {
                projection: {
                    name: 'Miller',
                    rotation: [ -5.8777777777777604 ],
                },
                center: [ 5.8777777777777604, 48.05944444444445 ],
                zoom: 4,
            },
            series: [
                {
                    nullColor: landColour.value,
                    borderColor: borderColour.value,
                },
                {
                    name: 'London flight routes',
                    type: 'mapline',
                    zIndex: 99,
                    data: [
                        {
                            id: "BHX - DUS",
                            color: servedColour.value,
                            lineWidth: 3,
                            geometry: {
                                "type": "LineString",
                                "coordinates": [
                                    [ -1.74777777777778, 52.4536111111111 ],
                                    [ 13.5033333333333, 52.3666666666667 ]
                                ]
                            }
                        },
                        {
                            id: "BHX - NCE",
                            color: unservedColour.value,
                            lineWidth: 3,
                            geometry: {
                                type: "LineString",
                                coordinates: [
                                    [ -1.74777777777778, 52.4536111111111 ],
                                    [ 7.215, 43.6652777777778 ]
                                ]
                            }
                        }
                    ]
                },
                {
                    type: 'mappoint',
                    name: 'Cities',
                    zIndex: 9999,
                    color: unservedColour.value,
                    data: [
                        {
                            name: undefined,
                            id: 'BHX',
                            lon:  -1.74777777777778,
                            lat:  52.4536111111111,
                        },
                        {
                            name: undefined,
                            id: 'NIC',
                            lon:  7.215,
                            lat:  43.6652777777778,
                        },
                        {
                            name: undefined,
                            id: 'BER',
                            color: servedColour.value,
                            lon:  13.5033333333333,
                            lat:  52.3666666666667,
                        },
                    ],
                },
            ] as never,
        });

        this.settingsMap.container.onmousemove = null;
    }


}
