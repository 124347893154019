import { map, of } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import * as cloudActions from '@rdc-apps/rdc-apex/src/lib/shared/data-access/actions';
import { cloudRefreshFailure, cloudRefreshSuccess } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/actions';
import { CloudRefreshService } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/services';
import { ToastType } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { ToastService } from "@rdc-apps/rdc-shared/src/lib/utilities";

@Injectable()
export class CloudRefreshEffects {

    private actions$ = inject(Actions);
    private toast = inject(ToastService);
    private cloudRefreshService = inject(CloudRefreshService);

    refresh$ = createEffect(() =>
        this.actions$.pipe(
            ofType(cloudActions.cloudRefresh),
            fetch({
                run: ({ cloud }) =>
                    this.cloudRefreshService.cloudRefresh(cloud).pipe(
                        map((refresh) =>
                            cloudRefreshSuccess({ refresh })
                        )
                    ),
                onError: () => {
                    this.toast.simpleToast(
                        ToastType.ERROR,
                        'Unable to refresh date',
                        5000
                    );

                    return of(cloudRefreshFailure());
                },
            })
        )
    );

}
