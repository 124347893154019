import { RepoItem } from "@rdc-apps/rdc-shared/src/lib/data-access/models";
import * as Highcharts from "highcharts";

export const highchartsFunctions = (data: (number | null)[][]) => {
    const n = data.length;

    let sumX = 0;
        let sumY = 0;
        let sumXY = 0;
        let sumX2 = 0;

    // Calculate the sums needed for linear regression
    for (let i = 0; i < n; i++) {
        let [ x, y ] = data[i];

        x = x || 0;
        y = y || 0;

        sumX += x;
        sumY += y;
        sumXY += x * y;
        sumX2 += x ** 2;
    }

    // Calculate the slope of the trend line
    const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX ** 2);

    // Calculate the intercept of the trend line
    const intercept = (sumY - slope * sumX) / n;

    const trendline = []; // Array to store the trend line data points

    // Find the minimum and maximum x-values from the scatter plot data
    const minX = Math.min(...data.map(([ x ]) => x || 0));
    const maxX = Math.max(...data.map(([ x ]) => x || 0));

    // Calculate the corresponding y-values for the trend line using the slope
    // and intercept
    trendline.push([ minX, minX * slope + intercept ]);
    trendline.push([ maxX, maxX * slope + intercept ]);

    return trendline;
}

export const legendItemClickLOBF = {
    events: {
        legendItemClick: (event: Highcharts.SeriesLegendItemClickEventObject): void => {
            setTimeout((): void => {
                event.target.xAxis.series.forEach((series: Highcharts.Series, index: number): void => {
                    if (index % 2 === 0) {
                        if (!series.visible) {
                            series.xAxis.series[index + 1].hide()
                        } else {
                            series.xAxis.series[index + 1].show()
                        }
                    }
                });
            });
        }
    }
}

export const rdcGenSeriesForScatter = (
    rdcChartResponse: { columns: { code: string; label: string; colour: string; year?: string }[]; rows: string[][], xAxisLabel: string; yAxisLabel: string },
    rdcTableResponse: string[][],
    arrayItemsPerSeries: number,
    arrayItemsPerTableSeries: number
): any[] => {

    const { columns, rows, xAxisLabel, yAxisLabel } = rdcChartResponse;

    let seriesToProcess = columns.slice(2);
    let sliced = 0;
    let tableSliced = 0;
    const seriesData = [];

    const colours = [ '#43E08C', '#9898E6' ];

    const bestFitItems = rows.filter((row) => row[0].includes('BestFit'));

    const rowsWithoutBestFit = rows.filter((row) => !row[0].includes('BestFit'));

    while (seriesToProcess.length) {

        const tableRowsForSeries: string[][] = [];

        const seriesRowsWithSectorLen = rowsWithoutBestFit
            .filter((row, ind) => {

                if(row[sliced + 2] && row[sliced + 3]) {

                    tableRowsForSeries.push(rdcTableResponse[ind]);

                    return true;
                }

                return false;
        });

        const series = rdcScatterSeries(
            xAxisLabel,
            yAxisLabel,
            seriesToProcess,
            seriesRowsWithSectorLen,
            tableRowsForSeries,
            sliced,
            tableSliced,
            {
                color: colours[seriesData.length / 2],
            });

        const bestFit = rdcBestFit(bestFitItems, sliced, {
            dashStyle: 'Dash',
            showInLegend: false,
            color: '#687287',
            enableMouseTracking: false,
            lineWidth: 1,
            states: {
                hover: {
                    lineWidth: 0
                }
            },
        });

        seriesData.push(series);
        seriesData.push(bestFit);

        seriesToProcess = seriesToProcess.slice(arrayItemsPerSeries);
        sliced = sliced + arrayItemsPerSeries;
        tableSliced = tableSliced + arrayItemsPerTableSeries;
    }

    return seriesData;
}

export const rdcBestFit = (bestFitRows: string[][], sliced: number, seriesConfig: Record<string, unknown>): any => {

    const maxims = bestFitRows[0] || [];
    const mins = bestFitRows[1] || [];

    const lineOfBestFitForSeries = [
        [ Number(mins[sliced + 2]), Number(mins[sliced + 4]) ],
        [ Number(maxims[sliced + 2]), Number(maxims[sliced + 4]) ],
    ];

    return {
        type: 'line',
        name: 'LOBF',
        data: [ ...lineOfBestFitForSeries ],
        marker: {
            enabled: false
        },
        ...seriesConfig, // overrides per use case
    };

}

export const rdcScatterSeries = (
    xAxisLabel: string,
    yAxisLabel: string,
    seriesToProcess: Partial<RepoItem<unknown>>[],
    rowsWithoutBestFit: string[][],
    tableRowsForSeries: string[][],
    sliced: number,
    tableSliced: number,
    seriesConfig: Record<string, unknown>
): any => {

    const mappedData = rowsWithoutBestFit
        .map((row) => {

            const tableRow = tableRowsForSeries.find((ro) => ro[1] === row[1]) || [];

            const parsedRowData = {
                x: Number(row[sliced + 2]),
                y: row[sliced  + 3] === '' ? null : Number(row[sliced  + 3]),
                name: row[0],
                code: row[1],
                tr: tableRow,
                formatted: tableRow[tableSliced + 3],
            };

            return parsedRowData;
        });

    return {
        name: seriesToProcess[0].label,
        id: seriesToProcess[0].code,
        opacity: 0.5,
        marker: {
            symbol: 'circle',
        },
        tooltip: {
            pointFormat: '<strong>{point.name}</strong></br>' +
                `${ yAxisLabel }: {point.formatted}</br>` +
                `${ xAxisLabel }: {point.x}`,
        },
        zIndex: sliced,
        data: mappedData,
        ...seriesConfig
    }

}
