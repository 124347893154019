import { catchError, map, Observable, of, take } from "rxjs";

import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { appEnvironment } from "@rdc-apps/rdc-apex/src/lib/shared/environment";
import { RdcFeature } from "@rdc-apps/rdc-shared/src/lib/constants";

@Injectable({ providedIn: 'root' })
export class FeatureFlagService {

    httpClient = inject(HttpClient);

    env = inject(appEnvironment);

    isEnabled(feature: RdcFeature): Observable<boolean> {
        return this.httpClient.get<{ features: { name: string; enabled: boolean }[] }>(`${ this.env.api.featureUrl }/ui/feature-flags/apex-ng`)
            .pipe(
                take(1),
                map((resp) => {
                    const featureFlag = resp.features.find(({ name }) => name === feature);

                    return !!featureFlag?.enabled;
                }),
                catchError(() => of(false)),
            );
    }

}
