import { catchError, EMPTY, map, Observable } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { AppEnvironment, appEnvironment } from '@rdc-apps/rdc-apex/src/lib/shared/environment';
import { RdcBaseHttpService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { ToastType } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { ToastService } from "@rdc-apps/rdc-shared/src/lib/utilities";

@Injectable({ providedIn: 'root' })
export class UserPasswordService extends RdcBaseHttpService {

    constructor(
        @Inject(appEnvironment) private environment: AppEnvironment,
        private toast: ToastService
    ) {
        super();
    }

    changePassword(password: string): Observable<void> {
        return this.http.post<string>( `${this.environment.api.baseUrl}/Account/user/changePassword`,
            { newPassword: password }, { headers: this.getTokenHeaders() } )
            .pipe(
                map(() => {
                    this.toast.simpleToast(ToastType.SUCCESS, 'Password successfully changed', 5000);

                    return;
                }),
                catchError(() => {
                    this.toast.simpleToast(ToastType.ERROR, 'Something went wrong when changing password', 5000);

                    return EMPTY;
                })
            );
    }

}
