import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { Router, RouterLink } from "@angular/router";
import { SustainabilityLink } from "@rdc-apps/rdc-apex/src/lib/shared/constants";
import { Pendoable } from "@rdc-apps/rdc-shared/src/lib/utilities";
import { IconComponent } from "shared-ui";

@Component({
    standalone: true,
    selector: 'rdc-apps-sustainability-item',
    templateUrl: './sustainability-item.component.html',
    styleUrl: './sustainability-item.component.scss',
    imports: [ CommonModule, IconComponent, RouterLink ],
})
export class SustainabilityItemComponent extends Pendoable {

    router = inject(Router);

    @Input({ required: true }) link!: SustainabilityLink;
}
