import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { RdcButtonDirective, RdcDialogItemDirective, RdcIconButtonDirective } from "shared-directives";
import { IconComponent } from "shared-ui";

@Component({
    standalone: true,
    selector: 'rdc-apps-table-builder-dialog',
    templateUrl: './table-builder-dialog.component.html',
    styleUrl: './table-builder-dialog.component.scss',
    imports: [ CommonModule, RdcDialogItemDirective, RdcButtonDirective, IconComponent, RdcIconButtonDirective ],
})
export class TableBuilderDialogComponent {

    @Output() basic: EventEmitter<boolean> = new EventEmitter();

}
