import { Subject, take } from 'rxjs';

import { ConnectedPosition, Overlay, OverlayConfig, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CommonModule } from "@angular/common";
import {
    ChangeDetectorRef,
    Component,
    ComponentRef,
    Directive,
    ElementRef,
    HostListener, inject,
    Input, OnChanges,
    OnDestroy, Renderer2,
    SimpleChanges,
    TemplateRef, ViewEncapsulation
} from '@angular/core';
import {
    connectedAbove,
    connectedBelow, connectedLeft, connectedRight,
    overlayPositions,
    RdcOverlayPosition
} from '@rdc-apps/rdc-shared/src/lib/constants';


@Component({
    standalone: true,
    selector: 'rdc-apps-tooltip',
    template: `
        <div *ngIf="text && !template" class="text-tooltip" [innerHTML]="text"></div>

        <ng-container *ngIf="!text && template" [ngTemplateOutlet]="template" [ngTemplateOutletContext]="context"></ng-container>
    `,
    styles: `
        :host {
            animation: showToolTip 50ms ease-in-out forwards;
            display: block;
            padding-left: 10px;
        }

        @keyframes showToolTip {
            0% {
                opacity: 0;
                transform: scale(0.8);
            }
            100% {
                opacity: 1;
                transform: scale(1);
            }
        }
    `,
    encapsulation: ViewEncapsulation.Emulated,
    imports: [ CommonModule ],
})
export class TooltipComponent {

    @Input() text: string | null = null;

    @Input() template: TemplateRef<HTMLElement> | null = null;

    @Input() context: Record<string, any> | null = null;

    mouseOut: Subject<void> = new Subject<void>();

    hovering = false;

    @HostListener('mouseenter') onHover(): void {
        this.hovering = true;
    }

    @HostListener('mouseleave') onLeave(): void {
        this.hovering = false;
        this.mouseOut.next();
        this.mouseOut.complete();
    }
}

@Directive({ selector: '[rdcAppsTooltip]', standalone: true })
export class TooltipDirective<T> implements OnDestroy, OnChanges {

    @Input() tooltipText: string | null | undefined = null;

    @Input() tooltipTemplate: TemplateRef<HTMLElement> | null = null;

    @Input() tooltipTemplateContext: Record<string, T> | null = null;

    @Input() tooltipPosition: RdcOverlayPosition = 'right';

    @Input() tooltipDisabled = false;

    @Input() tooltipDelay = 0;

    @Input() tooltipHideDelay = 150;

    @Input() tooltipOptions: OverlayConfig = {};

    private showTimeOut = 0;

    private tooltipRef: ComponentRef<any> | null = null;

    private positions: Map<string, ConnectedPosition[]> = overlayPositions;

    private overlayRef: OverlayRef | undefined;

    private mouseIsOverTarget = false;

    private cdr = inject(ChangeDetectorRef);

    private renderer2 = inject(Renderer2);

    constructor(
        private overlay: Overlay,
        private elementRef: ElementRef<HTMLElement>,
        private overlayPositionBuilder: OverlayPositionBuilder
    ) {}

    ngOnDestroy(): void {
        this.destroy();
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes['tooltipDisabled']?.currentValue === false && this.mouseIsOverTarget) {
            this.onCreateTooltip();
        } else if (changes['tooltipDisabled']?.currentValue === true) {
            this.destroy();
        }
    }

    @HostListener('mouseover', [ '$event' ]) onCreateTooltip(): void {

        if(this.overlayRef || this.tooltipDisabled) {
            return;
        }

        clearTimeout(this.showTimeOut);

        this.showTimeOut = setTimeout(() => {

            const chosenPosition = (this.positions.get(this.tooltipPosition) || [])[0];

            const positionStrategy = this.overlayPositionBuilder
                .flexibleConnectedTo(this.elementRef)
                .withFlexibleDimensions(false)
                .withPositions([
                    chosenPosition || connectedAbove,
                    connectedAbove,
                    connectedRight,
                    connectedBelow,
                    connectedLeft
                ]);

            this.overlayRef = this.overlay.create({
                ...this.tooltipOptions,
                panelClass: [ 'no-padding', 'rdc-apps-tooltip' ],
                hasBackdrop: false,
                disposeOnNavigation: true,
                positionStrategy
            });

            this.overlayRef.hostElement.classList.add('rdc-tooltip');

            const tooltipPortal = new ComponentPortal(TooltipComponent);

            this.tooltipRef = this.overlayRef.attach(tooltipPortal);

            this.tooltipRef.instance.text = this.tooltipText;
            this.tooltipRef.instance.template = this.tooltipTemplate;
            this.tooltipRef.instance.context = this.tooltipTemplateContext;

            this.cdr.detectChanges();

            this.renderer2.listen(this.overlayRef.overlayElement, 'mouseenter', () => {
                this.mouseIsOverTarget = true;
            });

            this.renderer2.listen(this.overlayRef.overlayElement, 'mouseleave', () => {
                this.destroy();
            });

        }, this.tooltipDelay);
    }

    @HostListener('mouseleave', [ '$event' ]) onDestroyTooltip(): void {
        clearTimeout(this.showTimeOut);

        if(!this.tooltipHideDelay) {
            this.destroy();

            return;
        }

        setTimeout(() => {
            if(this.mouseIsOverTarget) {
                return;
            }

            this.destroy();

        }, this.tooltipHideDelay);
    }

    @HostListener('click') clickDestroy(): void {
        this.destroy();
    }

    private destroy(): void {
        clearTimeout(this.showTimeOut);
        this.overlayRef?.detach();
        this.overlayRef?.dispose();
        this.overlayRef = undefined;
        this.mouseIsOverTarget = false;
    }

}
