<rdc-apps-loading-overlay *ngIf="!loaded"></rdc-apps-loading-overlay>

<section class="rdc-template-dialog">

    <header class="rdc-template-dialog-header">
        <h4 class="rdc-template-dialog-header-title">Template details</h4>
        <button
            display="compact"
            rdcAppsButton
            rdcAppsDialogItem>
            Done
        </button>
    </header>

    <div class="rdc-template-dialog-sidebar">
        <p>{{ data.template.label }}</p>

        <div>
            <p>How it could be used...</p>
            <p>{{ data.template.description }}</p>
        </div>

        <div class="rdc-template-dialog-sidebar-footer">
            <span style="flex: auto;">{{ study?.studyType === 'bar' ? 'Column' : study?.studyType | titlecase }}</span>
            <!-- TEMP -->
            <div class="template-type-image">
                <img ngSrc="/assets/rdc-apex/images/{{ study?.studyType }}-chart-ico.svg" fill>
            </div>
        </div>
    </div>

    <div class="rdc-template-dialog-main">

        <h5 class="template-small-title">Configure template</h5>

        <div class="rdc-template-dialog-main-template-string">

            <ng-container *ngFor="let str of languageStr; let i = index">

                <span *ngIf="str.trim().length">{{ str }}</span>

                <ng-container [ngSwitch]="true">

                    <ng-container *ngIf="(matches[i] | templateStrValue) as templateString">

                        <div *ngSwitchCase="(templateString.key.includes('comparison') && !templateString.key.includes('series'))" class="rdc-template-dialog-main-template-string-editable">

                            <ng-container *ngIf="templateString.key | PrependSeriesPipe : queryFormGroup().value as prepend">
                                <span>{{ prepend }}</span>
                            </ng-container>

                            <ng-container *ngFor="let series of seriesAsControls as sAc; let o = index">

                                <div class="edit-button-container">
                                    <button
                                        *ngIf="dynamicEntryText(templateString.key, o, 'series') as dynmaicKey"
                                        rdcAppsButton
                                        [disabled]="!matchDisableMap || matchDisableMap.includes(dynmaicKey)"
                                        [class.active]="editableAreas === dynmaicKey"
                                        class="rdc-template-dialog-main-template-string-editable-item"
                                        (click)="editingMatch = matches[i]; editableAreas = dynmaicKey; editableLabel = templateString.label">
                                        {{ templateValueMap.get(dynmaicKey) || (templateString.label + ' ' + seriesLetters[o]) }}
                                        <span *ngSwitchCase="(o + 1) < (sAc.length - 1)">,</span>
                                    </button>

                                    <span class="append-text" *ngSwitchCase="(o + 1) === (sAc.length - 1)">and</span>
                                </div>

                            </ng-container>

                            <ng-container
                                [ngTemplateOutlet]="addRemoveButtons"
                                [ngTemplateOutletContext]="{
                                    length: chartRequest.value.queryRequest?.chartProperties?.series?.length || 0,
                                    key: templateString.key,
                                    control: 'comparison',
                                }">
                            </ng-container>

                        </div>

                        <div *ngSwitchCase="templateString.key.includes('filters')" class="rdc-template-dialog-main-template-string-editable">

                            <ng-container *ngIf="templateString.key | PrependFilterPipe : chartRequest.value as prepend">
                                <span>{{ prepend }}</span>
                            </ng-container>

                            <ng-container *ngFor="let filters of filterItemArray(filterMap.get(templateString.key) || 0) as sAc; let o = index">

                                <div class="edit-button-container">
                                    <button
                                        *ngIf="dynamicEntryText(templateString.key, o) as dynmaicKey"
                                        rdcAppsButton
                                        class="rdc-template-dialog-main-template-string-editable-item"
                                        [disabled]="!matchDisableMap || matchDisableMap.includes(dynmaicKey)"
                                        [class.active]="editableAreas === dynmaicKey"
                                        (click)="editingMatch = matches[i]; editableAreas = dynmaicKey; editableLabel = templateString.label">
                                        <ng-container *ngIf="templateString.key | isSingleFilter : templateOptions; else withLetter">
                                            {{ templateValueMap.get(dynmaicKey) || filterOverrideMap.get(dynmaicKey) || templateString.label }}
                                        </ng-container>
                                        <ng-template #withLetter>
                                            {{ templateValueMap.get(dynmaicKey) || (filterOverrideMap.get(dynmaicKey) + ' ' + seriesLetters[o]) || templateString.label }}
                                        </ng-template>

                                        <span *ngSwitchCase="(o + 1) < (sAc.length - 1)">,</span>
                                    </button>

                                </div>

                                <span *ngSwitchCase="(o + 1) === (sAc.length - 1)">and</span>

                            </ng-container>

                            <ng-container
                                [ngTemplateOutlet]="addRemoveButtons"
                                [ngTemplateOutletContext]="{
                                    key: templateString.key,
                                    control: 'filter',
                                }">
                            </ng-container>

                        </div>

                        <ng-container *ngSwitchCase="templateString.key.includes('break')">
                            <span style="flex-basis: 100%; margin-bottom: 0;"></span>
                        </ng-container>

                        <ng-container *ngSwitchDefault>

                            <ng-container *ngIf="templateString.key | PrependSeriesPipe : queryFormGroup().value as prepend">
                                <span>{{ prepend }}</span>
                            </ng-container>

                            <button
                                rdcAppsButton
                                [disabled]="!matchDisableMap || matchDisableMap.includes(templateString.key)"
                                [class.active]="editableAreas === templateString.key"
                                class="rdc-template-dialog-main-template-string-editable-item"
                                (click)="editingMatch = matches[i]; editableAreas = templateString.key; editableLabel = templateString.label">
                                {{ templateValueMap.get(templateString.key) || seriesOverrideMap.get(templateString.key) || templateString.label }}
                            </button>

                        </ng-container>

                    </ng-container>

                </ng-container>

            </ng-container>
        </div>

    </div>

    <div class="rdc-template-dialog-main-edit" [style.border-width.px]="editableAreas ? 1 : 0">

        <div class="rdc-template-dialog-main-edit-header" *ngIf="editableAreas">
            <span class="template-small-title">{{  seriesOverrideMap.get(editableAreas) || filterOverrideMap.get(editableAreas) || editableLabel | titlecase }}</span>

            <button
                display="compact"
                rdcAppsButton
                rdcAppsIconButton
                (click)="editableAreas = ''; editableLabel = ''"
                [dialogCloseIconBtn]="true">
                <rdc-apps-icon size="sm">close</rdc-apps-icon>
            </button>
        </div>

        <ng-container *ngIf="dateRangeUtils && (userPrefColours$ | async)">

            <rdc-apps-qb-data-points
                class="rdc-template-datapoints"
                [style.display]="editableAreas === 'datapoint' ? 'block' :  'none'"
                [showGroupPills]="false"
                [showValidation]="false"

                [form]="chartRequest"
                [dataPointsData]="dataPointsData$ | async"
                (salesforceLead)="onRequestUpgrade($event)"
            ></rdc-apps-qb-data-points>

            <rdc-apps-template-query-xaxis
                *ngIf="study?.studyType !== 'scatter'"
                [style.display]="editableAreas === 'xaxis' ? 'block' :  'none'"
                [availableTypeCodes]="templateOptions.xAxisLimits?.availableTypeCodes"
                [availablePartitionCodes]="templateOptions.xAxisLimits?.availablePartitionCodes"

                [dateRangeUtils]="dateRangeUtils"
                [chartRequest]="chartRequest"
                [dataPointsData]="dataPointsData"
                (init)="onFormInit('xAxis')">
            </rdc-apps-template-query-xaxis>

            <rdc-apps-template-query-series-year-range
                #templateSeriesRange
                [style.display]="editableAreas.includes('seriesRange') ? 'block' :  'none'"
                [isOnTrial]="apexTrialMode$ | async"
                [dateRangeUtils]="dateRangeUtils"
                [userColours]="userColours"
                [chartRequest]="chartRequest">
            </rdc-apps-template-query-series-year-range>

            <rdc-apps-template-query-series
                [style.display]="editableAreas.includes('comparison') ? 'block' :  'none'"
                [showSection]="editableAreas"
                [templateOptions]="templateOptions"

                #seriesSelection
                [chartRequest]="chartRequest"
                [isTemplating]="true"
                [dataPoints]="dataPointsData"
                [dropDowns]="dataPointsData?.dropdowns"
                [userColours]="userColours"
                [dateRangeUtils]="dateRangeUtils"
                [autoCompleteResults$]="autoCompleteResults$"
                (init)="onFormInit('series')">
            </rdc-apps-template-query-series>


            <div class="filters-section">

                <rdc-apps-template-query-filters
                    [style.display]="editableAreas.includes('filters') ? 'block' :  'none'"
                    [editableAreas]="editableAreas"
                    [filterLimits]="templateOptions.filterLimits"
                    #tempFilters

                    [chartRequest]="chartRequest"
                    [dropDowns]="dataPointsData?.dropdowns"
                    [autoCompleteResults$]="autoCompleteResults$"
                    (init)="onFormInit('filters')">
                </rdc-apps-template-query-filters>

                <rdc-apps-template-query-time-period
                    [style.display]="editableAreas === 'timeperiod' ? 'block' :  'none'"

                    #timePeriodControls
                    [dateRangeUtils]="dateRangeUtils"
                    [chartRequest]="chartRequest"
                    [autoCompleteResults$]="autoCompleteResults$"
                    (init)="onFormInit('timePeriod')">
                </rdc-apps-template-query-time-period>

                <rdc-apps-query-builder-additional-filters
                    [chartRequest]="chartRequest"
                    [dropDowns]="dataPointsData?.dropdowns"
                    [dataPoints]="dataPointsData?.datapoints || []"
                    (init)="onFormInit('additionalFilters')">
                </rdc-apps-query-builder-additional-filters>

                <rdc-apps-qb-summaries
                    #summariesComponent
                    [queryType]="chartRequest.value.studyType"
                    [form]="queryRequest"
                    [dataPointsData]="dataPointsData$ | async"
                    (init)="onFormInit('summaries')"
                ></rdc-apps-qb-summaries>

            </div>

            <rdc-apps-chart-builder-group-by
                *ngIf="study?.studyType === 'scatter'"
                [inTemplateForm]="true"

                [style.display]="editableAreas === 'groupby' ? 'block' :  'none'"
                [chartRequest]="chartRequest"
                (init)="onFormInit('groupBy')">
            </rdc-apps-chart-builder-group-by>

        </ng-container>

        <ng-container *ngIf="editingMatch | shouldShowButtonIfSeries">
            <div *ngIf="editableAreas | shouldShowButton : templateOptions : queryFormGroup().value : templateValueMap as rm"
                 class="rdc-template-dialog-main-edit-add-remove">
                <button rdcAppsButton
                        (click)="editableRemItem()"
                        *ngIf="rm.remove">
                    <rdc-apps-icon size="xl">remove</rdc-apps-icon>
                </button>
                <button rdcAppsButton
                        (click)="editableAddItem()"
                        colour="primary" *ngIf="rm.add">
                    <rdc-apps-icon size="xl">add</rdc-apps-icon>
                </button>
            </div>
        </ng-container>

    </div>

    <footer class="rdc-template-dialog-footer">

        <button
            rdcAppsButton
            rdcAppsDialogItem
            colour="primary"
            [disabled]="chartRequest.invalid"
            (click)="onRunTemplate()">
            Run query
        </button>

    </footer>

</section>


<ng-template #addRemoveButtons let-key="key" let-index="index" let-length="length" let-control="control">

    <!-- compute if buttons should show -->
    <ng-container *ngIf="key | shouldShowButton : templateOptions : queryFormGroup().value : templateValueMap as shouldShow">

        <button
            *ngIf="shouldShow.remove"
            class="rdc-template-dialog-main-template-string-add-remove"
            display="compact"
            rdcAppsButton
            rdcAppsIconButton
            (click)="onRemoveTemplateItem(control, key)">
            <rdc-apps-icon size="sm">remove</rdc-apps-icon>
        </button>

        <button
            *ngIf="shouldShow.add"
            class="rdc-template-dialog-main-template-string-add-remove"
            display="compact"
            rdcAppsButton
            add-item
            rdcAppsIconButton
            (click)="onAddTemplateItem(length, control, key)">
            <rdc-apps-icon size="sm">add</rdc-apps-icon>
        </button>

    </ng-container>

</ng-template>
