import { distinctUntilChanged, filter } from "rxjs";

import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from "@angular/router";
import { Actions } from "@ngrx/effects";
import { RdcComponentUtils } from "@rdc-apps/rdc-shared/src/lib/utilities";
import { TooltipDirective } from "shared-directives";

import { IconComponent } from "../icon/icon.component";

interface Breadcrumb {
    label: string,
    url: string,
    icon?: string,
    fill?: string,
    toolTipText?: string,
}

@Component({
    selector: 'rdc-apps-breadcrumb',
    standalone: true,
    imports: [ CommonModule, RouterLink, IconComponent, TooltipDirective ],
    templateUrl: './breadcrumb.component.html',
    styleUrl: './breadcrumb.component.scss',
})
export class BreadcrumbComponent extends RdcComponentUtils implements OnInit {

    @Input() breadcrumbIconBgColour = '#4D66F2';

    @ViewChild('iconBgWrapper') iconBgWrapper!: ElementRef<HTMLDivElement>;

    fillOverride: string | undefined;

    breadcrumbs: Breadcrumb[] = [];

    router = inject(Router);
    route = inject(ActivatedRoute);

    constructor(actions: Actions) {
        super(actions);
    }

    ngOnInit(): void {
        this.breadcrumbs = this.createBreadCrumbs(this.route.root);

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                distinctUntilChanged()
            ).subscribe(() => {
                this.breadcrumbs = this.createBreadCrumbs(this.route.root);
            });
    }


    createBreadCrumbs(route: ActivatedRoute, url = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
        const children: ActivatedRoute[] = route.children;

        if (children.length === 0) {
            return breadcrumbs;
        }

        return children.reduce((acc: Breadcrumb[], child: ActivatedRoute) => {
            const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');
            const nextUrl = routeURL ? `${url}/${routeURL}` : url;

            this.fillOverride = child.snapshot.data['breadcrumbFill'] ?? this.fillOverride;

            const breadcrumb: Breadcrumb = {
                label: child.snapshot.data['breadcrumbLabel'],
                ...(child.snapshot.data['icon'] ? { icon: child.snapshot.data['icon'] } : undefined),
                ...(child.snapshot.data['toolTipText'] ? { toolTipText: child.snapshot.data['toolTipText'] } : undefined),
                url: nextUrl
            }

            acc.push(breadcrumb);

            return this.createBreadCrumbs(child, nextUrl, acc)
                .filter((crumb) => crumb.label !== undefined)
                .filter((obj, index, self) =>
                    index === self.findIndex((crumb) => crumb.label === obj.label)
                );

        }, breadcrumbs)
    }

    setBg(firstBreadcrumb: boolean): void {
        if (this.iconBgWrapper && !firstBreadcrumb) {
            this.iconBgWrapper.nativeElement.style.background = this.breadcrumbIconBgColour;
        }
    }

    removeBg(): void {
        if (this.iconBgWrapper) {
            this.iconBgWrapper.nativeElement.removeAttribute('style');
        }
    }
}
