import { SideNavItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

export const Clouds: SideNavItem[] = [
    {
        label: 'Network',
        route: [ '/', 'network' ],
        icon: 'n-icon-network',
        activeIcon: 'n-icon-network-fill',
        cloudType: 'network',
        content: 'Network cloud is here! Tap to get set up and start using this new functionality',
        active: false,
    },
    {
        label: 'Growth',
        route: [ '/', 'growth' ],
        icon: 'n-icon-growth',
        activeIcon: 'n-icon-growth-fill',
        cloudType: 'growth',
        content: 'Growth cloud is here! Tap to get set up and start using this new functionality',
        active: false,
    },
    {
        label: 'Risk',
        route: [ '/', 'risk' ],
        icon: 'n-icon-risk',
        activeIcon: 'n-icon-risk-fill',
        cloudType: 'risk',
        content: 'Risk cloud is here! Tap to get set up and start using this new functionality',
        active: false,
    },
    {
        label: 'Sustainability',
        route: [ '/', 'sustainability' ],
        icon: 'n-icon-sustainability',
        activeIcon: 'n-icon-sustainability-fill',
        cloudType: 'sustainability',
        content: 'Sustainability cloud is here! Tap to get set up and start using this new functionality',
        active: false,
    },
    {
        label: 'Performance',
        route: [ '/', 'performance' ],
        icon: 'n-icon-performance',
        activeIcon: 'n-icon-performance-fill',
        cloudType: 'performance',
        content: 'Performance cloud is here! Tap to get set up and start using this new functionality',
        active: false,
    },
]
