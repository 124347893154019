import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';


import * as GrowthActions from './growth.actions';
import { GrowthEntity } from './growth.models';
import * as TagActions from '../../tags/+state/tags.actions';

export const GROWTH_FEATURE_KEY = 'growth';

export interface GrowthState extends EntityState<GrowthEntity> {
    selectedId?: string | number; // which Growth record has been selected
    loaded: boolean; // has the Growth list been loaded
    error?: string | null; // last known error (if any)
}

export interface GrowthPartialState {
    readonly [GROWTH_FEATURE_KEY]: GrowthState;
}

export const growthAdapter: EntityAdapter<GrowthEntity> =
    createEntityAdapter<GrowthEntity>({
        selectId: (model) => model.routeId,
    });

export const initialGrowthState: GrowthState = growthAdapter.getInitialState({
    // set initial required properties
    loaded: false,
});

const reducer = createReducer(
    initialGrowthState,
    on(
        GrowthActions.getGrowthRouteAction,
        GrowthActions.refreshGrowthRouteAction, (state) => ({
            ...state,
            loaded: false,
            error: null,
        })),
    on(
        GrowthActions.getGrowthRouteSuccessAction,
        GrowthActions.refreshGrowthRouteSuccessAction,
        (state, { routes }) =>
            growthAdapter.setAll(routes, { ...state, loaded: true })
    ),
    on(GrowthActions.updateGrowthRouteAction, (state) => ({
        ...state,
        loaded: false,
        error: null,
    })),
    on(GrowthActions.updateGrowthRouteSuccessAction, (state, { routes }) =>
        growthAdapter.setMany(routes, { ...state, loaded: true })
    ),
    on(GrowthActions.createGrowthRouteAction, (state) => ({
        ...state,
        loaded: false,
        error: null,
    })),
    on(GrowthActions.createGrowthRouteSuccessAction, (state) => ({
        ...state,
        loaded: true,
        error: null,
    })),
    on(GrowthActions.deleteGrowthRouteAction, (state) => ({
        ...state,
        loaded: false,
        error: null,
    })),
    on(GrowthActions.deleteGrowthRouteSuccessAction, (state) => ({
        ...state,
        loaded: true,
        error: null,
    })),
    on(TagActions.createCloudRouteTagSuccess, (state, { tags, routeId }) => {
        const route = state.entities[routeId] ;

        if (!route) {
            return state;
        }

        const copiedTags = route.tags.slice();

        copiedTags.push(...tags);

        return growthAdapter.upsertOne({
            ...route,
            tags: copiedTags,
        },{ ...state });
    }),
    on(TagActions.removeTagsFromCloudRouteSuccess, (state, { tagIds, routeId }) => {
        const route = state.entities[routeId];

        if (!route) {
            return state;
        }

        const remaining = route.tags.filter((tag) => !tagIds.includes(tag.id));

        return growthAdapter.upsertOne({
            ...route,
            tags: remaining,
        },{ ...state });
    })
);

export function growthReducer(state: GrowthState | undefined, action: Action) {
    return reducer(state, action);
}
