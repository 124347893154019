import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { dropIn } from '@rdc-apps/rdc-apex/src/lib/shared/animations';
import { RdcButtonDirective, RdcDialogItemDirective, RdcIconButtonDirective } from "shared-directives";
import { IconComponent } from "shared-ui";

@Component({
    selector: 'rdc-apps-cloud-popup',
    standalone: true,
    imports: [ CommonModule, RdcButtonDirective, RdcDialogItemDirective, IconComponent, RdcIconButtonDirective ],
    templateUrl: './cloud-popup.component.html',
    styleUrls: [ './cloud-popup.component.scss' ],
    animations: [ dropIn ],
})
export class CloudPopupComponent {

    @Input() content!: string;

    @Output() closePopup = new EventEmitter();

}
