import { createAction, props } from '@ngrx/store';
import { ExportData } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { BaseCloudEntity } from "rdc-apex-store";

export const exportCloudRouteAction= createAction(
    '[Cloud Route] Export',
    props<{ routes: BaseCloudEntity[]; exportData: ExportData, cloudType: string }>()
);

export const exportCloudRouteSuccessAction= createAction(
    '[Cloud Route] Export Success',
    props<{ buffer: ArrayBuffer; exportData: ExportData, cloudType: string }>()
);

export const exportCloudRouteFailureAction= createAction(
    '[Cloud Route] Export Failure',
    props<{ error: any }>()
);
