 import { Dialog } from '@angular/cdk/dialog';
 import { CommonModule } from "@angular/common";
import {
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation
} from '@angular/core';
 import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
 import { RouterLink, RouterLinkActive, RouterLinkWithHref } from "@angular/router";
import { animSidebarIcon, animSidebarSvg, shrinkSidebarItem } from '@rdc-apps/rdc-shared/src/lib/animations';
 import { TooltipDirective } from "shared-directives";

 import { IconComponent } from '../icon/icon.component';

@Component({
    standalone: true,
    selector: 'rdc-apps-sidebar-item',
    templateUrl: './sidebar-item.component.html',
    styleUrls: [ './sidebar-item.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
    imports: [
        CommonModule,
        RouterLinkActive,
        TooltipDirective, RouterLink,
        IconComponent,
        BrowserAnimationsModule,
        RouterLinkWithHref,
    ],
    animations: [
        shrinkSidebarItem,
        animSidebarIcon,
        animSidebarSvg,
    ],
})
export class SidebarItemComponent {

    @Input() expanded!: boolean;
    @Input() disabled!: boolean;
    @Input() childItem!: boolean;
    @Input() expands!: boolean;
    @Input() role: 'default' | 'support' | 'account' | 'home' = 'default';
    @Input() accountEmail = 'example@example.com';
    @Input() icon!: string | undefined;
    @Input() activeIcon!: string | undefined;
    @Input() type: 'button' | 'link' = 'link';
    @Input() label = 'Apex home';
    @Input() requireExactRoute = false;
    @Input() route!: string[] | string;
    @Input() queryParams: Record<string, string> = {};
    @Input() isActive!: boolean;
    @Input() menuActive!: boolean;
    @Input() itemId!: string;

    @Output() sideMenuTrigger: EventEmitter<HTMLButtonElement> = new EventEmitter<HTMLButtonElement>();

    constructor(private dialog: Dialog) {}

    onSideMenuButtonClick(triggerButton: HTMLButtonElement): void {
        this.sideMenuTrigger.emit(triggerButton);
    }

    onSideMenuLinkClick(): void {
        if (this.dialog.openDialogs.length) {
            this.dialog.closeAll();
        }
    }

}
