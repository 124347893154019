import { CdkMenu } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { xAxisOptionsForType, xAxisTypeOptions } from '@rdc-apps/rdc-apex/src/lib/shared/constants';
import { TemplateEventService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { ToastService } from "@rdc-apps/rdc-shared/src/lib/utilities";
import { ChartBuilderSeriesSelectionComponent } from "apex-shared-ui";
import { StudyTemplateOptions } from 'rdc-apex-store';
import { RdcButtonDirective } from "shared-directives";
import { IconComponent, SelectAutocompleteModule } from "shared-ui";

@Component({
    standalone: true,
    selector: 'rdc-apps-template-query-series',
    templateUrl: './template-query-series.component.html',
    styleUrls: [ './template-query-series.component.scss' ],
    imports: [
        CommonModule,
        RdcButtonDirective,
        CdkMenu,
        FormsModule,
        IconComponent,
        ReactiveFormsModule,
        SelectAutocompleteModule,
    ],
})
export class TemplateQuerySeriesComponent extends ChartBuilderSeriesSelectionComponent implements OnChanges, OnInit {

    @Input() showSection = '';

    @Input() templateOptions: StudyTemplateOptions = {};

    displayComparisonIndex = 0;
    displaySeriesIndex = 0;

    locked = false;

    constructor(
        fb: FormBuilder,
        store: Store,
        toastService: ToastService,
        cdr: ChangeDetectorRef,
        public templateEventService: TemplateEventService
    ) {
        super(fb, store, toastService, cdr);
    }

    override ngOnInit() {
        const seriesLimits = this.templateOptions.seriesLimits;

        if (seriesLimits?.availableTypeCodes?.length) {
            this.fullSeriesDefTypeOptions = xAxisTypeOptions.filter(({ code }) => seriesLimits.availableTypeCodes?.includes(code as never));
        }

        if (seriesLimits?.availablePartitionCodes?.length) {

            for (const [ key, value ] of xAxisOptionsForType) {

                const filtered = value.filter((val) => seriesLimits.availablePartitionCodes?.includes(val.code as never));

                this.xAxisOptionsForType.set(key, filtered);
            }
        }

        super.ngOnInit();
    }

    ngOnChanges(): void {
        const indexes = this.showSection.match(/\d/g) || [ '0' ];

        this.displayComparisonIndex = Number(indexes[0]);

        const seriesIndex = Number(indexes[1]);

        this.displaySeriesIndex = isNaN(seriesIndex) ? -1 : seriesIndex;

        const comparisonLimit = this.templateOptions.comparisonLimits?.find(
            (compLimit) => compLimit.index === this.displayComparisonIndex);

        this.locked = !!comparisonLimit?.isLocked;
    }

    override onSeriesAutocompleteSelect(control: AbstractControl, index: number, event: Partial<RepoItem<unknown>>): void {
        super.onSeriesAutocompleteSelect(control, index, event);

        this.templateEventService.progress();
    }

    getControlControl(control: AbstractControl, controlName?: string): FormGroup {
        if(!controlName) {
            return control as FormGroup;
        }

        return control.get(controlName) as FormGroup;
    }
}
