import { Observable, takeUntil } from 'rxjs';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Actions } from "@ngrx/effects";
import { Store } from '@ngrx/store';
import { PresetColor } from "@rdc-apps/rdc-apex/src/lib/shared/data-access/models";
import { RdcComponentUtils } from '@rdc-apps/rdc-shared/src/lib/utilities';
import {
    AutocompleteDispatch,
    AutocompleteOption
, DataPointsDropdownData ,
    createAirlineGroup,
    createAirportGroup,
    deleteAirlineGroup,
    deleteAirportGroup,
    DeletePreferenceGroup,
    getAirlineGroups,
    getAirportGroups,
    getDefaultPreferencesAirline,
    getDefaultPreferencesAirport,
    getDefaultPreferencesUnitsMeasurements,
    getPresetColours,
    PreferencesGroup,
    PreferencesGroupType,
    PreferencesSelection,
    UserPreferences
} from "rdc-apex-store";



@Component({
    selector: 'rdc-apps-default-preferences',
    templateUrl: './default-preferences.component.html',
    styleUrls: [ './default-preferences.component.scss' ],
})
export class DefaultPreferencesComponent extends RdcComponentUtils implements OnInit {

    @Input() form!: FormGroup;
    @Input() formGroup!: FormGroup;
    @Input() autocompleteResults: AutocompleteOption[] = [];
    @Input() dataPointsDropDownData!: DataPointsDropdownData | null;

    @Output() autocomplete = new EventEmitter<AutocompleteDispatch>();

    airportGroups$!: Observable<PreferencesGroup[] | undefined>;
    airlineGroups$!: Observable<PreferencesGroup[] | undefined>;
    colourPrefs$!: Observable<PresetColor[] | undefined>;
    unitsMeasurements$!: Observable<UserPreferences | undefined | null>;

    showPreferencesGroup = false;
    editMode = false;
    givenFormGroupName!: string;
    groupType!: PreferencesGroupType;
    index = 0;

    readonly GROUP_TYPE = PreferencesGroupType;

    outputExample = '[LHR] London Heathrow';

    constructor(
        private store$: Store,
        actions: Actions,
    ) {
        super(actions);

        this.store$.dispatch(getAirportGroups());
        this.store$.dispatch(getAirlineGroups());

        this.unitsMeasurements$ = this.store$.select(getDefaultPreferencesUnitsMeasurements('defaultPreferencesState'));
        this.airportGroups$ = this.store$.select(getDefaultPreferencesAirport('defaultPreferencesState'));
        this.airlineGroups$ = this.store$.select(getDefaultPreferencesAirline('defaultPreferencesState'));
        this.colourPrefs$ = this.store$.select(getPresetColours('defaultPreferencesState'));
    }

    get defaultAirportsArray(): FormArray {
        return this.formGroup.get('defaultAirports') as FormArray;
    }

    get defaultAirlinesArray(): FormArray {
        return this.formGroup.get('defaultAirlines') as FormArray;
    }

    ngOnInit(): void {
        this.formGroup?.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed$)
            )
            .subscribe((defaultPreferences) => {
                const { iataCodes, shouldDisplayNames } = defaultPreferences;

                if (iataCodes && shouldDisplayNames) {
                    this.outputExample = '[LHR] London Heathrow';
                }

                if (!iataCodes && shouldDisplayNames) {
                    this.outputExample = 'London Heathrow';
                }

                if (iataCodes && !shouldDisplayNames) {
                    this.outputExample = 'LHR';
                }
            })

        this.unitsMeasurements$
            .pipe(
                takeUntil(this.componentDestroyed$)
            )
            .subscribe((unitsMeasurements) => {
                if (unitsMeasurements) {
                    this.formGroup.patchValue(unitsMeasurements);
                }
            });

        this.airportGroups$
            .pipe(
                takeUntil(this.componentDestroyed$)
            )
            .subscribe((groups) => {
                this.buildPreferences('defaultAirports', groups || []);
            });

        this.airlineGroups$
            .pipe(
                takeUntil(this.componentDestroyed$)
            )
            .subscribe((groups) => {
                this.buildPreferences('defaultAirlines', groups || []);
            });

        this.colourPrefs$
            .pipe(
                takeUntil(this.componentDestroyed$)
            )
            .subscribe((colours) => {
                const formArray = (this.formGroup.get('presetColours') as FormArray);

                formArray.clear();

                for(const colour of colours || []) {
                    formArray.push(new FormControl(colour));
                }
            });
    }

    onEdit(index: number, groupType: PreferencesGroupType): void {
        this.showPreferencesGroup = true;
        this.editMode = true;

        this.index = index;
        this.givenFormGroupName = groupType === PreferencesGroupType.Airport
            ? 'defaultAirports'
            : 'defaultAirlines';
        this.groupType = groupType;
    }

    onDelete(group: DeletePreferenceGroup, groupType: PreferencesGroupType): void {
        const { groupId } = group.group;

        if (!groupId) {
            return;
        }

        if (groupType === PreferencesGroupType.Airport) {
            this.defaultAirportsArray.removeAt(group.index);

            this.store$.dispatch(deleteAirportGroup({ groupId }));
        } else {
            this.defaultAirlinesArray.removeAt(group.index);

            this.store$.dispatch(deleteAirlineGroup({ groupId }));
        }
    }

    addFormGroup(formArray: FormArray): FormGroup {
        return new FormGroup({
            name: new FormControl('', Validators.required),
            isDefaultGroup: new FormControl(formArray.length === 0),
            selections: new FormArray([], Validators.required),
        });
    }

    onAddAirport(): void {
        this.addPreference(PreferencesGroupType.Airport, 'defaultAirports', this.defaultAirportsArray);
    }

    onAddAirline(): void {
        this.addPreference(PreferencesGroupType.Airline, 'defaultAirlines', this.defaultAirlinesArray);
    }

    addPreference(groupType: PreferencesGroupType, formGroupName: string, formArray: FormArray): void {
        this.showPreferencesGroup = true;
        this.groupType = groupType;
        this.givenFormGroupName = formGroupName;

        this.index = 0;

        if (formArray.length > 0) {
            this.index = formArray.length;
        }

        formArray.push(this.addFormGroup(formArray));
    }

    onAddGroup(group: PreferencesGroup): void {
        this.showPreferencesGroup = false;
        this.editMode = false;

        if (this.groupType === PreferencesGroupType.Airport) {
            this.setDefaultGroup(this.defaultAirportsArray);

            this.store$.dispatch(createAirportGroup({ group }));
        }

        if (this.groupType === PreferencesGroupType.Airline) {
            this.setDefaultGroup(this.defaultAirlinesArray);

            this.store$.dispatch(createAirlineGroup({ group }));
        }
    }

    onCancel(): void {
        if (this.editMode) {
            this.showPreferencesGroup = false;
            this.editMode = false;

            return;
        }

        this.showPreferencesGroup = false;

        if (this.groupType === PreferencesGroupType.Airport) {
            this.defaultAirportsArray.removeAt(this.index);
        }

        if (this.groupType === PreferencesGroupType.Airline) {
            this.defaultAirlinesArray.removeAt(this.index);
        }

    }

    onAutoComplete(query: string, type: string): void {
        this.autocomplete.emit({
            query,
            filter: type.toLowerCase(),
        });
    }

    setDefaultGroup(formArray: FormArray): void {
        if (formArray.controls[this.index].get('isDefaultGroup')?.value === false) {
            return;
        }

        formArray.controls.forEach((control, index): void => {
            if (index !== this.index) {
                if (control.get('isDefaultGroup')?.value) {
                    control.get('isDefaultGroup')?.patchValue(false);
                }
            }
        });
    }

    buildPreferences(controlName: string, preferencesGroups: PreferencesGroup[]): void {
        const formArray = (this.formGroup.get(`${controlName}`) as FormArray);

        if(!formArray) {
            return;
        }

        formArray.clear();

        preferencesGroups.forEach((preferenceGroup, index): void => {

            const group = new FormGroup({
                groupId: new FormControl(preferenceGroup.groupId),
                name: new FormControl(preferenceGroup.name),
                isDefaultGroup: new FormControl(preferenceGroup.isDefaultGroup),
                selections: new FormArray([]),
            });

            formArray.push(group);

            preferenceGroup.selections.forEach((preferencesSelection: PreferencesSelection): void => {
                const selection: FormGroup = new FormGroup({
                    id: new FormControl(preferencesSelection.id),
                    label: new FormControl(preferencesSelection.label),
                    iataCode: new FormControl(preferencesSelection.iataCode),
                    icaoCode: new FormControl(preferencesSelection.icaoCode),
                    threeLetterCode: new FormControl(preferencesSelection.threeLetterCode)
                });

                (formArray.controls[index].get('selections') as FormArray).push(selection);
            });
        });
    }
}
