import { debounce, firstValueFrom, interval, Observable, Subscription, takeUntil } from 'rxjs';

import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
    ɵFormGroupValue
} from '@angular/forms';
import { Store } from '@ngrx/store';
import {
    QbFiltersDateRangeUtils,
    QueryBuilderUtils,
    QuerySummariser
} from '@rdc-apps/rdc-apex/src/lib/query-builder/utilities';
import {
    chartBuilderChartOpts,
    hideAircraftGroupCodes,
    seriesTimePeriodOptsForXMonth,
    seriesTimePeriodOptsForXYear,
    xAxisOptionsForType,
    xAxisSubOptions,
    xAxisTypeOptions
} from '@rdc-apps/rdc-apex/src/lib/shared/constants';
import {
    ApexStudy, QueryRequestChartProps,
    QueryRequestChartSeries,
    QueryRequestChartSeriesDefs,
    QueryRequestChartXAxis,
    QueryRequestTimePeriodSpecific
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { overlayPositions } from '@rdc-apps/rdc-shared/src/lib/constants';
import { RepoItem, ToastType } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { getIsAppLoading } from '@rdc-apps/rdc-shared/src/lib/data-access/store/app-loading';
import { RdcComponentUtils, ToastService } from "@rdc-apps/rdc-shared/src/lib/utilities";
import { ColorPickerModule } from 'ngx-color-picker';
import {
    DataPointsEntity, QbDropDowns,
    autocomplete,
    AutocompleteOption,
    multiAutocomplete, APP_STUDY_BUILDER, APP_TEMPLATE_BUILDER
} from 'rdc-apex-store';
import { RdcButtonDirective, RdcIconButtonDirective, TooltipDirective } from "shared-directives";
import { IconComponent, SelectAutocompleteModule } from "shared-ui";

@Component({
    standalone: true,
    selector: 'rdc-apps-chart-builder-series-selection',
    templateUrl: './chart-builder-series-selection.component.html',
    styleUrls: [ './chart-builder-series-selection.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RdcButtonDirective,
        IconComponent,
        ColorPickerModule,
        CdkMenuModule,
        TooltipDirective,
        NgOptimizedImage,
        SelectAutocompleteModule,
        RdcIconButtonDirective,
    ],
})
export class ChartBuilderSeriesSelectionComponent extends RdcComponentUtils implements OnInit {

    @Input() chartRequest!: FormGroup<ApexStudy>;

    @Input() autoCompleteResults$!: Observable<AutocompleteOption[]>;

    @Input() dateRangeUtils!: QbFiltersDateRangeUtils;

    @Input() dataPoints!: DataPointsEntity | undefined;

    @Input() dropDowns!: QbDropDowns | undefined;

    @Input() userColours: string[] = [];

    @Input() isTemplating = false;

    @Output() init = new EventEmitter<void>();

    fullSeriesDefTypeOptions = xAxisTypeOptions.slice(1);

    seriesDefTypeOptions: Partial<RepoItem<string>>[][] = [ this.fullSeriesDefTypeOptions.slice() ];

    xAxisOptionsOverrides: Partial<RepoItem<string>>[][] = [];

    selectOptionsForComparison: Partial<RepoItem<string | number>>[][] = [];

    xAxisOptionsForType = new Map(xAxisOptionsForType);

    xAxisSubOptions = xAxisSubOptions;

    persistPartitions = [
        ...xAxisOptionsForType.get('timePeriod') || [],
        ...xAxisOptionsForType.get('emissionsScheme') || [],
        ...xAxisOptionsForType.get('originDestination') || [],
    ].map(({ code }) => code);

    chartBuilderChartOpts = chartBuilderChartOpts;

    seriesDefTypeSubs: (Subscription | undefined)[] = [];
    seriesDefPartitionSubs: (Subscription | undefined)[] = [];

    seriesLetters = [ 'A', 'B', 'C', 'D', 'E', 'F' ];

    colourPickerControl: AbstractControl | null = new FormControl<string>('');

    dropDownFilters = new Map<string, Partial<RepoItem<string | number>>[]>();

    overlayPositions = overlayPositions;

    mappedForSelect: { label: string | undefined; options: Partial<RepoItem<unknown>>[] }[] = [];

    allValue = {
        dataType: 'All',
        code: 'allDestinations',
    } as RepoItem<string>;

    allOriginValue =  {
        ...this.allValue,
        label: 'All origins',
    }
    allDestinationValue =  {
        ...this.allValue,
        label: 'All destinations',
    }

    showStaticOption = true;

    constructor(
        private fb: FormBuilder,
        private store: Store,
        private toastService: ToastService,
        private cdr: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {

        this.dropDownFilters
            .set('airlineType', this.dropDowns?.airlineTypes || [ ])
            .set('model', this.dropDowns?.aircraftModels || [ ])
            .set('family', this.dropDowns?.aircraftFamilies || [ ]);

        this.queryFormGroup('chartProperties')?.valueChanges
            .pipe(debounce(() => interval(10)))
            .subscribe(() => this.subscribeToSeriesDefControls());

        this.queryFormGroup('chartProperties').patchValue({ singleSeriesColour: this.userColours[0] });

        this.chartRequest.get('queryRequest.filters.timePeriod')?.valueChanges
            .pipe(debounce(() => interval(0)))
            .subscribe((tpValue) => {

                const spansMultipleYrs = this.contextualiseYearSelectionLabel(tpValue.specific || {});

                this.filterSeriesDefOptions();

                this.getFormArrayControls('series')?.forEach((series, index) => {
                    this.setLabelForSelectedSeries(series.value, index, { spansMultipleYrs });
                });
            });

        this.xAxisSubOptions.set('year', this.dateRangeUtils.selectableYearsAsRepoItems().map((year) => ({
            ...year,
            code: String(year.code),
        })));

        this.queryFormGroup('dataPoints').valueChanges
            .subscribe(() => {

                this.filterSeriesDefOptions()
            });

        this.queryFormGroup('chartProperties')
            .valueChanges
            .pipe(debounce(() => interval(25)))
            .subscribe((xAxis: ɵFormGroupValue<QueryRequestChartProps>) => {

                // Cant have the same control on both xAxis and a series def (unless time period), so reset the series
                xAxis.seriesDefinitions?.forEach(async(sd, ind) => {

                    const timePeriod: string = this.queryFormGroup('filters','timePeriod','type').value;

                    if(sd.type === 'timePeriod' && xAxis?.xAxis?.type === 'timePeriod' && timePeriod === 'specific') {
                        return;
                    }

                    if ((sd.type && xAxis.xAxis?.type) && (sd.type === xAxis.xAxis.type)) {

                        const isLoading = await firstValueFrom(this.store.select(getIsAppLoading(APP_STUDY_BUILDER, APP_TEMPLATE_BUILDER)));

                        if (!isLoading) {
                            this.toastService.simpleToast(ToastType.WARN, 'Series selection has been affected by this change', 5000);
                        }

                        this.getFormArrayControls('seriesDefinitions')?.at(ind)?.reset({ type: null });
                    }
                });

                // because time period type control will have been reset on change, need to resubscribe
                this.queryFormGroup('filters','timePeriod','type').valueChanges.subscribe(() => {
                    this.filterSeriesDefOptions();
                });

                this.filterSeriesDefOptions();
            });

        this.queryFormGroup('chartProperties','type').valueChanges.subscribe((type: 'single' | 'comparison') => {

            if (type === 'comparison') {

                const tpInXAxis = QueryBuilderUtils.timePeriodInXAxis(this.chartRequest);

                this.queryFormGroup('chartProperties').removeControl('singleSeriesColour');

                this.queryFormGroup('chartProperties').setControl('series', this.fb.nonNullable.array([
                    this.fb.nonNullable.group({
                        label: this.fb.nonNullable.control('New series', Validators.required),
                        chartType: this.fb.nonNullable.control(tpInXAxis ? 'line' : 'column'),
                        colour: this.fb.control(this.userColours[0] || '#43E08C', Validators.required),
                        attributeSelections: this.fb.nonNullable.array([]),
                    }),
                ], Validators.required));

                this.queryFormGroup('chartProperties').setControl('seriesDefinitions', this.fb.nonNullable.array([
                    this.fb.nonNullable.group({
                        type: this.fb.nonNullable.control(null, Validators.required),
                        partition: this.fb.nonNullable.control(null, Validators.required),
                    }),
                ], Validators.required));

                this.queryFormGroup('chartProperties', 'series')?.valueChanges
                    .subscribe((series: ɵFormGroupValue<QueryRequestChartSeries>[]) => {
                        series.forEach((ser, index) => this.setLabelForSelectedSeries(ser, index));

                        // show the static option by default
                        // if we have 2 series definitions then loop through each series and check if there are duplicate attribute selections
                        // if so remove the static option for that particular series
                        if (this.queryFormGroup<FormArray>('chartProperties', 'seriesDefinitions').length === 2) {

                            this.showStaticOption = true;

                            const hasBothAll = !!this.queryFormGroup<FormArray>('chartProperties', 'series').controls.find(({ value }) => value.attributeSelections[0]?.selected?.code?.includes('all') && value.attributeSelections[1]?.selected?.code?.includes('all'));

                            const hasAllInOneUndefined2nd = !!this.queryFormGroup<FormArray>('chartProperties', 'series').controls.find(({ value }) => {

                                const hasAllInOne = value.attributeSelections.find((val: any) => val?.selected?.code?.includes('all'));
                                const otherUndefined = value.attributeSelections.findIndex((val: any) => !val) >= 0;

                                return hasAllInOne && otherUndefined;
                            });

                            if(hasBothAll && hasAllInOneUndefined2nd) {
                                this.showStaticOption = false;
                            }

                            return;
                        }

                        const hasAllInOneSingle = !!this.queryFormGroup<FormArray>('chartProperties', 'series').controls.find(({ value }) =>
                            value.attributeSelections.find((val: any) => val?.selected?.code?.includes('all'))
                        );

                        if (this.queryFormGroup<FormArray>('chartProperties', 'seriesDefinitions').length === 1 && hasAllInOneSingle) {
                            this.showStaticOption = false;
                        }

                    });

                this.filterSeriesDefOptions();

                this.subscribeToSeriesDefControls();

                return;
            }

            this.queryFormGroup('chartProperties')
                .setControl('singleSeriesColour', this.fb.nonNullable.control(this.userColours[0] || '#43E08C', Validators.required));

            this.queryFormGroup('chartProperties').removeControl('series');
            this.queryFormGroup('chartProperties').removeControl('seriesDefinitions');
        });

        this.chartRequest.get('queryRequest.chartProperties')?.valueChanges
            .pipe(debounce(() => interval(0)))
            .subscribe(({ series = [] }) => {
                this.preventDuplicateSeriesSelections(series);
            });

        this.init.emit();

        this.autoCompleteResults$
            .pipe(
                takeUntil(this.componentDestroyed$)
            )
            .subscribe((value) => {
                this.mappedForSelect = this.setOriginDestinationOptions(value);
            });
    }


    private preventDuplicateSeriesSelections(series: ɵFormGroupValue<QueryRequestChartSeries>[]): void {

        if(series?.length) {

            series.forEach((ser, ind) => {

                const selectionsForSeries = {
                    attributeSelections: ser.attributeSelections,
                    emissionsSchemeSelection: ser.emissionsSchemeSelection,
                    timePeriodSelection: ser.timePeriodSelection?.selected,
                }

                for(let i = (series.length - 1); i >= 0; i--) {

                    const selections = {
                        attributeSelections: series[i].attributeSelections,
                        emissionsSchemeSelection: series[i].emissionsSchemeSelection,
                        timePeriodSelection: series[i].timePeriodSelection?.selected,
                    }

                    const attrSel = series[i].attributeSelections || [];

                    const hasAllSet = (attrSel?.filter((sel) => sel).length === attrSel?.length);

                    const hasNothingForAll = (attrSel.length === 0) && !series[i].emissionsSchemeSelection && !series[i].timePeriodSelection?.selected;

                    const hasUnsetAttr = (!hasAllSet && !series[i].emissionsSchemeSelection && !series[i].timePeriodSelection?.selected);

                    if (!hasAllSet || hasNothingForAll || hasUnsetAttr) {
                        return;
                    }

                    if( (i > ind) && JSON.stringify(selections) === JSON.stringify(selectionsForSeries) ) {

                        const seriesFormControl = (this.chartRequest.get('queryRequest.chartProperties.series') as FormArray)?.at(i);

                        seriesFormControl?.get('attributeSelections')?.reset([], { emitEvent: false });

                        seriesFormControl?.patchValue({
                            label: 'Select series filter',
                            timePeriodSelection: { selected: null },
                            emissionsSchemeSelection: null,
                        });

                        this.chartRequest.updateValueAndValidity();

                        this.showStaticOption = true;

                        this.toastService.simpleToast(ToastType.DEFAULT, 'Series duplicates another, the duplicated series has been reset.', 5000);
                    }

                }
            });

        }

    }

    subscribeToSeriesDefControls(): void {

        this.clearTypePartitionsSubscriptions();

        this.getFormArrayControls('seriesDefinitions')?.forEach((control, index) => {

            const typeSubscription = control.get('type')?.valueChanges.subscribe((type) => {

                const seriesDefFormGroup = this.queryFormGroup<FormArray>('chartProperties','seriesDefinitions');

                seriesDefFormGroup.at(index).patchValue({ type }, { emitEvent: false });

                if (type === 'emissionsScheme') {

                    seriesDefFormGroup.at(index).get('partition')?.clearValidators();

                    seriesDefFormGroup.at(index).patchValue({ partition: 'emissionsScheme' });

                    return;
                }
                seriesDefFormGroup.at(index).get('partition')?.addValidators(Validators.required);

                seriesDefFormGroup.at(index).patchValue({ partition: null });
            });

            const partitionSubscription = control.get('partition')?.valueChanges.subscribe((partition: string) => {

                let seriesDefinitionsValues = this.queryFormGroup(
                    'chartProperties',
                    'seriesDefinitions'
                ).value as ɵFormGroupValue<QueryRequestChartSeriesDefs>[];

                if (!this.persistPartitions.includes(partition)) {

                    this.queryFormGroup<FormArray>('chartProperties','series').controls.forEach((series) => {

                        const ctrl = (series.get('attributeSelections') as FormArray).controls;

                        if(ctrl.at(index)) {
                            ctrl.at(index)?.reset({ selection: null });
                        } else {
                            ctrl.at(index - 1)?.reset({ selection: null });
                        }

                    });
                }

                seriesDefinitionsValues = seriesDefinitionsValues.map((sd, ind) => ({
                    ...sd,
                    partition: ind === index ? partition : sd.partition,
                }));

                this.assignControlsToSeriesItems(seriesDefinitionsValues);

                if (seriesDefinitionsValues[index].type === 'timePeriod') {
                    control.get('timePeriodSelection')?.patchValue({ selected: null });

                    this.cdr.detectChanges();

                    return; // no need to reset if it's timePeriod (it would reset the incorrect control)
                }

            });

            this.seriesDefTypeSubs.push(typeSubscription);
            this.seriesDefPartitionSubs.push(partitionSubscription);
        });
    }

    private contextualiseYearSelectionLabel(specificValues: ɵFormGroupValue<QueryRequestTimePeriodSpecific>): boolean {

        if (this.dateRangeUtils.rangeSpansTwoYears(specificValues)) {
            const mapped = this.dateRangeUtils.selectableYearsAsRepoItems().map((year) => {

                const plusOne = ((year.code || 0) + 1);

                return {
                    ...year,
                    code: String(year.code),
                    label: `${ year.code }/${ String(plusOne).substring(2) }`,
                };
            });

            this.xAxisSubOptions.set('year', mapped);

            return true;
        }

        this.xAxisSubOptions.set('year', this.dateRangeUtils.selectableYearsAsRepoItems().map((year) => ({
            ...year,
            code: String(year.code),
        })));

        return false;
    }

    private assignControlsToSeriesItems(seriesDefinitions: ɵFormGroupValue<QueryRequestChartSeriesDefs>[]): void {

        this.filterSeriesDefOptions();

        this.queryFormGroup<FormArray>('chartProperties','series').controls.forEach((control) => {

            const previous = control.value;

            const attArr = ((control as FormGroup).get('attributeSelections') as FormArray);

            attArr?.clear();

            let deleteTimePeriod = true;
            let deleteEmissionScheme = true;

            seriesDefinitions.forEach((comparison) => {

                if (comparison.type === 'timePeriod') {

                    deleteTimePeriod = false;

                    (control as FormGroup).setControl('timePeriodSelection', this.fb.nonNullable.group({
                        partition: this.fb.nonNullable.control(comparison.partition, Validators.required),
                        selected: this.fb.nonNullable.control(previous.timePeriodSelection?.selected, Validators.required),
                    }));

                    return;
                }

                if (comparison.type === 'emissionsScheme') {

                    deleteEmissionScheme = false;

                    if(control.get('emissionsSchemeSelection')) {
                        return;
                    }

                    (control as FormGroup).setControl(
                        'emissionsSchemeSelection',
                        this.fb.nonNullable.control(previous.emissionsSchemeSelection, Validators.required)
                    );

                    return;
                }

                const i = previous.attributeSelections.findIndex((ab: any) => (ab?.type === comparison.type));

                attArr?.push(new FormControl(previous.attributeSelections[i], Validators.required));

            });

            if (deleteTimePeriod) {
                (control as FormGroup).removeControl('timePeriodSelection');
            }

            if (deleteEmissionScheme) {
                (control as FormGroup).removeControl('emissionsSchemeSelection');
            }
        });

    }

    private adjustForDropdownSelections(index: number): number {
        const seriesDefinitionsValues = this.queryFormGroup('chartProperties', 'seriesDefinitions').value;

        const i = seriesDefinitionsValues.filter((seriesDef: any) => [ 'timePeriod', 'emissionsScheme' ].includes(seriesDef.type || ''));

        const indexToUse = (index - i.length) < 0 ? 0 : (index - i.length);

        return indexToUse;
    }

    queryFormGroup<T = FormGroup>(...additionalPaths: string[]): T {
        const path = additionalPaths.length ? `queryRequest.${ additionalPaths.join('.') }` : 'queryRequest';

        return this.chartRequest.get(path) as T;
    }

    getFormArrayControls(path: string): AbstractControl[] {
        return (this.chartRequest.get<string>(`queryRequest.chartProperties.${ path }`) as FormArray)?.controls;
    }

    onAutocomplete(type: string, query: string): void {
        this.store.dispatch(autocomplete({ filter: type, query }));
    }

    onMultiAutocomplete(query: string): void {
        const types = [
            "Airport",
            "City",
            "Country",
            "Region",
            "SubRegion",
            "Continent"
        ];

        if (query.length > 1) {
            this.store.dispatch(multiAutocomplete({ filters: types, query, limit: 5 }));
        }
    }

    onAddSeries(index: number): void {

        const seriesCount = this.queryFormGroup<FormArray>('chartProperties','series')?.controls.length || 0;

        const series = this.fb.nonNullable.group({
            label: this.fb.nonNullable.control('New series', Validators.required),
            chartType: this.fb.nonNullable.control('column'),
            colour: this.fb.control(this.userColours[seriesCount] || '#43E08C', Validators.required),
            attributeSelections: this.fb.nonNullable.array([]),
        });

        this.queryFormGroup<FormArray>('chartProperties','series').insert(index + 1, series);

        this.assignControlsToSeriesItems(this.queryFormGroup<FormArray>('chartProperties','seriesDefinitions').value);

        // check if we have 2 seriesDefinitions to enable the static option
        if (this.queryFormGroup<FormArray>('chartProperties', 'seriesDefinitions').length > 1) {
            this.showStaticOption = true;
        }
    }

    onAddComparison(): void {
        this.showStaticOption = true;

        this.queryFormGroup<FormArray>('chartProperties','seriesDefinitions').push(
            this.fb.nonNullable.group({
                type: this.fb.nonNullable.control(null, Validators.required),
                partition: this.fb.nonNullable.control(null, Validators.required),
            }), { emitEvent: false }
        );

        const attributeValues: RepoItem<string>[] = [];

        this.getFormArrayControls('series').forEach((series) => {
            attributeValues.push((series.get('attributeSelections') as FormArray).value);
        });

        this.seriesDefTypeOptions.push(this.fullSeriesDefTypeOptions.slice()); // add a new set of controls

        this.subscribeToSeriesDefControls();

        this.assignControlsToSeriesItems(this.queryFormGroup<FormArray>('chartProperties','seriesDefinitions').value);

        this.getFormArrayControls('series').forEach((series, ind) => {
            series.get('attributeSelections')?.patchValue(attributeValues[ind]);
        });

        this.queryFormGroup<FormArray>('chartProperties','seriesDefinitions').markAsUntouched();
    }

    onRemoveComparison(index: number, type: string): void {

        const seriesDefinitions = this.getFormArrayControls('seriesDefinitions');

        seriesDefinitions.at(index)?.reset({ type: null, partition: null }, { emitEvent: false });

        if (index === 0 && seriesDefinitions.length === 1) {

            this.queryFormGroup<FormArray>('chartProperties','series').controls.forEach((control) => {
                const currentColour = control.value.colour;

                control.reset({
                    colour: currentColour,
                });
            });

            return;
        }

        this.clearTypePartitionsSubscriptions();

        this.queryFormGroup<FormArray>('chartProperties','seriesDefinitions').removeAt(index);

        const attributeValues: RepoItem<string>[] = [];

        this.getFormArrayControls('series').forEach((series) => {
            if (![ 'timePeriod', 'emissionsScheme' ].includes(type)) {
                (series.get('attributeSelections') as FormArray).removeAt(index);
            }

            attributeValues.push((series.get('attributeSelections') as FormArray).value);
        });

        this.assignControlsToSeriesItems(this.queryFormGroup('chartProperties', 'seriesDefinitions').value);

        this.subscribeToSeriesDefControls();

        this.getFormArrayControls('series').forEach((series, ind) => {
            series.get('attributeSelections')?.patchValue(attributeValues[ind]);
        });

        this.queryFormGroup<FormArray>('chartProperties','seriesDefinitions').markAsUntouched();

        if (this.queryFormGroup<FormArray>('chartProperties', 'seriesDefinitions').length === 1) {
            this.showStaticOption = false;
        }
    }

    //*
    // Recalculates the remaining options for selection within the series selection based on the users selections.
    // */
    private filterSeriesDefOptions(): void {

        const datapoint = this.queryFormGroup('dataPoints','dataPointCodes').value[0];
        const seriesDefsFormArray = this.queryFormGroup<FormArray>('chartProperties','seriesDefinitions');

        this.seriesDefTypeOptions = this.seriesDefTypeOptions.map(() => {

            let options = this.fullSeriesDefTypeOptions.slice();

            if (!QueryBuilderUtils.isInDataPointGroups([ 'sustainability' ], datapoint, this.dataPoints)) {
                options = this.clearControlIfPresent(seriesDefsFormArray, options,'emissionsScheme');
            }

            if (QueryBuilderUtils.isInDataPointGroups(hideAircraftGroupCodes, datapoint, this.dataPoints)) {
                options = this.clearControlIfPresent(seriesDefsFormArray, options,'aircraft');
            }

            return options;
        }); // reset to all options

        this.xAxisOptionsOverrides = [];

        const xAxis: ɵFormGroupValue<QueryRequestChartXAxis> = this.queryFormGroup('chartProperties','xAxis').value;
        const timePeriod: string = this.queryFormGroup('filters','timePeriod','type').value;
        const seriesDefinitions: ɵFormGroupValue<QueryRequestChartSeriesDefs>[] = this.queryFormGroup('chartProperties','seriesDefinitions')?.value || [];

        this.selectOptionsForComparison = seriesDefinitions.map((def) => xAxisSubOptions.get(def.partition || '') || []);

        this.seriesDefTypeOptions = this.seriesDefTypeOptions.map((arr, index) => {

            const seriesDefsClone = [ ...seriesDefinitions ];

            // remove the current item from what will be our filter,
            // as we need to be able to select it in the instance it is used.
            seriesDefsClone.splice(index, 1);

            const usedSeriesDefinitions = seriesDefsClone.map((sDef) => sDef.type);

            if ([ 'season', 'quarter', 'year', 'calendarMonth' ].includes(xAxis.partition || '') && timePeriod === 'specific') {

                if (seriesDefinitions[index]?.type === 'timePeriod') {
                    // season and quarter use the same options
                    let overrides = xAxis.partition === 'year' ? seriesTimePeriodOptsForXYear : seriesTimePeriodOptsForXMonth;

                    overrides = overrides.filter(({ code }) => this.xAxisOptionsForType.get('timePeriod')?.find((c) => c.code === code) );

                    this.xAxisOptionsOverrides[index] = overrides;
                }

                return arr.filter((option) => !usedSeriesDefinitions.includes(option.code));
            }

            usedSeriesDefinitions.push(xAxis.type);

            if (seriesDefinitions[index]?.type === 'timePeriod') {

                // season and quarter use the same options
                const overrides = [ ...seriesTimePeriodOptsForXYear, ...seriesTimePeriodOptsForXMonth ]
                    .filter(({ code }) => this.xAxisOptionsForType.get('timePeriod')?.find((c) => c.code === code) );

                this.xAxisOptionsOverrides[index] = overrides;
            }

            return arr.filter((option) => !usedSeriesDefinitions.includes(option.code));
        });

        seriesDefsFormArray?.controls.forEach((control, index) => {

            if(control.value.type === 'timePeriod') {
                this.queryFormGroup<FormArray>('chartProperties','series')?.controls.forEach((ctrl) => {
                    ctrl.patchValue({ timePeriodSelection: { partition: seriesDefinitions[index]?.partition } }, { emitEvent: false });
                });
            }

            if (control.touched) {
                this.cdr.detectChanges();

                control?.markAllAsTouched();
            }
        });
    }

    private clearControlIfPresent(seriesDefsFormArray: FormArray, options: Partial<RepoItem<string>>[], controlName: string) {

        seriesDefsFormArray?.controls.forEach(async (control: any) => {
            if (control.value.type === controlName) {
                control.patchValue({ type: null });

                const isLoading = await firstValueFrom(this.store.select(getIsAppLoading(APP_STUDY_BUILDER, APP_TEMPLATE_BUILDER)));

                if (!isLoading) {
                    this.toastService.simpleToast(ToastType.WARN, 'Series selection has been affected by this change', 5000);
                }
            }
        });

        return options.filter(({ code }) => code !== controlName);
    }

    onSelected(control: AbstractControl, index: number, event: Partial<RepoItem<unknown>>): void {
        const array = (control.get('attributeSelections') as FormArray);
        const seriesDefinition = this.queryFormGroup<FormArray>('chartProperties', 'seriesDefinitions')?.at(index).value;

        const ind = this.adjustForDropdownSelections(index);

        this.queryFormGroup<FormArray>('chartProperties', 'seriesDefinitions').at(index).patchValue({ partition: 'originDestination' })

        if(array.at(ind)) {
            array.at(ind).setValue({
                type: seriesDefinition.type,
                partition: event.dataType,
                selected: event,
            });
        } else {
            array.push(new FormGroup({
                type: new FormControl(seriesDefinition.type),
                partition: new FormControl(event.dataType),
                selected: new FormControl(event),
            }));
        }

        const seriesArray: any[] = []
        const seriesControls = this.queryFormGroup<FormArray>('chartProperties', 'series').controls;

        seriesControls.forEach((seriesControl) => {
            seriesArray.push(seriesControl.value)
        });

        // show the static option by default
        // if we have 2 series definitions then loop through each series and check if there are duplicate attribute selections
        // if so remove the static option for that particular series
        if (this.queryFormGroup<FormArray>('chartProperties', 'seriesDefinitions').length === 2) {

            let indexAt0Value;
            let indexAt1Value;

            this.showStaticOption = true;

            if (this.queryFormGroup<FormArray>('chartProperties', 'series').length > 1) {

                seriesArray.forEach((item) => {
                    indexAt0Value = !!item.attributeSelections[0]
                    indexAt1Value = !!item.attributeSelections[1]
                })

                if (indexAt0Value) {
                    if (this.checkSeriesAttrSelection(seriesArray)) {
                        this.showStaticOption = false;
                    }
                }

                if (indexAt1Value) {
                    if (this.checkSeriesAttrSelection(seriesArray)) {
                        this.showStaticOption = false;
                    }
                }
            }

            return;
        }

        if (this.queryFormGroup<FormArray>('chartProperties', 'seriesDefinitions').length === 1) {
            seriesArray.some((item) => {
                if (item.attributeSelections.length === 1 && item.attributeSelections[0]?.partition === 'All') {
                    this.showStaticOption = false;
                }
            });
        }
    }

    // check each attribute selection to see if the partition value is All
    checkSeriesAttrSelection(array: any[]) {

        for (const item of array) {
            const selection = item.attributeSelections

            if (selection && selection.length === 1) {
                if (selection[0]?.partition === 'All') {
                    return true;
                }
            }

            if (selection && selection.length === 2) {
                if (selection.every((select: any): boolean => select?.partition === 'All')) {
                    return true;
                }
            }
        }

        return false;
    }

    onMetricCategory(category: Partial<RepoItem<unknown>>, index: number): void {
        const seriesAtIndex = this.queryFormGroup<FormArray>('chartProperties','series').controls.at(0) || {} as AbstractControl;

        if (category.code === 'origin') {
            this.showStaticOption = false;

            setTimeout(() => {
                this.onSelected(seriesAtIndex, index, this.allOriginValue);
            });
        }

        if (category.code === 'destination') {
            this.showStaticOption = false;

            setTimeout(() => {
                this.onSelected(seriesAtIndex, index, this.allDestinationValue);
            });
        }
    }

    onSeriesAutocompleteSelect(control: AbstractControl, index: number, event: Partial<RepoItem<unknown>>): void {
        const array = (control.get('attributeSelections') as FormArray);
        const seriesDefinition = this.queryFormGroup<FormArray>('chartProperties', 'seriesDefinitions')?.at(index).value;

        const ind = this.adjustForDropdownSelections(index);

        if(array.at(ind)) {

            array.at(ind).setValue({
                type: seriesDefinition.type,
                partition: seriesDefinition.partition,
                selected: event,
            });

            return;
        }

        array.push(new FormGroup({
            type: new FormControl(seriesDefinition.type),
            partition: new FormControl(seriesDefinition.partition),
            selected: new FormControl(event),
        }));
    }

    showSeriesCompare(value: string): boolean {
        return [ 'origin', 'destination' ].includes(value);
    }

    getAttributeValue(control: AbstractControl, index: number): RepoItem<string> | undefined {
        const array = (control.get('attributeSelections') as FormArray);

        const ind = this.adjustForDropdownSelections(index);

        return array.at(ind)?.value?.selected;
    }

    onRemoveAttributeSelection(control: AbstractControl, index: number): void {
        const array = (control.get('attributeSelections') as FormArray);

        const ind = this.adjustForDropdownSelections(index);

        this.showStaticOption = true;

        return array.at(ind).reset();
    }

    private clearTypePartitionsSubscriptions(): void {
        this.seriesDefTypeSubs.forEach((sub) => sub?.unsubscribe());
        this.seriesDefPartitionSubs.forEach((sub) => sub?.unsubscribe());

        this.seriesDefTypeSubs = [];
        this.seriesDefPartitionSubs = [];
    }

    onRemoveSeries(index: number): void {
        this.queryFormGroup<FormArray>('chartProperties', 'series').removeAt(index);

        if (this.queryFormGroup<FormArray>('chartProperties', 'seriesDefinitions').length === 1) {

            const seriesControls = this.queryFormGroup<FormArray>('chartProperties','series').controls;

            this.showStaticOption = !seriesControls.some((control) => control.get('attributeSelections')?.value[0]?.partition === 'All');
        }
    }

    private setLabelForSelectedSeries(
        series: ɵFormGroupValue<QueryRequestChartSeries>,
        index: number,
        options: Record<string, any> = {}
    ): void {

        this.queryFormGroup<FormArray>('chartProperties', 'series')
            .at(index)
            .patchValue({ label: QuerySummariser.createLabelForSeries(series, options) }, { emitEvent: false });
    }

    get showChartTypeSelection(): boolean {
        const chartValue = this.chartRequest.value;

        return this.isSingleSeries && chartValue.studyType !== 'scatter';
    }

    get isSingleSeries(): boolean {
        const chartValue = this.chartRequest.value;

        return chartValue.queryRequest?.chartProperties?.type === 'single';
    }

    setColourPickerControl(control: AbstractControl | null): void {
        this.colourPickerControl = control;
    }

    private setOriginDestinationOptions(routes: Partial<RepoItem<unknown>>[]): { label: string | undefined; options: Partial<RepoItem<unknown>>[] }[] {

        const airports: Partial<RepoItem<unknown>>[] = [];
        const countries: Partial<RepoItem<unknown>>[] = [];
        const continents: Partial<RepoItem<unknown>>[] = [];
        const cites: Partial<RepoItem<unknown>>[] = [];
        const regions: Partial<RepoItem<unknown>>[] = [];
        const subRegions: Partial<RepoItem<unknown>>[] = [];

        routes.forEach((route): void => {

            if (route.dataType === 'Airport') {
                airports.push({
                    label: route.label,
                    iataCode: route.iataCode,
                    icaoCode: route.icaoCode,
                    id: route.id,
                    dataType: route.dataType,
                    type: route.dataType,
                    code: 'airport',
                });
            }
            if (route.dataType === 'Country') {
                countries.push({
                    label: route.label,
                    iataCode: route.iataCode,
                    icaoCode: route.icaoCode,
                    id: route.id,
                    dataType: route.dataType,
                    type: route.dataType,
                    code: 'country',
                });
            }
            if (route.dataType === 'Continent') {
                continents.push({
                    label: route.label,
                    iataCode: route.iataCode,
                    icaoCode: route.icaoCode,
                    id: route.id,
                    dataType: route.dataType,
                    type: route.dataType,
                    code: 'continent',
                });
            }
            if (route.dataType === 'City') {
                cites.push({
                    label: route.label,
                    iataCode: route.iataCode,
                    icaoCode: route.icaoCode,
                    id: route.id,
                    dataType: route.dataType,
                    type: route.dataType,
                    code: 'city',
                });
            }
            if (route.dataType === 'Region') {
                regions.push({
                    label: route.label,
                    iataCode: route.iataCode,
                    icaoCode: route.icaoCode,
                    id: route.id,
                    dataType: route.dataType,
                    type: route.dataType,
                    code: 'region',
                });
            }
            if (route.dataType === 'Subregion') {
                subRegions.push({
                    label: route.label,
                    iataCode: route.iataCode,
                    icaoCode: route.icaoCode,
                    id: route.id,
                    dataType: route.dataType,
                    type: route.dataType,
                    code: 'subRegion',
                });
            }
        });

        const fullList = [
            { label: 'Airport', options: airports },
            { label: 'Country', options: countries },
            { label: 'Continent', options: continents },
            { label: 'City', options: cites },
            { label: 'Region', options: regions },
            { label: 'Sub-Region', options: subRegions },
        ];

        return fullList.map((list) =>
            ({
                ...list,
            })
        );
    }
}
