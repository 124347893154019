import { ConnectedPosition, Overlay, OverlayConfig, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
    AfterViewInit,
    ComponentRef,
    Directive,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges
} from '@angular/core';
import { CloudPopupService } from "@rdc-apps/rdc-apex/src/lib/shared/utilities";
import { overlayPositions, RdcOverlayPosition } from '@rdc-apps/rdc-shared/src/lib/constants';
import { CloudPopupComponent } from 'apex-shared-ui';

@Directive({
    selector: '[rdcAppsCloudPopup]',
    standalone: false
})
export class CloudPopupDirective implements AfterViewInit, OnChanges, OnDestroy {

    @Input() cloudParentComponentInstance!: any;
    @Input() cloudPopupPosition: RdcOverlayPosition = 'right';
    @Input() sidebarMenuTriggered!: boolean;
    @Input() sidebarMenuOpen!: boolean;
    @Input() sideMenuExpanded!: boolean;

    overlayConfig: OverlayConfig = { width: 160 };
    delay = 1250;
    cloudRef: ComponentRef<CloudPopupComponent> | null = null;

    private overlayRef!: OverlayRef;
    private positions: Map<string, ConnectedPosition[]> = overlayPositions;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private overlay: Overlay,
        private overlayPositionBuilder: OverlayPositionBuilder,
        private cloudPopupService: CloudPopupService,
    ) {}

    ngOnDestroy(): void {
        this.overlayRef?.dispose();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes['sideMenuExpanded'] && changes['sideMenuExpanded'].currentValue !== changes['sideMenuExpanded'].previousValue ||
            changes['sidebarMenuTriggered'] && changes['sidebarMenuTriggered'].previousValue === true ||
            changes['sidebarMenuOpen'] && changes['sidebarMenuOpen'].currentValue !== changes['sidebarMenuOpen'].previousValue ||
            changes['sidebarMenuOpen'] && !changes['sidebarMenuOpen'].firstChange && changes['sidebarMenuOpen'].currentValue !== changes['sidebarMenuOpen'].previousValue
        ) {
            if (this.overlayRef !== undefined) {
                setTimeout(() => {
                    this.renderCloudPopup();
                });
            }
        }
    }

    ngAfterViewInit(): void {

        this.createCloudPopup();

        this.cloudParentComponentInstance.cloudTrigger.subscribe(() => {
            if (this.overlayRef !== undefined) {
                this.overlayRef.dispose();

                // disable remaining overlayRefs
                this.cloudPopupService.removeRemainingPopups();
            }
        });

        setTimeout(() => {
            if (this.cloudRef !== undefined) {

                this.cloudRef?.instance.closePopup.subscribe(() => {
                    this.overlayRef.dispose();
                });
            }
        },this.delay);
    }

    createCloudPopup(): void {
        if (this.cloudParentComponentInstance.cloudActive
            // only trigger the popup on home page url
            && window.location.pathname.split('/')[1] === 'home'
            && this.cloudParentComponentInstance.cloudOnboarded !== undefined
            && !this.cloudParentComponentInstance.cloudOnboarded
        ) {
            const positionStrategy = this.overlayPositionBuilder
                .flexibleConnectedTo(this.elementRef)
                .withPositions(this.positions.get(this.cloudPopupPosition) || []);

            this.overlayRef = this.overlay.create({
                ...this.overlayConfig,
                positionStrategy,
                disposeOnNavigation: true,
            });

            this.overlayRef.hostElement.classList.add('rdc-cloud-popup-overlay');

            const cloudPopupPortal = new ComponentPortal(CloudPopupComponent);

            setTimeout(() => {
                this.cloudRef = this.overlayRef?.attach(cloudPopupPortal);
                this.cloudRef.instance.content = this.cloudParentComponentInstance.cloudContent;
            }, this.delay);
        }
    }

    renderCloudPopup(): void {
        if (this.overlayRef !== undefined) {
            this.overlayRef.addPanelClass('rdc-cloud-popup');

            setTimeout(() => {
                this.overlayRef.removePanelClass('rdc-cloud-popup');
                this.overlayRef.updatePosition();
            },250);
        }
    }
}
