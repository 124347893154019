import { debounce, filter, interval, Observable, of, take, takeUntil, withLatestFrom } from 'rxjs';

import { Dialog, DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule, Location, NgOptimizedImage } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { QbFiltersDateRangeUtils, QueryBuilderUtils } from '@rdc-apps/rdc-apex/src/lib/query-builder/utilities';
import { QueryBuilderType, QueryRequestChartProps } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { QueryService } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/services';
import { TemplateEventService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { CustomValidators } from '@rdc-apps/rdc-shared/src/lib/custom-validators';
import { sendActivity } from '@rdc-apps/rdc-shared/src/lib/data-access/actions';
import { RegisteredInterestService } from '@rdc-apps/rdc-shared/src/lib/data-access/local-storage';
import { appLoaded, AppLoadStatus, getIsAppLoading } from '@rdc-apps/rdc-shared/src/lib/data-access/store/app-loading';
import { RdcDialogHelper } from '@rdc-apps/rdc-shared/src/lib/utilities';
import {
    ChartBuilderComponent,
    ChartBuilderGroupByComponent, ChartLoader,
    QbDataPointsComponent,
    QbSummariesComponent, QueryBuilderAdditionalFiltersComponent
} from "apex-shared-ui";
import {
    runApexTrialMode, StudyTemplateOptions, TemplateSetup, QueryEntity,
    FiltersDateRangeStoreModule,
    getLoadedDateRange
    , getDefaultPreferences, APP_USER_PREFERENCES, APP_STUDY_TEMPLATES
} from 'rdc-apex-store';
import { RdcButtonDirective, RdcDialogItemDirective, RdcIconButtonDirective } from "shared-directives";
import { ConfirmDialogComponent, IconComponent, LoadingOverlayComponent } from "shared-ui";

import {
    CreateArrayPipe,
    PrependFilterPipe,
    PrependSeriesPipe,
    ShouldShowButton,
    ShouldShowButtonIfSeries,
    SingleFilterItemPipe
} from './prepend-filter.pipe';
import { TemplateStringValuePipe } from './template-string-value.pipe';
import { TemplateHelper } from './TemplateHelper';
import { TemplateQueryFiltersComponent } from '../template-query-filters/template-query-filters.component';
import { TemplateQuerySeriesComponent } from '../template-query-series/template-query-series.component';
import { TemplateQuerySeriesYearRangeComponent } from '../template-query-series-year-range/template-query-series-year-range.component';
import { TemplateQueryTimePeriodComponent } from '../template-query-timeperiod/template-query-time-period.component';
import { TemplateQueryXaxisComponent } from '../template-query-xaxis/template-query-xaxis.component';


@Component({
    selector: 'rdc-apps-templated-chart-builder',
    templateUrl: './templated-chart-builder.component.html',
    styleUrls: [ './templated-chart-builder.component.scss' ],
    imports: [
        CommonModule,
        QbDataPointsComponent,
        QbSummariesComponent,
        ChartBuilderGroupByComponent,
        FiltersDateRangeStoreModule,
        RdcDialogItemDirective,
        RdcButtonDirective,
        IconComponent,
        TemplateQueryFiltersComponent,
        TemplateQuerySeriesComponent,
        TemplateStringValuePipe,
        PrependFilterPipe,
        TemplateQueryXaxisComponent,
        TemplateQueryTimePeriodComponent,
        QueryBuilderAdditionalFiltersComponent,
        PrependSeriesPipe,
        LoadingOverlayComponent,
        ShouldShowButton,
        ShouldShowButtonIfSeries,
        NgOptimizedImage,
        TemplateQuerySeriesYearRangeComponent,
        SingleFilterItemPipe,
        RdcIconButtonDirective,
        CreateArrayPipe,
    ]
})
export class TemplatedChartBuilderComponent extends ChartBuilderComponent implements OnInit {

    @ViewChild('tempFilters') tempFilters!: TemplateQueryFiltersComponent;

    @ViewChild('templateSeriesRange') templateSeriesRange!: TemplateQuerySeriesYearRangeComponent;

    matches: string[] = [];

    matchDisableMap: string[] | null = null;

    languageStr: string[] = [];

    editableAreas = 'datapoint';

    editingMatch = '';

    editableLabel = 'Data point';

    templateString = '';

    templateOptions: StudyTemplateOptions = {};

    templateValueMap = new Map<string, string | undefined>();

    filterOverrideMap = new Map<string, string | undefined>();

    seriesOverrideMap = new Map<string, string | undefined>();

    filterMap: Map<string, number> = new Map();

    seriesLetters = [ 'A', 'B', 'C', 'D', 'E', 'F' ];

    loadingTemplate$: Observable<boolean> = of(true);

    loadingUserPreferences$: Observable<boolean> = of(true);

    source = 'Not provided';

    constructor(
        queryService: QueryService,
        activatedRoute: ActivatedRoute,
        store$: Store,
        fb: FormBuilder,
        router: Router,
        dialog: Dialog,
        cdr: ChangeDetectorRef,
        actions$: Actions,
        regInterestService: RegisteredInterestService,
        chartLoader: ChartLoader,
        location: Location,
        @Inject(DIALOG_DATA) public data: TemplateSetup,
        private dialogRef: DialogRef,
        override rdcDialogHelper: RdcDialogHelper,
        private templateEventService: TemplateEventService,
    ) {
        super(queryService, activatedRoute, store$, fb, router, dialog, cdr, rdcDialogHelper, chartLoader, location, actions$, regInterestService );

        this.templateString = this.data.template.templateText
            .replaceAll(' from/to {{', ' {{')
            .replaceAll(' to/from {{', ' {{')
            .replaceAll(' for {{', ' {{')
            .replaceAll(' from {{', ' {{')
            .replaceAll(' to {{',  ' {{');

        this.templateOptions = this.data.template.templateOptions;

        this.study = JSON.parse(JSON.stringify(this.data.template.templateStudyModel)) as QueryEntity;

        this.source = this.data.source;
    }

    override ngOnInit() {

        this.loaded = false;

        this.apexTrialMode$ = this.store.select(runApexTrialMode('userDetailsState'));

        this.store$.dispatch(getDefaultPreferences());

        this.loadingUserPreferences$ = this.store$.select(getIsAppLoading(APP_USER_PREFERENCES));

        const activityCode = QueryBuilderUtils.getTypeOfQuery(this.data.template.templateStudyModel).activityCode;

        this.store$.dispatch(sendActivity({
            activity: {
                activityCode:  `rdc.q.apex.query.${ activityCode }.new`,
                detail: {
                    area: 'Data & Visualisation',
                    launchSource: this.source,
                    creationType: this.data.template.templateId || 'Blank',
                },
            },
        }));

        this.dialogRef.disableClose = true;

        this.dialogRef.backdropClick.subscribe(() => {

            if (this.chartRequest.untouched) {
                this.dialogRef.close();

                return;
            }

            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                width: '100%',
                panelClass: [ 'rdc-dialog' ],
                positionStrategy: this.rdcDialogHelper.dialogPosition(),
                maxWidth: 352,
                data: {
                    icon: 'caution',
                    title: 'Abandon study?',
                    body: 'By clicking away you will lose your progress. Are you sure you wish to close the template?',
                    cancelText: 'Go back',
                    confirmText: 'Abandon',
                },
            });

            dialogRef.componentInstance?.confirm
                .pipe(take(1))
                .subscribe(() => this.dialogRef.close());
        });

        this.loadingTemplate$ = this.store$.select(getIsAppLoading(APP_USER_PREFERENCES));

        this.queryType = this.study?.studyType === 'scatter' ? 'scatter' : 'chart';

        // eslint-disable-next-line
        const regExp = /[^{\}]+(?=})/g;

        const sections = [ ...this.templateString.matchAll(regExp) ]
            .flat();

        this.matches = sections.slice();

        let st = this.templateString
            .replaceAll('{{', '')
            .replaceAll('}}', '');

        sections.forEach((sec) => st = st.replace(sec, '**'));

        this.languageStr = st.split('**').filter((s) => !!s);

        const nonNullable = this.fb.nonNullable; // for readability

        // TODO: extract into a const as used more than once now
        this.chartRequest = nonNullable.group({
            studyId: nonNullable.control('00000000-0000-0000-0000-000000000000', Validators.required),
            studyName: nonNullable.control('New study', Validators.required),
            studyType: nonNullable.control(QueryBuilderType.Column, Validators.required),
            queryRequest: nonNullable.group({
                dataPoints: nonNullable.group({
                    dataPointCodes: nonNullable.array([
                        nonNullable.control('', Validators.required),
                    ]),
                }),
                chartProperties: nonNullable.group<QueryRequestChartProps>({
                    type: nonNullable.control('single', Validators.required),
                    singleSeriesColour: nonNullable.control('#43E08C', Validators.required),
                    xAxis: nonNullable.group({
                        type: nonNullable.control('', Validators.required),
                    }),
                }),
                summaries: nonNullable.group({}),
                adjustments: nonNullable.group({}), // populated by component
                filters: nonNullable.group({
                    origin: nonNullable.group({
                        type: nonNullable.control('all', Validators.required),
                        selected: nonNullable.array([]),
                    }, { validators: CustomValidators.noNulls() }),
                    destination: nonNullable.group({
                        type: nonNullable.control('all', Validators.required),
                        selected: nonNullable.array([]),
                    }, { validators: CustomValidators.noNulls() }),
                    airline: nonNullable.group({
                        type: nonNullable.control('all', Validators.required),
                        selected: nonNullable.array([]),
                    }, { validators: CustomValidators.noNulls() }),
                    aircraft: nonNullable.group({
                        type: nonNullable.control('all', Validators.required),
                        selected: nonNullable.array([]),
                    }, { validators: CustomValidators.noNulls() }),
                    timePeriod: nonNullable.group({
                        type: nonNullable.control<'specific' | 'relative'>('specific', Validators.required),
                    }),
                    cabinTypes: nonNullable.group({
                        types: nonNullable.array([ 'economy' ], Validators.required),
                    }),
                    emissionScheme: nonNullable.group({
                        types: nonNullable.array([ 'all' ], Validators.required),
                    }),
                    schedulesFilters: nonNullable.group({
                        franchisePartners: this.fb.nonNullable.control(true),
                        nonStopServices: this.fb.nonNullable.control(false),
                    }),
                }),
            }),
        });

        this.queryRequest = this.chartRequest.get('queryRequest') as FormGroup;

        this.study = JSON.parse(JSON.stringify(this.data.template.templateStudyModel));

        delete this.study?.queryRequest.adjustments;

        this.store$.dispatch(AppLoadStatus.loading(
            APP_STUDY_TEMPLATES,
            'loading template...',
        ));

        this.initialised
            .pipe(
                takeUntil((this.finishedForm$)))
            .subscribe(async (initialisedSections) => {

                const initialisedCount = this.queryType === 'scatter' ? 5 : 6;

                if (initialisedSections.length === initialisedCount) { // all items are initialise

                    await this.loadQuery(this.queryType);

                    this.finishedForm$.next(true);
                }
            });

        this.finishedForm$
            .pipe(
                withLatestFrom(this.apexTrialMode$),
                take(1)
            )
            .subscribe((sub) => {
                const onTrial = sub[1];

                ChartLoader.setDefaultDatesForTemplate(this.data.template.code, this.chartRequest, this.templateSeriesRange, onTrial);

                setTimeout(() => {
                    this.loaded = true;
                    this.store$.dispatch(appLoaded({ key: APP_STUDY_TEMPLATES }));

                    this.cdr.detectChanges();

                    this.templateEventService.toggleDataPointDrawer();
                }, 500);
            });

        // TODO: extract into new method
        this.userPrefColours$
            .pipe(
                filter((colours) => !!colours),
                takeUntil(this.componentDestroyed$),
                take(1)
            )
            .subscribe((colours) => this.userColours = (colours || []).map(({ colour }) => colour));

        this.store$.select(getLoadedDateRange)
            .pipe(
                withLatestFrom(this.store.select(runApexTrialMode('userDetailsState'))),
                filter((dateRange) => !!dateRange),
                takeUntil(this.componentDestroyed$),
                take(1)
            )
            .subscribe(([ dateRange, onTrial ]) =>  this.dateRangeUtils = new QbFiltersDateRangeUtils(dateRange, onTrial));

        this.dataPointsData$
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((data) => this.dataPointsData = data);

        this.chartRequest.get('queryRequest')?.valueChanges
            .pipe(debounce(() => interval(0)))
            .subscribe(async () => {

                const {
                    templateValueMap,
                    filterMap,
                    filterOverrides,
                    seriesOverrides,
                } = TemplateHelper.getValuesForTemplateItems(
                    this.matches.filter((match) => !match.includes('break')),
                    this.chartRequest,
                    this.filterMap,
                    this.dataPointsData?.datapoints,
                    this.tempFilters.disabledControls,
                    this.templateOptions.filterLimits || [],
                );

                this.filterOverrideMap = filterOverrides;
                this.templateValueMap = templateValueMap;
                this.seriesOverrideMap = seriesOverrides;
                this.filterMap = filterMap;

                this.matchDisableMap = TemplateHelper.getInvalidTemplateSections(
                    this.matches.filter((match) => !match.includes('break')),
                    this.templateValueMap,
                    this.chartRequest
                );

                this.cdr.detectChanges();
            });

        this.templateEventService.progressTemplate.subscribe(() => {

            setTimeout(() => {
                this.onNext();
            }, 100);

        });
    }

    onNext(progress = true): void {

        const strMap = new TemplateStringValuePipe();

        const keyArr = Array.from(this.templateValueMap.keys());

        // keys need to be in series order
        keyArr.sort((a,b) => {

            if (
                a.includes('series') && a.includes('comparison') &&
                b.includes('series') && b.includes('comparison')
            ) {
                const aIndex = a.substring(a.length - 2, a.length - 1);
                const bIndex = b.substring(b.length - 2, b.length - 1);

                return Number(aIndex) - Number(bIndex);
            }

            return 0;
        });

        const currInd = keyArr.findIndex((k) => k === this.editableAreas) || 0;

        if(currInd < 0) {
            this.clearEditableArea();

            return;
        }

        let nextInd = currInd;

        if(progress) {
            nextInd = currInd + 1;
        }

        let nextCode = keyArr[nextInd];

        if(!nextCode) {
            this.clearEditableArea();

            return;
        }

        for(let i = 0; i < (currInd + 1); i++) {

            if(!this.templateValueMap.get(keyArr[i])) {

                nextCode = keyArr[i];
                nextInd = i;

                break;
            }

        }

        const label = strMap.transform(this.matches[nextInd] || this.matches[this.matches.length - 1])?.label;

        this.editingMatch = nextCode;

        this.editableLabel = this.seriesOverrideMap.get(nextCode) || this.filterOverrideMap.get(nextCode) || label || '';

        this.editableAreas = nextCode;
    }

    private clearEditableArea(): void {
        this.editingMatch = '';
        this.editableLabel = '';
        this.editableAreas = '';
    }

    onRunTemplate(): void {
        const type = QueryBuilderUtils.getTypeOfQuery(this.study as never);

        this.router.navigate(
            [ '/', 'query-builder', type.type, this.data.template.templateId ],
            {
                state: {
                    query: this.removeConditionalProps(this.chartRequest.getRawValue())
                },
                queryParams: { ts: Date.now() } }
        );
    }

    get seriesAsControls(): AbstractControl[] {
        return this.queryFormGroup<FormArray>('chartProperties.series')?.controls || [];
    }

    dynamicEntryText(key: string, index: number, descriptor = ''): string {
        return `${key}${ descriptor }[${ index }]`;
    }

    filterItemArray(length: number): unknown[] {
        // there always needs to be at least one
        return new Array(length);
    }

    onRemoveTemplateItem(control: 'comparison' | 'filter', key: string): void {

        if (control === 'comparison') {
            const seriesArr = (this.chartRequest.get('queryRequest.chartProperties.series') as FormArray);

            if(seriesArr) {
                seriesArr.removeAt(seriesArr.length - 1);

                setTimeout(() => {
                    this.onNext(false);
                },25);
            }

            return;
        }

        this.tempFilters.onRemoveFilter(key, true);

        setTimeout(() => {
            this.onNext(false);
        },25);
    }

    onAddTemplateItem(length: number, control: 'comparison' | 'filter', key: string): void {

        if (control === 'comparison') {
            this.seriesSelectionComp.onAddSeries(length);

            setTimeout(() => {
                this.templateEventService.progress();
            });

            return;
        }

        this.tempFilters.onAddFilter(key);

        this.templateEventService.progress();
    }

    editableRemItem(): void {

        if (this.editableAreas.includes('filters')) {

            this.tempFilters.onRemoveFilter(this.editableAreas);

        } else {
            const indexes = this.editableAreas.match(/\d/g) || [ '0' ];

            const seriesIndex = Number(indexes[1]);

            this.seriesSelectionComp.onRemoveSeries(seriesIndex);
        }

        this.editingMatch = '';
        this.editableLabel = '';
        this.editableAreas = '';
    }

    editableAddItem(): void {

        if (this.editableAreas.includes('filters')) {

            this.tempFilters.onAddFilter(this.editableAreas);

            this.templateEventService.progress();

            return;
        }

        const indexes = this.editableAreas.match(/\d/g) || [ '0' ];

        const seriesIndex = Number(indexes[1]);

        this.onAddTemplateItem(seriesIndex ,'comparison', this.editableAreas);
    }


}
