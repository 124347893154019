import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { sustainabilityLinks } from "@rdc-apps/rdc-apex/src/lib/shared/constants";
import { SustainabilityItemComponent } from "apex-shared-ui"
import { RdcButtonDirective, RdcDialogItemDirective, RdcIconButtonDirective, TooltipDirective } from "shared-directives";
import { IconComponent } from "shared-ui";

@Component({
    selector: 'rdc-apps-sustainability-drawer',
    templateUrl: './sustainability-drawer.component.html',
    styleUrl: './sustainability-drawer.component.scss',
    imports: [
        CommonModule,
        RdcButtonDirective,
        RdcDialogItemDirective,
        IconComponent,
        TooltipDirective,
        SustainabilityItemComponent,
        RdcIconButtonDirective,
    ]
})
export class SustainabilityDrawerComponent {

    readonly sustainabilityLinks = sustainabilityLinks;

}
