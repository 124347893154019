import { DIALOG_DATA } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Inject, Output, ViewEncapsulation } from '@angular/core';
import { RdcButtonDirective, RdcDialogItemDirective, RdcIconButtonDirective } from "shared-directives";

import { IconComponent } from "../icon/icon.component";

@Component({
    selector: 'rdc-apps-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: [ './confirm-dialog.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
    imports: [ CommonModule, RdcButtonDirective, IconComponent, RdcDialogItemDirective, RdcIconButtonDirective ]
})
export class ConfirmDialogComponent {

    constructor(@Inject(DIALOG_DATA) public data: {
        confirmText?: string;
        cancelText?: string;
        icon?: 'success' | 'caution' | null;
        title?: string;
        body?: string;
    } = {}) {}

    @Output() confirm: EventEmitter<void> = new EventEmitter();

}
