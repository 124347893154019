import { AppEnvironment } from '@rdc-apps/rdc-apex/src/lib/shared/environment';

export const environment: AppEnvironment = {
    enableAnalytics: true,
    production: false,
    testing: true,
    development: false,
    api: {
        baseUrl: 'https://testing-api.rdcaviation.com/apex/web/api/v1',
        featureUrl: 'https://testing-api.rdcaviation.com/api/v1',
    },
    rdcApps: {
        loop: 'https://testing-loop.rdcaviation.com',
        apc: 'https://beta.airportcharges.com',
        apexLegacy: 'https://beta.rdcapex.com?view=classic',
        apexRoutePerformance: 'https://beta.rdcapex.com/RoutePerformance',
        apexLegacyBase: 'https://beta.rdcapex.com',
    },
    oidcConfig: {
        IdentityEndpoint: 'https://testing-identity.rdcaviation.com',
        BaseUrl: 'https://testing-app.rdcapex.com',
        ClientId: 'implicit-apex-webapp',
        LogoutRedirectUrl: 'https://testing-app.rdcapex.com/',
    },
    authRedirect: 'https://testing-app.rdcapex.com/',
    salesforceLeadUrl: 'https://prod-23.uksouth.logic.azure.com:443/workflows/b17a93ed4da2424c86015dbfa121a1d5/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=9DWn6Bj0idk4-wxFLE0Jzm73CcjlJSa1-i9464lk3tQ',
};
