import { of, skipWhile, switchMap, take } from "rxjs";

import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { getUserSettingsData } from 'rdc-apex-store';

export const ApexOnboardedGuard: CanActivateFn = () => {
    const store = inject(Store);
    const router = inject(Router);

    return store.select(getUserSettingsData('userSettingsState'))
        .pipe(
            skipWhile((settings) => !settings),
            take(1),
            switchMap((settings) => {

                if (settings?.userOnboardingCompleted) {
                    return of(true);
                }

                return of(router.parseUrl(`/`));
            })
        );
};
