import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

export interface AddedRoute {
    origin: RouteOption;
    destination: RouteOption;
    airline?: RouteOption;
    isServed?: boolean;
    isChecking?: boolean;
    benchmarkSingle?: RouteOption;
    benchmarkGroup?: RouteOption[];
    targetAirlines?: RouteOption[];
    routeType?: Partial<RepoItem<string>>;
}

export interface RouteServed {
    isServed: boolean;
    originAirport: number;
    destinationAirport: number;
    allowedBenchmarkAirports: RouteOption[];
}

export type ServedRoute = Omit<RouteServed, 'isServed' | 'allowedBenchmarkAirports'>;

export interface RouteOption {
    id: number;
    label: string;
    icaoCode: string;
    iataCode: string;
    threeLetterCode: string;
}

export interface RouteAirport {
    id: number;
    label: string;
    icaoCode: string;
    iataCode: string;
    longitude: number;
    latitude: number;
    country: string;
    countryId: number;
    countryIataCode: string;
    continent: string;
    continentId: number;
    continentIataCode: string;
    region: string;
    regionId: number;
    regionIataCode: string;
    subRegion: string;
    subRegionIataCode: string;
    subRegionId: number;
}

export interface ConvertedFilteredRoutes {
    originAirports: RouteAirport[];
    destinationAirports: RouteAirport[];
    destinationSubRegionAirports: string[];
    destinationRegionAirports: string[];
    countries: string[];
    continent: string[];
    type: string[];
    carriers: RouteOption[];
}
