import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { ChartSeriesType } from './chart-series-type';

export interface SeriesTypeDefinition {
    type: FormControl<string | null>;
    partition: FormControl<string | null>;
}

export interface SeriesDefTimePeriodSelection {
    partition: FormControl<string | null>;
    selected: FormControl<string | number | null>;
}

export interface SeriesDefinition {
    label: FormControl<string | null>;
    colour: FormControl<string | null>;
    attributeSelections: FormArray<FormControl<SeriesAttributeSelection | null>>;
    timePeriodSelection?: FormGroup<SeriesDefTimePeriodSelection>;
}

export interface QbFilterAutocompleteOption {
    id: number;
    label: string;
    icaoCode: string;
    iataCode: string;
    threeLetterCode: string;
}

export interface SeriesAttributeSelection extends QbFilterAutocompleteOption {
    type: FormControl<string | null>;
    partition: FormControl<string | null>;
    selected: QbFilterAutocompleteOption;
}

export interface UQBSeries {
    type: FormControl<ChartSeriesType | null>;
    seriesDefinitions?: FormArray<FormGroup<SeriesTypeDefinition>>;
    series?: FormArray<FormGroup<SeriesDefinition>>;
}
