import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QbFiltersDateRangeUtils } from '@rdc-apps/rdc-apex/src/lib/query-builder/utilities';
import { ApexStudy, QueryRequestChartSeries } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { TemplateEventService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { SelectAutocompleteModule } from "shared-ui";

@Component({
    standalone: true,
    selector: 'rdc-apps-template-query-series-year-range',
    templateUrl: './template-query-series-year-range.component.html',
    styleUrls: [ './template-query-series-year-range.component.scss' ],
    imports: [ CommonModule, SelectAutocompleteModule ],
})
export class TemplateQuerySeriesYearRangeComponent implements OnInit {

    @Input() dateRangeUtils!: QbFiltersDateRangeUtils;

    @Input() chartRequest!: FormGroup<ApexStudy>;

    @Input() isOnTrial!: boolean | null;

    @Input() userColours: string[] = [ 'rgba(80,180,50,0.5)', 'rgba(237,86,27,0.5)', 'rgba(155,222,222,0.5)', 'rgba(75,75,75,0.5)' ];

    constructor(
        public templateEventService: TemplateEventService
    ) {}

    fb = inject(FormBuilder);

    startYearOpts: Partial<RepoItem<number>>[] = [];

    endYearOpts: Partial<RepoItem<number>>[] = [];

    yearRangeForm = this.fb.nonNullable.group({
        startYear: this.fb.nonNullable.control(0, Validators.required),
        endYear: this.fb.nonNullable.control(0, Validators.required),
    });

    ngOnInit() {
        this.startYearOpts = this.dateRangeUtils.selectableYearsAsRepoItems();

        // then we want to remove the last year
        if(!this.isOnTrial) {
            this.startYearOpts = this.startYearOpts.slice(0, this.startYearOpts.length - 1);
        }

        this.endYearOpts = this.dateRangeUtils.selectableYearsAsRepoItems();

        this.yearRangeForm.valueChanges.subscribe(() => {

            const { startYear, endYear } = this.yearRangeForm.controls;

            let limit = startYear.value + 3;

            if(this.isOnTrial) {
                limit = startYear.value + 1;
            }

            if(endYear.value > limit) {
                endYear.patchValue(0);
            }

            this.startYearOpts = this.dateRangeUtils.selectableYearsAsRepoItems()
                .filter(({ code }) => code !== endYear.value);

            this.endYearOpts = this.dateRangeUtils
                .selectableYearsAsRepoItems(startYear.value)
                .filter((endYrOpt) => endYrOpt.code && endYrOpt.code <= limit)
                .filter(({ code }) => code !== startYear.value);

            this.yearRangeForm.get('endYear')?.setValidators(Validators.min(startYear.value + 1));

            if (this.yearRangeForm.valid && this.chartRequest) {
                this.populateSeriesValues();
            }
        });
    }

    private populateSeriesValues(): void {

        if(this.yearRangeForm.invalid) {
            return;
        }

        const { startYear, endYear } = this.yearRangeForm.controls;

        const seriesControl = this.chartRequest.get('queryRequest.chartProperties.series') as FormArray<FormGroup<QueryRequestChartSeries>>;
        const seriesDefs = this.chartRequest.get('queryRequest.chartProperties.seriesDefinitions') as FormArray;

        seriesDefs.patchValue([ {
            type: 'timePeriod',
            partition: 'year',
        } ]);

        seriesControl?.clear();

        let count = 0;

        const type = this.chartRequest.value.studyType === 'bar' ? 'column' : this.chartRequest.value.studyType;

        for (let i = startYear.value; i <= endYear.value; i++) {
            if (count === 4 || (this.isOnTrial && count === 2)) {
                return;
            }

            const series = this.fb.nonNullable.group({
                label: this.fb.nonNullable.control(i, Validators.required),
                chartType: this.fb.nonNullable.control(type),
                colour: this.fb.control(this.userColours[count] || '#43E08C', Validators.required),
                attributeSelections: this.fb.nonNullable.array([]),
                timePeriodSelection: this.fb.nonNullable.group({
                    partition: this.fb.control('year', Validators.required),
                    selected: this.fb.control(String(i), Validators.required),
                }),
            });

            seriesControl.push(series as never);
            count++;
        }

    }

    onProgressIfValid(value: Partial<RepoItem<unknown>>) {
        const startYear = this.yearRangeForm.controls.startYear.value

        if(startYear === value.code) {
            return;
        }

        this.templateEventService.progress();
    }
}
