<rdc-apps-header header="Colours" title="Picker preset colours"></rdc-apps-header>
<p>These colours are available to use for chart series.</p>

<ng-container [formGroup]="form">
    <div class="colours" formArrayName="colours">
        <div class="colours-item" *ngFor="let control of coloursFormArray.controls; let index = index">
            <button
                rdcAppsButton
                [formArrayName]="index"
                [(colorPicker)]="colours[index].colour"
                [cpPresetColors]="cpColours"
                [cpAlphaChannel]="'disabled'"
                [cpPosition]="'bottom'"
                [cpPositionOffset]="'50%'"
                [cpHeight]="'256px'"
                [cpWidth]="'148px'"
                [cpPresetLabel]="'Presets'"
                [cpPositionRelativeToArrow]="true"
                [style.background-color]="colours[index].colour"
                (colorPickerChange)="setColour(index, $event)"
            ></button>
        </div>
        <a (click)="onResetDefaultColours()">Reset to defaults</a>
    </div>
</ng-container>

