<header>
    <section class="container edit-query-top">

        <a rdcAppsButton
           colour="primary"
           (click)="onNewQuery()"
        >
            <rdc-apps-icon *ngIf="studyType === 'table'" class="new-query-icon" rdcIcon="n-icon-build-fill" size="xs"></rdc-apps-icon>
            <rdc-apps-icon *ngIf="['scatter', 'chart'].includes(studyType)" class="new-query-icon" rdcIcon="n-icon-chart" size="xxs"></rdc-apps-icon>
            <span>New {{ studyType === 'table' ? 'query' : 'chart' }}</span>
        </a>

        <button
            #quickRunTrigger
            class="qb-run-saved-btn"
            rdcAppsButton
            type="button"
            [class.menu-active]="dialogRef"
            [disabled]="disabledSavedQueries"
            (click)="onOpenLoadMenu(quickRunTrigger, false)"
            (keydown.arrowDown)="onOpenLoadMenu(quickRunTrigger)"
        >
            <span>Load</span>
            <rdc-apps-icon class="run-saved-icon" size="xl">expand_more</rdc-apps-icon>
        </button>

        <span class="divider"></span>

        <div class="edit-query-inputs">
            <ng-container [formGroup]="nameControlFormGroup">
                <rdc-apps-validated-control
                    controlName="studyName"
                    [control]="nameControlFormGroup.get('studyName')"
                    [validationMessages]="nameValidationMessages"
                    [validateBeforeInteraction]="false"
                    [showValidation]="true"
                >
                    <label class="text-hidden">
                        <span>Name</span>
                        <div class="icon-input">
                            <input
                                class="study-name"
                                type="text"
                                placeholder="Name"
                                formControlName="studyName"
                                (keydown.enter)="onSaveKeyDown($event)"
                            >
                            <rdc-apps-icon>edit</rdc-apps-icon>
                        </div>
                    </label>
                </rdc-apps-validated-control>
            </ng-container>

            <rdc-apps-menu-button [menu]="saveMenu" menuPosition="below-center-left">
                <button
                    rdcAppsButton
                    rdcAppsMenuButton
                    type="button"
                    class="save-button"
                    [disabled]="!!isSaving || nameControlFormGroup.invalid"
                    (click)="saveAction.emit()"
                >
                    <span>Save</span>
                </button>
            </rdc-apps-menu-button>

        </div>

        <button
            class="export-button"
            rdcAppsButton
            type="button"
            colour="primary"
            [disabled]="disableExport || nameControlFormGroup.invalid || !queryService.lastRunQuery || !queryService.results?.tableRows?.length"
            (click)="onExport()"
        >
            Export
        </button>

    </section>
</header>


<ng-template #saveMenu>
    <div cdkMenu class="no-animate save-as-menu">
        <button
            rdcAppsContextMenuItem
            cdkMenuItem
            cdkMenuitemTypeaheadLabel="*"
            [disabled]="!editingQuery"
            [cdkMenuItemDisabled]="!editingQuery"
            (cdkMenuItemTriggered)="onSaveAs()">
            <span>Save as...</span>
        </button>
    </div>
</ng-template>

<ng-template #savedQueryMenu>
    <div cdkMenu class="no-animate saved-study-menu">
        <ng-container *ngFor="let query of savedQueries; let i = index">
            <!-- hide the active study, it is also disabled in case we do want to show it in the future -->
            <button
                *ngIf="query.studyId !== activeStudyId"
                class="saved-query-item"
                rdcAppsContextMenuItem
                cdkMenuItem
                cdkMenuitemTypeaheadLabel="*"
                [cdkMenuItemDisabled]="query.studyId === activeStudyId"
                [rdcContextMenuItemActive]="query.studyId === activeStudyId"
                (cdkMenuItemTriggered)="onLoadSaved(query)"
            >
                <rdc-apps-icon
                    class="query-pinned-star"
                    *ngIf="query.pinned"
                    size="xxxs"
                    [class.pinned]="query.pinned"
                    [fill]="query.pinned"
                >
                    star
                </rdc-apps-icon>
                <span>{{ query.studyName }}</span>
            </button>
        </ng-container>
    </div>
</ng-template>
