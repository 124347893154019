import { CommonModule } from "@angular/common";
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { animSidebarIcon, animSidebarSvg } from '@rdc-apps/rdc-shared/src/lib/animations';
import { Icon } from '@rdc-apps/rdc-shared/src/lib/data-access/models';

@Component({
    standalone: true,
    selector: 'rdc-apps-icon',
    templateUrl: './icon.component.html',
    styleUrls: [ './icon.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
    imports: [ CommonModule ],
    animations: [
        animSidebarIcon,
        animSidebarSvg,
    ],
})
export class IconComponent {

    @Input() size: Icon = 'md';

    @Input() fill = false;

    @Input() weight: 'normal' | 'bold' | 'semi-bold' = 'normal';

    @Input() rdcIcon = '' ;

    @Input() spin = false;

    @Input() sideBarIcon = false;

    @Input() sideBarExpanded!: boolean;

    rdcIconDefs = [
        'n-icon-refresh',
        'n-icon-build',
        'n-icon-build-fill',
        'n-icon-classic',
        'n-icon-expanded',
        'n-icon-expand',
        'n-icon-cloud',
        'n-icon-growth',
        'n-icon-growth-fill',
        'n-icon-home',
        'n-icon-home-alt',
        'n-icon-home-fill',
        'n-icon-fin',
        'n-icon-network',
        'n-icon-network-fill',
        'n-icon-sustainability',
        'n-icon-sustainability-fill',
        'n-icon-support',
        'n-icon-performance',
        'n-icon-performance-fill',
        'n-icon-risk',
        'n-icon-risk-fill',
        'n-icon-user',
        'n-icon-apps',
        'n-icon-tower',
        'n-icon-benchmark',
        'n-icon-plane',
        'n-icon-airlines',
        'n-icon-map-menu-btn',
        'n-icon-map-menu-btn-open',
        'n-icon-measure',
        'n-icon-check-mini',
        'n-icon-chart',
        'n-icon-gear',
        'n-icon-sort-asc',
        'n-icon-sort-desc',
        'n-icon-sort-none',
        'n-icon-map-arrow',
        'n-icon-map-arrow-fill',
        'n-icon-arrow-circle-up',
    ];
}
