import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/header';
import { RdcButtonDirective } from "shared-directives";
import { IconComponent, SelectAutocompleteModule, ValidatedControlComponent } from "shared-ui";

import { DefaultPreferencesGroupComponent } from './default-preferences-group.component';


@NgModule({
    imports: [
        CommonModule,
        IconComponent,
        ValidatedControlComponent,
        RdcButtonDirective,
        HeaderModule,
        ReactiveFormsModule,
        SelectAutocompleteModule,
    ],
    declarations: [ DefaultPreferencesGroupComponent ],
    exports: [
        DefaultPreferencesGroupComponent,
    ],
})
export class DefaultPreferencesGroupModule {}
