import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DefaultPreferencesGroupModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/default-preferences-group';
import { DefaultPreferencesItemModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/default-preferences-item';
import { HeaderModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/header';
import { SummaryGroupOrderModule } from '@rdc-apps/rdc-apex/src/lib/shared/pipes/summary-group-order';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { RdcButtonDirective, TooltipDirective } from "shared-directives";
import { DisplayCodesComponent, SelectAutocompleteModule } from "shared-ui";

import { DefaultPreferencesComponent } from './default-preferences.component';


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        HeaderModule,
        DefaultPreferencesItemModule, DefaultPreferencesGroupModule,
        RdcButtonDirective,
        SummaryGroupOrderModule,
        NgScrollbarModule, SelectAutocompleteModule,
        NgScrollbarModule, DisplayCodesComponent, TooltipDirective,
    ],
    declarations: [ DefaultPreferencesComponent ],
    exports: [
        DefaultPreferencesComponent,
    ],
})
export class DefaultPreferencesModule {}
