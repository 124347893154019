import { map } from 'rxjs';

import { inject } from '@angular/core';
import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { getTags , getUserDetails , getUserSettings } from 'rdc-apex-store';



export const AuthorizationGuard: CanActivateFn = (
    _,
    state: RouterStateSnapshot
) => {
    const store = inject(Store);
    const router: Router = inject(Router);
    const oidcSecurityService = inject(OidcSecurityService);

    return oidcSecurityService.isAuthenticated$
        .pipe(
            map(({ isAuthenticated }) => {

                if (!isAuthenticated) {
                    // set url session
                    sessionStorage.setItem('origin', `${state.url}`);

                    router.navigate([ 'authorize' ]);

                    return false;
                }

                store.dispatch(getTags());
                store.dispatch(getUserDetails());
                store.dispatch(getUserSettings());

                return true;
        })
    );
};
