import { debounce, distinctUntilChanged, firstValueFrom, interval, Observable, Subject, take } from 'rxjs';

import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
    ɵFormGroupValue
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { QbFiltersDateRangeUtils } from '@rdc-apps/rdc-apex/src/lib/query-builder/utilities';
import {
    rdcTrialYearStart,
    specificQuarterOptions,
    specificTimeFrameOpts,
    studyBuilderRelativeOpts,
    xAxisSubOptions
} from '@rdc-apps/rdc-apex/src/lib/shared/constants';
import {
    ApexStudy,
    QueryRequestChartProps,
    QueryRequestTimePeriod,
    QueryRequestTimePeriodSpecific
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { TimePeriodRenderService } from "@rdc-apps/rdc-apex/src/lib/shared/utilities";
import { CustomValidators } from '@rdc-apps/rdc-shared/src/lib/custom-validators';
import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { RdcComponentUtils } from '@rdc-apps/rdc-shared/src/lib/utilities';
import { runApexTrialMode, 
    autocomplete,
    AutocompleteDispatch,
    AutocompleteOption
} from 'rdc-apex-store';
import { SelectAutocompleteModule } from "shared-ui";

@Component({
    standalone: true,
    selector: 'rdc-apps-query-builder-time-period-options',
    templateUrl: './query-builder-time-period-options.component.html',
    styleUrls: [ './query-builder-time-period-options.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
    imports: [ CommonModule, ReactiveFormsModule, SelectAutocompleteModule ],
})
export class QueryBuilderTimePeriodOptionsComponent extends RdcComponentUtils implements OnInit {

    @Input() chartRequest!: FormGroup<ApexStudy>;

    @Input() autoCompleteResults$!: Observable<AutocompleteOption[]>;

    @Input() dateRangeUtils!: QbFiltersDateRangeUtils;

    @Output() init = new EventEmitter<void>();

    showCompleteTimePeriodSelection = true;
    noTimePeriodSelection = false;

    specificTimeFrameOpts = specificTimeFrameOpts;
    studyBuilderRelativeOpts = studyBuilderRelativeOpts;

    startMonths: Partial<RepoItem<string>>[] = [];
    endMonths: Partial<RepoItem<string>>[] = [];

    startYears: Partial<RepoItem<number>>[] = [];
    endYears: Partial<RepoItem<number>>[] = [];

    updateTimePeriodControls: any = new Subject();

    apexTrialMode$!: Observable<boolean>;

    constructor(
        private store: Store,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private timePeriodRenderService: TimePeriodRenderService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.apexTrialMode$ = this.store.select(runApexTrialMode('userDetailsState'));

        this.startMonths = this.dateRangeUtils.calendarMonthsRepoItems();
        this.endMonths = this.dateRangeUtils.calendarMonthsRepoItems();

        this.startYears = this.dateRangeUtils.selectableYearsAsRepoItems();
        this.endYears = this.dateRangeUtils.selectableYearsAsRepoItems();

        this.getAsFormGroup('filters').valueChanges
            .pipe(
                debounce(()=> interval(0)),
                distinctUntilChanged((a, b) => JSON.stringify(a.timePeriod) === JSON.stringify(b.timePeriod))
            )
            .subscribe(() => {
                this.updateTimePeriodControls.next();
            });

        this.getAsFormGroup('filters.timePeriod').valueChanges
            .pipe(debounce(()=> interval(0)))
            .subscribe((timePeriod) => {

                const { startYear, endYear, startMonth } = timePeriod.specific || {};

                this.startMonths = this.dateRangeUtils.calendarMonthsRepoItems(
                    'january',
                    false,
                    startYear || 2000,
                    (startYear || 2000) === (endYear || 2060)
                );

                this.endMonths = this.dateRangeUtils.calendarMonthsRepoItems(
                    startMonth,
                    false,
                    endYear || 2060,
                    (startYear || 2000) === (endYear || 2060)
                );

                this.endYears = this.dateRangeUtils.selectableYearsAsRepoItems(timePeriod.specific?.startYear);

                this.cdr.detectChanges();
            });

        this.getAsFormGroup('chartProperties')?.valueChanges
            .pipe(debounce(()=> interval(0)))
            .subscribe(() => {
                this.updateTimePeriodControls.next();
            });

        this.updateTimePeriodControls
            .pipe(debounce(() => interval(0)))
            .subscribe(async () => {
                const isOnTrial = await firstValueFrom(this.apexTrialMode$);

                this.renderTimePeriod(this.getAsFormGroup('chartProperties').value, isOnTrial);
            });

        this.timePeriodRenderService.rendered.pipe(take(1)).subscribe(() => {
            this.init.emit();
        });

        const onTrial = await firstValueFrom(this.apexTrialMode$);

        this.renderTimePeriod(this.getAsFormGroup('chartProperties').value, onTrial);
    }

    onAutocomplete(event: AutocompleteDispatch): void {
        this.store.dispatch(autocomplete(event));
    }

    getAsFormGroup(path: string): FormGroup {
        return this.chartRequest.get(`queryRequest.${ path }`) as FormGroup;
    }

    timePeriodFormGroup<T = FormGroup>(...additionalPaths: string[]): T {
        const path = additionalPaths.length ? `queryRequest.filters.timePeriod.${additionalPaths.join('.')}` : 'queryRequest.filters.timePeriod';

        return this.chartRequest.get(path) as T;
    }

    get specificToTypeValue(): string {
        return this.timePeriodFormGroup('specific', 'type')?.value;
    }

    // eslint-disable-next-line complexity
    private renderTimePeriod(chartProperties: ɵFormGroupValue<QueryRequestChartProps>, onTrial: boolean): void {

        this.noTimePeriodSelection = false;

        const timePeriodVal = this.getAsFormGroup('filters.timePeriod').value as ɵFormGroupValue<QueryRequestTimePeriod>;

        const hasTimePeriodInSeries = this.timePeriodInSeries(chartProperties.seriesDefinitions);
        const hasTimePeriodInXAxis = chartProperties.xAxis?.type === 'timePeriod';

        this.startYears = this.dateRangeUtils.selectableYearsAsRepoItems();
        this.endYears = this.dateRangeUtils.selectableYearsAsRepoItems(timePeriodVal.specific?.startYear);

        const defaultYr = onTrial ? rdcTrialYearStart : (new Date().getFullYear() - 1);

        if (!hasTimePeriodInSeries && !hasTimePeriodInXAxis) {

            this.showCompleteTimePeriodSelection = true;

            if (this.getAsFormGroup('filters.timePeriod.type').value === 'specific') {

                this.getAsFormGroup('filters.timePeriod').removeControl('relative');

                // reset if yearStartsMonth
                const typeVal = (timePeriodVal.specific?.type === 'yearStartsMonth') ? null : timePeriodVal.specific?.type;

                this.getAsFormGroup('filters.timePeriod').setControl('specific', this.fb.nonNullable.group({
                    type: this.fb.nonNullable.control(typeVal || 'months', Validators.required),
                    startYear: this.fb.nonNullable.control(timePeriodVal.specific?.startYear || defaultYr, Validators.required),
                    endYear: this.fb.nonNullable.control(timePeriodVal.specific?.endYear || defaultYr, Validators.required),
                    startMonth: this.fb.nonNullable.control(timePeriodVal.specific?.startMonth || this.startMonths[0].code, Validators.required),
                    endMonth: this.fb.nonNullable.control(timePeriodVal.specific?.endMonth || this.endMonths[this.endMonths.length - 1].code, Validators.required),
                }));

                this.getAsFormGroup('filters.timePeriod.specific.type').valueChanges
                    .subscribe((specificType) => this.handleSpecificType(specificType));

                this.handleSpecificType(timePeriodVal.specific?.type || 'months', timePeriodVal);

                this.cdr.detectChanges();

                this.timePeriodRenderService.render();

                return;
            }

            this.getAsFormGroup('filters.timePeriod').removeControl('specific');

            this.getAsFormGroup('filters.timePeriod').setControl('relative', this.fb.nonNullable.group({
                relativeMonths: this.fb.nonNullable.control(timePeriodVal.relative?.relativeMonths || 'last12Months', Validators.required),
            }));

            this.timePeriodRenderService.render();

            return;
        }

        this.showCompleteTimePeriodSelection = false;

        this.getAsFormGroup('filters.timePeriod')?.addControl('specific', this.fb.nonNullable.group({}));

        const timePeriodFormGroup = this.getAsFormGroup('filters.timePeriod.specific');

        if ((timePeriodVal.type === 'relative') && hasTimePeriodInXAxis) {

            this.getAsFormGroup('filters.timePeriod')?.removeControl('specific');

            this.noTimePeriodSelection = true;

            this.timePeriodRenderService.render();

            return; // hide everything
        }

        if (this.isInSeriesPartitions(chartProperties, 'relativeYears')) {

            [ 'startYear', 'endYear', 'yearStartsMonth', 'year', 'type', 'seasons', 'quarters' ].forEach((control) => {
                this.getAsFormGroup('filters.timePeriod.specific')?.removeControl(control);
            });

            this.noTimePeriodSelection = true;

            this.timePeriodRenderService.render();

            return; // hide everything
        }

        this.getAsFormGroup('filters.timePeriod')?.patchValue({ type: 'specific' }, { emitEvent: false });

        if (hasTimePeriodInSeries && hasTimePeriodInXAxis) {

            this.noTimePeriodSelection = true;

            const remControls = [ 'yearStartsMonth', 'year', 'type', 'seasons', 'quarters' ];
            const clearControls = [];

            const partition = this.getAsFormGroup('chartProperties.xAxis.partition')?.value;

            if(partition !== 'year') {
                clearControls.push('startYear', 'endYear');
            }
            if(partition !== 'calendarMonth') {
                clearControls.push('startMonth', 'endMonth');
            }

            clearControls.forEach((ctrl) => {
                timePeriodFormGroup.get(ctrl)?.clearValidators();
                timePeriodFormGroup.get(ctrl)?.patchValue(null);
            });

            remControls.forEach((controlName) => timePeriodFormGroup.removeControl(controlName));

            this.timePeriodRenderService.render();

            this.cdr.detectChanges();

            return;
        }

        this.getAsFormGroup('filters.timePeriod')?.removeControl('relative');

        const seriesDefsHaveTimePeriod = chartProperties.seriesDefinitions?.find(({ partition }) => [ 'year', 'relativeYears' ].includes(partition || ''));

        const showMonthSelection = (chartProperties.xAxis?.partition === 'year' || seriesDefsHaveTimePeriod);

        if (showMonthSelection) {

            timePeriodFormGroup.setControl('type', new FormControl('yearStartsMonth', Validators.required));

            timePeriodFormGroup.addControl('yearStartsMonth', this.fb.nonNullable.control(timePeriodVal.specific?.yearStartsMonth || 'january', Validators.required));

            [ 'year', 'startMonth', 'endMonth', 'seasons', 'quarters' ].forEach((controlName) => timePeriodFormGroup.removeControl(controlName));

            if (this.isInSeriesPartitions(chartProperties, 'year')) {
                [ 'startYear', 'endYear' ].forEach((controlName) => timePeriodFormGroup?.removeControl(controlName));
            }

            this.timePeriodRenderService.render();

            return;
        }

        timePeriodFormGroup?.addControl('year', this.fb.nonNullable.control(
            timePeriodVal.specific?.year || defaultYr,
            Validators.required
        ));

        [ 'yearStartsMonth', 'type', 'seasons', 'quarters' ].forEach((controlName) => timePeriodFormGroup?.removeControl(controlName));

        if (!hasTimePeriodInXAxis) {
            [ 'startYear', 'endYear', 'startMonth', 'endMonth' ].forEach((controlName) => timePeriodFormGroup?.removeControl(controlName));
        } else {

            if (chartProperties.xAxis?.partition !== 'calendarMonth') {
                [ 'startMonth', 'endMonth' ].forEach((controlName) => timePeriodFormGroup?.removeControl(controlName));
            }

            if(chartProperties.xAxis?.partition !== 'year') {
                [ 'startYear', 'endYear' ].forEach((controlName) => timePeriodFormGroup?.removeControl(controlName));
            }
        }

        timePeriodFormGroup?.valueChanges.subscribe((specificValues) => {
            this.contextualiseYearSelectionLabel(specificValues);
        });

        this.contextualiseYearSelectionLabel(timePeriodFormGroup.value);

        this.timePeriodRenderService.render();
    }


    private isInSeriesPartitions(chartProperties: any, pt: string): boolean {
        return !!chartProperties.seriesDefinitions?.find(({ partition }: any) => partition === pt);
    }

    private contextualiseYearSelectionLabel(specificValues: ɵFormGroupValue<QueryRequestTimePeriodSpecific>): void {
        if (this.dateRangeUtils.rangeSpansTwoYears(specificValues)) {

            this.endYears = this.endYears.map((year) => ({
                ...year,
                label: `${ year.code }/${ String((year.code || 0) + 1).substring(2) }`,
            }));

            xAxisSubOptions.set('years', this.endYears);

            return;
        }

        this.endYears = this.dateRangeUtils.selectableYearsAsRepoItems();

        xAxisSubOptions.set('years', this.endYears);
    }

    private timePeriodInSeries(seriesDefinitions: Partial<{ type: string; partition: string }>[] = []): boolean {
        return !!seriesDefinitions.find(({ type }) => type === 'timePeriod');
    }

    private handleSpecificType(
        specificType: 'months' | 'quarters' | 'seasons' | 'yearStartsMonth',
        previous?: ɵFormGroupValue<QueryRequestTimePeriod>
    ): void {
        const specificGroup = this.getAsFormGroup('filters.timePeriod.specific');

        switch (specificType) {

            case 'seasons': {
                [ 'quarters', 'startMonth', 'endMonth' ].forEach((controlName) => specificGroup.removeControl(controlName));

                const sumIsBool = typeof previous?.specific?.seasons?.summer === 'boolean';
                const winIsBool = typeof previous?.specific?.seasons?.winter === 'boolean';

                specificGroup.addControl('seasons', this.fb.nonNullable.group({
                    summer: this.fb.nonNullable.control(sumIsBool ? previous?.specific?.seasons?.summer : true),
                    winter: this.fb.nonNullable.control(winIsBool ? previous?.specific?.seasons?.winter : true),
                }, { validators: CustomValidators.atLeastOneChecked() }));

                break;
            }
            case 'quarters': {
                [ 'seasons', 'startMonth', 'endMonth' ].forEach((controlName) => specificGroup.removeControl(controlName));

                const controls: Record<string, AbstractControl> = {};

                specificQuarterOptions.forEach((quarter) => {
                    const quarterValBool = typeof (previous?.specific?.quarters || {})[quarter] === 'boolean';

                    controls[quarter] = this.fb.nonNullable.control(quarterValBool ? (previous?.specific?.quarters || {})[quarter] : true);
                });

                specificGroup.addControl('quarters', this.fb.nonNullable.group(controls, { validators: CustomValidators.atLeastOneChecked() }));

                break;
            }
            default: {
                [ 'seasons', 'quarters' ].forEach((controlName) => specificGroup.removeControl(controlName));

                specificGroup.addControl('startMonth', this.fb.nonNullable.control(specificGroup.value.startMonth, Validators.required));
                specificGroup.addControl('endMonth', this.fb.nonNullable.control(specificGroup.value.endMonth, Validators.required));

                this.cdr.detectChanges();

                break;
            }
        }

    }
}



