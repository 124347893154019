import { map, of, tap } from 'rxjs';

import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { Store } from '@ngrx/store';
import { appLoaded, AppLoadStatus } from '@rdc-apps/rdc-shared/src/lib/data-access/store/app-loading';


import { APP_USER_DETAILS } from './user-details-loading-keys';
import * as UserDetailsActions from './user-details.actions';
import { UserDetailsService } from './user-details.service';
import { httpErrorAction } from '../../error/+state/error.actions';

@Injectable()
export class UserDetailsEffects {

    private loadingOptions = AppLoadStatus.loading(APP_USER_DETAILS, 'Getting user...', { global: true, overlay: true });

    get$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserDetailsActions.getUserDetails),
            tap(() => this.store$.dispatch(this.loadingOptions)),
            fetch({
                run: () =>
                    this.userDetailsService.get().pipe(
                        tap(() => this.store$.dispatch(appLoaded({ key: APP_USER_DETAILS }))),
                        map((response) =>
                            UserDetailsActions.getUserDetailsSuccess({
                                userDetails: response,
                            })
                        )
                    ),
                onError: (action, response) =>
                    of(httpErrorAction({ action, response })),
            })
        )
    );

    create$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserDetailsActions.updateUserDetails),
            fetch({
                run: ({ updateUserDetails }) =>
                    this.userDetailsService.update(updateUserDetails).pipe(
                        map((response) =>
                            UserDetailsActions.updateUserDetailsSuccess({
                                userDetails: response,
                            })
                        )
                    ),
                onError: (action, response) =>
                    of(httpErrorAction({ action, response })),
            })
        )
    );

    constructor(
        private actions$: Actions,
        private store$: Store,
        private userDetailsService: UserDetailsService
    ) {}
}
