import { map, tap, withLatestFrom } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { Store } from '@ngrx/store';
import { appLoaded, AppLoadStatus } from '@rdc-apps/rdc-shared/src/lib/data-access/store/app-loading';

import * as StudyTemplateActions from './study-templates.actions';
import { fetchStudyTemplatesSuccess } from './study-templates.actions';
import { TemplateStatus } from './study-templates.models';
import { getStudyTemplateStatus } from './study-templates.selectors';
import { StudyTemplatesService } from './study-templates.service';
import { APP_STUDY_TEMPLATES } from '../../app-loading-keys';


@Injectable()
export class StudyTemplatesEffects {

    constructor(
        private readonly actions$: Actions,
        private readonly store$: Store,
        private readonly studyTemplatesService: StudyTemplatesService
    ) {}

    fetch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(StudyTemplateActions.fetchStudyTemplates),
            withLatestFrom(this.store$.select(getStudyTemplateStatus)),
            fetch({
                run: (props, status) => {

                    this.store$.dispatch(AppLoadStatus.loading(APP_STUDY_TEMPLATES, 'Loading templates...'));

                    if (status !== TemplateStatus.FETCHED) {

                        return this.studyTemplatesService.get()
                            .pipe(
                                tap(() => this.loaded()),
                                map((results) => ({
                                    type: fetchStudyTemplatesSuccess.type,
                                    results,
                                }))
                            );
                    }

                    this.loaded();

                    return {
                        type: fetchStudyTemplatesSuccess.type,
                        results: [],
                    };
                },
                onError: (action, errorResp: HttpErrorResponse) => {
                    this.loaded();

                    if (errorResp.status === 404) {
                        // we don't care about 404's, this just means no results
                        return StudyTemplateActions.fetchStudyTemplatesSuccess({ results: [] });
                    }

                    // create a string of all the errors
                    return StudyTemplateActions.fetchStudyTemplatesFailure({ error: 'Something went wrong loading these templates...' });
                },
            })
        )
    );

    private loaded(): void {
        this.store$.dispatch(appLoaded({ key: APP_STUDY_TEMPLATES }));
    }
}
