import { animate, state, style, transition, trigger } from '@angular/animations';
import { Dialog } from '@angular/cdk/dialog';
import { CommonModule } from "@angular/common";
import {
    Component,
    ElementRef, EventEmitter,
    Input, Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { RouterLink, RouterLinkActive } from "@angular/router";
import { AnimationsModule } from "@rdc-apps/rdc-shared/src/lib/animations";
import { easing } from '@rdc-apps/rdc-shared/src/lib/constants';
import { NgScrollbarModule } from "ngx-scrollbar";
import { TooltipDirective } from "shared-directives";

import { IconComponent } from '../icon/icon.component';

@Component({
    standalone: true,
    selector: 'rdc-apps-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: [ './sidebar.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
    imports: [
        CommonModule,
        IconComponent,
        AnimationsModule,
        TooltipDirective,
        RouterLinkActive,
        NgScrollbarModule,
        RouterLink,
    ],
    animations: [
        trigger('sideBarLogo', [
            state('open',
                style({ width: '184px' })
            ),
            state('closed',
                style({ width: '36px' })
            ),
            transition('* <=> *', [
                animate(`250ms ${ easing.standard }`),
            ]),
        ]),
    ],
})
export class SidebarComponent {

    @Input() appLogo = 'https://rdcaviation.com/img/apex-logo-white-full.svg';
    @Input() previewVersion = '';
    @Output() toggle = new EventEmitter<boolean>();

    @ViewChild('sidebar', { static: true }) sidebar: ElementRef<HTMLDivElement> | null = null;
    @ViewChild('smallLogoElm', { static: true }) smallLogoElm: ElementRef<HTMLImageElement> | null = null;

    open = true;

    constructor(private dialog: Dialog) {}

    onToggle(): void {
        this.open = !this.open;

        this.toggle.emit(this.open);
    }

}
