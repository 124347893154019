import { concatMap, delay, from, Observable, of } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { AppEnvironment, appEnvironment } from '@rdc-apps/rdc-apex/src/lib/shared/environment';
import { RdcBaseHttpService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { Activity } from "@rdc-apps/rdc-shared/src/lib/data-access/models";

@Injectable()
export class ActivityService extends RdcBaseHttpService {

    activities: Activity[] = [];

    constructor(
        @Inject(appEnvironment) private env: AppEnvironment
    ) {
        super();
    }

    send(activity: Activity): Observable<void> {
        this.clear();

        return this.http.post<void>(`${ this.env.api.baseUrl }/activity`, activity, { headers: this.getTokenHeaders() });
    }

    queue(activity: Activity): void {
        this.activities.push(activity);
    }

    executeQueue(): void {
        from(this.activities)
            .pipe(
                concatMap((activityItem) => of(activityItem).pipe(delay(1000)))
            ).subscribe((activity) => this.send(activity).subscribe());
    }

    clear(): void {
        this.activities = [];
    }

}
