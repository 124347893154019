import { CdkMenuModule } from "@angular/cdk/menu";
import { CommonModule } from "@angular/common";
import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ɵFormGroupValue } from '@angular/forms';
import { QuerySummariser } from '@rdc-apps/rdc-apex/src/lib/query-builder/utilities';
import { scatterGroupByOptions } from "@rdc-apps/rdc-apex/src/lib/shared/constants";
import { ApexStudy } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { overlayPositions, rdcBestFit } from '@rdc-apps/rdc-shared/src/lib/constants';
import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import * as Highcharts from 'highcharts';
import Exporting from "highcharts/modules/exporting";
import Heatmap from "highcharts/modules/heatmap";
import OfflineExporting from "highcharts/modules/offline-exporting";
import Treemap from "highcharts/modules/treemap";
import { DataPointsEntity } from 'rdc-apex-store';

import { SelectAutocompleteModule } from '../select-autocomplete/select-autocomplete.module';

@Component({
    selector: 'rdc-apps-chart',
    templateUrl: './chart.component.html',
    styleUrls: [ './chart.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
    imports: [ CommonModule, CdkMenuModule, SelectAutocompleteModule ]
})
export class ChartComponent implements OnChanges, OnDestroy, OnInit {

    @ViewChild('flow', { static: true }) flow!: ElementRef<HTMLDivElement>;

    @Input() query!: ɵFormGroupValue<ApexStudy> | any;

    @Input() dataPoints!: DataPointsEntity | any;

    @Input() lastRunTime = -1;

    @Input() xAxis: string | undefined = 'xAxis';

    @Input() scatterGroupBy: string | undefined = undefined;

    @Input() yAxis: string | undefined = 'yAxis';

    @Input() columns: { label: string; code: string; seriesType?: string; colour: string }[] = [];

    @Input() rows: string[][] = [];

    @Input() tableRows: string[][] = [];

    @Input() userFormatting: { decimalPoint?: string; thousandsSep?: string } = {};

    @Input() userColours: string[] = [ 'rgba(80,180,50,0.5)', 'rgba(237,86,27,0.5)', 'rgba(155,222,222,0.5)', 'rgba(75,75,75,0.5)' ];

    @Input() allowExporting = true;

    highlight: Partial<RepoItem<unknown>> | undefined;

    observer!: ResizeObserver;

    chart!: Highcharts.Chart;

    title = 'New study';

    dataPoint = 'None';

    highlightableDatapoints: Partial<RepoItem<string>>[] = [];

    highlightDataForDataPoint: Map<string | number, { label: string; name: string; code: string; data: any[] }> = new Map();

    scatterSeries: { name: string; id: string; zIndex: number; data: Record<string, any>[] }[] = [];

    overlayPositions = overlayPositions;

    chartCredits = {
        enabled: true,
        href: 'http://www.rdcaviation.com',
        text: 'rdcaviation.com',
        position: {
            y:-9,
        },
        style: {
            fontSize: '13px',
        }
    };

    scatterBase= {
        chart: {
            type: 'scatter',
            zoomType: 'xy',
        },
        xAxis: {
            labels: {
                format: '{value:,.f}',
                style: {
                    fontSize: '12px',
                }
            },
            title: {
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                }
            },
        },
        yAxis: {
            softMin: 0,
            softMax: 0,
            labels: {
                format: '{value:,.f}',
                style: {
                    fontSize: '12px',
                }
            },
            title: {
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                }
            },
        },
        exporting: {
            chartOptions: { // This changes the export options to adjust overlaps.
                legend: {
                    itemStyle: { fontSize: '14px' },
                },
            },
            sourceWidth: 1280,
            sourceHeight: 720,
        },
        legend: {
            enabled: true,
            itemStyle: { fontSize: '12px' },
        },
        plotOptions: {
            scatter: {
                marker: {
                    radius: 4,
                },
            },
            series: {
                turboThreshold: 1000000,
                dataLabels: {
                    enabled: true,
                    format: '{point.code}',
                },
            },
        },
        series: [],
        credits: this.chartCredits,
    };

    constructor() {
        Exporting(Highcharts);
        Treemap(Highcharts);
        Heatmap(Highcharts);
        OfflineExporting(Highcharts);
    }

    ngOnInit(): void {
        this.observer = new ResizeObserver(() => {
            if (this.columns?.length && this.rows?.length) {
                this.chart.reflow();
            }
        });

        this.observer.observe(this.flow.nativeElement);

        Highcharts.setOptions({
            colors: this.userColours,
            lang: {
                ...this.userFormatting,
                numericSymbols: [ 'K', ' M', ' B', ' T', ' Qa', ' Qi', ' Sx', ' Sp', ' O', ' N' ],
            },
        });
    }

    ngOnDestroy(): void {
        this.observer.disconnect();
    }

    ngOnChanges(): void {
        this.highlight = undefined;

        const filterText = QuerySummariser.summariseFilterBy(this.query.queryRequest?.filters, this.dataPoints);

        const isComparison = this.query.queryRequest.chartProperties.type === 'comparison';

        this.title = `${ this.yAxis }${ isComparison ? ' comparison' : '' }${ filterText ? ', ' : '' }${ filterText }`;

        if (!this.columns.length && !this.rows.length) {
            return;
        }

        this.columns = this.columns.filter(({ code }) => code !== 'DateSortable');

        switch (this.query?.studyType) {
            case 'scatter': {
                this.scatter();
                break;
            }
            default: {
                this.basic();
                break;
            }
        }
    }

    private get definedSeries(): any {
        return this.query?.queryRequest?.chartProperties?.series || [];
    }

    private hexToRgb(hex: string) {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        const hex2 = hex.replace(shorthandRegex, function(m, r, g, b) {
            return r + r + g + g + b + b;
        });

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex2);

        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        } : null;
    }

    private scatter(): void {

        const groupByLabel = scatterGroupByOptions.find(({ code }) => code === this.scatterGroupBy)?.label || this.xAxis;

        const bestFitItems = this.rows.filter((row) => row[0].includes('BestFit'));

        const rows = [ ...this.rows ].filter((row) => !row[0].includes('BestFit'));

        this.scatterSeries = [];

        const singleSeriesColour = this.query?.queryRequest?.chartProperties?.singleSeriesColour;

        let seriesToProcess = this.columns.slice(2);
        let sliced = 0;
        let tableSliced = 0;
        let currentSeries = 0;

        const arrayItemsPerSeries = 3;
        const arrayItemsPerTable = 2;

        this.highlightableDatapoints = [];
        this.highlightDataForDataPoint = new Map();

        const showGroupByLabel = seriesToProcess.length === 3;

        while (seriesToProcess.length) {

            let colour = singleSeriesColour || this.definedSeries[currentSeries]?.colour || this.userColours[currentSeries];
            let highlightRgb = colour;
            let borderRgb = colour;

            const colourRgb = this.hexToRgb(colour);

            if (colourRgb) {
                colour = `rgba(${ colourRgb.r },${ colourRgb.g },${ colourRgb.b }, 0.8)`;

                highlightRgb = `rgba(${ colourRgb.r + (colourRgb.r * 0.25)  },${ colourRgb.g + (colourRgb.g * 0.25) },${ colourRgb.b + (colourRgb.b * 0.25) }, 1)`;
                borderRgb = `rgba(${ colourRgb.r - (colourRgb.r * 0.33)  },${ colourRgb.g - (colourRgb.g * 0.33) },${ colourRgb.b - (colourRgb.b * 0.33) }, 1)`;
            }

            const tableRowsForSeries: string[][] = [];

            const seriesRowsWithSectorLen = rows.filter((row, ind) => {

                if(row[sliced + 2]) {

                    tableRowsForSeries.push(this.tableRows[ind]);

                    return true;
                }

                return false;
            });

            const series: any = {
                name: showGroupByLabel ? groupByLabel : seriesToProcess[0].label,
                id: seriesToProcess[0].code,
                color: colour,
                dataLabels: {
                    zIndex: 2,
                },
                marker: {
                    symbol: 'circle',
                },
                data: seriesRowsWithSectorLen.map((row) => {

                        const label = `[${ row[1] }] ${ row[0] }`;

                        const existing = this.highlightDataForDataPoint.get(row[1])?.data || [];

                        const tableRow2 = tableRowsForSeries.find((ro) => ro[1] === row[1]) || [];

                        const parsedRowData = {
                            x: Number(row[sliced + 2]),
                            y: row[sliced  + 3] === '' ? null : Number(row[sliced  + 3]),
                            name: row[0],
                            code: row[1],
                            formatted: tableRow2[tableSliced + 3],
                            td: tableRow2,
                            row,
                        };

                        this.highlightDataForDataPoint.set(row[1], {
                            label,
                            code: row[1],
                            name: `Highlight - ${ label }`,
                            dataLabels: {
                                zIndex: 6,
                            },
                            data: [
                                ...existing,
                                {
                                    name: `Highlight - ${ label }`,
                                    marker: {
                                        radius: 10,
                                        symbol: 'diamond',
                                        lineWidth: 2,
                                        lineColor: borderRgb,
                                    },
                                    color: highlightRgb,
                                    data: [ parsedRowData ],
                                },
                            ],
                        } as any);

                        return parsedRowData;
                    }),
            };

            this.scatterSeries.push(series);

            if (bestFitItems.length > 2) {

                const polynomialLine = {
                    type: 'spline',
                    name: 'Best fit',
                    color: colour,
                    enableMouseTracking: false,
                    data: bestFitItems.map((bfi) => [ Number(bfi[sliced + 2]), Number(bfi[sliced + 4]) ]),
                    marker: {
                        enabled: false,
                    },
                    states: {
                        hover: {
                            lineWidth: 2,
                        },
                    },
                } as never;

                this.scatterSeries.push(polynomialLine);

            } else {
                // last 2 rows in a scatter response are the min and max for the lines of best fit.

                const bestFit = rdcBestFit(bestFitItems, sliced, {
                    name: 'Best fit',
                    color: colour,
                    enableMouseTracking: false,
                    states: {
                        hover: {
                            lineWidth: 2,
                        },
                    },
                });

                this.scatterSeries.push(bestFit);
            }

            seriesToProcess = seriesToProcess.slice(arrayItemsPerSeries);
            sliced = sliced + arrayItemsPerSeries;
            tableSliced = tableSliced + arrayItemsPerTable;
            currentSeries++;
        }

        this.highlightDataForDataPoint.forEach((value) =>
            this.highlightableDatapoints.push({ label: value.label, code: value.code, nameWoCode: value.name })
        );

        this.highlightableDatapoints.sort((a, b) => (a['nameWoCode']).localeCompare(b['nameWoCode']));

        this.chart = Highcharts.chart('chart', {
            ...this.scatterBase,
            title: {
                text: this.title,
                style: {
                    color: '#727F8F',
                    fontWeight: 'bold',
                },
            },
            xAxis: {
                labels: {
                    style: {
                        fontSize: '12px',
                    }
                },
                title: {
                    text: this.xAxis || groupByLabel,
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }
                },
            },
            yAxis: {
                softMin: 0,
                softMax: 0,
                labels: {
                    style: {
                        fontSize: '12px',
                    }
                },
                title: {
                    text: this.yAxis,
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }
                },
            },
            exporting: {
                ...this.scatterBase.exporting,
                enabled: this.allowExporting,
            },
            legend: {
                enabled: this.query.queryRequest.chartProperties.type !== 'single',
                itemStyle: { fontSize: '12px' },
            },
            tooltip: {
                pointFormat: `<strong>[{point.code}] {point.name}</strong>
                    </br>${ this.xAxis }: <strong>{point.x:,.0f}</strong>
                    </br>${ this.yAxis }: <strong>{point.formatted}</strong>`,
            },
            series: this.scatterSeries,
            credits: this.chartCredits,
        } as never);

    }

    private basic(): void {

        const categories = this.rows.map((data) => data[0]);

        const singleSeriesColour = this.query?.queryRequest?.chartProperties?.singleSeriesColour;

        // sometimes the xAxisCode is not present, we need to adjust how we ge the data in these instances.
        const hasCode = !!this.columns.find(({ code }) => code === 'xAxisCode');

        const sliceCount = hasCode ? 2 : 1;

        const slicedCols = this.columns.slice(sliceCount);

        const series = slicedCols.map((column, seriesIndex) => {

            let colour = singleSeriesColour || column.colour || this.userColours[seriesIndex];

            const colourRgb = this.hexToRgb(colour);

            if (colourRgb) {
                colour = `rgba(${ colourRgb?.r },${ colourRgb?.g },${ colourRgb?.b }, 1)`;
            }

            return {
                name: column.label,
                color: colour,
                marker: {
                    symbol: 'circle',
                },
                type: column.seriesType === 'bar' ? 'column' : column.seriesType,
                data: this.rows.map((rowData) => {
                    const tableRow = this.tableRows.find((ro) => ro[0] === rowData[0]) || [];

                    return {
                        y: rowData.slice(sliceCount)[seriesIndex] === '' ? null : Number(rowData.slice(sliceCount)[seriesIndex]),
                        name: `<strong>${ rowData[0] }</strong></br>${column.label}`,
                        formatted: tableRow[sliceCount + seriesIndex],
                        td: tableRow,
                        d: rowData,
                    }
                }),
            };
        });

        this.chart = Highcharts.chart('chart', {
            title: {
                text: this.title,
                style: {
                    color: '#727F8F',
                    fontWeight: 'bold',
                },
            },
            xAxis: {
                labels: {
                    style: {
                        fontSize: '12px',
                    }
                },
                title: {
                    text: this.xAxis,
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }
                },
                categories,
            },
            yAxis: {
                softMin: 0,
                softMax: 0,
                labels: {
                    style: {
                        fontSize: '12px',
                    }
                },
                title: {
                    text: this.yAxis,
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }
                },
            },
            legend: {
                enabled: this.query.queryRequest.chartProperties.type !== 'single',
                itemStyle: { fontSize: '12px' },
            },
            tooltip: {
                pointFormat: `${ this.yAxis }: {point.formatted}`,
            },
            exporting: {
                enabled: this.allowExporting,
                chartOptions: { // This changes the export options to adjust overlaps.
                    legend: {
                        itemStyle: { fontSize: '14px' },
                    },
                },
                sourceWidth: 1280,
                sourceHeight: 720,
            },
            plotOptions: {
                series: {
                    turboThreshold: 1000000,
                },
            },
            series,
            credits: this.chartCredits,
        } as never);

    }

    onHighlightOptChange($event: Partial<RepoItem<unknown>>) {
        this.highlight = $event;

        const itemCode = String($event.code) || '';

        const highlights = this.highlightDataForDataPoint.get(itemCode)?.data || [];

        let seriesWithHighlights = this.scatterSeries.slice();

        highlights.forEach((highlight, index) => {
            // 2 items per "series"
            const math = (2 * (index + 1)) + index;

            seriesWithHighlights.splice(math, 0, highlight);
        });

        seriesWithHighlights = seriesWithHighlights.map((series, index) => ({
            ...series,
            zIndex: index,
        }));

        this.chart = Highcharts.chart('chart', {
            ...this.scatterBase,
            title: {
                text: this.title,
                style: {
                    color: '#727F8F',
                    fontWeight: 'bold',
                },
            },
            yAxis: {
                softMin: 0,
                softMax: 0,
                labels: {
                    style: {
                        fontSize: '12px',
                    }
                },
                title: {
                    text: this.yAxis,
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }
                },
            },
            exporting: {
                ...this.scatterBase.exporting,
                enabled: this.allowExporting,
            },
            plotOptions: {
                ...this.scatterBase.plotOptions,
                scatter: {
                    marker: {
                        radius: 3, // make other series smaller
                    },
                },
            },
            tooltip: {
                pointFormat: `<strong>[{point.code}] {point.name}</strong>
                    </br>${ this.xAxis }: <strong>{point.x:,.f}</strong>
                    </br>${ this.yAxis }: <strong>{point.formatted}</strong>`,
            },
            series: seriesWithHighlights,
        } as never);

    }
}
