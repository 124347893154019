import { of } from 'rxjs';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { Store } from '@ngrx/store';
import { initQueriesViaDataPoints , httpErrorAction , initQueries } from 'rdc-apex-store';



@Injectable()
export class QueryViaDataPointEffects {
    initQueriesViaDataPoints = createEffect(() =>
        this.actions$.pipe(
            ofType(initQueriesViaDataPoints),
            fetch({
                run: () => this.store$.dispatch(initQueries()),
                onError: (action, response) =>
                    of(httpErrorAction({ action, response })),
            })
        )
    );

    constructor(private actions$: Actions, private store$: Store) {}
}
