import { Injectable } from '@angular/core';
import { AbstractControl, FormArray } from '@angular/forms';
import { RouteOption } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';

@Injectable({ providedIn: 'root' })
export class IataIcaoCodesService {

    itemSummary(value: RouteOption, array: RouteOption[], index: number): string {
        const code = value.iataCode || value.icaoCode || value.threeLetterCode || '';

        return array.length > 1 && array.length - 1 !== index
            ? `${code},`
            : code;
    }

    itemSummaryControl(control: AbstractControl, controlsArray: FormArray, index: number): string {
        const code = control.value.iataCode || control.value.icaoCode || control.value.threeLetterCode || '';

        return controlsArray.length > 1 && controlsArray.length - 1 !== index
            ? `${code},`
            : code;
    }

}
