import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RdcButtonDirective, RdcIconButtonDirective } from "shared-directives";
import { IconComponent } from "shared-ui";

import { DefaultPreferencesItemComponent } from './default-preferences-item.component';



@NgModule({
    imports: [ CommonModule, IconComponent, RdcButtonDirective, RdcIconButtonDirective ],
    declarations: [ DefaultPreferencesItemComponent ],
    exports: [
        DefaultPreferencesItemComponent,
    ],
})
export class DefaultPreferencesItemModule {}
