import { debounce, interval, Subject, takeUntil } from "rxjs";

import { CdkDrag, CdkDragDrop, CdkDropList } from "@angular/cdk/drag-drop";
import {
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    CdkVirtualScrollableElement,
    CdkVirtualScrollViewport
} from "@angular/cdk/scrolling";
import { CommonModule } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    computed,
    effect,
    ElementRef,
    EventEmitter,
    HostListener,
    inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    Signal,
    signal,
    TemplateRef,
    untracked,
    ViewChild,
    ViewChildren,
    WritableSignal
} from '@angular/core';
import { Icon } from "@rdc-apps/rdc-shared/src/lib/data-access/models";
import { TooltipDirective } from "shared-directives";

import {
    FormatNumericalPipe, HasGroupsAndWidthPipe, HeightCombined,
    RowsForColumnPipe,
    RowStatePipe,
    SortingByColumnPipe,
    TableClientFilterPipe
} from "./client-filter.pipe";
import { columnSettingsProperties } from "./column-settings-properties";
import { RdcTableVirtualScrollDirective } from "./rdc-scroll";
import { IconComponent } from "../icon/icon.component";
import { LoadingOverlayComponent } from "../loading-overlay/loading-overlay.component";

export interface RdcDataTableColumn extends RdcDataTableColumnSettings {
    label: string;
    code: string;
    flex?: string;
    width?: number;
    minWidth?: number;
    originalIndexInData?: number;
}
export interface RdcDataTableColumnSettings {
    widthOverride?: number;
    isNumerical?: boolean;
    actionable?: boolean;
    showTooltip?: boolean;
    hidden?: boolean;
    sortAlias?: string;
    sortable?: boolean;
    columnExpands?: boolean;
    forceAlign?: 'left' | 'right';
    movable?: boolean;
    backgroundColour?: string;
    headerTemplate?: TemplateRef<any>;
    grouping?: {
        headerTemplate?: TemplateRef<any>;
        groupCode: string;
        groupName: string;
    }
}

interface DataTableResizeSubject {
    index: number;
    column: RdcDataTableColumn,
}

export interface RdcDataTableRowAction {
    row: Record<string, string | boolean>,
    rowId: string
}

export interface RdcDataTableSort {
    columnCode: string;
    sortDirection: 'descending' | 'ascending';
}

export interface RdcDataTableRowStateOptions {
    label: string | undefined;
    state: string | undefined;
    subRows?: string[][];
    subRowExpanded?: boolean;
    rowInComplete?: boolean;
    subRowCompleteness?: string[][];
    baseData?: RdcDataTableColumn[];
    tooltip?: string;
    tooltipIcon?: string;
    tooltipIconSize?: Icon;
}

export type RdcDataTableRowState = Map<string, RdcDataTableRowStateOptions>;

export interface RdcDataTableCellAction {
    column: RdcDataTableColumn;
    row: Record<string, string>;
    rowId: string;
    expandable?: boolean;
    rowExpanded?: boolean;
}


@Component({
    standalone: true,
    selector: 'rdc-apps-table',
    templateUrl: './table.component.html',
    styleUrl: './table.component.scss',
    imports: [
        CommonModule,
        CdkVirtualScrollViewport,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        IconComponent,
        CdkDropList,
        CdkDrag,
        TableClientFilterPipe,
        LoadingOverlayComponent,
        CdkVirtualScrollableElement,
        FormatNumericalPipe,
        SortingByColumnPipe,
        RowsForColumnPipe,
        RowStatePipe,
        TooltipDirective,
        HasGroupsAndWidthPipe,
        HeightCombined,
        RdcTableVirtualScrollDirective,
    ],
})
export class TableComponent implements OnInit, OnDestroy {

    @ViewChild('viewportContainer', { static: true }) viewPortContainer!: ElementRef<HTMLElement>;

    @ViewChildren('columnContainer') columnContainers!: QueryList<ElementRef<HTMLElement>>;

    @ViewChild('viewport', { static: true }) vsViewport!: CdkVirtualScrollViewport;

    @ViewChild('tableGroupElements') tableGroupElements!: ElementRef<HTMLDivElement>;

    @ViewChild('tableFooter', { static: true }) tableFooter!: ElementRef<HTMLElement>;

    @ViewChild('headerRow', { static: true }) headerRow!: ElementRef<HTMLElement>;

    lastReceived = new Map().set('rows', 0);

    lastProcessed = new Map().set('rows', 0);

    subRowCount = signal(0);

    @Input() customCellClass = '';
    @Input() customHeaderClass = '';

    // eslint-disable-next-line
    @Input('rows') set setRows(rows: string[][]) {
        // deep level clone otherwise it still seems to reference the original array, add the rowId (index) too
        this.rows.set([ ...rows ].map((row, index) => [ String(index + 1), ...row ]));

        this.cdr.detectChanges();

        this.lastReceived.set('rows', Date.now());
    }

    // eslint-disable-next-line
    @Input('columns') set setColumns(columns: RdcDataTableColumn[]) {
        this.columns.set(columns);

        this.lastReceived.set('columns', Date.now());
    };

    // eslint-disable-next-line
    @Input('hiddenColumnCodes') set setHiddenColumns(columnCodes: string[]) {
        this.hiddenColumnCodes.set([ 'rowId', ...columnCodes ]); // always hide rowId

        //  update the columns accordingly
        this.parsedCols.update((cols) =>
            cols.map((col) => ({ ...col, hidden: this.hiddenColumnCodes().includes(col.code) }))
        );
    };

    // eslint-disable-next-line
    @Input('fixedFooterRow') set setFixedFooterRow(fixedFooterRow: string[] | undefined) {
        this.fixedFooterRow.set(fixedFooterRow);
    }
    // eslint-disable-next-line
    @Input('fixedHeaderRow') set setFixedHeaderRow(fixedHeaderRow: string[] | undefined) {
        this.fixedHeaderRow.set(fixedHeaderRow);
    }

    // eslint-disable-next-line
    @Input('serverSideSorting') set setServerSorting(sortOnServer: boolean) {
        this.serverSideSorting = sortOnServer;
        //  clear any previous server side sorting if this has changed dynamically
        if (sortOnServer) {
            this.sorting = undefined;
        }
    };

    serverSideSorting = false;

    @Input() rowSize = 32;

    @Input() fixedRowType: 'normal' | 'total' = 'total';

    @Input() loading = false;

    @Input() highlightColumns: string[] = [];

    @Input() sortable = true;

    @Input() columnMode: 'stretch' | 'append' | 'append-full-width' | 'append-full-width-centered' = 'stretch';

    @Input() orderable  = true;

    @Input() resizable  = true;

    @Input() multiline  = false;

    @Input() sorting: RdcDataTableSort | undefined | null = undefined;

    // give the container elm if you want it to have a fixed/responsive height. omit and it will grow / shrink to the
    // record count, then you might want to pass a maxHeight to stop it going too large
    @Input() fitToContainerElm!: HTMLElement;

    @Input() stretchToContainerHeight = true;

    // override the state of a row action column by row id
    // eslint-disable-next-line
    @Input('rowStates') set setRowStates(givenRowStates: RdcDataTableRowState) {
        this.storedRowStates.set(givenRowStates || new Map());

        this.lastReceived.set('rowStates', Date.now());
    }

    storedRowStates = signal<RdcDataTableRowState>(new Map());

    parsedRowStates = signal(0);

    rowStates: RdcDataTableRowState = new Map();

    // maximum height of the table when not using the dynamic height table
    @Input() maxHeightPx: number | undefined = undefined;

    @Input() defaultRowActionLabel = 'Row action';

    @Input() disableCopy: boolean | null | undefined = false;

    @Output() sort = new EventEmitter<RdcDataTableSort>();

    @Output() clientSort = new EventEmitter<RdcDataTableSort>();

    @Output() cellAction = new EventEmitter<RdcDataTableCellAction>();

    @Output() rowAction = new EventEmitter<any>();

    @HostListener('document:mousemove', [ '$event' ]) onMouseMove(event: MouseEvent) {
        if(!this.resizeSubject) {
            return;
        }

        const newSize = this.tempResizeWidth[this.resizeSubject.index] + event.movementX;

        this.tempResizeWidth[this.resizeSubject.index] = newSize;

        this.parsedCols.update((cols) => [ ...cols ]);
    }

    @HostListener('document:mouseup', [ '$event' ]) onResizeFinish() {
        if(!this.resizeSubject) {
            return;
        }

        const index = this.resizeSubject.index;

        const sizeForColumn = this.tempResizeWidth[this.resizeSubject.index];

        this.parsedCols.update((columns) => {
            columns[index].width = sizeForColumn;
            columns[index].minWidth = undefined;
            columns[index].widthOverride = undefined;

            return [ ...columns ];
        });

        this.resizeSubject = undefined;
        this.tempResizeWidth = [];
    }

    tempResizeWidth: number[] = [];

    parsedCols: WritableSignal<RdcDataTableColumn[]> = signal([]);

    parsedRows: WritableSignal<string[][]> = signal([]);

    rows: WritableSignal<string[][]> = signal([]);

    columns: WritableSignal<RdcDataTableColumn[]> = signal([]);

    hiddenColumnCodes: WritableSignal<string[]> = signal([ 'rowId' ]);

    dragging = false;

    resizeSubject: DataTableResizeSubject | undefined = undefined;

    fixedFooterRow: WritableSignal<string[] | undefined> = signal(undefined);
    parsedFixedFooterRow: WritableSignal<string[] | undefined> = signal(undefined);

    fixedHeaderRow: WritableSignal<string[] | undefined> = signal(undefined);
    parsedFixedHeaderRow: WritableSignal<string[] | undefined> = signal(undefined);

    rowHighlightIndex = -1;
    groupHighlightIndex = -1;

    tableCalculatedWidth: Signal<string> = computed(() => {

        if([ 'stretch', 'append-full-width', 'append-full-width-centered' ].includes(this.columnMode)) {
            return '100%';
        }

        let widthOfColumns = 0;

        this.parsedCols().forEach((col, ind) => {
            if(col.hidden) {
                return;
            }

            const columnWidth = this.tempResizeWidth[ind] || col.widthOverride || col.width || col.minWidth || 0;

            widthOfColumns += columnWidth;
        });

        return `${ widthOfColumns + 1 }px`;

    });

    columnGroups = signal<{ label: string; code: string; width: number; headerTemplate?: any }[]>([]);

    hideGroupNames = signal<boolean>(true);

    sortedArr = signal<string[][]>([]);

    itemSizes = computed(() => {
        this.openRowTrigger();

        return this.sortedArr().map(([ rowId ]) => {
            const srl = this.rowStates.get(rowId)?.subRows?.length;

            const open = this.rowStates.get(rowId)?.subRowExpanded;

            if(open && srl) {
                return (this.rowSize * (srl + 1)); // plus one for parent row
            }

            return this.rowSize;
        });
    });

    highlightHeight = computed(() => {
        this.openRowTrigger();
        this.dataRefreshed();

        let highlightSize = (this.parsedRows()?.length * this.rowSize) + this.headerSize();

        if(this.parsedFixedHeaderRow()) {
            highlightSize+= this.rowSize;
        }

        Array.from(this.rowStates.keys()).forEach((key) => {
            const keyValue = this.rowStates.get(key);

            if(keyValue?.subRowExpanded && keyValue?.subRows?.length) {
                highlightSize += (this.rowSize * keyValue.subRows.length);
            }
        });

        const maxHeightPx = (this.maxHeightPx || 0);

        setTimeout(() => {
            this.cdr.detectChanges();
        });

        if(highlightSize < this.viewPortHeight()) {
            return highlightSize;
        } else if (highlightSize < maxHeightPx) {
            return highlightSize - 1;
        }

        return (maxHeightPx || this.viewPortHeight()) - 1;
    });

    borderlessEndCol: Signal<boolean> = computed(() => {
        let widthOfColumns = 0;

        const displayedColumns = this.parsedCols().filter(({ hidden }) => !hidden);

        displayedColumns.forEach((col) => {

            const columnWidth = col.widthOverride || col.width || col.minWidth || 0;

            widthOfColumns += columnWidth;
        });

        if(this.columnMode === 'stretch') {

            const allHaveWidth = displayedColumns.every((col) => col.widthOverride || col.width);

            return !allHaveWidth || (widthOfColumns >= this.viewPortContainer.nativeElement.clientWidth);
        }

        return widthOfColumns >= this.viewPortContainer.nativeElement.clientWidth;
    });

    borderlessEndRow: WritableSignal<boolean> = signal(false);

    // determined size of the scrollbar for different browsers and operating systems
    private scrollBarSize: WritableSignal<number> = signal(0);

    // dynamic size of the footer
    private footerSize: WritableSignal<number> = signal(0);

    headerSize: WritableSignal<number> = signal(0);

    // reactive size of the parent container when applicable, so the table can resize correctly
    private containedHeight: WritableSignal<number> = signal(0);

    openRowTrigger = signal(0);
    dataRefreshed = signal(Date.now());

    private rowDefinedHeight: Signal<number> = computed(() => {
        this.openRowTrigger();

        let calcHei = (this.rows().length * this.rowSize) + this.headerSize();

        if (this.fixedFooterRow()) {
            calcHei += this.rowSize;
        }
        if (this.fixedHeaderRow()) {
            calcHei += this.rowSize;
        }

        Array.from(this.rowStates.keys()).forEach((key) => {
            const keyValue = this.rowStates.get(key);

            if(keyValue?.subRowExpanded && keyValue?.subRows?.length) {
                calcHei += (this.rowSize * keyValue.subRows.length);
            }
        });

        if(this.scrollBarSize() > 0) {
            return (calcHei + this.scrollBarSize());
        }

        return calcHei;
    });

    private vpContainerHeightObs = new ResizeObserver(([ viewportContainer ]) => {
        const viewPortHeight = this.vsViewport.elementRef.nativeElement.clientHeight;
        const vpContainerHeight = this.viewPortContainer.nativeElement.clientHeight;

        this.applyLastRowBorderIfApplicable(viewportContainer.contentRect.height);

        const newScrollBarSize = (viewPortHeight - vpContainerHeight) + this.headerSize();

        if(newScrollBarSize){
            this.scrollBarSize.set(newScrollBarSize > 30 ? 0 : newScrollBarSize);
        }
    });

    private fitToParentElementObs = new ResizeObserver(() => {
        this.containedHeight.set(this.fitToContainerElm.offsetHeight);
    });

    private headerRowObs = new ResizeObserver(([ header ]) => {
        this.headerSize.set(Math.ceil(header.contentRect.height));
    });

    private tableFooterObs = new ResizeObserver(([ footer ]) => {
        this.footerSize.set(Math.ceil(footer.contentRect.height));
    });

    private viewPortObs = new ResizeObserver(() => {
        this.parsedCols.update((cols) => [ ...cols ]);
    });

    protected cdr = inject(ChangeDetectorRef);

    columnRepositions: [number, number][] = [];

    private tableRerender$ = new Subject<void>();
    private destroyed$ = new Subject<void>();

    // the definitive height to use, if the table is within a provided container, use its dimensions
    // otherwise, use the dynamic ever-growing height from the rows (max height can override)
    viewPortHeight: Signal<number> = computed(() => {

        if(this.fitToContainerElm) {

            const calcHei = (this.rows().length * this.rowSize) + this.headerSize();

            if(!this.stretchToContainerHeight && calcHei <= this.containedHeight()) {
                return calcHei;
            }

            return (this.containedHeight() - this.footerSize());
        }

        if(!this.parsedRows().length) {
            return 110; // min height for empty table
        }

        return this.rowDefinedHeight();
    });

    constructor() {
        // when the viewport height changes, detect changes and rerender scroll
        effect(() => {
            this.viewPortHeight();

            this.cdr.detectChanges();
            this.vsViewport.checkViewportSize();
        });

        // when new rows and columns received
        effect(() => {
            this.columns();
            this.rows();
            this.storedRowStates();

            this.tableRerender$.next();
        }, { allowSignalWrites: true });
    }

    ngOnDestroy() {
        this.vpContainerHeightObs.disconnect();
        this.viewPortObs.disconnect();
        this.tableFooterObs.disconnect();
        this.headerRowObs.disconnect();
        this.fitToParentElementObs.disconnect();

        this.destroyed$.next();
        this.destroyed$.complete();
        this.tableRerender$.complete()
    }

    groupObservers: ResizeObserver[] = [];

    ngOnInit(): void {

        this.columnContainers.changes
            .pipe(debounce(() =>  interval(0)))
            .subscribe((colElements: QueryList<ElementRef<HTMLElement>>) => {

                this.groupObservers.forEach((obs) => obs.disconnect());

                this.groupObservers = [];

                colElements.forEach(({ nativeElement }) => {

                    const obs = new ResizeObserver(() => {

                        const groups: { label: string; code: string; width: number; headerTemplate?: any }[] = [];

                        let groupIndex = 0;

                        this.parsedCols()
                            .filter(({ hidden }) => !hidden)
                            .reduce((prevCode: string, cc, currentIndex) => {

                                const groupCode = cc.grouping?.groupCode || 'undefined';
                                const groupName = cc.grouping?.groupName || '';
                                const groupHeaderTemplate = cc.grouping?.headerTemplate;

                                const boundingBox = colElements.get(currentIndex)?.nativeElement.getBoundingClientRect();

                                if(prevCode === groupCode) {

                                    const previous = groups[groupIndex] || { width: 0 };

                                    groups[groupIndex] = {
                                        label: groupName,
                                        code: groupCode,
                                        width: previous.width + (boundingBox?.width || 0),
                                        headerTemplate: groupHeaderTemplate,
                                    };

                                    return prevCode;
                                }

                                groupIndex++;

                                groups[groupIndex] = {
                                    label: groupName,
                                    code: groupCode,
                                    width: boundingBox?.width || 0,
                                    headerTemplate: groupHeaderTemplate,
                                };

                                return groupCode;

                            }, 'undefined');

                        this.hideGroupNames.set(!groups.some((definedGroup) => definedGroup.label));

                        this.columnGroups.set(groups);
                    });

                    obs.observe(nativeElement);

                    this.groupObservers.push(obs);
                });

            });

        this.tableRerender$
            .pipe(
                takeUntil(this.destroyed$),
            ).subscribe(() => {
            this.parseTableData();
            this.dataRefreshed.set(Date.now());
        });

        this.vpContainerHeightObs.observe(this.viewPortContainer.nativeElement);
        this.viewPortObs.observe(this.vsViewport.elementRef.nativeElement);
        this.tableFooterObs.observe(this.tableFooter.nativeElement);
        this.headerRowObs.observe(this.headerRow.nativeElement);

        if(!this.fitToContainerElm) {
            return;
        }

        this.fitToParentElementObs.observe(this.fitToContainerElm);
    }

    private parseTableRows(): string[][] {

        const rows = this.rows().slice().map((row) => {
            let mappedRow = row;

            for(const [ prev, curr ] of this.columnRepositions) {
                mappedRow = this.moveInArray(row, curr, prev);
            }

            return mappedRow;
        });

        const lastRowId = String(this.rows().length + 1);

        this.setFixedRowIfDefined(this.fixedHeaderRow(), this.parsedFixedHeaderRow, '0');
        this.setFixedRowIfDefined(this.fixedFooterRow(), this.parsedFixedFooterRow, lastRowId);

        return rows;
    }

    private setFixedRowIfDefined(value: string[] | undefined, row: WritableSignal<string[] | undefined>, rowId: string) {
        if(value) {
            let parsedRow = [ rowId, ...value ];

            for(const [ prev, curr ] of this.columnRepositions) {
                parsedRow = this.moveInArray(parsedRow, curr,prev);
            }

            row.set(parsedRow);
        } else {
            row.set(undefined);
        }
    }

    private parseTableData(): void {

        const columnsChanged = this.columnsChanged(this.columns());

        if(columnsChanged) {
            this.columnRepositions = [];
        }

        this.parseRowStateData();

        let rowsHaveChanged = false;

        let rows: string[][] = [];

        if(this.lastProcessed.get('rows') !== this.lastReceived.get('rows')) {
            rows = this.parseTableRows();

            rowsHaveChanged = true;
        }

        if(!columnsChanged) {
            this.updateColumnSettings();
        }

        const columns = columnsChanged ? this.columns() : untracked(this.parsedCols);

        // if the columns have changed (or is first instance)
        if(columnsChanged) {
            // used to keep order of states when sorted on client
            columns.unshift({
                code: 'rowId',
                label: 'Row Id',
            });
        }

        const parsedCols: RdcDataTableColumn[] = [];

        // const containsAlphabeticChars = /[a-zA-Z]/;
        // eg: 104 m/ 10.5 bn - VALID, 104 million - invalid
        const endsWithNumSpaceOneOrTwoChar = /[0-9][\s][a-z]{1,2}$/gmi;

        const pixelsPerChar = 9.5;

        columns.forEach((col, columnIndex: number) => {

            // sort rows by length of string for that column
            const rowWithLongestItem = [ ...rows ]
                .sort((rowA, rowB) => rowB[columnIndex].length - rowA[columnIndex].length)[0] || [];

            const longestRowValue = (rowWithLongestItem[columnIndex] || '').replace(/[^a-zA-Z0-9]/gmi, '');

            // we want to do stretch mode, so only use the longest word form the header row
            const longestHeaderWord = col.label
                .split(' ')
                .sort((a,b) => b.length - a.length)[0] || '';

            const longestValue = (longestHeaderWord.length > longestRowValue.length) ? longestHeaderWord : longestRowValue;

            let calculatedColumnWidth = col.widthOverride || (longestValue.length * pixelsPerChar);

            if(this.sortable) {
                // add 20px for the sort icon, else words will be cut
                calculatedColumnWidth+= 20;
            }

            const isNumerical = (longestRowValue.length && !isNaN(longestRowValue as never)) || endsWithNumSpaceOneOrTwoChar.test(longestRowValue);

            const flexGrowShrink = this.columnMode === 'stretch' ? '1 1' : '0 0';

            const colMinWidth = (calculatedColumnWidth < 75) ? 75 : calculatedColumnWidth;

            parsedCols.push({
                ...col,
                flex: col.width ? undefined : `${ flexGrowShrink } ${ calculatedColumnWidth }px`, // creates for example: '1 1 250px' as a flex value
                minWidth: col.width ?? colMinWidth,
                isNumerical: col.isNumerical ?? isNumerical,
                hidden: untracked(this.hiddenColumnCodes).includes(col.code),
                sortable: String(col.sortable) === 'false' ? false : true,
                movable: String(col.movable) === 'false' ? false : true,
            });

        });

        this.parsedCols.set(parsedCols);

        this.cdr.detectChanges();

        if(rowsHaveChanged) {
            this.parsedRows.set(rows);

            this.lastProcessed.set('rows', this.lastReceived.get('rows'));
        }
    }

    private columnsChanged(columns: RdcDataTableColumn[]): boolean {

        const existingColumns = untracked(this.parsedCols)
            .filter(({ code }) => code !== 'rowId')
            .map(({ code, label }) => `${code}-${label}`);

        const newColumns = [ ...columns ]
            .filter(({ code }) => code !== 'rowId')
            .map(({ code, label }) => `${code}-${label}`);

        const columnsMatchExisting = newColumns.every((colStr) => existingColumns.includes(colStr || '*'));
        const noNewColumns = (newColumns.length === existingColumns.length);

        return !(columnsMatchExisting && noNewColumns);
    }

    onDrop(data: CdkDragDrop<any, any>): void {
        const { previousIndex, currentIndex, item } = data;

        // account for any hidden columns, as they will be shifted left by 1 for each hidden col
        const adjustedPrevInd = previousIndex;
        const adjustedCurrInd = currentIndex;

        this.columnRepositions.push([ adjustedPrevInd, adjustedCurrInd ]);

        // move the column into the new position
        this.parsedCols.update((columns) =>
            this.moveInArray(columns, adjustedCurrInd, adjustedPrevInd)
        );

        // move the values for each row into the new position
        this.parsedRows.update((rows) =>
            [ ...rows ].map((row) => this.moveInArray(row, adjustedCurrInd, adjustedPrevInd))
        );

        const footerRow = this.parsedFixedFooterRow();
        const headerRow = this.parsedFixedHeaderRow();

        if(footerRow) {
            const updated = this.moveInArray(footerRow, adjustedCurrInd, adjustedPrevInd);

            this.parsedFixedFooterRow.set(updated);
        }

        if(headerRow) {
            const updated = this.moveInArray(headerRow, adjustedCurrInd, adjustedPrevInd);

            this.parsedFixedHeaderRow.set(updated);
        }

        Array.from(this.rowStates.keys()).forEach((key) => {
            const rowState = this.rowStates.get(key);

            if(rowState?.subRows?.length) {

                const upd = rowState?.subRows.map((row) => this.moveInArray(row, adjustedCurrInd, adjustedPrevInd)) || [];

                this.rowStates.set(key, {
                    ...rowState,
                    subRows: upd,
                });
            }
        });

        this.dragging = false;
        this.rowHighlightIndex = -1;
    }

    onSort(column: RdcDataTableColumn): void {
        if(!this.sortable || !column.sortable) {
            return;
        }
        // by default the apply the general sort direction logic
        // numbers descend first, numbers ascend first
        let newSortDirection: 'descending' | 'ascending' = column.isNumerical ? 'descending' : 'ascending';

        // if the column code is the same as the current sort.
        if([ column.code, column.sortAlias ].includes(this.sorting?.columnCode || '*')) {
            // get the existing sort direction
            const existingSortDirection = this.sorting?.sortDirection;

            if(column.isNumerical && existingSortDirection === 'ascending') {
                if (this.serverSideSorting) {
                    this.sort.emit(undefined);

                    return;
                }
                this.sorting = undefined;
                this.clientSort.emit(this.sorting);

                return;
            }
            else if(!column.isNumerical && existingSortDirection === 'descending') {
                if(this.serverSideSorting) {
                    this.sort.emit(undefined);

                    return;
                }
                this.sorting = undefined;
                this.clientSort.emit(this.sorting);

                return;
            }

            // set it to the opposite of the current
            newSortDirection = (existingSortDirection === 'descending') ? 'ascending' : 'descending';
        }

        if(this.serverSideSorting) {

            this.sort.emit({
                columnCode: column.sortAlias || column.code,
                sortDirection: newSortDirection,
            });

            return;
        }

        this.sorting = {
            columnCode: column.sortAlias || column.code,
            sortDirection: newSortDirection,
        };

        this.clientSort.emit(this.sorting);
    }

    protected moveInArray<T>(arr: T[], currentIndex: number, previousIndex: number): T[] {

        const dragValue = arr[previousIndex];

        arr.splice(previousIndex, 1);
        arr.splice(currentIndex, 0, dragValue);

        return [ ...arr ];
    }

    private applyLastRowBorderIfApplicable(viewPortHeight: number): void {
        // determine if the final row needs a border-bottom
        let sizeOfContent = (this.rows().length * this.rowSize) + this.headerSize();

        if(this.fixedFooterRow()) {
            sizeOfContent += this.rowSize;
        }

        this.borderlessEndRow.set((viewPortHeight - sizeOfContent < 0) || [ 0, 1 ].includes(viewPortHeight - sizeOfContent));
    }

    onCellAction(column: RdcDataTableColumn, row: string[]): void {

        const currVal = this.rowStates.get(row[0]);

        if(column.columnExpands && currVal) {

            this.openRowTrigger.set(Date.now());

            const newVal = !currVal.subRowExpanded;

            if(newVal) {
                this.subRowCount.update((count) => count + (currVal.subRows?.length || 0));
            } else {
                this.subRowCount.update((count) => count - (currVal.subRows?.length || 0));
            }

            this.rowStates.set(row[0], {
                ...currVal,
                subRowExpanded: newVal,
            });
        }

        const parsedRow: { [key: string]: string } = {};

        this.parsedCols().forEach((col, ind) =>
            parsedRow[col.code] = row[ind]
        );

        this.cellAction.emit({
            column,
            row: parsedRow,
            rowId: parsedRow['rowId'],
            expandable: !!column.columnExpands,
            rowExpanded: currVal ? !currVal.subRowExpanded : false,
        });
    }


    setResizeTargetCol(col: RdcDataTableColumn, colIndex: number, cc: HTMLDivElement): void {
        if(!this.resizable) {
            return;
        }

        col.flex = undefined;

        this.tempResizeWidth[colIndex] = cc.clientWidth;

        this.resizeSubject = {
            column: { ...col },
            index: colIndex
        }
    }

    private updateColumnSettings() {
        // adopt new settings, if any, from given columns
        this.parsedCols.update((columns) =>
            columns.map((column) => {

                const findColNewVals = untracked(this.columns)
                    .find(({ code }) => code === column.code);

                if(!findColNewVals) {
                    return column;
                }

                let updated = { ...column };

                columnSettingsProperties.forEach(prop => {
                    const value = (findColNewVals as any)[prop] || (column as any)[prop];

                    updated = { ...updated, [prop]: value };
                })

                return updated;
            }));
    }

    private parseRowStateData() {
        // only reparse if they've changed
        if(this.lastProcessed.get('rowStates') === this.lastReceived.get('rowStates')) {
            return;
        }

        const storedRowStateMap = untracked(this.storedRowStates);
        const parsedRowStateMap = new Map(storedRowStateMap);

        Array.from(storedRowStateMap.keys()).forEach((key) => {
            const rowState = storedRowStateMap.get(key);

            if(rowState?.subRows?.length) {

                const rows = rowState?.subRows.map((subRow, ind) => {

                    let completeSubRow = [ String(ind), ...subRow ];

                    for(const [ prev, curr ] of this.columnRepositions) {
                        completeSubRow = this.moveInArray(completeSubRow, curr, prev);
                    }

                    return completeSubRow;
                });

                parsedRowStateMap.set(key, {
                    ...rowState,
                    subRows: rows,
                });
            }
        });

        this.rowStates = parsedRowStateMap;

        this.parsedRowStates.set(Date.now());

        this.cdr.detectChanges();

        this.lastProcessed.set('rowStates', this.lastReceived.get('rowStates'));
    }

}

