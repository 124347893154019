import { AppEnvironment, appEnvironment } from '@rdc-apps/rdc-apex/src/lib/shared/environment';

import { Component, Inject, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import './apex.worker';

@Component({
    selector: 'rdc-apps-root',
    templateUrl: './app.component.html',
    standalone: false
})
export class AppComponent implements OnInit {
    title = 'Apex';

    constructor(
        private oidcSecurityService: OidcSecurityService,
        @Inject(appEnvironment) public environment: AppEnvironment
    ) {}

    ngOnInit(): void {
        this.oidcSecurityService.checkAuth().subscribe();
    }
}
