export const assumptionsColumnHeaders : { label: string; code: string; width: number }[] = [
    {
        label: 'Field / Calculation',
        code: 'Calc',
        width:172
    },
    {
        label: 'Logic',
        code: 'LG',
        width: 216

    },
    {
        label: 'Where to change',
        code: 'WTC',
        width: 206
    },
]

export const riskBenchmarkQSData = [
    [
        'Served destinations at focus airport and benchmark airports',
        'Flown to from focus airport and benchmark airports in the last month',
        'Cannot be changed',
    ],
    [
        'Risk definition',
        'RSI <= 5.0',
        'Cannot be changed',
    ],
]

export const riskAirportAnalysisQSData = [
    [
        'Served destinations',
        'Flown to from focus airport in the last month',
        'Cannot be changed',
    ],
    [
        'Risk definition',
        'RSI <= 5.0',
        'Cannot be changed',
    ],
]

export const growthBenchmarkQSData = [
    [
        'Unserved routes that are served from benchmarks',
        'Flown to from benchmark airports last month but not from focus airport in last 13 months or in future',
        'Cannot be changed',
    ],
    [
        'Growth definition',
        'RSI >= 5.1 at any chosen benchmark',
        'Cannot be changed',
    ],
]

export const growthMarketAnalysisQSData = [
    [
        'Unserved destinations',
        'Not flown to from focus airport in last 13 months or in future',
        'Cannot be changed',
    ],
    [
        'Growth definition',
        'RSI >= 5.1 at any chosen benchmark',
        'Cannot be changed',
    ],
]

export const networkMapQSData = [
    [
        'Date Range',
        'Routes flown within the current IATA season',
        'Cannot be changed',
    ],
]
