import { map, switchMap, tap } from 'rxjs';


import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fetch } from '@nrwl/angular';
import { sendActivity } from '@rdc-apps/rdc-shared/src/lib/data-access/actions';
import { ToastType } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import {
    appLoaded,
    AppLoadStatus
} from '@rdc-apps/rdc-shared/src/lib/data-access/store/app-loading';
import { ToastService } from "@rdc-apps/rdc-shared/src/lib/utilities";

import { QueriesService } from './queries.service';
import { QueryDataPointsConversionService } from './query-data-points-conversion.service';
import { APP_USER_QUERIES } from './query-loading-keys';
import * as QueryActions from './query.actions';
import { getDataPoints } from '../../data-points/+state/data-points.selectors';

@Injectable()
export class QueryEffects {

    setLoading$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                QueryActions.initQueries,
                QueryActions.saveQuery,
                QueryActions.deleteQuery,
            ),
            fetch({
                run: () => this.store$.dispatch(AppLoadStatus.loading(APP_USER_QUERIES))
            })
        )
    );

    setLoaded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                QueryActions.loadQueriesSuccess,
                QueryActions.loadQueriesFailure,
                QueryActions.saveQuerySuccess,
                QueryActions.saveQueryFailure,
                QueryActions.deleteQuerySuccess,
                QueryActions.deleteQueryFailure,
            ),
            fetch({
                run: () => this.store$.dispatch(appLoaded({ key: APP_USER_QUERIES }))
            })
        )
    );


    init$ = createEffect(() =>
        this.actions$.pipe(
            ofType(QueryActions.initQueries),
            tap(() => this.store$.dispatch(AppLoadStatus.loading(APP_USER_QUERIES, 'Loading studies...', { global: true, overlay: true }))),
            fetch({
                run: () =>
                    this.queryService.get()
                        .pipe(
                            concatLatestFrom(() => this.store$.select(getDataPoints('dataPointState'))),
                            switchMap(([ queries, dataPoints ]) => this.queryDataPointsConversionService.loadQueries(queries, dataPoints)),
                            tap(() => this.store$.dispatch(appLoaded({ key: APP_USER_QUERIES }))),
                            map((queryList) => QueryActions.loadQueriesSuccess({
                                queries: queryList,
                            }))
                        ),
                onError: () => QueryActions.loadQueriesFailure({
                    error: 'Error fetching queries!', // temp for now
                }),
            })
        )
    );

    save$ = createEffect(() =>
        this.actions$.pipe(
            ofType(QueryActions.saveQuery),
            fetch({
                run: ({ query, templateId }) =>
                    this.queryDataPointsConversionService.saveQuery(query)
                        .pipe(
                            switchMap((queryConversion) => this.queryService.save(queryConversion)),
                            map((response) => QueryActions.saveQuerySuccess({
                                query,
                                id: response.success.data.studyId,
                                isNew: query.studyId === '00000000-0000-0000-0000-000000000000',
                                statusCode: response.status,
                                templateId,
                            }))
                        ),
                onError: () => QueryActions.loadQueriesFailure({
                    error: 'Error saving query!', // temp for now
                }),
            })
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(QueryActions.deleteQuery),
            fetch({
                run: ({ studyId }) =>
                    this.queryService.delete(studyId)
                        .pipe(
                            map(() => QueryActions.deleteQuerySuccess({ studyId }))
                        ),
                onError: () => QueryActions.deleteQueryFailure({
                    error: 'Error deleting query!', // temp for now
                }),
            })
        )
    );

    deleteSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(QueryActions.deleteQuerySuccess),
        tap(() => this.toast.simpleToast(ToastType.SUCCESS, 'Query deleted successfully', 3000))
    ), { dispatch: false });

    createSuccess$ = createEffect(() => this.actions$.pipe(
        ofType(QueryActions.saveQuerySuccess),
        tap(async({ id, isNew, statusCode, query, templateId }) => {

            this.toast.simpleToast(ToastType.SUCCESS, 'Study saved successfully', 5000);

            let activityCode = 'table';

            if (query.studyType !== 'table') {
                activityCode = (query.studyType === 'scatter') ? 'scatter' : 'multi';
            }

            this.store$.dispatch(sendActivity({
                activity: {
                    activityCode: `rdc.q.apex.query.${ activityCode }.save`,
                    detail: {
                        creationType: templateId || 'Blank',
                        area: 'Data & Visualisation',
                        queryObject: query,
                    },
                },
            }));

            if (isNew) {
                this.toast.simpleToast(ToastType.DEFAULT, `New study saved as: ${ query.studyName }, You are now editing: ${ query.studyName }`, 8000);

                switch (query.studyType) {
                    case 'table': {
                        await this.router.navigate([ '/', 'query-builder', 'table', id ], { queryParams: { created: true } });
                        break;
                    }
                    case 'scatter': {
                        await this.router.navigate([ '/', 'query-builder', 'scatter', id ], { queryParams: { created: true } });
                        break;
                    }
                    default: {
                        await this.router.navigate([ '/', 'query-builder', 'chart', id ], { queryParams: { created: true } });
                    }
                }

                return;
            }

            if (statusCode === 0) {
                return;
            }
        })
    ), { dispatch: false });

    constructor(
        private readonly actions$: Actions,
        private readonly router: Router,
        private readonly queryService: QueriesService,
        private store$: Store,
        private queryDataPointsConversionService: QueryDataPointsConversionService,
        private readonly toast: ToastService
    ) {}
}
