import { createAction, props } from '@ngrx/store';
import { Activity } from "@rdc-apps/rdc-shared/src/lib/data-access/models";

export const sendActivity = createAction(
    '[Activity] Send Activity',
    props<{ activity: Activity }>()
);

export const sendActivitySuccess = createAction(
    '[Activity] Send Activity Success'
);

export const sendActivityFailure = createAction(
    '[Activity] Send Activity Failure'
);

export const queueActivity = createAction(
    '[Activity] Queue Activities',
    props<{ activity: Activity }>()
);

export const executeQueuedActivities = createAction(
    '[Activity] Execute Queue Activities'
);

export const clearQueuedActivities = createAction(
    '[Activity] Clear Queue Activities'
);
