import { CloudOnboardingContentType, EmptyCloudItem } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';

export const growthCloudOnboardingContent: CloudOnboardingContentType = {
    ['growth']: {
        pageOne: {
            title: 'Welcome to the Growth cloud',
            text: 'Use the power of Apex to identify and monitor new route opportunities, airlines, and backfilling of services',
        },
        pageTwo: {
            title: 'Origin',
            text: 'Set an origin airport to make it easier to track and suggest routes. You can change this later',
            label: 'Origin',
        },
        pageThree: {
            title: 'Setting up cloud',
            text: '',
            label: '',
        },
        pageFour: {
            title: 'Setup complete',
            text: 'Start setting up routes straight away or use our applets to discover routes to add the the Target list',
        },
    },
};

export const riskCloudOnboardingContent: CloudOnboardingContentType = {
    ['risk']: {
        pageOne: {
            title: 'Welcome to the Risk cloud',
            text: 'Use the power of Apex to identify and monitor routes at risk at your airport and those of your peers',
        },
        pageTwo: {
            title: 'Default origin',
            text: 'Set an origin airport to make it easier to track and suggest routes. You can change this later',
            label: 'Origin',
        },
        pageThree: {
            title: 'Setting up cloud',
            text: '',
            label: '',
        },
        pageFour: {
            title: 'Setup complete',
            text: 'Start setting up routes straight away or use our applets to discover routes to add the Watchlist',
        },
    },
};

export const networkCloudOnboardingContent: CloudOnboardingContentType = {
    ['network']: {
        pageOne: {
            title: 'Welcome to the Network cloud',
            text: 'Network cloud provides easily accessible insights into the high level performance of airline and airport route networks.',
        },
        pageTwo: {
            title: 'Define defaults',
            text: 'Set a default airport and airline and we\'ll automatically add it to your queries, for quicker access to insights!',
            label: 'Airport (optional)',
            labelTwo: 'Airline (optional)',
        },
        pageThree: {
            title: 'Setting up cloud',
            text: '',
            label: '',
        },
        pageFour: {
            title: 'Setup complete',
            text: 'That\'s it! We\'ll now take you to your Network cloud homepage where you can explore the various analysis and snapshot views.',
        },
    },
};

export const emptyGrowthCloudItems: EmptyCloudItem[] = [
    {
        icon: 'n-icon-benchmark',
        iconSize: 'xxxxs',
        type: 'link',
        launch:'benchmark',
        cloud: 'growth',
        title: 'Benchmark analysis',
        copy: 'Analyse the strongest routes at a group of benchmark airports sorted by your choice of performance metric',
    },
    {
        icon: 'n-icon-plane',
        iconSize: 'xxxxs',
        type: 'link',
        launch:'discover',
        cloud: 'growth',
        title: 'Market analysis',
        copy: 'Explore the best performing routes on an airline network or to a certain market',
    },
    {
        icon: 'add_circle',
        iconSize: 'xxl',
        type: 'modal',
        launch:'',
        cloud: '',
        title: 'Manual add',
        copy: 'If you already know which routes to target, add them here',
    },
];

export const emptyRiskCloudItems: EmptyCloudItem[] = [
    {
        icon: 'n-icon-plane',
        iconSize: 'xxxxs',
        type: 'link',
        launch:'routes',
        cloud: 'risk',
        title: 'Airport analysis',
        copy: 'Identify the weakest performing routes at an airport',
    },
    {
        icon: 'n-icon-benchmark',
        iconSize: 'xxxxs',
        type: 'link',
        launch:'benchmark',
        cloud: 'risk',
        title: 'Benchmark analysis',
        copy: 'Compare destinations from an airport with other airports where performance is weakest',
    },
    {
        icon: 'add_circle',
        iconSize: 'xxl',
        type: 'modal',
        launch:'',
        cloud: '',
        title: 'Manual add',
        copy: 'If you already know which routes to keep monitoring, add them here',
    },
];

