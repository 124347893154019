import { CdkStepper } from '@angular/cdk/stepper';
import { CommonModule } from "@angular/common";
import { Component, Input } from '@angular/core';

@Component({
    standalone: true,
    selector: 'rdc-apps-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: [ './stepper.component.scss' ],
    providers: [ { provide: CdkStepper, useExisting: StepperComponent } ],
    imports: [ CommonModule ]
})
export class StepperComponent extends CdkStepper {
    @Input() activeClass = 'active';

}
