import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
    selector: 'rdc-apps-temp-user-authorize',
    templateUrl: './temp-user-authorize.component.html',
    styleUrls: [ './temp-user-authorize.component.scss' ],
    standalone: false
})
export class TempUserAuthorizeComponent implements OnInit {

    constructor(
        private oidcSecurityService: OidcSecurityService,
    ) { }

    ngOnInit(): void {
        this.oidcSecurityService.authorize();
    }
}
