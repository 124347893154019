import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';


import * as NetworkActions from './network.actions';
import { NetworkEntity } from './network.models';
import { deleteQuerySuccess } from '../../query/+state/query.actions';
import * as TagActions from '../../tags/+state/tags.actions';


export const NETWORK_FEATURE_KEY = 'network';

export interface NetworkState extends EntityState<NetworkEntity> {
    selectedId?: string | number; // which Network record has been selected
    loaded: boolean; // has the Network list been loaded
    saving: boolean,
    error?: string | null; // last known error (if any)
}

export interface NetworkPartialState {
    readonly [NETWORK_FEATURE_KEY]: NetworkState;
}

export const networkAdapter: EntityAdapter<NetworkEntity> =
    createEntityAdapter<NetworkEntity>({
        selectId: (model) => model.studyId,
    });

export const initialNetworkState: NetworkState = networkAdapter.getInitialState(
    {
        // set initial required properties
        loaded: false,
        saving: false,
    }
);

const reducer = createReducer(
    initialNetworkState,
    on(NetworkActions.loadNetworkQueriesAction, (state) => ({
        ...state,
        loaded: false,
        error: null,
    })),
    on(NetworkActions.loadNetworkQueriesSuccessAction, (state, { networkQueries }) =>
        networkAdapter.setAll(networkQueries, { ...state, loaded: true })
    ),
    on(NetworkActions.loadNetworkQueriesFailureAction, (state, { error }) => ({
        ...state,
        error,
    })),
    on(NetworkActions.setNetworkActiveQueryAction, (state, { selectedId }) => ({
        ...state,
        selectedId,
    })),
    on(NetworkActions.saveNetworkQueryAction, (state) => ({
        ...state,
        saving: true,
    })),
    on(NetworkActions.saveNetworkQuerySuccessAction, (state, { networkQuery,  studyId }) => {

        const query: NetworkEntity = {
            ...networkQuery,
            studyId,
        };

        return networkAdapter.upsertOne(query, { ...state, saving: false });
    }),
    on(deleteQuerySuccess, (state, { studyId }) => networkAdapter.removeOne(studyId, state)),

    on(TagActions.setStudyTagsSuccess, (state, { tags, studyId }) => {
        const study = state.entities[studyId];

        if (!study) {
            return state;
        }

        return networkAdapter.upsertOne({
            ...study,
            tags: [ ...study.tags, ...tags ], // previous tags plus the new one
        },{ ...state });
    }),
    on(TagActions.removeTagsSuccess, (state, { tagIds, studyId }) => {
        const study = state.entities[studyId] ;

        if (!study) {
            return state;
        }

        const remaining = study.tags.filter((tag) => !tagIds.includes(tag.id));

        return networkAdapter.upsertOne({
            ...study,
            tags: remaining,
        },{ ...state });
    })
);

export function networkReducer(
    state: NetworkState | undefined,
    action: Action
) {
    return reducer(state, action);
}
