import { filter, Observable, Subscription, take, withLatestFrom } from 'rxjs';

import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EmbeddedViewRef,
    HostListener,
    inject,
    Inject,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SettingsComponent } from '@rdc-apps/rdc-apex/src/lib/settings/feature/settings';
import { TabItemComponent } from "@rdc-apps/rdc-apex/src/lib/settings/ui/tab-item";
import { BACK_TO_APEX_CLASSIC, LOGOUT, Clouds } from '@rdc-apps/rdc-apex/src/lib/shared/constants';
import { AppEnvironment, appEnvironment } from '@rdc-apps/rdc-apex/src/lib/shared/environment';
import { animSidebarAccCont, animSidebarIcon, shrinkSidebarItem } from '@rdc-apps/rdc-shared/src/lib/animations';
import { overlayPositions, RdcFeature } from '@rdc-apps/rdc-shared/src/lib/constants';
import { sendActivity } from '@rdc-apps/rdc-shared/src/lib/data-access/actions';
import { TooltipsService } from '@rdc-apps/rdc-shared/src/lib/data-access/local-storage';
import { MappedSideNav, SideNavItem, ToastType } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import {
    getGloballyLoading,
    getGloballyOverlayLoading,
    getIsAppLoading,
    getLoadingToastMessages
} from '@rdc-apps/rdc-shared/src/lib/data-access/store/app-loading';
import { RdcComponentUtils, RdcDialogHelper, ToastService } from '@rdc-apps/rdc-shared/src/lib/utilities';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { OnboardingComponent } from 'home-ui';
import { getUserSettingsData, UserSettings ,
    APP_USER_DETAILS,
    getUserDetailsData,
    UserDetails, clearHttpErrorAction, ErrorService,
    getAirportGroups,
    getDefaultPreferences
} from 'rdc-apex-store';
import { SidebarComponent } from "shared-ui";

import { HasCloudAccessPipe } from './has-cloud-access.pipe';
import { AppDrawerComponent } from "../app-drawer/app-drawer.component";
import {
    ChartBuilderQuickLauncherComponent
} from "../chart-builder-quick-launcher/chart-builder-quick-launcher.component";
import { MapDialogComponent } from "../map-dialog/map-dialog.component";
import {
    QueryBuilderQuickLauncherComponent
} from "../query-builder-quick-launcher/query-builder-quick-launcher.component";
import { SustainabilityDrawerComponent } from "../sustainability-drawer/sustainability-drawer.component";

declare let pendo: { initialize: (pendoData: Record<string, unknown>) => void };

@Component({
    selector: 'rdc-apps-apex-layout',
    templateUrl: './apex-layout.component.html',
    styleUrls: [ './apex-layout.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
    animations: [
        shrinkSidebarItem,
        animSidebarIcon,
        animSidebarAccCont,
    ],
    standalone: false
})
export class ApexLayoutComponent extends RdcComponentUtils implements OnInit {

    loading$!: Observable<boolean>;
    userDetails$!: Observable<UserDetails | undefined>;
    userSettings$!: Observable<UserSettings | undefined>;

    swUpdate = inject(SwUpdate);

    showTopLoader = true;
    showOverlayLoader = true;
    overlayPositions = overlayPositions;
    clouds = Clouds;
    openMenus: string[] = [];
    userDetails!: UserDetails | undefined | null;
    userSettings!: UserSettings;
    sidebarMenuTriggered = false;
    sidebarMenuOpen = false;
    appsMenuTriggered = false;
    sidebarOverlay!: HTMLElement;
    loadingToastRef!: EmbeddedViewRef<unknown> | null;
    breadcrumbIconBgColour!: string;
    appDialog!: DialogRef<unknown, AppDrawerComponent>;
    nvSubscription!: Subscription;
    nvToast!: EmbeddedViewRef<HTMLElement>;

    protected readonly rdcFeature = RdcFeature;

    @ViewChild('sidebar') sidebar!: SidebarComponent;
    @ViewChild('newVersionDialog', { static: true }) newVersionDialog!: TemplateRef<HTMLDivElement>;
    @ViewChild('main') main!: ElementRef;

    @HostListener('click', [ '$event.target' ]) onClick(element: HTMLElement): void {

        if (typeof element?.className === 'string' && element.className.includes('side-bar-overlay')) {
            this.dialog.closeAll();

            this.destroyOverlay();
        }
    }

    constructor(
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public tooltips: TooltipsService,
        private actions: Actions,
        private store$: Store,
        private errorService: ErrorService,
        private toast: ToastService,
        private dialog: Dialog,
        private overlay: Overlay,
        private renderer: Renderer2,
        private cdr: ChangeDetectorRef,
        private gtm: GoogleTagManagerService,
        private oidcSecurityService: OidcSecurityService,
        private rdcDialogHelper: RdcDialogHelper,
        private hasCloudAccessPipe: HasCloudAccessPipe,
        @Inject(appEnvironment) public env: AppEnvironment
    ) {
        super(actions);

        if (env.enableAnalytics) {
            this.gtm.addGtmToDom();
        }

        this.errorService.setErrorPagePath('error');

        this.store$.dispatch(clearHttpErrorAction());
        this.store$.dispatch(getDefaultPreferences());

        this.userDetails$ = this.store$.select(getUserDetailsData('userDetailsState'));
        this.userSettings$ = this.store$.select(getUserSettingsData('userSettingsState'));
    }

    onSettingsMenu(): void {
        if (this.appsMenuTriggered) {
            this.appDialog.close();
        }

        this.dialog.closeAll();
    }

    onOpenSettings(tabIndex?: number): void {

        const dialogRef = this.dialog.open(SettingsComponent, {
            positionStrategy: this.rdcDialogHelper.dialogPosition(),
            disableClose: true,
            minHeight: '576px',
            maxHeight: '100vh',
            width: '720px',
            panelClass: [ 'rdc-dialog-no-border' ],
        });

        dialogRef.closed.subscribe();

        setTimeout(() => {
            if(dialogRef.componentInstance) {
                dialogRef.componentInstance.settingsTabs.select({} as TabItemComponent, tabIndex ? Number(tabIndex) : 0);
            }
        });
    }


    get onErrorPage(): boolean {
        return [ '/error' ].includes(this.router.routerState.snapshot.url);
    }

    get showRoutePerformanceLink(): boolean {
        return this.userDetails?.organisation?.activeCloudCodes?.includes('limited-experience-routeperformance')
            && this.userDetails?.organisation.experience === 'Full' || false;
    }

    get showBackToApexLink(): boolean {
        return this.userDetails?.organisation.experience === 'Limited' || false;
    }

    get showBreadcrumb(): boolean {

        switch (true) {
            case (this.router.routerState.snapshot.url.includes('/query-builder')):
                return true;
            case (this.router.routerState.snapshot.url.includes('/growth') && this.userSettings?.growthOnboardingCompleted):
                return true;
            case (this.router.routerState.snapshot.url.includes('/risk') && this.userSettings?.riskOnboardingCompleted):
                return true;
            case (this.router.routerState.snapshot.url.includes('/network') && this.userSettings?.networkOnboardingCompleted):
                return true;
            case (this.router.routerState.snapshot.url.includes('/sustainability') && true): // this.userSettings?.sustainabilityOnboardingCompleted
                return true;
            default:
                return false;
        }
    }

    ngOnInit(): void {
        localStorage.removeItem('onLoadSettingsAction'); // set in the settings components

        this.setBreadcrumbIconBgColour();

        this.pwaSetup();

        this.activatedRoute.queryParams
            .pipe(
                take(1),
                filter((params) => params['openSettingsModal'] === 'true')
            )
            .subscribe((params) => this.onOpenSettings(params['tabIndex']));

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {

                this.dialog.closeAll();

                this.cloudOnBoarding();

                this.setBreadcrumbIconBgColour();
            });

        this.loading$ = this.store$.select(getIsAppLoading(
            APP_USER_DETAILS
        ));

        this.userSettings$
            .pipe(
                withLatestFrom(this.userDetails$)
            ).subscribe(([ settings, details ]) => {
            if (details && settings) {

                this.clouds = this.mappedCloudsNavItems(this.clouds, details, settings);

                this.userDetails = details;
                this.userSettings = settings;

                // user flow instance
                this.oidcSecurityService.userData$
                    .pipe(take(1))
                    .subscribe((oidcUserDetailResult) => {
                        const { organisation } = details || {};

                        const {
                            firstName,
                            lastName,
                            jobTitle,
                            organisation : orgDetails
                        } = details;

                        const {
                            userOnboardingCompleted,
                            growthOnboardingCompleted,
                            riskOnboardingCompleted,
                            networkOnboardingCompleted,
                            sustainabilityOnboardingCompleted,
                            performanceOnboardingCompleted,
                        } = settings;

                        // pendo instance
                        pendo.initialize({
                            visitor: {
                                id: details.userId,
                                email: oidcUserDetailResult.userData.email,
                                firstName,
                                lastName,
                                name: `${details.firstName} ${details.lastName}`,
                                jobTitle,
                            },
                            account: {
                                id: organisation.organisationId,
                                name: organisation.name,
                                activeCloudCodes: orgDetails.activeCloudCodes,
                                isOnTrial: orgDetails.isOnTrial,
                                experience: orgDetails.experience,
                                userOnboardingCompleted,
                                growthOnboardingCompleted,
                                riskOnboardingCompleted,
                                networkOnboardingCompleted,
                                sustainabilityOnboardingCompleted,
                                performanceOnboardingCompleted,
                            },
                        });
                    });

                // user onboarding
                if (!settings.userOnboardingCompleted) {

                    this.store$.dispatch(getAirportGroups());
                    const dialogRef = this.dialog.open(OnboardingComponent,
                        {
                            positionStrategy: this.rdcDialogHelper.dialogPosition(),
                            disableClose: true,
                            minHeight: '512px',
                            maxHeight: '100vh',
                            width: '464px',
                            panelClass: [ 'rdc-dialog-no-border' ],
                        });

                    dialogRef.closed.subscribe();
                }

                setTimeout(() => {
                    this.cloudOnBoarding();
                });
            }
        });

        this.store$.select(getLoadingToastMessages)
            .subscribe((toastMessage) => {
                if (toastMessage && !this.loadingToastRef) {
                    this.loadingToastRef = this.toast.simpleToast(ToastType.LOADING, toastMessage, false);

                    return;
                } else if (toastMessage) {
                    return;
                }

                this.loadingToastRef?.destroy();
                this.loadingToastRef = null;
            });

        this.store$.select(getGloballyLoading)
            .subscribe((globallyLoading) => {
                this.showTopLoader = globallyLoading;
                this.cdr.detectChanges();
            });

        this.store$.select(getGloballyOverlayLoading)
            .subscribe((overlayLoading) => {
                this.showOverlayLoader = overlayLoading;
                this.cdr.detectChanges();
            });
        // no need to unsubscribe because layout is ever present
        // replace with interceptor
        this.actions
            .subscribe((action: { type: string; error?: string; errors?: Record<string, string[]> }) => {
                if (action.error || action.errors) {
                    this.toast.simpleToast(ToastType.ERROR, action.error || JSON.stringify(action.errors), 5000);
                }
            });
    }

    private pwaSetup(): void {
        this.nvSubscription?.unsubscribe();

        if (this.swUpdate.isEnabled && (this.env.production || this.env.testing)) {

            this.nvSubscription = this.swUpdate.versionUpdates.subscribe(evt => {
                switch (evt.type) {
                    case 'VERSION_READY':
                        this.nvToast = this.toast.toastTemplate(ToastType.DEFAULT, this.newVersionDialog, 120000)
                        break;
                    case 'VERSION_INSTALLATION_FAILED':
                        this.toast.simpleToast(ToastType.ERROR, 'Failed to update, please try again', 10000);
                        break;
                    default:
                    break;
                }
            });
        }
    }

    setBreadcrumbIconBgColour(): void {
        this.breadcrumbIconBgColour = [ 'growth', 'risk', 'network', 'network/upgrade', 'sustainability', 'performance' ].includes(this.router.routerState.snapshot.url.slice(1)) ? '#43E08C' : '#4D66F2'
    }

    // may want to make this a more reusable function when other menus come along down the line
    onOpenQuickRunMenu(trigger: HTMLButtonElement, allowSubMenu = true, queryCategory: string, ...queryTypes: string[]): void {
        this.dialog.closeAll();

        if (!this.sidebarMenuTriggered) {

            if (allowSubMenu) {
                localStorage.setItem('sidebarMenuItem', trigger?.id || '');
                this.sidebarMenuTriggered = true;
            }

            // eslint-disable-next-line max-len
            const component = [ 'chart', 'scatter' ].includes(queryCategory) ? ChartBuilderQuickLauncherComponent : QueryBuilderQuickLauncherComponent;

            let title = 'Column, Line & Area';

            switch (queryCategory) {
                case 'scatter': {
                    title = 'Scatter plots';
                    break;
                }
                case 'table' : {
                    title = 'Query builder';
                    break;
                }
            }

            const dialogRef = this.dialog.open(component, {
                hasBackdrop: false,
                backdropClass: 'none',
                id: `query-builder-quick-launcher-${queryCategory}`,
                panelClass: [ 'rdc-sidebar-panel' ],
                height: '100%',
                data: {
                    title,
                    queryCategory,
                    queryTypes,
                },
                positionStrategy: this.overlay.position()
                    .flexibleConnectedTo(trigger)
                    .withLockedPosition()
                    .withPositions(overlayPositions.get('right') || []),
            });

            this.createOverlay();

            dialogRef?.closed
                .pipe(take(1))
                .subscribe(() => {
                    this.openMenus.splice(this.openMenus.indexOf(`quick-launch-${queryCategory}`), 1);
                    this.sidebarMenuTriggered = false;
                    this.sidebarMenuOpen = false;
                    this.destroyOverlay();
                });

            this.openMenus.push(`quick-launch-${queryCategory}`);
        }
    }

    onOpenAppDrawer(trigger: HTMLButtonElement) {
        this.dialog.closeAll();

        if (!this.appsMenuTriggered) {

            this.appsMenuTriggered = true;

            this.appDialog = this.dialog.open(AppDrawerComponent, {
                hasBackdrop: false,
                backdropClass: 'none',
                id: 'apex-app-drawer',
                panelClass: [ 'rdc-sidebar-panel' ],
                height: '100%',
                positionStrategy: this.overlay.position()
                    .flexibleConnectedTo(trigger)
                    .withLockedPosition()
                    .withPositions(overlayPositions.get('right') || []),
            });

            this.createOverlay();

            this.appDialog?.closed
                .pipe(take(1))
                .subscribe(() => {
                    this.openMenus.splice(this.openMenus.indexOf('app-drawer'), 1);
                    this.appsMenuTriggered = false;
                    this.destroyOverlay();
                });

            this.openMenus.push('app-drawer');
        }
    }

    onLegacyApex(): void {
        this.store$.dispatch(sendActivity({
            activity: {
                activityCode: BACK_TO_APEX_CLASSIC,
            },
        }));
    }

    onLogOut(): void {
        this.store$.dispatch(sendActivity({
            activity: {
                activityCode: LOGOUT,
            },
        }));
    }

    onToggleMenu(menu: { element: Element; open: boolean }): void {
        const previousMenuItem = localStorage.getItem('sidebarMenuItem');

        if (!this.sidebar.open) {
            if (previousMenuItem) {
                setTimeout(() => {
                    document.getElementById(previousMenuItem)?.click();
                }, 250);

                return;
            }

            setTimeout(() => {
                document.getElementById('chart-column')?.click();
            }, 250);

            return;
        }

        this.sidebarMenuOpen = menu.open;

        if (previousMenuItem) {
            document.getElementById(previousMenuItem)?.click();

            return;
        }

        document.getElementById('chart-column')?.click();
    }

    mappedCloudsNavItems(navItems: SideNavItem[], userDetails: UserDetails, userSettings: UserSettings): SideNavItem[] {
        const userSettingsObj = Object.entries(userSettings);
        const mappedSettings: MappedSideNav[] = [];

        userSettingsObj.forEach((setting) =>
            mappedSettings.push({ cloud: setting[0].split('OnboardingCompleted')[0], onboarded: setting[1] })
        );

        return navItems.map((navItem) => {
            const matchedItem = mappedSettings.find((mappedItem) => mappedItem.cloud === navItem.cloudType);

            if (matchedItem) {
                return {
                    ...navItem,
                    onboarded: matchedItem.onboarded,
                };
            }

            return navItem;
        }).map((cloud) => ({
            ...cloud, active: userDetails.organisation.activeCloudCodes.includes(cloud.cloudType),
        }));
    }

    private createOverlay(): void {
        this.sidebarOverlay = this.renderer.createElement('div');

        this.sidebarOverlay.classList.add('side-bar-overlay');

        this.renderer.appendChild(this.main.nativeElement, this.sidebarOverlay);
    }

    private destroyOverlay(): void {
        this.renderer.removeChild(this.main.nativeElement, this.sidebarOverlay);
    }

    onSidebarToggle() {
        this.dialog.closeAll();
    }

    onExpandIfNotExpanded() {
        if (!this.sidebar.open) {
            this.dialog.getDialogById('query-builder-quick-launcher-table')?.close();
        }
        this.sidebar.open = true;
        this.appDialog?.close();
    }

    cloudOnBoarding(): void {
        if (!this.userSettings?.riskOnboardingCompleted || !this.userSettings?.growthOnboardingCompleted) {
            if ([ '/growth', '/risk' ].includes(this.router.routerState.snapshot.url)) {
                this.sidebar.open = false;
            }
        }

        if (!this.userSettings.userOnboardingCompleted) {
            this.sidebar.open = false;
        }

    }

    onNetworkMap(): void {
        const hasAccess = this.hasCloudAccessPipe.transform(this.userDetails, 'network');

        if (hasAccess) {

            this.store$.dispatch(sendActivity({
                activity: {
                    activityCode: `rdc.q.apex.network.network-map.new`,
                    detail: {
                        launchSource: 'Sidenav.New'
                    }
                },
            }));

            this.router.navigate([ 'network', 'network-map' ]);
        } else {

            setTimeout(() => {
                const dialogRef = this.dialog.open(MapDialogComponent, {
                    positionStrategy: this.rdcDialogHelper.dialogPosition(),
                    hasBackdrop: true,
                    id: `mapplet-modal`,
                    panelClass: [ 'rdc-dialog-no-border' ],
                    height: '512px',
                    width: '572px',
                });

                dialogRef.componentInstance?.cloudLink.subscribe(() => {
                    this.router.navigate([ 'network' , 'upgrade' ]);
                    this.dialog.closeAll();
                });
            }, 250);
        }
    }

    get isChartBuilderActive(): boolean {
        return (this.router.isActive('query-builder/chart', false) || this.router.isActive('query-builder/scatter', false))
            || (this.router.isActive('query-builder/network-map', false))
            && !this.router.isActive('query-builder/table', false)
    }

    onUpdateApex(): void {
        this.swUpdate.activateUpdate();
    }

    onDismissUpdate(): void {
        this.nvToast?.destroy();
    }

    onSustainabilityDrawer(trigger: HTMLElement): void {
        this.dialog.closeAll();

        const dialog = this.dialog.open(SustainabilityDrawerComponent, {
            hasBackdrop: false,
            backdropClass: 'none',
            autoFocus: 'none',
            panelClass: [ 'rdc-sidebar-panel' ],
            height: '100%',
            positionStrategy: this.overlay.position()
                .flexibleConnectedTo(trigger)
                .withLockedPosition()
                .withPositions(overlayPositions.get('right') || []),
        });

        this.createOverlay();

        this.openMenus.push('sustainability-drawer')

        dialog?.closed
            .pipe(take(1))
            .subscribe(() => {
                this.openMenus.splice(this.openMenus.indexOf('sustainability-drawer'), 1);
                this.destroyOverlay();
            });
    }
}
