import { filter, map, of } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { ToastType } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { ToastService } from "@rdc-apps/rdc-shared/src/lib/utilities";


import * as TagsActions from './tags.actions';
import { TagsService } from './tags.service';
import { httpErrorAction } from '../../error/+state/error.actions';

@Injectable()
export class TagsEffects {
    get$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagsActions.getTags),
            fetch({
                run: () =>
                    this.tagsService
                        .get()
                        .pipe(
                            map((response) =>
                                TagsActions.getTagsSuccess({ tags: response })
                            )
                        ),
                onError: (action, response) =>
                    of(httpErrorAction({ action, response })),
            })
        )
    );

    create$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagsActions.createTags),
            fetch({
                run: ({ tags }) =>
                    this.tagsService
                        .create(tags)
                        .pipe(
                            map(() => TagsActions.createTagsSuccess({ tags }))
                        ),
                onError: () => {
                    this.toastService.simpleToast(
                        ToastType.ERROR,
                        'Error creating tag, please try again later',
                        5000
                    );

                    return of(TagsActions.tagsFailure());
                },
            })
        )
    );

    delete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagsActions.deleteTags),
            fetch({
                run: ({ tagIds }) =>
                    this.tagsService
                        .delete(tagIds)
                        .pipe(
                            map(() => TagsActions.deleteTagsSuccess({ tagIds }))
                        ),
                onError: () => {
                    this.toastService.simpleToast(
                        ToastType.ERROR,
                        'Error deleting tag, please try again later',
                        5000
                    );

                    return of(TagsActions.tagsFailure());
                },
            })
        )
    );

    search$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagsActions.searchTags),
            filter(({ value }) => !!value),
            fetch({
                run: ({ value }) =>
                    this.tagsService.search(value).pipe(
                        map((response) =>
                            TagsActions.searchTagsSuccess({
                                tags: response,
                            })
                        )
                    ),
                onError: (action, response: HttpErrorResponse) =>
                    of(httpErrorAction({ action, response })),
            })
        )
    );

    patchStudy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagsActions.setStudyTags),
            fetch({
                run: ({ tags, studyId }) =>
                    this.tagsService.updateStudyTags(studyId, tags).pipe(
                        map(() =>
                            TagsActions.setStudyTagsSuccess({
                                tags,
                                studyId,
                            })
                        )
                    ),
                onError: () => {
                    this.toastService.simpleToast(
                        ToastType.ERROR,
                        'Error adding tag, please try again later',
                        5000
                    );

                    return of(TagsActions.tagsFailure());
                },
            })
        )
    );

    createCloudRouteTag$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagsActions.createCloudRouteTag),
            fetch({
                run: ({ tags, routeId, cloudType }) =>
                    this.tagsService.createCloudRouteTags(routeId, tags, cloudType).pipe(
                        map(() =>
                            TagsActions.createCloudRouteTagSuccess({
                                tags,
                                routeId,
                            })
                        )
                    ),
                onError: () => {
                    this.toastService.simpleToast(
                        ToastType.ERROR,
                        'Error adding tag, please try again later',
                        5000
                    );

                    return of(TagsActions.tagsFailure());
                },
            })
        )
    );

    remove$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagsActions.removeTagsFromStudy),
            fetch({
                run: ({ tagIds, studyId }) =>
                    this.tagsService.untag(studyId, tagIds).pipe(
                        map(() =>
                            TagsActions.removeTagsSuccess({
                                tagIds,
                                studyId,
                            })
                        )
                    ),
                onError: () => {
                    this.toastService.simpleToast(
                        ToastType.ERROR,
                        'Error removing tag, please try again later',
                        5000
                    );

                    return of(TagsActions.tagsFailure());
                },
            })
        )
    );

    removeTagFromCloudRoute$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TagsActions.removeTagsFromCloudRoute),
            fetch({
                run: ({ tagIds, routeId, cloudType }) =>
                    this.tagsService.removeCloudRouteTags(routeId, tagIds, cloudType).pipe(
                        map(() =>
                            TagsActions.removeTagsFromCloudRouteSuccess({
                                tagIds,
                                routeId,
                            })
                        )
                    ),
                onError: () => {
                    this.toastService.simpleToast(
                        ToastType.ERROR,
                        'Error removing tag, please try again later',
                        5000
                    );

                    return of(TagsActions.tagsFailure());
                },
            })
        )
    );

    constructor(
        private readonly actions$: Actions,
        private tagsService: TagsService,
        private toastService: ToastService
    ) {}
}
