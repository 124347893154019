import { Observable, of } from 'rxjs';

import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { sendActivity } from '@rdc-apps/rdc-shared/src/lib/data-access/actions';
import { getAllSavedQueries, QueryEntity } from "rdc-apex-store";

@Component({
    selector: 'rdc-apps-query-builder-quick-launcher',
    templateUrl: './query-builder-quick-launcher.component.html',
    styleUrls: [ './query-builder-quick-launcher.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
})
export class QueryBuilderQuickLauncherComponent {

    savedQueries$: Observable<QueryEntity[]> = of([]);

    date = Date.now();

    constructor(
        private router: Router,
        private store$: Store,
        @Inject(DIALOG_DATA) public data: {
            title: string;
            queryCategory: string;
            queryTypes: string[];
        }
    ) {
        this.savedQueries$ = this.store$.select(getAllSavedQueries(...this.data.queryTypes || [ 'table' ]));
    }

    onNewQuery(basicQb = false): void {
        this.router.navigate([ '/', 'query-builder', this.data.queryCategory ],
            { queryParams:
                    {
                        ts: this.date,
                        ...(this.data.queryCategory === 'table' ? { bqb: basicQb } : {})
                    }
            });

        let activityCode = 'table';

        if (this.data.queryCategory !== 'table') {
            activityCode = this.data.queryCategory === 'scatter' ? 'scatter' : 'multi';
        }

        this.store$.dispatch(sendActivity({
            activity: {
                activityCode:  `rdc.q.apex.query.${ activityCode }.new`, // chart, table or scatter
                detail: {
                    area: 'Data & Visualisation',
                    launchSource: 'Sidenav.New',
                    creationType: this.data.queryCategory === 'table'
                        ? basicQb
                            ? 'basic'
                            : 'extended'
                        : 'Blank',
                },
            },
        }));
    }

    get newQueryButtonText(): string {
        return `New ${this.data.queryCategory === 'scatter' ? `${this.data.queryCategory} plot` : this.data.queryCategory}`
    }
}
