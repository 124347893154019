import { map, Observable } from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { AppEnvironment, appEnvironment } from '@rdc-apps/rdc-apex/src/lib/shared/environment';
import { RdcBaseHttpService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { ToastType, RdcResponseDto } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { ToastService } from "@rdc-apps/rdc-shared/src/lib/utilities";

import { Growth, GrowthCreateRoute, GrowthUpdateRoute, GrowthEntity } from './growth.models';

@Injectable({ providedIn: 'root' })
export class GrowthService extends RdcBaseHttpService {

    constructor(
        private toast: ToastService,
        @Inject(appEnvironment) private environment: AppEnvironment
    ) {
        super();
    }

    get(): Observable<GrowthEntity[]> {
        // eslint-disable-next-line max-len
        return this.http.get<RdcResponseDto<Growth>>(`${this.environment.api.baseUrl}/GrowthCloud/routes`, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data.routes)
            );
    }

    create(
        ...routesSet: GrowthCreateRoute[]
    ): Observable<GrowthEntity[]> {
        // eslint-disable-next-line max-len
        return this.http.post<RdcResponseDto<Growth>>(`${this.environment.api.baseUrl}/GrowthCloud/routes/create`, { routes: routesSet }, { headers: this.getTokenHeaders() }).pipe(
                map((response) => {

                    if (Number(response.success.data.created.slice(0,1)) > 0) {
                        const msg = response.success.data.singleRouteMessage || response.success.data.created;

                        this.toast.simpleToast(ToastType.SUCCESS, msg, 5000);
                    }

                    if (Number(response.success.data.skipped.slice(0,1)) > 0) {
                        const msg = response.success.data.singleRouteMessage || response.success.data.skipped;

                        this.toast.simpleToast(ToastType.WARN, msg, 5000);
                    }

                    return response.success.data.routes;
                })
            );
    }

    createViaNetwork(route: GrowthCreateRoute): Observable<GrowthEntity[]> {
        // eslint-disable-next-line max-len
        return this.http.post<RdcResponseDto<Growth>>(`${this.environment.api.baseUrl}/GrowthCloud/route/create`, { route }, { headers: this.getTokenHeaders() }).pipe(
            map((response) => {
                let toastType = ToastType.ERROR;

                if(response.success.data.skipped) {
                    toastType = ToastType.WARN;
                } else if(response.success.data.created) {
                    toastType = ToastType.SUCCESS;
                }

                this.toast.simpleToast(toastType, response.success.data.message || '', 5000);

                return response.success.data.routes;
            })
        );
    }

    update(route: GrowthUpdateRoute): Observable<GrowthEntity[]> {
        // eslint-disable-next-line max-len
        return this.http.post<RdcResponseDto<Growth>>(`${this.environment.api.baseUrl}/GrowthCloud/routes/update`, route,{ headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data.routes)
            );
    }

    delete(routeId: string): Observable<GrowthEntity[]> {
        // eslint-disable-next-line max-len
        return this.http.delete<RdcResponseDto<Growth>>(`${this.environment.api.baseUrl}/GrowthCloud/routes/delete/${routeId}`, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data.routes)
            );
    }

    refresh(): Observable<GrowthEntity[]> {
        // eslint-disable-next-line max-len
        return this.http.get<RdcResponseDto<Growth>>(`${this.environment.api.baseUrl}/GrowthCloud/routes/refresh`, { headers: this.getTokenHeaders() })
            .pipe(
                map((response) => response.success.data.routes)
            );
    }

}
