import { take, takeUntil, takeWhile } from 'rxjs';

import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from "@angular/router";
import { UserPasswordService } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/services';
import { CustomValidators } from '@rdc-apps/rdc-shared/src/lib/custom-validators';
import { RdcComponentUtils } from '@rdc-apps/rdc-shared/src/lib/utilities';
import { UserDetails } from 'rdc-apex-store';

@Component({
    selector: 'rdc-apps-account',
    templateUrl: './account.component.html',
    styleUrls: [ './account.component.scss' ],
    standalone: false
})
export class AccountComponent extends RdcComponentUtils implements OnInit {

    @Input() form!: FormGroup;
    @Input() userDetails!: UserDetails | undefined | null;

    @ViewChild('passwordInput') passwordInput!: ElementRef<HTMLInputElement>;

    validationMessages = {
        required: 'This field is required',
        email: 'Email address required',
        pattern: 'At least 12 characters including an uppercase character, a lowercase character, a digit, and a special character',
        misMatch: 'Passwords do not match'
    };

    showValidation = false;
    passwordMode = false;
    control!: AbstractControl;
    switchControl: boolean | undefined = false;
    regexPattern = '(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^\\s\\da-zA-Z]).{12,}';

    constructor(
        public userPasswordService: UserPasswordService,
        private changeDetectorRef: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute
    ) {
        super();
    }

    ngOnInit(): void {

        this.activatedRoute.queryParams
            .pipe(
                takeWhile(() => !localStorage.getItem('onLoadSettingsAction')),
                take(1)
            )
            .subscribe((params) => {

                if(params['settingsAction'] === 'updatePassword') {
                    this.onUpdate();
                }

                if(params['settingsAction']) {
                    localStorage.setItem('onLoadSettingsAction', params['settingsAction']);
                }
            })

        if (this.userDetails) {
            this.form.patchValue({
                ...this.userDetails,
                organisation: this.userDetails.organisation.name
            });
        }
    }

    onUpdate(): void {
        this.passwordMode = true;
        this.form.get('passwords')?.setValidators(
            CustomValidators.matchValidator(
                (this.form.controls['passwords'] as FormGroup).controls['password'],
                (this.form.controls['passwords'] as FormGroup).controls['confirmPassword']
            ));

        this.form.get('passwords.password')?.setValidators([ Validators.required, Validators.pattern(this.regexPattern) ]);
        this.form.get('passwords.confirmPassword')?.setValidators([ Validators.required ]);

        this.changeDetectorRef.detectChanges();
    }

    onCancel(): void {
        this.passwordMode = false;
        this.showValidation = false;

        this.clearValidatorsAndUpdateValidity(this.form, 'passwords');
        this.clearValidatorsAndUpdateValidity(this.form, 'passwords.password');
        this.clearValidatorsAndUpdateValidity(this.form, 'passwords.confirmPassword');
    }

    onChangePassword(): void {
        this.form.updateValueAndValidity();

        if (this.form.invalid) {
            this.showValidation = true;

            this.switchControl = this.form.get('passwords.password')?.valid && this.form.get('passwords.confirmPassword')?.valid;

            return;
        }

        this.showValidation = false;
        this.passwordMode = false;

        this.userPasswordService.changePassword(this.form.get('passwords.confirmPassword')?.value)
            .pipe(
                takeUntil(this.componentDestroyed$)
            ).subscribe();

        this.clearValidatorsAndUpdateValidity(this.form, 'passwords');
        this.clearValidatorsAndUpdateValidity(this.form, 'passwords.password');
        this.clearValidatorsAndUpdateValidity(this.form, 'passwords.confirmPassword');
    }

    onTogglePasswordVisibility(input: HTMLInputElement): void {
        switch (input.type) {
            case 'text':
                input.type = 'password';
                break;
            case 'password':
                input.type = 'text';
                break;
            default:
                input.type = 'text';
        }
    }

    protected clearValidatorsAndUpdateValidity(formGroup: FormGroup, control: string): void {
        formGroup.get(`${control}`)?.reset();
        formGroup.get(`${control}`)?.clearValidators();
        formGroup.get(`${control}`)?.updateValueAndValidity();
    }
}
