<div class="cloud" [formGroup]="form">
    <ng-container>
        <rdc-apps-header header="Clouds" title="Defaults"></rdc-apps-header>

        <div class="form-group">
            <label>Default airline</label>
            <rdc-apps-select
                placeholder="Search airline"
                [filterable]="true"
                [showValidation]="false"
                [options]="autocompleteResults"
                [maskSelected]="formGroup.value?.cloudDefaultAirline"
                (autocomplete)="onAutoComplete($event, 'airline')"
                (valueSelected)="onUpdateCloudDefaultAirline($event)"
                (clearSelected)="onClearDefault('cloudDefaultAirline')">
            </rdc-apps-select>
        </div>


        <div class="form-group">
            <label>Default airport</label>
            <rdc-apps-select
                placeholder="Search airports"
                [filterable]="true"
                [options]="autocompleteResults"
                [showValidation]="false"
                [maskSelected]="formGroup.value?.cloudDefaultAirport"
                (autocomplete)="onAutoComplete($event, 'airport')"
                (valueSelected)="onUpdateCloudDefaultAirport($event)"
                (clearSelected)="onClearDefault('cloudDefaultAirport')">
            </rdc-apps-select>
        </div>

        <rdc-apps-header title="Access"></rdc-apps-header>

        <div class="cloud-access-tiles-section">

            @for (cloud of clouds; track cloud) {
                <a class="cloud-access-tiles"
                   [routerLink]="cloud.route"
                   (click)="closeCloudDialog()">
                    <rdc-apps-icon
                        class="cloud-icon"
                        size="sm"
                        [rdcIcon]="cloud.icon">
                    </rdc-apps-icon>
                    <div>
                        <p>{{ cloud.label }}</p>
                    </div>

                    @if (userAccessableCloud.includes(cloud.cloudType)) {
                        <div class="icon-container-active">
                            <rdc-apps-icon
                                size="xxxxs"
                                rdcIcon="n-icon-check-mini">
                            </rdc-apps-icon>
                            <label>Active</label>
                        </div>
                    }
                    @else {
                        <div class="cloud-pill">
                            Info
                        </div>
                    }

                </a>
            }

        </div>

    </ng-container>
</div>
