import { Directive, ElementRef, HostBinding, Input, OnChanges, Renderer2 } from '@angular/core';

import { RdcButtonDirective } from "./button.directive";

@Directive({
    selector: '[rdcAppsMenuButton]',
    standalone: true,
})
export class MenuButtonDirective extends RdcButtonDirective implements OnChanges {

    @Input() position: 'right' | 'left' = 'left';

    @HostBinding('style.borderTopRightRadius') radiusTr = 0;
    @HostBinding('style.borderBottomRightRadius') radiusBr = 0;

    constructor(
        elementRef: ElementRef,
        renderer: Renderer2
    ) {
        super(elementRef, renderer);
    }

    override ngOnChanges(): void {
        super.ngOnChanges();

        const button = this.elementRef.nativeElement;

        this.renderer.addClass(button, `btn-${ this.position }-menu`);
    }
}
