import { appEnvironmentProvider } from '@rdc-apps/rdc-apex/src/lib/shared/environment';
import { ApexShellModule } from '@rdc-apps/rdc-apex/src/lib/shell/feature';

import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthModule } from 'angular-auth-oidc-client';

import { AppComponent } from './app.component';
import { environment } from '../environments/environment';

const hasOriginSession = Boolean(sessionStorage.getItem('origin'));
const postLoginUrl = hasOriginSession
    ? `${decodeURIComponent(sessionStorage.getItem('origin') || '')}`
    : '';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        ApexShellModule,
        // !environment.production ? StoreDevtoolsModule.instrument() : [],
        AuthModule.forRoot({
            config: {
                authority: environment.oidcConfig.IdentityEndpoint,
                redirectUrl: `${environment.oidcConfig.BaseUrl}/signin-oidc-callback`,
                postLoginRoute: `${postLoginUrl}`,
                postLogoutRedirectUri: `${environment.oidcConfig.BaseUrl}/logged-out`,
                clientId: environment.oidcConfig.ClientId,
                scope: 'openid profile apexwebappapi',
                responseType: 'id_token token',
                useRefreshToken: true,
                silentRenewUrl: `${environment.oidcConfig.BaseUrl}/silent-renew/`,
                forbiddenRoute: '/authorize',
                unauthorizedRoute: '/authorize',
                maxIdTokenIatOffsetAllowedInSeconds: 600,
                renewTimeBeforeTokenExpiresInSeconds: 60,
            },
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: false,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        appEnvironmentProvider(environment),
    ],
    bootstrap: [ AppComponent ],
})
export class AppModule {}
