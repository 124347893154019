<div class="rdc-table"
     (selectstart)="!disableCopy"
     (mousedown)="!disableCopy"
     [class.prevent-copy]="disableCopy"
     [style.width]="tableCalculatedWidth()">

    <rdc-apps-loading-overlay *ngIf="loading"></rdc-apps-loading-overlay>

    <div #viewportContainer
         cdkVirtualScrollingElement
         [style.height.px]="viewPortHeight() || 110"
         [style.max-height.px]="maxHeightPx">


        <section class="table-group-container">

        <div #tableGroupElements *ngIf="(columnGroups() | hasGroupsAndWidth)">

            <div class="table-groups-row header-template">
                <ng-container *ngFor="let col of columnGroups(); let colIndex = index">
                    <div
                        (mouseenter)="groupHighlightIndex = colIndex"
                        (mouseleave)="groupHighlightIndex = -1"
                        class="table-groups-row-cell template"
                        [style.width.px]="col.width"
                        [style.min-width.px]="col.width"
                        [ngClass]="{
                            'borderless-right': borderlessEndCol()
                        }">
                        <ng-container [ngTemplateOutlet]="col.headerTemplate || mpt" [ngTemplateOutletContext]="{ group: col, groupIndex: colIndex }"></ng-container>

                        <div [class.active]="col.headerTemplate && ((groupHighlightIndex === colIndex) || highlightColumns.includes(col.code))"
                             [class.transparent]="groupHighlightIndex > 0 && groupHighlightIndex !== colIndex && highlightColumns.includes(col.code)"
                             [style.height.px]="highlightHeight() | combined : tableGroupElements : highlightColumns.includes(col.code) : this.viewPortHeight()"
                             class="table-column-action-highlight sub-row-highlight">
                        </div>
                    </div>

                </ng-container>

            </div>

            <div *ngIf="!hideGroupNames()" [style.min-height.px]="rowSize + 1" class="table-groups-row">
                <ng-container *ngFor="let col of columnGroups();">
                    <div class="table-groups-row-cell"
                        [style.width.px]="col.width"
                        [style.min-width.px]="col.width"
                        [class.borderless-end]="borderlessEndCol()">
                        {{ col.label }}
                    </div>
                </ng-container>
            </div>

        </div>

        <!-- TABLE HEADER -->
        <div #headerRow
             class="table-header-row"
             cdkDropList
             cdkDropListOrientation="horizontal"
             [style.display]="this.parsedRows().length || this.parsedFixedFooterRow() || this.parsedFixedHeaderRow() ? undefined : 'none' "
             (cdkDropListDropped)="onDrop($event)">


            <div class="table-header-row-templates buttons" [class]="columnMode">
                <ng-container *ngFor="let col of parsedCols(); let colIndex = index">

                    <ng-container *ngIf="!col.hidden">

                        <div class="button-template-container"
                            (mouseenter)="rowHighlightIndex = colIndex"
                            (mouseleave)="rowHighlightIndex = -1"
                            [style.flex]="col.flex"
                            [style.width.px]="tempResizeWidth[colIndex] || col.widthOverride || col.width"
                            [style.min-width.px]="tempResizeWidth[colIndex] || col.widthOverride || col.minWidth"
                            [style.max-width.px]="col.widthOverride"
                            [ngClass]="{
                                'borderless-right': borderlessEndCol(),
                                'force-align-left': col.forceAlign === 'left',
                                'force-align-right': col.forceAlign === 'right'
                            }">

                            <ng-container [ngTemplateOutlet]="col.headerTemplate || mpt" [ngTemplateOutletContext]="{ column: col, columnIndex: colIndex, columnRows: parsedRows() | rowsForColumn : colIndex }"></ng-container>

                            <div [class.active]="col.headerTemplate && ((rowHighlightIndex === colIndex) || highlightColumns.includes(col.code))"
                                 [class.transparent]="rowHighlightIndex > 0 && rowHighlightIndex !== colIndex && highlightColumns.includes(col.code)"
                                 [style.height.px]="highlightHeight()"
                                 class="table-column-action-highlight">
                            </div>
                        </div>

                    </ng-container>

                </ng-container>
            </div>

            <div class="table-header-row-cells" [class]="columnMode">

                <ng-container *ngFor="let col of parsedCols(); let colIndex = index;">

                    <ng-container *ngIf="!col.hidden else mptCell">
                        <div #columnContainer
                             class="table-header-cell"
                             cdkDrag
                             [style.flex]="col.flex"
                             [style.width.px]="tempResizeWidth[colIndex] || col.widthOverride || col.width"
                             [style.min-width.px]="tempResizeWidth[colIndex] || col.widthOverride || col.minWidth"
                             [style.max-width.px]="col.widthOverride"
                             [style.min-height.px]="rowSize"
                             [attr.hidden]="col.hidden"
                             [ngClass]="{
                                'numerical' : col.isNumerical,
                                'clickable' : sortable && col.sortable,
                                'borderless-right': borderlessEndCol(),
                                'border-left': columnMode === 'append-full-width-centered' && !borderlessEndCol(),
                                'borderless-bottom': borderlessEndRow()
                             }"
                             [cdkDragData]="{ column: col }"
                             [cdkDragDisabled]="!orderable || !col.movable || !hideGroupNames()"
                             (cdkDragStarted)="dragging = true">

                            <div class="table-header-cell-value">
                                <div (click)="onSort(col)" class="header-cell-text">
                                    <div class="th-label"
                                         [class.numerical]="col.isNumerical"
                                         [className]="customHeaderClass"
                                         [ngClass]="{
                                            'force-align-left': col.forceAlign === 'left',
                                            'force-align-right': col.forceAlign === 'right',
                                         }">
                                        <span *ngIf="col.code !== 'Action'">{{ col.label }}</span>
                                    </div>

                                    <div class="th-sort-cont">
                                        <ng-container *ngIf="sortable && col.sortable">

                                            <rdc-apps-icon *ngIf="sorting | isSortingBy: col as sort; else noSort" [rdcIcon]="sort.icon" size="smallest"></rdc-apps-icon>

                                            <ng-template #noSort>
                                                <rdc-apps-icon class="sort-default" rdcIcon="n-icon-sort-none" size="smallest"></rdc-apps-icon>
                                            </ng-template>

                                        </ng-container>
                                    </div>
                                </div>

                                <span
                                    (mouseenter)="col.movable = false"
                                    (mouseleave)="col.movable = true"
                                    [class.disabled]="!resizable"
                                    (mousedown)="setResizeTargetCol(col, colIndex, columnContainer)"
                                    class="cell-resize-handle">
                                </span>
                            </div>


                        </div>

                    </ng-container>

                    <ng-template #mptCell> <!-- ensures drag and drop continuity is kept -->
                        <span cdkDrag></span>
                    </ng-template>

                </ng-container>

            </div>

        </div>

        </section>

        <ng-container *ngIf="parsedFixedHeaderRow() as fixedHeaderRow">
            <div class="table-fixed-row start"
                 [ngClass]="fixedRowType"
                 [style.top.px]="headerSize()"
                 [style.opacity]="dragging || resizeSubject ? 0.25 : 1">
                <ng-container *ngFor="let col of parsedCols(); let colIndex = index;">
                    <div class="table-cell"
                         *ngIf="!col.hidden"
                         [ngClass]="{
                             'numerical': col.isNumerical,
                             'borderless-right': borderlessEndCol(),
                             'force-align-left': col.forceAlign === 'left',
                             'force-align-right': col.forceAlign === 'right'
                         }"
                         [style.flex]="col.flex"
                         [style.width.px]="tempResizeWidth[colIndex] || col.widthOverride || col.width"
                         [style.min-width.px]="tempResizeWidth[colIndex] || col.widthOverride || col.minWidth"
                         [style.max-width.px]="col.widthOverride"
                         [style.height.px]="rowSize">

                        <ng-container *ngIf="fixedHeaderRow[colIndex] | formatIfNumerical : col.isNumerical as formatted">
                            <span [class.negative-num]="formatted.isNegative">{{ formatted.value }}</span>
                        </ng-container>

                    </div>
                </ng-container>
            </div>
        </ng-container>

        <cdk-virtual-scroll-viewport
            #viewport
            rdcTableVirtualScrollStrategy
            [itemHeights]="itemSizes()">

            <div class="table-body" [class]="columnMode">
                <!-- NORMAL ROWS AND CELLS -->
                <div class="table-row-container"
                     *cdkVirtualFor="let row of (parsedRows() | clientTableSort : parsedCols() : sorting : serverSideSorting : sortedArr)(); let rowIndex = index"
                     [style.height.px]="itemSizes()[rowIndex]">

                    <!-- Always validates as returns an empty obj if not present-->
                    <ng-container *ngIf="rowStates | getRowState : row : openRowTrigger() as rowState">

                        <div class="table-row" [style.opacity]="dragging || resizeSubject ? 0.25 : 1">

                             <ng-container *ngFor="let col of parsedCols(); let colIndex = index;">

                                 <ng-container *ngIf="!col.hidden">

                                     <div class="table-cell"
                                          [ngClass]="{
                                             'numerical': col.isNumerical,
                                             'actionable': col.actionable || col.columnExpands,
                                             'border-left': columnMode === 'append-full-width-centered' && !borderlessEndCol(),
                                             'borderless-right': borderlessEndCol(),
                                             'borderless-bottom': borderlessEndRow(),
                                             'force-align-left': col.forceAlign === 'left',
                                             'force-align-right': col.forceAlign === 'right'
                                          }"
                                          [className]="customCellClass"
                                          [style.flex]="col.flex"
                                          [style.background-color]="col.backgroundColour"
                                          [style.width.px]="tempResizeWidth[colIndex] || col.widthOverride || col.width"
                                          [style.min-width.px]="tempResizeWidth[colIndex] || col.widthOverride || col.minWidth"
                                          [style.max-width.px]="col.widthOverride"
                                          [style.height.px]="rowSize"
                                          (click)="onCellAction(col, row)">

                                         <ng-container [ngSwitch]="col.code">

                                            <!-- rdc specific row override - replace in future? -->
                                             <ng-container *ngSwitchCase="'Action'">

                                                <div class="row-action-content">
                                                    <rdc-apps-icon
                                                        *ngIf="rowState.state === 'success'"
                                                        size="smallest"
                                                        rdcIcon="n-icon-check-mini">
                                                    </rdc-apps-icon>

                                                    <span class="row-action-text" [class]="rowState.state">
                                                        {{ rowState.label || defaultRowActionLabel }}
                                                    </span>
                                                </div>

                                             </ng-container>


                                             <ng-container *ngSwitchDefault>

                                                 <ng-container *ngIf="row[colIndex] | formatIfNumerical : col.isNumerical as formatted">
                                                     <rdc-apps-icon *ngIf="col.columnExpands && rowState.subRows?.length" size="xxs">
                                                         {{ rowState.subRowExpanded ? 'expand_more' : 'chevron_right' }}
                                                     </rdc-apps-icon>

                                                     <span [ngClass]="{ 'multi-line': multiline, 'negative-num': formatted.isNegative }">{{ formatted.value }}</span>
                                                 </ng-container>

                                                 <rdc-apps-icon
                                                     *ngIf="rowState.tooltip && col.showTooltip"
                                                     rdcAppsTooltip
                                                     [tooltipText]="rowState.tooltip"
                                                     [size]="rowState.tooltipIconSize || 'smallest'">
                                                     {{ rowState.tooltipIcon }}
                                                 </rdc-apps-icon>

                                                 <div *ngIf="col.actionable" class="row-actionable-icon">
                                                    <span class="row-action-icon">
                                                        <rdc-apps-icon size="smallest">arrow_upward</rdc-apps-icon>
                                                    </span>
                                                 </div>

                                             </ng-container>

                                         </ng-container>

                                     </div>

                                 </ng-container>

                             </ng-container>

                        </div>


                        <ng-container *ngIf="rowState.subRowExpanded">

                            <ng-container *ngIf="rowState.subRows as subRows">

                                <ng-container *ngFor="let sRow of (subRows | clientTableSort : parsedCols() : sorting : false)()">
                                    <div class="table-row" [style.opacity]="dragging || resizeSubject ? 0.25 : 1">
                                        <ng-container *ngFor="let col of parsedCols(); let colIndex = index;">
                                            <div class="table-cell sub-row-cell"
                                                 *ngIf="!col.hidden"
                                                 [ngClass]="{
                                                    'numerical': col.isNumerical,
                                                    'borderless-right': borderlessEndCol(),
                                                    'force-align-left': col.forceAlign === 'left',
                                                    'force-align-right': col.forceAlign === 'right'
                                                 }"
                                                 [style.flex]="col.flex"
                                                 [style.width.px]="tempResizeWidth[colIndex] || col.widthOverride || col.width"
                                                 [style.min-width.px]="tempResizeWidth[colIndex] || col.widthOverride || col.minWidth"
                                                 [style.max-width.px]="col.widthOverride"
                                                 [style.height.px]="rowSize">

                                                <ng-container *ngIf="sRow[colIndex] | formatIfNumerical : col.isNumerical as formatted">
                                                    <span [class.negative-num]="formatted.isNegative">{{ formatted.value }}</span>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </div>

                                </ng-container>

                            </ng-container>

                        </ng-container>


                    </ng-container>

                </div>


                <ng-container *ngIf="!parsedRows().length && !parsedFixedHeaderRow() && !parsedFixedFooterRow()">
                    <div [style.height.px]="viewPortHeight() || 110" class="table-empty">
                        <img height="64" width="64" src="/assets/rdc-apex/images/apex-no-data.svg" alt="No data">
                        <h4>No data to display</h4>
                    </div>
                </ng-container>

            </div>

        </cdk-virtual-scroll-viewport>

        <ng-container *ngIf="parsedFixedFooterRow() as fixedFooterRow">
            <div class="table-fixed-row end" [ngClass]="fixedRowType" [style.opacity]="dragging || resizeSubject ? 0.25 : 1">
                <ng-container *ngFor="let col of parsedCols(); let colIndex = index;">
                    <div class="table-cell"
                         *ngIf="!col.hidden"
                         [ngClass]="{
                             'numerical': col.isNumerical,
                             'borderless-right': borderlessEndCol(),
                             'force-align-left': col.forceAlign === 'left',
                             'force-align-right': col.forceAlign === 'right'
                         }"
                         [style.flex]="col.flex"
                         [style.width.px]="tempResizeWidth[colIndex] || col.widthOverride || col.width"
                         [style.min-width.px]="tempResizeWidth[colIndex] || col.widthOverride || col.minWidth"
                         [style.max-width.px]="col.widthOverride"
                         [style.height.px]="rowSize">

                        <ng-container *ngIf="fixedFooterRow[colIndex] | formatIfNumerical : col.isNumerical as formatted">
                            <span [class.negative-num]="formatted.isNegative">{{ formatted.value }}</span>
                        </ng-container>

                    </div>
                </ng-container>
            </div>
        </ng-container>

    </div>

    <footer #tableFooter [class.borderless]="!tableFooter.innerHTML.trim()">
        <ng-content></ng-content>
    </footer>

</div>

<ng-template #mpt></ng-template>
