import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { RdcButtonDirective, RdcIconButtonDirective } from "shared-directives";
import { IconComponent } from "shared-ui";

@Component({
    selector: 'rdc-apps-map-dialog',
    standalone: true,
    imports: [ CommonModule, RdcButtonDirective, IconComponent, RdcIconButtonDirective ],
    templateUrl: './map-dialog.component.html',
    styleUrl: './map-dialog.component.scss',
})
export class MapDialogComponent {

    @Output() cloudLink = new EventEmitter();

}
