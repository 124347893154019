<ng-container *ngFor="let dataPoint of dataPointsMapped; let menuIndex = index;">
    <button
        #openSelect="cdkMenuTriggerFor"
        #buttonElement
        class="data-points-group-pill"
        type="button"
        rdcAppsButton
        [ngClass]="{
            'active': openSelect.isOpen(),
            'hidden': dataPoint.dataPointsData.length === 0,
        }"
        [attr.id]="dataPoint.code"
        [cdkMenuPosition]="overlayPositions.get('below')"
        [cdkMenuTriggerFor]="menu"
        (click)="onGroupSelection(dataPoint)"
    >
        <span>{{ dataPoint.label }}</span>
        <ng-container *ngIf="groupLocked(dataPoint) else expandIcon">
            <ng-container *ngTemplateOutlet="lockIcon; context: { locked: 'group-locked' }"></ng-container>
        </ng-container>
    </button>
    <ng-template #menu>
        <div
            [formGroup]="form"
            cdkMenu
            class="cdk-menu no-animate data-points-group-pill-menu"
        >
            <ng-container *ngIf="!studyTypeTable">
                <ul class="data-points-group-pill-list">
                    <li *ngFor="let dataPointControl of dataPoint.dataPointsData; let index = index">

                        <ng-container *ngIf="dataPointLocked(dataPoint, index)">
                            <ng-container *ngTemplateOutlet="lockIcon; context: { locked: 'data-point-locked' }"></ng-container>
                        </ng-container>

                        <ng-container *ngTemplateOutlet="dataPointLabel; context: { dataPointControl: dataPointControl, dataPoint: dataPoint, index: index }"></ng-container>

                        <ng-container *ngIf="!dataPointLocked(dataPoint, index)">
                            <input
                                type="radio"
                                name="dataPoint"
                                [attr.id]="dataPointControl.code"
                                [checked]="isChecked(form.get('dataPointCodes')?.value, dataPointControl.code)"
                                [value]="dataPointControl.code"
                                (change)="onChange(dataPoint, dataPointControl, menuIndex)"
                            >
                        </ng-container>

                    </li>
                </ul>
                <ng-container *ngTemplateOutlet="upgrade; context: { dataPoint: dataPoint }"></ng-container>
            </ng-container>

            <ng-container *ngIf="studyTypeTable && controlFormGroup && controlFormGroup.value.value" [formGroupName]="dataPoint.code">
                <ng-container *ngIf="controlFormGroup.value.controls | keyvalue as dataPointControls">
                    <button
                        rdcAppsButton
                        rdcAppsIconButton
                        class="data-points-group-pill-button"
                        (click)="onAddAll(controlFormGroup.key, dataPointControls, dataPoint.dataPointsData)"
                    >
                        <rdc-apps-icon size="xs">
                            {{ allCheckedControls(controlFormGroup.key, dataPointControls, dataPoint.dataPointsData) ? 'do_not_disturb_on' : 'add_circle' }}
                        </rdc-apps-icon>
                        {{ allCheckedControls(controlFormGroup.key, dataPointControls, dataPoint.dataPointsData) ? 'Remove' : 'Add' }} all
                    </button>

                    <ul class="data-points-group-pill-list">

                        <li *ngFor="let dataPointControl of dataPoint.dataPointsData; let index = index">

                            <ng-container *ngIf="dataPointLocked(dataPoint, index)">
                                <ng-container *ngTemplateOutlet="lockIcon; context: { locked: 'data-point-locked' }"></ng-container>
                            </ng-container>

                            <ng-container *ngTemplateOutlet="dataPointLabel; context: { dataPointControl: dataPointControl, dataPoint: dataPoint, index: index }"></ng-container>

                            <ng-container *ngIf="!dataPointLocked(dataPoint, index)">
                                <input
                                    *ngIf="hasControl(dataPoint.code, dataPointControl.code)"
                                    type="checkbox"
                                    [attr.id]="dataPointControl.code"
                                    [formControlName]="dataPointControl.code"
                                >
                            </ng-container>
                        </li>
                    </ul>

                    <ng-container *ngTemplateOutlet="upgrade; context: { dataPoint: dataPoint }"></ng-container>
                </ng-container>
            </ng-container>
        </div>
    </ng-template>
</ng-container>

<ng-template #expandIcon>
    <rdc-apps-icon size="xs">expand_more</rdc-apps-icon>
</ng-template>

<ng-template #lockIcon let-locked="locked">
    <img [attr.class]="locked" [attr.src]="LOCKED_ICON_SRC" alt="locked icon">
</ng-template>

<ng-template #dataPointLabel let-dataPointControl="dataPointControl" let-dataPoint="dataPoint" let-index="index">
    <label rdcAppsTooltip
        [tooltipText]="!dataPointControl.visibleInBasicQueryBuilder && basicBuilder ? 'Available in Extended builder' : dataPointDescriptions.get(dataPointControl.code) || 'No description'"
        [tooltipDelay]="250"
        [tooltipPosition]="'above'"
        [tooltipOptions]="{ panelClass: 'tooltip-hover' }"
        [tooltipDisabled]="tooltips.disabled"
        [tooltipHideDelay]="0"
        [ngClass]="{'disabled': dataPointLocked(dataPoint, index) }"
        [attr.for]="dataPointControl.code"
    >
        {{ getFullName(dataPointControl.code) }}
    </label>
</ng-template>

<ng-template #upgrade let-dataPoint="dataPoint">
    <p
        *ngIf="upgradeDataPoints(dataPoint)"
        class="data-points-group-pill-locked-options"
    >
        To unlock all of the options in this group <a (click)="salesforceLead.emit(dataPoint.label)">request an upgrade</a>.
    </p>
</ng-template>
