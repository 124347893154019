<div
    *ngIf="chartRequest && (study || queryService.results)"
    class="top-bar-container"
    id="app-header"
>
    <rdc-apps-qb-top-bar
        @dropInOut
        [studyType]="queryType"
        [activeStudyId]="study?.studyId"
        [editingQuery]="chartRequest.valid && !!study"
        [disableExport]="apexTrialMode$ | async"
        [nameControlFormGroup]="chartRequest"
        [savedQueries]="(savedQueries$ | async) || []"
        (saveAction)="onSave()"
        (saveAsAction)="onSaveAs()"
        (exportAction)="onExport()">
    </rdc-apps-qb-top-bar>
</div>

<div #chartBuilderContainer class="rdc-chart-builder-container">

    <ng-scrollbar [autoHeightDisabled]="false" visibility="hover">
        <div class="rdc-chart-builder-form" #chartBuilderForm>
            <section style="overflow:hidden;" [@showHide]="{ value: queryOpen ? 'open' : 'closed' }">

                <div *ngIf="(queryService.status | async)?.state === queryStates.QUERYING"
                     class="rdc-query-running-overlay">
                    <rdc-apps-loading-overlay backgroundStyle="transparent" message="Running study"></rdc-apps-loading-overlay>
                </div>

                <rdc-apps-qb-play-reset-buttons
                    *ngIf="queryOpen"
                    [invalid]="(chartRequest && chartRequest.invalid)"
                    [queryState]="queryServiceState"
                    [showForm]="true"
                    (formSubmit)="onSubmit()"
                ></rdc-apps-qb-play-reset-buttons>

                <div class="container" *ngIf="dateRangeUtils && (userPrefColours$ | async)">

                    <div class="clear-all-selections">
                        <button rdcAppsButton
                                class="container-section clear-button"
                                (click)="cleanReset()">
                            <rdc-apps-icon size="xxs">delete</rdc-apps-icon>
                            <p>Clear selections</p>
                        </button>

                    </div>

                    <rdc-apps-accordion [canCloseAll]="false" #accordion>

                        <rdc-apps-accordion-item
                            titleText="Data point"
                            subText="({{ dataPointLabel }})"
                            [invalid]="dataPointsInvalid"
                            [open]="true">

                            <rdc-apps-qb-data-points
                                #dataPointsComponent
                                [form]="chartRequest"
                                [dataPointsData]="dataPointsData$ | async"
                                (salesforceLead)="onRequestUpgrade($event)"
                            ></rdc-apps-qb-data-points>

                        </rdc-apps-accordion-item>


                        <rdc-apps-accordion-item
                            *ngIf="queryType !== 'scatter'"
                            titleText="X-axis (horizontal)"
                            subText="({{ xAxisSummary }})"
                            [invalid]="xAxisInvalid">

                            <rdc-apps-chart-builder-x-axis
                                [dateRangeUtils]="dateRangeUtils"
                                [chartRequest]="chartRequest"
                                [dataPointsData]="dataPointsData"
                                (init)="onFormInit('xAxis')">
                            </rdc-apps-chart-builder-x-axis>

                        </rdc-apps-accordion-item>


                        <rdc-apps-accordion-item
                            titleText="Series"
                            subText="({{ seriesSummary }})"
                            [invalid]="seriesInvalid">

                            <rdc-apps-chart-builder-series-selection
                                #seriesSelection
                                [chartRequest]="chartRequest"
                                [dataPoints]="dataPointsData"
                                [dropDowns]="dataPointsData?.dropdowns"
                                [userColours]="userColours"
                                [dateRangeUtils]="dateRangeUtils"
                                [autoCompleteResults$]="autoCompleteResults$"
                                (init)="onFormInit('series')">
                            </rdc-apps-chart-builder-series-selection>

                        </rdc-apps-accordion-item>

                        <rdc-apps-accordion-item
                            titleText="Filters"
                            subText="({{ filtersSummary }})"
                            [invalid]="filtersInvalid">

                            <div class="filters-section">

                                <rdc-apps-query-builder-filter-autocomplete
                                    [ngClass]="{ bordered : timePeriodControls.noTimePeriodSelection }"
                                    [chartRequest]="chartRequest"
                                    [dropDowns]="dataPointsData?.dropdowns"
                                    [dataPoints]="dataPointsData?.datapoints || []"
                                    [autoCompleteResults$]="autoCompleteResults$"
                                    (init)="onFormInit('filters')">
                                </rdc-apps-query-builder-filter-autocomplete>

                                <rdc-apps-query-builder-time-period-options
                                    #timePeriodControls
                                    [dateRangeUtils]="dateRangeUtils"
                                    [chartRequest]="chartRequest"
                                    [autoCompleteResults$]="autoCompleteResults$"
                                    (init)="onFormInit('timePeriod')">
                                </rdc-apps-query-builder-time-period-options>

                                <rdc-apps-query-builder-additional-filters
                                    [chartRequest]="chartRequest"
                                    [dropDowns]="dataPointsData?.dropdowns"
                                    [dataPoints]="dataPointsData?.datapoints || []"
                                    (init)="onFormInit('additionalFilters')">
                                </rdc-apps-query-builder-additional-filters>

                                <rdc-apps-qb-summaries
                                    #summariesComponent
                                    [queryType]="chartRequest.value.studyType"
                                    [form]="queryRequest"
                                    [dataPointsData]="dataPointsData$ | async"
                                    (init)="onFormInit('summaries')"
                                ></rdc-apps-qb-summaries>

                            </div>

                        </rdc-apps-accordion-item>

                        <!-- Only on scatter page -->
                        <rdc-apps-accordion-item
                            *ngIf="chartRequest.value.studyType === 'scatter'"
                            titleText="Output options"
                            [invalid]="groupByInvalid">

                            <rdc-apps-chart-builder-group-by
                                [chartRequest]="chartRequest"
                                (init)="onFormInit('groupBy')">
                            </rdc-apps-chart-builder-group-by>
                        </rdc-apps-accordion-item>

                    </rdc-apps-accordion>

                </div>

            </section>

            <div *ngIf="!study && !queryService.results" class="uqb-table-spacer"></div>

            <div class="uqb-toggle-container" *ngIf="chartRequest && (study || queryService.results)">
                <rdc-apps-drawer-toggle
                    [isOpen]="queryOpen"
                    (toggled)="queryOpen = !queryOpen">
                </rdc-apps-drawer-toggle>
            </div>

        </div>


        <div class="results-container">

            <ng-container [ngSwitch]="true">

                <section class="rdc-apps-chart-results"
                         [style.min-height.px]="window.innerHeight - 90"
                         *ngSwitchCase="!!queryService.results?.chartResponse?.rows?.length">

                    <ng-container *ngIf="queryService.results as resultSet">
                        <div class="chart-results-container">
                            <rdc-apps-chart
                                #chartComponent
                                [userColours]="userColours"
                                [allowExporting]="(apexTrialMode$ | async) === false"
                                [userFormatting]="(userFormatting$ | async) || {}"
                                [xAxis]="resultSet.chartResponse?.xAxisLabel"
                                [scatterGroupBy]="queryService.lastRunQuery?.queryRequest?.chartProperties?.scatter?.groupBy"
                                [yAxis]="resultSet.chartResponse?.yAxisLabel"
                                [rows]="resultSet.chartResponse?.rows || []"
                                [tableRows]="resultSet.tableRows || []"
                                [columns]="resultSet.chartResponse?.columns || []"
                                [dataPoints]="dataPointsData"
                                [query]="queryService.lastRunQuery"
                            ></rdc-apps-chart>
                        </div>

                        <div class="chart-results-table">
                            <rdc-apps-table
                                [disableCopy]="apexTrialMode$ | async"
                                [resizable]="false"
                                [maxHeightPx]="426"
                                [columns]="resultSet.tableColumns | ApplyColumnSettings : columnSettings"
                                [rows]="resultSet.tableRows">
                            </rdc-apps-table>

                            <small class="chart-results-small">Blank cells signify no data available</small>
                        </div>
                    </ng-container>

                </section>

                <section class="rdc-apps-chart-results empty"
                         [style.min-height.px]="window.innerHeight - chartBuilderForm.clientHeight"
                         *ngSwitchCase="queryService.results && !queryService.results.chartResponse?.rows?.length">
                    <div class="empty-state-body">
                        <h4>Your query returned no results</h4>
                        <p>Try the following:</p>
                        <ul>
                            <li>Remove filters to broaden the range of your search</li>
                            <li>Check that Airline(s) serve the Destination(s) that you've applied</li>
                        </ul>
                    </div>
                </section>

                <rdc-apps-empty-state *ngSwitchDefault
                    title="Awaiting input"
                    copy="As soon as you run a query your results will show here"
                ></rdc-apps-empty-state>

            </ng-container>



        </div>

    </ng-scrollbar>

</div>

