export interface RepoItem<T> {
    code: T;
    id: string | number;
    label: string;
    displayOrder: number;
    type?: string;
    iataCode?: string;
    icaoCode?: string;
    dataType?: string;
    disabled?: boolean;
    [key: string]: any;
}
