import { DialogRef } from '@angular/cdk/dialog';
import {
    Directive,
    ElementRef,
    HostBinding,
    HostListener,
    inject,
    Input,
    OnChanges,
    Optional,
    Renderer2
} from '@angular/core';

// Closes dialog instance on click, like cdkMenuItem
@Directive({ selector : '[rdcAppsDialogItem]', standalone: true })
export class RdcDialogItemDirective {

    @HostListener('click') closeDialog(): void {
        this.dialogRef?.close();
    }

    // needs to be optional, as we may use this in components that can appear within or
    // outside a dialog, in this instance, DialogRef is not given.
    constructor(@Optional() private dialogRef: DialogRef) {
    }
}

@Directive({ selector : '[rdcAppsContextMenuItem]', standalone: true })
export class RdcContextMenuItemDirective implements OnChanges {

    @Input() itemClosesMenuOnClick = true;

    @Input() rdcContextMenuItemActive = false;

    @Input() cdkMenuItemDisabled: boolean | undefined = false;

    @Input() disabled: boolean | undefined = false;

    @HostBinding('class.rdc-context-menu-item') rdcDialogItem = true;

    @HostListener('click') closeDialog(): void {
        if(this.itemClosesMenuOnClick) {
            this.dialogRef?.close();
        }
    }

    // needs to be optional, as we may use this in components that can appear within or
    // outside a dialog, in this instance, DialogRef is not given.
    @Optional() private dialogRef = inject(DialogRef);

    private elementRef: ElementRef<HTMLElement> = inject(ElementRef);
    private renderer2 = inject(Renderer2);

    ngOnChanges() {
        this.conditionallyAddClass('active', this.rdcContextMenuItemActive);

        this.conditionallyAddAttribute('disabled', this.cdkMenuItemDisabled ?? this.disabled ?? false);
    }

    private conditionallyAddClass(className: string, conditional: boolean) {
        if(conditional) {
            this.renderer2.addClass(this.elementRef.nativeElement, className);
        } else {
            this.renderer2.removeClass(this.elementRef.nativeElement, className);
        }
    }
    private conditionallyAddAttribute(attributeName: string, conditional: boolean, value = 'true') {
        if(conditional) {
            this.renderer2.setAttribute(this.elementRef.nativeElement, attributeName, value);
        } else {
            this.renderer2.removeAttribute(this.elementRef.nativeElement, attributeName);
        }
    }
}
