import {
    AfterContentInit, ChangeDetectorRef,
    Component,
    ContentChildren, ElementRef,
    Input, OnChanges,
    QueryList, SimpleChanges,
    ViewChildren
} from '@angular/core';
import { TabItemComponent } from '@rdc-apps/rdc-apex/src/lib/settings/ui/tab-item';

@Component({
    selector: 'rdc-apps-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: [ './tabs.component.scss' ],
})
export class TabsComponent implements AfterContentInit, OnChanges {

    @Input() hideContent = false;
    @Input() display: 'horizontal' | 'vertical' = 'vertical';
    @Input() heading!: string;
    @Input() setDefaultTab = true;
    @Input() activeTabIndex = 0;
    @Input() allowUserSwitching = true;

    activeXPosition = 0;
    activeWidth = 0;
    initialised = false;

    @ContentChildren(TabItemComponent) tabs!: QueryList<TabItemComponent>;

    @ViewChildren('tabButton') tabButtons!: QueryList<ElementRef<HTMLButtonElement>>;

    constructor(private cdr: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['activeTabIndex']?.currentValue !== changes['activeTabIndex']?.previousValue) {
            this.select(this.tabs?.get(this.activeTabIndex), this.activeTabIndex);
        }
    }

    ngAfterContentInit(): void {
        const activeTabs = this.tabs.filter((tab): boolean => tab.active);

        this.cdr.detectChanges();

        if (this.setDefaultTab && activeTabs.length === 0) {
            this.select(this.tabs.get(this.activeTabIndex), this.activeTabIndex);

            setTimeout(() => { // allow initial load to happen without animation
                this.initialised = true;
            });
        }
    }

    select(tabItem: TabItemComponent | undefined, index: number): void {
        if (!tabItem || tabItem.disabled) {
            return;
        }

        this.activeXPosition = this.tabButtons?.get(index)?.nativeElement.offsetLeft || 0;
        this.activeWidth = this.tabButtons?.get(index)?.nativeElement.offsetWidth || 0;

        this.tabs.forEach((tab, tabIndex) => {
            tab.active = false;

            if(tabIndex === index) {
                tab.active = true;
            }
        });

    }

}
