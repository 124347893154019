import { debounce, interval, take } from "rxjs";

import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, ɵFormGroupValue } from '@angular/forms';
import { rdcTrialYearStart } from "@rdc-apps/rdc-apex/src/lib/shared/constants";
import { ApexStudy, QueryRequestChartProps } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { TimePeriodRenderService } from "@rdc-apps/rdc-apex/src/lib/shared/utilities";
import { QueryEntity } from "rdc-apex-store";



@Injectable({ providedIn: 'root' })
export class ChartLoader {

    constructor(private timePeriodRenderService: TimePeriodRenderService) {
    }

    static setDefaultDatesForTemplate(
        templateCode: string,
        chartRequest: FormGroup<ApexStudy>,
        templateSeriesRange: { yearRangeForm: FormGroup<{ startYear: FormControl, endYear: FormControl, }> },
        onTrial?: boolean,
    ): void {

        const trialMinYear = rdcTrialYearStart;

        const currentYear = onTrial ? trialMinYear + 2 : new Date().getFullYear();
        const lastFullYear = onTrial ? trialMinYear : new Date().getFullYear() - 1;

        const isScatterType = chartRequest.get('studyType')?.value === 'scatter';

        const currYr = isScatterType ? currentYear - 1 : currentYear;

        const hasTpInSeries = chartRequest.value.queryRequest?.chartProperties?.seriesDefinitions?.some(({ partition }) => partition === 'year');

        if (hasTpInSeries) {

            const seriesItems = chartRequest.value.queryRequest?.chartProperties?.series?.length || 0;

            let finalStartYear = currYr;

            for (let i = 1; i < seriesItems; i++) {
                finalStartYear--;
            }

            templateSeriesRange.yearRangeForm.patchValue({
                startYear: finalStartYear,
                endYear: currYr,
            });
        }

        switch (templateCode) {
            case 'column1a':
            case 'column1b': {

                if(onTrial) {

                    chartRequest.patchValue({
                        queryRequest: { filters: { timePeriod: { specific: { startYear: trialMinYear, endYear: (trialMinYear + 1) } } } }
                    });

                    break;
                }

                chartRequest.patchValue({
                    queryRequest: { filters: { timePeriod: { specific: { startYear: currentYear - 4, endYear: currentYear } } } }
                });

                break;
            }
            case 'timePeriod1': {

                if(onTrial) {
                    templateSeriesRange.yearRangeForm.patchValue({
                        startYear: trialMinYear,
                        endYear: (trialMinYear + 1),
                    });
                }

                break;
            }
            default: {

                if(onTrial) {

                    chartRequest.patchValue({
                        queryRequest: { filters: { timePeriod: { specific: { endYear: (trialMinYear + 1), startYear: trialMinYear, year: trialMinYear } } } }
                    });

                    break;
                }

                chartRequest.patchValue({
                    queryRequest: { filters: { timePeriod: { specific: { endYear: lastFullYear, startYear: lastFullYear, year: lastFullYear } } } }
                });
            }
        }
    }

    load(
        chartForm: FormGroup<ApexStudy>,
        loadedStudy: QueryEntity,
        seriesSelectionComp: { onAddSeries: (ind: number) => void, onAddComparison: () => void },
    ): Promise<void> {

        return new Promise((resolve) => {

            chartForm.patchValue(loadedStudy as never);

            chartForm.patchValue({ queryRequest: { chartProperties: { xAxis: { partition: loadedStudy.queryRequest.chartProperties?.xAxis.partition } } } } as never);

            chartForm.patchValue({ queryRequest: { filters: { timePeriod: { type: loadedStudy.queryRequest.filters.timePeriod?.type } } } } as never);

            const { seriesDefinitions, series }: ɵFormGroupValue<QueryRequestChartProps> = loadedStudy.queryRequest.chartProperties as never;

            (chartForm.get<string>('queryRequest.chartProperties.seriesDefinitions') as FormArray)?.clear();

            // in the event series defs are required but not yet present
            seriesDefinitions?.forEach(() => {
                seriesSelectionComp?.onAddComparison();
            });

            const filters: any = loadedStudy.queryRequest.filters || {};

            Object.keys(filters).forEach((key) => {
                (chartForm.get<string>(`queryRequest.filters.${ key }.selected`) as FormArray)?.clear();

                filters[key]?.selected?.forEach((selected: never) => {
                    (chartForm.get<string>(`queryRequest.filters.${ key }.selected`) as FormArray)?.push(new FormControl(selected));
                });
            });

            this.timePeriodRenderService.rendered
                .pipe(
                    debounce(() => interval(100)),
                    take(1),
                )
                .subscribe(() => {

                    (chartForm.get<string>('queryRequest.chartProperties.seriesDefinitions') as FormArray)?.clear();

                    (chartForm.get<string>('queryRequest.chartProperties.series') as FormArray)?.clear();

                    seriesDefinitions?.forEach((def) => {
                        (chartForm.get<string>('queryRequest.chartProperties.seriesDefinitions') as FormArray)?.push(new FormGroup({
                            type: new FormControl(def.type),
                            partition: new FormControl(def.partition),
                        }));
                    });

                    series?.forEach((def, index) => {
                        seriesSelectionComp?.onAddSeries(index);

                        (chartForm.get<string>('queryRequest.chartProperties.series') as FormArray)?.at(index).patchValue(def);
                    });

                    chartForm.patchValue({ queryRequest: { filters: { timePeriod: loadedStudy.queryRequest.filters.timePeriod } } } as never);

                    setTimeout(() => {
                        chartForm.patchValue({ queryRequest: { filters: { timePeriod: loadedStudy.queryRequest.filters.timePeriod } } } as never);

                        resolve();
                    });

                });
        });


    }
}
