import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import * as moment from 'moment';


import * as QueryActions from './query.actions';
import { QueryEntity } from './query.models';
import { querySortComparer } from './query.sort-comparer';
import * as TagActions from '../../tags/+state/tags.actions';

export const QUERY_FEATURE_KEY = 'queries';

export interface QueryState extends EntityState<QueryEntity> {
    id: string | null | undefined; // which Query record has been selected
    loading: boolean;
    loadedSaved: boolean; // has the Query list been loaded
    saving: boolean;
    errors?: Record<string, string[]> | undefined | null; // last known error (if any)
}

export interface QueryPartialState {
    readonly [QUERY_FEATURE_KEY]: QueryState;
}

// API should handle the sorting when it's implemented
export const queryAdapter: EntityAdapter<QueryEntity> =
    createEntityAdapter<QueryEntity>({
        selectId: (model) => model.studyId,
        sortComparer: querySortComparer,
    });

export const initialQueryState: QueryState = queryAdapter.getInitialState({
    // set initial required properties
    id: null,
    loading: false,
    loadedSaved: false,
    saving: false,
});

const reducer = createReducer(
    initialQueryState,

    // saved queries functionality
    on(QueryActions.initQueries, (state) => ({
        ...state,
        loading: true,
        loadedSaved: false,
        error: null,
    })),

    on(QueryActions.loadQueriesSuccess, (state, { queries }) =>
        queryAdapter.setMany(queries, { ...state, loading: false, loadedSaved: true })
    ),

    on(QueryActions.saveQuery, (state) => ({
        ...state,
        saving: true,
    })),

    on(QueryActions.saveQuerySuccess, (state, { query, id }) => {
        const activeQry = state.entities[id];

        const completeQuery: QueryEntity = {
            ...query,
            studyId: id,
            createdUtc: query.createdUtc || moment().toISOString(true),
            lastUpdatedUtc: moment().toISOString(true),
            lastRunUtc: query.lastRunUtc || activeQry?.lastUpdatedUtc || moment().toISOString(true),
        };

        return queryAdapter.upsertOne(completeQuery, { ...state, saving: false });
    }),

    on(QueryActions.updateQueryLastRun, (state, { studyId }) => queryAdapter.upsertOne({
        studyId,
        lastRunUtc: moment().toISOString(true),
    } as QueryEntity, { ...state, saving: false })),

    on(QueryActions.deleteQuerySuccess, (state, { studyId }) => queryAdapter.removeOne(studyId, state)),

    on(QueryActions.loadQueriesFailure, (state, { error }) => ({
        ...state,
        error,
        loadedSaved: true,
        saving: false,
    })),

    on(QueryActions.setActiveQuery, (state, { id }) => ({
        ...state,
        id,
    })),

    on(TagActions.setStudyTagsSuccess, (state, { tags, studyId }) => {
        const study = state.entities[studyId];

        if (!study) {
            return state;
        }

        return queryAdapter.upsertOne({
            ...study,
            tags: [ ...study.tags, ...tags ], // previous tags plus the new one
        },{ ...state });
    }),

    on(TagActions.createTagsSuccess, (state, { tags }) => {
        if (!state.id) {
            return state;
        }

        const study = state.entities[state.id] ;

        if (!study) {
            return state;
        }

        const modifiedTags = study.tags.slice();

        tags.forEach((tag) => {
            const tagIndex = modifiedTags.findIndex(({ id }) => id === tag.id);

            if (tagIndex >= 0) { // replace existing
                modifiedTags.splice(tagIndex, 1, tag);
            }
        });

        return queryAdapter.upsertOne({
            ...study,
            tags: modifiedTags,
        },{ ...state });
    }),

    on(TagActions.removeTagsSuccess, (state, { tagIds, studyId }) => {
        const study = state.entities[studyId] ;

        if (!study) {
            return state;
        }

        const remaining = study.tags.filter((tag) => !tagIds.includes(tag.id));

        return queryAdapter.upsertOne({
            ...study,
            tags: remaining,
        },{ ...state });
    })

);

export function queryReducer(state: QueryState | undefined, action: Action) {
    return reducer(state, action);
}
