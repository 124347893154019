import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { scatterGroupByOptions } from '@rdc-apps/rdc-apex/src/lib/shared/constants';
import { ApexStudy } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { TemplateEventService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { SelectAutocompleteModule } from "shared-ui";

@Component({
    selector: 'rdc-apps-chart-builder-group-by',
    templateUrl: './chart-builder-group-by.component.html',
    styleUrls: [ './chart-builder-group-by.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
    imports: [ CommonModule, ReactiveFormsModule, SelectAutocompleteModule ]
})
export class ChartBuilderGroupByComponent implements OnInit {

    @Input() chartRequest!: FormGroup<ApexStudy>;

    @Input() inTemplateForm = false;

    @Output() init = new EventEmitter<void>();

    groupByOptions = scatterGroupByOptions;
    constructor(
        private fb: FormBuilder,
        public templateEventService: TemplateEventService
    ) {}

    ngOnInit(): void {

        this.queryRequestFormGroup('chartProperties').setControl('scatter', this.fb.nonNullable.group({
            groupBy: this.fb.nonNullable.control('destination.airport', Validators.required),
        }));

        this.queryRequestFormGroup().setControl('summaries', this.fb.nonNullable.group({
            destination : this.fb.nonNullable.control('airport', Validators.required),
        }));

        const groupBy = this.queryRequestFormGroup('chartProperties','scatter','groupBy');

        groupBy?.valueChanges
            .subscribe((groupByOption) => {

                const split = (groupByOption || 'all').split('.');

                this.queryRequestFormGroup().setControl('summaries', this.fb.nonNullable.group({
                    [split[0]]: this.fb.nonNullable.control(split[1] || split[0], Validators.required),
                }));
            });

        this.init.emit();
    }

    queryRequestFormGroup<T = FormGroup>(...additionalPaths: string[]): T {
        const path = additionalPaths.length ? `queryRequest.${additionalPaths.join('.')}` : 'queryRequest';

        return this.chartRequest.get(path) as T;
    }

}
