import { debounce, distinctUntilChanged, firstValueFrom, interval, Observable, Subscription } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Actions } from "@ngrx/effects";
import { Store } from '@ngrx/store';
import { QueryBuilderUtils } from "@rdc-apps/rdc-apex/src/lib/query-builder/utilities";
import {
    autocompleteTypeFilters,
    xAxisOptionsForType
} from '@rdc-apps/rdc-apex/src/lib/shared/constants';
import { ApexStudy } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { CustomValidators } from "@rdc-apps/rdc-shared/src/lib/custom-validators";
import { RepoItem, ToastType } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import { getIsAppLoading } from "@rdc-apps/rdc-shared/src/lib/data-access/store/app-loading";
import { RdcComponentUtils, ToastService } from "@rdc-apps/rdc-shared/src/lib/utilities";
import {
    clearQuery,
    DataPoint,
    QbDropDowns,
    autocomplete,
    AutocompleteOption,
    APP_STUDY_BUILDER,
    APP_TEMPLATE_BUILDER
} from "rdc-apex-store";
import { IconComponent, SelectAutocompleteModule } from "shared-ui";

@Component({
    selector: 'rdc-apps-query-builder-filter-autocomplete',
    templateUrl: './query-builder-filter-autocomplete.component.html',
    styleUrls: [ './query-builder-filter-autocomplete.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
    imports: [ CommonModule, ReactiveFormsModule, IconComponent, SelectAutocompleteModule ]
})
export class QueryBuilderFilterAutocompleteComponent extends RdcComponentUtils implements OnInit, OnDestroy {

    @Input() chartRequest!: FormGroup<ApexStudy>;

    @Input() dropDowns: QbDropDowns | undefined = undefined;

    @Input() autoCompleteResults$!: Observable<AutocompleteOption[]>;

    @Input() dataPoints: DataPoint[] = [];

    @Output() init = new EventEmitter<void>();

    @Output() optionChangedEvent = new EventEmitter<string>();

    autocompleteTypeFilters = autocompleteTypeFilters;

    optionsForFilter = new Map(xAxisOptionsForType);

    disabledControls: string[] = [];

    dropDownFilters = new Map<string, RepoItem<string>[] | undefined>();

    hideFilters: string[] = [];

    constructor(
        private store: Store,
        private toastService: ToastService,
        actions: Actions
    ) {
        super(actions);
    }

    ssub: Subscription | undefined;

    ngOnInit(): void {

        this.onActions([ clearQuery ], () => {
            this.ssub?.unsubscribe();

            setTimeout(() => {
                this.disabledControls = [];
            });
        });

        this.dropDownFilters
            .set('airlineType', this.dropDowns?.airlineTypes)
            .set('model', this.dropDowns?.aircraftModels)
            .set('family', this.dropDowns?.aircraftFamilies);

        autocompleteTypeFilters.forEach((filter) => {

            this.optionsForFilter.set(filter, [ { label: 'All', code: 'all' }, ...(this.optionsForFilter.get(filter) || []) ]);

            this.filtersFormGroup(filter, 'type')?.valueChanges
                .pipe(distinctUntilChanged((previous, current) => previous === current))
                .subscribe((filterType) => {
                    this.filtersFormGroup<FormArray>(filter, 'selected').clear();

                    if (filterType !== 'all') {
                        this.filtersFormGroup<FormArray>(filter, 'selected').setValidators([ Validators.required, CustomValidators.notEmpty() ]);
                        this.filtersFormGroup<FormArray>(filter, 'selected').updateValueAndValidity();

                        return;
                    }

                    this.filtersFormGroup<FormArray>(filter, 'selected').clearValidators();
                    this.filtersFormGroup<FormArray>(filter, 'selected').updateValueAndValidity();
                });

        });

        this.chartRequest.get('queryRequest')?.valueChanges
            .pipe(
                debounce(() => interval(0)),
                distinctUntilChanged((a, b) =>
                    JSON.stringify(a.dataPoints) === JSON.stringify(b.dataPoints)
                )
            )
            .subscribe((queryReq) => {
                if(
                    QueryBuilderUtils.tableBuilderShouldHideAircraft(queryReq) ||
                    QueryBuilderUtils.chartBuilderShouldHideAircraft(queryReq, this.dataPoints)
                ) {
                    this.chartRequest.get('queryRequest.filters.aircraft')?.patchValue({
                        type: 'all',
                        selected: [],
                    });

                    this.hideFilters = [ 'aircraft' ];

                    return;
                }
                this.hideFilters = [];
        });

        this.chartRequest.get('queryRequest.chartProperties.type')?.valueChanges.subscribe((type) => {

            if (type === 'single') {
                this.disabledControls = [];

                return;
            }

            this.ssub?.unsubscribe();

            this.ssub = this.chartRequest.get('queryRequest.chartProperties.seriesDefinitions')?.valueChanges.subscribe((seriesDefs) => {

                this.disabledControls = [];

                seriesDefs?.forEach(async (sd) => {
                    const seriesType = sd.type;

                    if (autocompleteTypeFilters.includes(seriesType as never)) {
                        const isLoading = await firstValueFrom(this.store.select(getIsAppLoading(APP_STUDY_BUILDER, APP_TEMPLATE_BUILDER)));

                        // inform users their filters have been updated
                        if (this.filtersFormGroup(seriesType).value.type !== 'all' && !isLoading) {
                            this.toastService.simpleToast(ToastType.WARN, 'Filters selection has been affected by this change', 5000);
                        }

                        this.filtersFormGroup(seriesType).patchValue({ type: 'all', selected: [] });

                        this.filtersFormGroup<FormArray>(seriesType, 'selected').removeValidators(Validators.required);

                        this.disabledControls.push(seriesType);

                        return;
                    }
                });
            });
        });

        this.init.emit();
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();

        this.optionChangedEvent.complete();
    }

    filtersFormGroup<T = FormGroup>(...additionalPaths: string[]): T {
        const path = additionalPaths.length ? `queryRequest.filters.${ additionalPaths.join('.') }` : 'queryRequest.filters';

        return this.chartRequest.get(path) as T;
    }

    filterGroupSelected(filter: string): RepoItem<string>[] {
        return this.filtersFormGroup<FormArray>(filter, 'selected')?.value || [];
    }

    onAutocomplete(filter: string, query: string) {
        this.store.dispatch(autocomplete({ filter, query }));
    }

    onSelect(filter: string, selected: Partial<RepoItem<unknown>>): void {

        const filterGroup = this.filtersFormGroup(filter);

        if (filterGroup.value.selected.find((option: any) => JSON.stringify(option) === JSON.stringify(selected))) {
            return;
        }

        this.filtersFormGroup<FormArray>(filter, 'selected').push(new FormControl(selected));

        // filterGroup.markAsTouched();

        this.onBlur();
    }

    onRemoveSelected(filter: string, index: number): void {
        this.filtersFormGroup<FormArray>(filter, 'selected').removeAt(index);
        this.filtersFormGroup<FormArray>(filter, 'selected').markAsTouched();
    }

    invalidAndTouched(filter: string): boolean {
        return this.filtersFormGroup(filter, 'selected').invalid && this.filtersFormGroup(filter, 'selected').touched;
    }

    onFilterTypeChange(filter: string): void {
        setTimeout(() => {
            this.optionChangedEvent.emit(filter);
        });
    }

    onFilterClose($event: Partial<RepoItem<unknown>> | null, filter: "origin" | "destination" | "airline" | "aircraft") {
        this.filtersFormGroup(filter).markAsUntouched();

        const filterGroup = this.filtersFormGroup(filter);

        const othersUnTouched = this.autocompleteTypeFilters
            .filter((f) => f !== filter)
            .every((fil) => this.filtersFormGroup(fil).valid);

        setTimeout(() => {
            if(filterGroup.value.type === $event?.code || filterGroup.value.type === 'all') { // if unchanged mark as touched so can error
                this.onBlur();
            } else if(othersUnTouched) {
                this.filtersFormGroup().markAsUntouched();
            }
        });
    }

    onBlur(...exclude: string[]): void {
        this.autocompleteTypeFilters.forEach((filter) => {
            if(!exclude.includes(filter)) {
                this.filtersFormGroup(filter, 'selected')?.markAsTouched();
            }
        });
    }

    onTypeClick(): void {
        if(this.filtersFormGroup().invalid) return;

        this.filtersFormGroup().markAsUntouched();
    }
}
