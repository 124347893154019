import { Pipe, PipeTransform } from '@angular/core';
import { RepoItem } from '@rdc-apps/rdc-shared/src/lib/data-access/models';
import {
    QbDropDownOptionGroup, QbOutputSummaryGroup
} from 'rdc-apex-store';

@Pipe({ name: 'dropdownsForSummary' })
export class DropdownOptionsForSummaryPipe implements PipeTransform {

    transform(value: QbOutputSummaryGroup, groups: QbDropDownOptionGroup[] = []): RepoItem<string>[] {

        const code =  [ 'origin', 'destination' ].includes(value.code) ? 'originDestination' : value.code;

        return groups.find((group) => group.code === code)?.dropdownOptions || [];
    }

}
