import {
    BehaviorSubject,
    combineLatest,
    debounce,
    filter,
    firstValueFrom,
    interval,
    Observable,
    Subject,
    take,
    takeUntil,
    tap,
    withLatestFrom
} from 'rxjs';

import { Dialog } from '@angular/cdk/dialog';
import { CommonModule, DatePipe, Location } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    inject,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {
    FormArray,
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    Validators,
    ɵFormGroupRawValue,
    ɵFormGroupValue,
    ɵTypedOrUntyped
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLinkWithHref } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
    BreadcrumbBuilder,
    QbFiltersDateRangeUtils,
    QueryBuilderUtils,
    QuerySummariser
} from '@rdc-apps/rdc-apex/src/lib/query-builder/utilities';
import { TabItemModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/tab-item';
import { TabsModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/tabs';
import { showHide } from '@rdc-apps/rdc-apex/src/lib/shared/animations';
import {
    rdcTrialYearStart
} from '@rdc-apps/rdc-apex/src/lib/shared/constants';
import {
    ApexStudy,
    PresetColor,
    QueryBuilderType,
    QueryRequestChartProps,
    QueryRequestTimePeriod
} from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { QueryService } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/services';
import { dropInOut } from '@rdc-apps/rdc-shared/src/lib/animations';
import { QueryActions, QueryStates } from '@rdc-apps/rdc-shared/src/lib/constants';
import { sendActivity } from '@rdc-apps/rdc-shared/src/lib/data-access/actions';
import { RegisteredInterestService } from '@rdc-apps/rdc-shared/src/lib/data-access/local-storage';
import { appLoaded, AppLoadStatus } from '@rdc-apps/rdc-shared/src/lib/data-access/store/app-loading';
import { ApexSalesforceLeadGenerator, RdcDialogHelper } from '@rdc-apps/rdc-shared/src/lib/utilities';
import { ExportingMimeTypeValue } from 'highcharts';
import { NgScrollbar } from 'ngx-scrollbar';
import {
    getUserDetailsData, runApexTrialMode,
    clearQuery,
    getAllSavedQueries,
    QueryEntity,
    saveQuery
    ,
    FiltersDateRangeEntity,
    getFiltersDateRange,
    getLoadedDateRange
    ,
    getDefaultPreferences,
    getDefaultPreferencesUnitsMeasurements,
    getPresetColours,
    getUserHighchartsFormatting
    , DataPointsEntity, getDataPoints, AutocompleteOption, getAllAutocomplete, APP_STUDY_BUILDER, APP_RUNNING_STUDY
} from 'rdc-apex-store';
import { ApplyColumnSettingsPipe } from "rdc-table";
import { RdcButtonDirective, TooltipDirective } from "shared-directives";
import {
    AccordionComponent,
    AccordionItemComponent,
    ChartComponent,
    DatatableExportComponent,
    DrawerToggleComponent,
    EmptyStateComponent,
    ExportInputs,
    IconComponent,
    LoadingOverlayComponent,
    RdcDataTableColumnSettings,
    TableComponent
} from 'shared-ui';

import { ChartLoader } from "./chart-loader";
import { ChartBuilderGroupByComponent } from "../chart-builder-group-by/chart-builder-group-by.component";
import {
    ChartBuilderSeriesSelectionComponent
} from "../chart-builder-series-selection/chart-builder-series-selection.component";
import { ChartBuilderXAxisComponent } from "../chart-builder-x-axis/chart-builder-x-axis.component";
import { QbDataPointsComponent } from "../qb-data-points/qb-data-points.component";
import { QbFormFiltersComponent } from "../qb-form-filters/qb-form-filters.component";
import { QbPlayButtonsComponent } from '../qb-play-reset-buttons/qb-play-buttons.component';
import { QbSaveAsComponent } from "../qb-save-as/qb-save-as.component";
import { QbSummariesComponent } from "../qb-summaries/qb-summaries.component";
import {
    QueryBuilderAdditionalFiltersComponent
} from "../query-builder-additional-filters/query-builder-additional-filters.component";
import {
    QueryBuilderFilterAutocompleteComponent
} from "../query-builder-filter-autocomplete/query-builder-filter-autocomplete.component";
import {
    QueryBuilderTimePeriodOptionsComponent
} from "../query-builder-time-period-options/query-builder-time-period-options.component";
import { QbTopBarComponent } from "../top-bar/qb-top-bar.component";


interface TemplateLocationState {
    query?: QueryEntity;
}

@Component({
    standalone: true,
    selector: 'rdc-apps-chart-builder',
    templateUrl: './chart-builder.component.html',
    styleUrls: [ './chart-builder.component.scss' ],
    encapsulation: ViewEncapsulation.Emulated,
    imports: [
        CommonModule,
        QbTopBarComponent,
        AccordionComponent,
        AccordionItemComponent,
        RouterLinkWithHref,
        ReactiveFormsModule,
        ChartBuilderXAxisComponent,
        RdcButtonDirective,
        IconComponent,
        ChartBuilderSeriesSelectionComponent,
        QbFormFiltersComponent,
        QbSummariesComponent,
        QueryBuilderTimePeriodOptionsComponent,
        QueryBuilderFilterAutocompleteComponent,
        QueryBuilderAdditionalFiltersComponent,
        ChartComponent,
        QbDataPointsComponent,
        ChartBuilderGroupByComponent,
        EmptyStateComponent,
        QbPlayButtonsComponent,
        TabsModule,
        TabItemModule,
        TooltipDirective,
        NgScrollbar,
        TableComponent,
        ApplyColumnSettingsPipe,
        LoadingOverlayComponent,
        DrawerToggleComponent,
    ],
    animations: [ showHide, dropInOut ],
})
export class ChartBuilderComponent extends ApexSalesforceLeadGenerator implements OnInit {

    @ViewChild('seriesSelection') seriesSelectionComp!: ChartBuilderSeriesSelectionComponent;

    @ViewChild('summariesComponent') summariesComponent!: QbSummariesComponent;

    @ViewChild('dataPointsComponent') dataPointsComponent!: QbDataPointsComponent;

    @ViewChild('accordion') accordion!: AccordionComponent;

    @ViewChild('chartComponent') chartComponent!: ChartComponent;

    @ViewChild('chartBuilderContainer', { static: true }) chartBuilderContainer!: ElementRef<HTMLDivElement>;

    dataPointsData$!: Observable<DataPointsEntity | undefined>;

    filterDataDateRange$!: Observable<FiltersDateRangeEntity | null>;
    autoCompleteResults$!: Observable<AutocompleteOption[]>;
    savedQueries$!: Observable<QueryEntity[]>;
    userPrefColours$!: Observable<PresetColor[] | undefined>;
    userFormatting$!: Observable<{ decimalPoint?: string; thousandsSep?: string }>;

    columnSettings: Map<string, RdcDataTableColumnSettings> = new Map()
        .set('Summarisation', { widthOverride: 200 })
        .set('xAxis', { backgroundColour: '#F6F8FA' });

    study: QueryEntity | null = null;

    queryType: 'scatter' |'chart' = 'chart';

    chartRequest!: FormGroup<ApexStudy>;

    dataPointsData!: DataPointsEntity | undefined;

    dateRangeUtils!: QbFiltersDateRangeUtils;

    queryRequest = new FormGroup({});

    queryOpen = true;

    displayTable = false;

    loaded = false;

    initialised = new BehaviorSubject<string[]>([]);

    window = window;

    queryStates = QueryStates;

    userColours: string[] = [];

    filtersSummary = 'None';
    seriesSummary = 'None';
    xAxisSummary = 'None';
    dataPointLabel = 'None';

    finishedForm$: Subject<boolean> = new Subject();

    cancelTypeUpdates$ = new Subject<void>();

    template: QueryEntity | null = null;
    templateId: string | undefined = undefined;

    private cancelledQuery = false;

    apexTrialMode$!: Observable<boolean>;

    breadcrumbBuilder = inject(BreadcrumbBuilder);

    queryServiceState: QueryStates = QueryStates.INITIAL;

    @HostListener('window:keydown.control.enter', [ '$event' ])
    onKeyboardSubmit(): void {
        this.onSubmit();
    }

    override ngOnInit(): void {

        this.queryService.status
            .pipe(
                takeUntil(this.componentDestroyed$)
            )
            .subscribe((status) => {
                this.queryServiceState = status.state;
            });

        this.apexTrialMode$ = this.store.select(runApexTrialMode('userDetailsState'));

        const nonNullable = this.fb.nonNullable; // for readability

        this.chartRequest = nonNullable.group({
            studyId: nonNullable.control('00000000-0000-0000-0000-000000000000', Validators.required),
            studyName: nonNullable.control('New study', Validators.required),
            studyType: nonNullable.control(QueryBuilderType.Column, Validators.required),
            queryRequest: nonNullable.group({
                dataPoints: nonNullable.group({
                    dataPointCodes: nonNullable.array([
                        nonNullable.control('', Validators.required),
                    ]),
                }),
                chartProperties: nonNullable.group<QueryRequestChartProps>({
                    type: nonNullable.control('single', Validators.required),
                    singleSeriesColour: nonNullable.control('#43E08C', Validators.required),
                    xAxis: nonNullable.group({
                        type: nonNullable.control('', Validators.required),
                    }),
                }),
                summaries: nonNullable.group({}),
                adjustments: nonNullable.group({}), // populated by component
                filters: nonNullable.group({
                    origin: nonNullable.group({
                        type: nonNullable.control('all', Validators.required),
                        selected: nonNullable.array([]),
                    }),
                    destination: nonNullable.group({
                        type: nonNullable.control('all', Validators.required),
                        selected: nonNullable.array([]),
                    }),
                    airline: nonNullable.group({
                        type: nonNullable.control('all', Validators.required),
                        selected: nonNullable.array([]),
                    }),
                    aircraft: nonNullable.group({
                        type: nonNullable.control('all', Validators.required),
                        selected: nonNullable.array([]),
                    }),
                    timePeriod: nonNullable.group<QueryRequestTimePeriod>({
                        type: nonNullable.control<'specific' | 'relative'>('specific', Validators.required),
                    }),
                    cabinTypes: nonNullable.group({
                        types: nonNullable.array([ 'economy' ], Validators.required),
                    }),
                    emissionScheme: nonNullable.group({
                        types: nonNullable.array([ 'all' ], Validators.required),
                    }),
                    schedulesFilters: nonNullable.group({
                        franchisePartners: this.fb.nonNullable.control(true),
                        nonStopServices: this.fb.nonNullable.control(false),
                    }),
                }),
            }),
        });

        this.queryRequest = this.chartRequest.get('queryRequest') as FormGroup;

        this.chartRequest.valueChanges
            .pipe(debounce(() => interval(50)))
            .subscribe((request: ɵFormGroupValue<ApexStudy>) => {
                const { queryRequest, studyType } = request;

                this.dataPointLabel = QueryBuilderUtils.dataPointLabel(this.dataPointsData, queryRequest?.dataPoints);

                this.filtersSummary = QuerySummariser.summariseFilterBy(queryRequest?.filters, this.dataPointsData);
                this.seriesSummary = QuerySummariser.summariseSeriesSelection(queryRequest?.chartProperties, studyType);
                this.xAxisSummary = QuerySummariser.summariseXAxis(queryRequest?.chartProperties?.xAxis);

                if (this.chartRequest.untouched && !this.chartRequest.dirty) {
                    return;
                }
            });

        this.handleRouterEvents();

        this.userPrefColours$
            .pipe(
                filter((colours) => !!colours),
                takeUntil(this.componentDestroyed$),
                take(1)
            )
            .subscribe((colours) => this.userColours = (colours || []).map(({ colour }) => colour));

        this.store$.select(getLoadedDateRange)
            .pipe(
                withLatestFrom(this.apexTrialMode$),
                filter(([ dateRange ]) => !!dateRange),
                takeUntil(this.componentDestroyed$),
                take(1)
            )
            .subscribe(([ dateRange, onTrial ]) => this.dateRangeUtils = new QbFiltersDateRangeUtils(dateRange, onTrial));

        this.dataPointsData$
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((data) => this.dataPointsData = data);

        this.queryService.status
            .pipe(
                takeUntil(this.componentDestroyed$),
                filter((sState) => sState.state === QueryStates.HAS_RESULTS)
            )
            .subscribe(() => {
                if(this.cancelledQuery) {
                    this.cancelledQuery = false;

                    return;
                }

                this.queryOpen = false;
                this.displayTable = false;

                const studyType = QueryBuilderUtils.getTypeOfQuery(this.queryService.lastRunQuery as never);

                this.store$.dispatch(sendActivity({
                    activity: {
                        activityCode: `rdc.q.apex.query.${ studyType.activityCode }.run`,
                        detail: {
                            area: 'Data & Visualisation',
                            type: studyType.type,
                            creationType: this.templateId || 'Blank',
                            launchSource: 'Query.Run',
                            queryObject: this.queryService.lastRunQuery || {},
                        },
                    },
                }));
            });

    }

    handleRouterEvents(): void {
        combineLatest([
            this.activatedRoute.data,
            this.activatedRoute.queryParams,
        ]).pipe(
            filter(([ { savedQuery }, { created } ]) => !(created && !savedQuery)),
            tap(([ data, { created } ]) => !created ? this.queryService.reset(): null),
            debounce(() => interval(100)),
        )
            .subscribe(async([ { queryType, savedQuery }, { execute, created } ]) => {

                const value = queryType === 'chart' ? 'Column, Line & Area' : 'Scatter plots';
                this.breadcrumbBuilder.generateAdditionalContent(value);

                this.store$.dispatch(AppLoadStatus.loading(
                    APP_STUDY_BUILDER,
                    'Initialising study builder...',
                    { overlay: true, global: true }
                ));

                this.template = null;
                this.templateId = undefined;
                this.queryType = queryType;

                this.template = (this.location.getState() as TemplateLocationState).query || null;

                if (this.template) {
                    this.templateId = this.activatedRoute.snapshot.params['id'];

                    await this.assignDynamicTemplateValues();
                }

                this.study = savedQuery || this.template || this.study;

                if(!this.study && !created) {
                    await this.cleanReset()
                }

                this.queryOpen = !this.study || !execute;

                if (this.queryType === 'scatter') {
                    this.savedQueries$ = this.store$.select(getAllSavedQueries('scatter'));

                    this.chartRequest.get('queryRequest.chartProperties.xAxis.type')?.clearValidators();
                } else {
                    this.savedQueries$ = this.store$.select(getAllSavedQueries('bar', 'line', 'area'));
                }

                if((this.study && execute) || this.template) {

                    this.store$.dispatch(AppLoadStatus.loading(
                        APP_RUNNING_STUDY,
                        'Running study...',
                        { overlay: true, global: true }
                    ));

                    this.queryService.lastRunQuery = this.study;
                    // this.executeStatus = QueryStates.QUERYING;

                    if(this.study) {
                        this.queryService.chart(this.study);
                    }
                }

                this.store$.dispatch(appLoaded({ key: APP_STUDY_BUILDER }));

                this.initialised
                    .pipe(takeUntil((this.finishedForm$)))
                    .subscribe(async (initialisedSections) => {

                        const initialisedCount = this.queryType === 'scatter' ? 5 : 6;

                        if (initialisedSections.length >= initialisedCount) { // all items are initialised
                            await this.loadQuery(this.queryType);

                            this.finishedForm$.next(true);
                        }
                    });

            });
    }

    queryFormGroup<T = FormGroup>(...additionalPaths: string[]): T {
        const path = additionalPaths.length ? `queryRequest.${ additionalPaths.join('.') }` : 'queryRequest';

        return this.chartRequest.get(path) as T;
    }


    onSubmit(): void {

        if(this.queryService.querying) {
            this.cancelledQuery = true;

            this.queryService.killRequest();

            return;
        }

        this.chartRequest.markAllAsTouched();

        if (this.chartRequest.valid) {

            const formValue = this.removeConditionalProps(this.chartRequest.getRawValue());

            this.cancelledQuery = false;

            this.queryService.chart(formValue as QueryEntity);
        }
    }

    onSave(): void {
        const formValue = this.removeConditionalProps(this.chartRequest.getRawValue());

        this.store$.dispatch(saveQuery({ query: formValue as any, templateId: this.templateId }));
    }

    constructor(
        public queryService: QueryService,
        protected activatedRoute: ActivatedRoute,
        protected store$: Store,
        protected fb: FormBuilder,
        protected router: Router,
        protected dialog: Dialog,
        protected cdr: ChangeDetectorRef,
        protected rdcDialogHelper: RdcDialogHelper,
        protected chartLoader: ChartLoader,
        protected location: Location,
        actions$: Actions,
        regInterestService: RegisteredInterestService,
    ) {
        super(store$, actions$, regInterestService);

        this.finishedForm$.next(false);

        this.store$.dispatch(getFiltersDateRange());
        this.store$.dispatch(getDefaultPreferences());

        this.dataPointsData$ = this.store$.select(getDataPoints('dataPointState'));

        this.userPrefColours$ = this.store$.select(getPresetColours('defaultPreferencesState'));
        this.userFormatting$ = this.store$.select(getUserHighchartsFormatting('defaultPreferencesState'));
        this.autoCompleteResults$ = this.store$.select(getAllAutocomplete);
        this.filterDataDateRange$ = this.store$.select(getLoadedDateRange);
    }

    async onExport(): Promise<void> {
        if(await firstValueFrom(this.apexTrialMode$)) {
            return;
        }

        const studyType = QueryBuilderUtils.getTypeOfQuery(this.queryService.lastRunQuery as never);

        const dialogRef = this.dialog.open<any, ExportInputs, DatatableExportComponent>(DatatableExportComponent, {
            positionStrategy: this.rdcDialogHelper.dialogPosition(),
            panelClass: [ 'rdc-dialog' ],
            width: '352px',
            data: {
                context: 'applet',
                export: 'chart',
                exportFormats: [ 'tabular', 'chart' ],
                queryResults: this.queryService.results,
            },
        });

        dialogRef?.componentInstance?.export
            .pipe(take(1))
            .subscribe((exportValue) => {

                if(exportValue.export === 'chart') {

                    const nameWithoutSpaces = this.chartRequest.value.studyName?.split(' ').join('_');
                    const dateTime = new DatePipe('en-GB').transform(new Date().getTime(),'yyyy-MMM-dd_HHmm');

                    const filename = `${ nameWithoutSpaces }_${ dateTime }`;

                    (this.chartComponent.chart as any).exportChartLocal({
                        type: exportValue.exportType as ExportingMimeTypeValue,
                        filename,
                    }, {});

                    this.store$.dispatch(sendActivity({
                        activity: {
                            activityCode: `rdc.q.apex.query.${ studyType.activityCode }.export`,
                            detail: {
                                launchSource: 'Query.export',
                                area: 'Data & Visualisation',
                                queryObject: this.queryService.lastRunQuery as never,
                            },
                        },
                    }));

                    return;
                }

                this.queryService.export(
                    exportValue,
                    studyType.activityCode,
                    this.queryService.results,
                    this.templateId,
                    false
                );

                this.queryService.status
                    .pipe(
                        filter(({ action }) => action === QueryActions.EXPORT),
                        take(1)
                    )
                    .subscribe(() => {
                        dialogRef.close();
                    });

            });
    }

    onSaveAs(): void {
        const formValue = this.removeConditionalProps(this.chartRequest.getRawValue());

        this.dialog.open(QbSaveAsComponent, {
            disableClose: true,
            positionStrategy: this.rdcDialogHelper.dialogPosition(),
            width: '352px',
            data: {
                query: formValue,
            },
            panelClass: [ 'rdc-dialog' ],
        });
    }

    protected async loadQuery(studyType: 'scatter' | 'table' | 'chart'): Promise<void> {

        this.cancelTypeUpdates$.next();

        if (studyType === 'scatter') {
            this.chartRequest.patchValue({
                studyType: QueryBuilderType.Scatter,
                queryRequest: { chartProperties: { xAxis: { type: 'sectorLength' } } },
            });
        }

        this.cdr.detectChanges();

        if (this.study) {
            await this.chartLoader.load(this.chartRequest, this.study, this.seriesSelectionComp, this.cdr);

            this.cdr.detectChanges();
        }

        this.chartRequest.get('queryRequest.chartProperties.xAxis')?.valueChanges
            .pipe(takeUntil(this.cancelTypeUpdates$))
            .subscribe((cp) => {
                this.setChartTypesIfUntouched(cp.type);
            });
    }


    /* Only if controls are untouched by the user, default them to line for time period xAxis.
    * It would be annoying as a user if I set my query types then they suddenly changed */
    private async setChartTypesIfUntouched(type?: string): Promise<void> {

        const studyType = this.chartRequest.get('studyType');

        if (studyType?.value === 'scatter') {
            return;
        }

        if (type === 'timePeriod' && studyType?.untouched) {
            this.chartRequest.patchValue({ studyType: QueryBuilderType.Line });
        } else if (studyType?.untouched) {
            this.chartRequest.patchValue({ studyType: QueryBuilderType.Column });
        }

        this.queryFormGroup<FormArray>('chartProperties', 'series')?.controls.forEach((control) => {

            if (type === 'timePeriod' && control.get('chartType')?.untouched) {
                control.patchValue({ chartType: QueryBuilderType.Line });
            } else if (control.get('chartType')?.untouched) {
                control.patchValue({ chartType: 'column' });
            }
        });
    }


    onFormInit($event: string): void {
        const val = this.initialised.value;

        this.initialised.next([ ...val, $event ]);
    }

    async onRequestUpgrade(dataPointGroupName: string): Promise<void> {

        const user = await firstValueFrom(
            this.store.select(getUserDetailsData('userDetailsState'))
        );

        this.onGenerateLead(user, `Data points - ${ dataPointGroupName }`, '' );
    }

    // ============ Validation functions ================

    get xAxisInvalid(): boolean {
        const xAxisFormGroup = this.queryFormGroup('chartProperties','xAxis');

        return (xAxisFormGroup.invalid && xAxisFormGroup.touched) || (xAxisFormGroup.value.type === 'timePeriod' && !!this.queryFormGroup('filters.timePeriod')?.invalid);
    }

    get groupByInvalid(): boolean {
        const group = this.queryFormGroup('chartProperties.scatter');

        return group?.touched && group?.invalid;
    }

    get filtersInvalid(): boolean {
        const filters = this.queryFormGroup('filters');
        const adjustments = this.queryFormGroup('adjustments');
        const summaries = this.queryFormGroup('summaries');

        return (filters.invalid && filters.touched) ||
            (adjustments.invalid && adjustments.touched) ||
            (summaries.invalid && summaries.touched);
    }

    get seriesInvalid(): boolean {
        const seriesGroup = this.queryFormGroup('chartProperties','series');
        const seriesDefsGroup = this.queryFormGroup('chartProperties','seriesDefinitions');

        return (seriesGroup?.invalid && seriesGroup?.touched) || (seriesDefsGroup?.invalid && seriesDefsGroup?.touched);
    }

    get dataPointsInvalid(): boolean {
        const dataPointGroup = this.queryFormGroup('dataPoints');

        return (dataPointGroup?.invalid && dataPointGroup?.touched);
    }

    async cleanReset() {

        this.accordion?.onToggle(0, true);

        this.queryService.reset();

        const preferences = await firstValueFrom(
            this.store$.select(getDefaultPreferencesUnitsMeasurements('defaultPreferencesState'))
        );

        const onTrial = await firstValueFrom(this.apexTrialMode$);

        this.chartRequest.reset();

        this.store$.dispatch(clearQuery());

        this.chartRequest.patchValue({
            studyType: this.queryType === 'scatter' ? this.queryType : 'bar',
            queryRequest: {
                filters: {
                    aircraft: { type: 'all' },
                    airline: { type: 'all' },
                    origin: { type: 'all' },
                    destination: { type: 'all' },
                    timePeriod: {
                        type: 'specific',
                        specific: {
                            type: 'months',
                            startYear: onTrial ? rdcTrialYearStart : new Date().getFullYear() - 1,
                            endYear: onTrial ? rdcTrialYearStart : new Date().getFullYear() - 1,
                            startMonth: 'january',
                            endMonth: 'december',
                        }
                    },
                    cabinTypes: {
                        types: [ 'economy' ]
                    },
                    emissionScheme: {
                        types: [ 'all' ],
                    },
                    schedulesFilters: {
                        nonStopServices: true,
                        franchisePartners: true,
                    }
                },
                adjustments: {
                    ...preferences,
                },
                chartProperties: {
                    xAxis: {
                        type: this.queryType === 'scatter' ? 'sectorLength' : null,
                    },
                    type: 'single',
                },
            }
        } as any);

        (this.chartRequest.get('queryRequest.chartProperties.xAxis') as FormGroup)?.removeControl('partition');
        (this.chartRequest.get('queryRequest.chartProperties.xAxis') as FormGroup).markAsUntouched();
    }

    private async assignDynamicTemplateValues() {

        const colours = await firstValueFrom(
            this.userPrefColours$.pipe(filter((col) => !!col))
        );

        if(!colours) {
            return;
        }

        if(this.template?.queryRequest.chartProperties?.singleSeriesColour) {
            this.template.queryRequest.chartProperties.singleSeriesColour = colours[0].colour;
        }

        this.template?.queryRequest.chartProperties?.series?.forEach((series, index) => {
            series.colour = colours[index].colour;

            series.chartType = (this.template?.studyType === 'bar' ? 'column': this.template?.studyType) || 'column';
        });
    }

    protected removeConditionalProps(formValue: ɵTypedOrUntyped<ApexStudy, ɵFormGroupRawValue<ApexStudy>, any>): ɵTypedOrUntyped<ApexStudy, ɵFormGroupRawValue<ApexStudy>, any> {

        const dataPointCode = formValue.queryRequest.dataPoints.dataPointCodes[0] || '';

        const dataPoint = this.dataPointsData?.datapoints.find(({ code }) => code === dataPointCode);

        if(!dataPoint) {
            return formValue;
        }

        if(!dataPoint.datapointGroups.find(({ datapointGroupCode }) => datapointGroupCode === 'pricing')) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            delete formValue.queryRequest.filters.cabinTypes;
        }

        if(!dataPoint.datapointGroups.find(({ datapointGroupCode }) => datapointGroupCode === 'sustainability')) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            delete formValue.queryRequest.filters.emissionScheme;
        }

        return formValue;
    }

}
