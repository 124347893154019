import { CdkMenuItem, CdkMenuModule } from "@angular/cdk/menu";
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RdcFilterPipe } from "@rdc-apps/rdc-shared/src/lib/pipes/filter";
import { NgScrollbar } from "ngx-scrollbar";
import {
    RdcButtonDirective,
    RdcContextMenuItemDirective,
    RdcDialogItemDirective, RdcIconButtonDirective,
    RdcMenuItemDirective
} from "shared-directives";

import { SelectAutoCompleteComponent } from "./autocomplete.component";
import { SelectComponent } from "./select.component";
import { RemoveEmptyCatPipe, SelectFilterPipe, VirtualScrollHeightPipe } from "./select.pipes";
import { IconComponent } from "../icon/icon.component";




@NgModule({
    declarations: [
        SelectComponent,
        SelectAutoCompleteComponent,
    ],
    imports: [
        CommonModule,
        RdcButtonDirective,
        RdcDialogItemDirective,
        CdkMenuModule,
        CdkMenuItem,
        ReactiveFormsModule,
        NgScrollbar,
        IconComponent,
        CdkVirtualScrollViewport,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        VirtualScrollHeightPipe,
        RemoveEmptyCatPipe,
        RdcFilterPipe,
        SelectFilterPipe,
        RdcMenuItemDirective,
        RdcContextMenuItemDirective,
        RdcIconButtonDirective,
    ],
    exports: [
        SelectComponent,
        SelectAutoCompleteComponent,
    ],
})
export class SelectAutocompleteModule {}
