<a class="sustainability-item"
   [routerLink]="link.route"
   [class.active]="router.isActive(link.route.join('/'), true)"
   [attr.pendo-element]="pendoElement"
   [attr.pendo-index]="pendoIndex">
    <div class="icon-round">
        <rdc-apps-icon style="width: 24px; height: 24px;" [size]="link.iconSize || 'xxxs'" [rdcIcon]="link.icon"></rdc-apps-icon>
    </div>
    <div class="sustainability-item-text">
        <span class="main-text">{{ link.title }}</span>
        @if(link.subtext) {
            <span class="subtext" [innerHTML]="link.subtext"></span>
        }
    </div>
</a>
