<form class="account" [formGroup]="form">

    <rdc-apps-header header="Account" title="General" [noMargin]="true"></rdc-apps-header>

        <div class="account-container">
            <rdc-apps-validated-control
                controlName="email"
                [attr.title]="form.get('email')?.value"
                [control]="form.get('email')"
                [validationMessages]="validationMessages"
                [showValidation]="showValidation"
            >
                <div class="form-group">
                    <label>Account email</label>
                    <input
                        type="text"
                        formControlName="email"
                        [ngClass]="{ 'disabled' : form.get('email')?.disabled }"
                    >
                </div>
            </rdc-apps-validated-control>
        </div>

        <div class="account-container" *ngIf="!passwordMode">
            <div class="form-group max-width">
                <label>Password</label>
                <input
                    type="password"
                    data-lpignore="true"
                    name="password"
                    placeholder="••••••••"
                    autocomplete="on"
                    disabled="disabled"
                >
            </div>
            <a (click)="onUpdate()">Update password</a>
        </div>

        <ng-container *ngIf="passwordMode" formGroupName="passwords">
            <div class="account-container">
                <rdc-apps-validated-control
                    controlName="password"
                    [control]="switchControl ? form.get('passwords') : form.get('passwords.password')"
                    [validationMessages]="validationMessages"
                    [showValidation]="showValidation"
                >
                    <div class="form-group max-width relative" [class.invalid]="showValidation">
                        <label>New Password</label>
                        <input
                            #newPassword
                            type="password"
                            data-lpignore="true"
                            name="password"
                            autocomplete="on"
                            formControlName="password"
                            (keydown.space)="$event.preventDefault()"
                        >
                        <rdc-apps-icon
                            size="xs"
                            [ngClass]="{ 'visible': newPassword.type === 'text' }"
                            (click)="onTogglePasswordVisibility(newPassword)"
                        >
                            {{ newPassword.type === 'text' ? 'visibility' : 'visibility_off' }}
                        </rdc-apps-icon>
                    </div>
                </rdc-apps-validated-control>
                <a (click)="onCancel()">Cancel</a>
            </div>

            <div class="account-container column">
                <rdc-apps-validated-control
                    controlName="confirmPassword"
                    [control]="switchControl ? form.get('passwords') : form.get('passwords.confirmPassword')"
                    [validationMessages]="validationMessages"
                    [showValidation]="showValidation"
                >
                    <div class="form-group relative" [class.invalid]="showValidation">
                        <label>Confirm Password</label>
                        <input
                            #confirmPassword
                            type="password"
                            data-lpignore="true"
                            name="password"
                            autocomplete="on"
                            formControlName="confirmPassword"
                            (keydown.space)="$event.preventDefault()"
                        >
                        <rdc-apps-icon
                            size="xs"
                            [ngClass]="{ 'visible': confirmPassword.type === 'text' }"
                            (click)="onTogglePasswordVisibility(confirmPassword)"
                        >
                            {{ confirmPassword.type === 'text' ? 'visibility' : 'visibility_off' }}
                        </rdc-apps-icon>
                    </div>
                </rdc-apps-validated-control>
                <button
                    class="confirm"
                    rdcAppsButton
                    colour="primary"
                    (click)="onChangePassword()"
                >
                    Change password
                </button>
            </div>
        </ng-container>


        <div class="account-container">
            <div class="form-group">
                <label>Organisation</label>
                <input
                    type="text"
                    formControlName="organisation"
                    [ngClass]="{ 'disabled' : form.get('organisation')?.disabled }"
                >
            </div>
        </div>

        <rdc-apps-header title="Personal details" [noMargin]="true"></rdc-apps-header>

        <div class="account-container">
            <rdc-apps-validated-control
                controlName="firstName"
                [control]="form.get('firstName')"
                [validationMessages]="validationMessages"
                [showValidation]="showValidation"
            >
                <div class="form-group">
                    <label>First name</label>
                    <input type="text" formControlName="firstName">
                </div>
            </rdc-apps-validated-control>
        </div>

        <div class="account-container">
            <rdc-apps-validated-control
                controlName="lastName"
                [control]="form.get('lastName')"
                [validationMessages]="validationMessages"
                [showValidation]="showValidation"
            >
                <div class="form-group">
                    <label>Last name</label>
                    <input type="text" formControlName="lastName">
                </div>
            </rdc-apps-validated-control>
        </div>

    <div class="account-container">
        <div class="form-group">
            <label>Job title</label>
            <input type="text" formControlName="jobTitle" placeholder="Enter job title (optional)">
        </div>
    </div>
</form>

