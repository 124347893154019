import { debounce, interval, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { xAxisOptionsForType, xAxisTypeOptions } from '@rdc-apps/rdc-apex/src/lib/shared/constants';
import { TemplateEventService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { ToastService } from "@rdc-apps/rdc-shared/src/lib/utilities";
import { ChartBuilderXAxisComponent } from "apex-shared-ui";
import { SelectAutocompleteModule } from "shared-ui";

@Component({
    standalone: true,
    selector: 'rdc-apps-template-query-xaxis',
    templateUrl: './template-query-xaxis.component.html',
    styleUrls: [ './template-query-xaxis.component.scss' ],
    imports: [ CommonModule, ReactiveFormsModule, SelectAutocompleteModule ],
})
export class TemplateQueryXaxisComponent extends ChartBuilderXAxisComponent implements OnInit {

    @Input() availableTypeCodes: string[] | undefined = [ ];

    @Input() availablePartitionCodes: string[] | undefined = [ ];

    @Input() maxYearRange = 50;

    constructor(
        store: Store,
        fb: FormBuilder,
        cdr: ChangeDetectorRef,
        toastService: ToastService,
        public templateEventService: TemplateEventService
    ) {
        super(store, fb, cdr, toastService);
    }

    override async ngOnInit() {

        if (this.availableTypeCodes?.length) {
            this.fullxAxisTypeOptions = xAxisTypeOptions.filter((opt) => this.availableTypeCodes?.includes(opt.code as never));
        }

        if (this.availablePartitionCodes?.length) {
            for (const [ key, value ] of xAxisOptionsForType) {

                const filtered = value.filter((val) => this.availablePartitionCodes?.includes(val.code as never));

                this.fullxAxisOptionsForType.set(key, filtered);
            }
        }

        super.ngOnInit();

        this.chartRequest.get('queryRequest.filters.timePeriod')?.valueChanges
            .pipe(
                debounce(() => interval(0)),
                takeUntil(this.componentDestroyed$)
            )
            .subscribe((timePeriod) => {

                if (this.chartRequest.value.queryRequest?.chartProperties?.xAxis?.type !== 'timePeriod') {
                    return;
                }

                this.endYearOpts = this.dateRangeUtils
                    .selectableYearsAsRepoItems(timePeriod.specific?.startYear)
                    .slice(0);

                if (!this.endYearOpts.find((year) => year.code === timePeriod.specific?.endYear)) {

                    this.chartRequest.get('queryRequest.filters.timePeriod.specific')?.patchValue(
                        { endYear: this.endYearOpts[this.endYearOpts.length - 1].code },
                        { emitEvent: false }
                    );
                }

                this.cdr.detectChanges();
            });
    }
}
