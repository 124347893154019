import { of, map } from 'rxjs';

import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';


import {
    getUserSettings,
    getUserSettingsSuccess,
    setUserSettings,
    setUserUserSettingsSuccess
} from './user-settings.actions';
import { UserSettingsService } from './user-settings.service';
import { httpErrorAction } from '../../error/+state/error.actions';

@Injectable()
export class UserSettingsEffects {
    get$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getUserSettings),
            fetch({
                run: () =>
                    this.userSettingsService.get()
                        .pipe(
                            map((userSettings) =>
                                getUserSettingsSuccess({ userSettings })
                            )
                        ),
                onError: (action, response) =>
                    of(httpErrorAction({ action, response })),
            })
        )
    );

    set$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setUserSettings),
            fetch({
                run: ({ userSettings }) =>
                    this.userSettingsService.set(userSettings)
                        .pipe(
                            map((response) =>
                                setUserUserSettingsSuccess({ userSettings: response })
                            )
                        ),
                onError: (action, response) =>
                    of(httpErrorAction({ action, response })),
            })
        )
    );

    constructor(
        private actions$: Actions,
        private userSettingsService: UserSettingsService
    ) {}
}
