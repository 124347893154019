import { map, Observable } from 'rxjs';

import { DatePipe } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ExportData } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';
import { AppEnvironment, appEnvironment } from '@rdc-apps/rdc-apex/src/lib/shared/environment';
import { RdcBaseHttpService } from '@rdc-apps/rdc-apex/src/lib/shared/utilities';
import { saveAs } from 'file-saver';
import { BaseCloudEntity } from "rdc-apex-store";

@Injectable({ providedIn: 'root' })
export class CloudExportService extends RdcBaseHttpService {

    exportedRoutes: BaseCloudEntity[] = [];

    constructor(
        @Inject(appEnvironment) private environment: AppEnvironment
    ) {
        super();
    }

    export(routes: BaseCloudEntity[], exportData: ExportData, cloudType: string): Observable<ArrayBuffer> {

        const routeUrl = cloudType === 'growth'
            ? '/GrowthCloud/routes/export'
            : '/RiskCloud/routes/export';

        // eslint-disable-next-line max-len
        return this.http.post(`${this.environment.api.baseUrl}${routeUrl}`, { routes, exportType: exportData.exportType }, { headers: this.getTokenHeaders(), responseType: 'arraybuffer' } )
            .pipe(
                map((response) => response)
            );
    }

    downloadExportedFile(buffer: ArrayBuffer, exportData: ExportData, cloudType: string) {
        const blob = new Blob([ buffer ], { type: `text/${ exportData.exportType }` });
        const dateTime = new DatePipe('en-GB').transform(new Date().getTime(),'yyyy-MMM-dd_HHmm');

        // for some reason it cant detect the tsv file type so had to append it manually
        const fileName = `${ cloudType === 'growth' ? 'target' : 'watch' }_list_${dateTime}${exportData.exportType === 'tsv' ? '.tsv' : ''}`;

        saveAs(blob, fileName);
    }

    setExportedRoutes(routes: BaseCloudEntity[]): void {
        this.exportedRoutes = [];

        this.exportedRoutes.push(...routes);
    }

    getExportedRoutes(): BaseCloudEntity[] {
        return this.exportedRoutes;
    }

}
