import { Subject, take, takeUntil } from "rxjs";

import { Dialog, DialogRef } from "@angular/cdk/dialog";
import { CdkMenu, CdkMenuItem, CdkMenuItemCheckbox } from "@angular/cdk/menu";
import { Overlay } from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";
import {
    Component,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { connectedAbove, connectedBelow, connectedLeft, connectedRight } from "@rdc-apps/rdc-shared/src/lib/constants";
import { TagsFormGroup } from "@rdc-apps/rdc-shared/src/lib/data-access/models";
import {
    TagEntity
} from 'rdc-apex-store';
import { RdcButtonDirective, RdcContextMenuItemDirective } from "shared-directives";

import { IconComponent } from "../icon/icon.component";

export interface FilterForm {
    tags: FormArray<FormGroup<TagsFormGroup>>;
}

@Component({
    selector: 'rdc-apps-tag-filter',
    templateUrl: './tag-filter.component.html',
    styleUrls: [ './tag-filter.component.scss' ],
    imports: [
        CommonModule,
        IconComponent,
        RdcButtonDirective,
        CdkMenu,
        CdkMenuItem,
        CdkMenuItemCheckbox,
        ReactiveFormsModule,
        RdcContextMenuItemDirective
    ]
})
export class TagFilterComponent<T extends { [K in keyof T]: T[K] } & FilterForm> implements OnInit, OnChanges, OnDestroy {

    @Input() tags!: TagEntity[];

    @Input() filterGroup!: FormGroup<T>;

    @ViewChild('menu') tagFilterMenu!: TemplateRef<unknown>;

    selectedTagCount = 0;
    prevMap = new Map<string, boolean>();
    destroyed$ = new Subject();

    private dialog = inject(Dialog);
    private overlay = inject(Overlay);

    private dialogRef: DialogRef | undefined;

    get tagsArray() {
        return this.filterGroup.controls.tags as FormArray<FormGroup<TagsFormGroup>>;
    }

    ngOnInit(): void {
        this.tagsArray.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe((tags) => {
                this.selectedTagCount = tags.filter(({ checked }) => checked).length;
            });
    }

    ngOnDestroy() {
        this.destroyed$.complete();
    }

    ngOnChanges() {
        this.tagsArray.clear();

        this.tags.forEach((tag) => {

            this.tagsArray.push(new FormGroup({
                label: new FormControl(tag.label, { nonNullable: true }),
                id: new FormControl(tag.id, { nonNullable: true }),
                hexaDecimalColour: new FormControl(tag.hexaDecimalColour, { nonNullable: true }),
                checked: new FormControl(this.prevMap.get(tag.id) || false, { nonNullable: true }),
            }));
        });
    }

    onOpenTagMenu(selectTrigger: HTMLButtonElement | HTMLInputElement, focus = true, restoreFocus = true) {
        event?.preventDefault();

        this.dialogRef?.close();

        this.dialogRef = this.dialog.open(this.tagFilterMenu, {
            backdropClass: 'none',
            panelClass: [ 'rdc-select-component-dialog' ],
            maxHeight: 250,
            minWidth: 150,
            autoFocus: focus ? 'first-tabbable' : 'non_existing_element',
            restoreFocus,
            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(selectTrigger)
                .withPositions([
                    connectedBelow,
                    connectedAbove,
                    connectedRight,
                    connectedLeft
                ]),
        });

        this.dialogRef.closed
            .pipe(take(1))
            .subscribe(() => {
                this.dialogRef = undefined;
            });
    }

    onSelectTag(index: number): void {
        const control = this.tagsArray.at(index);
        const currentValue = this.tagsArray.at(index).value;

        control.patchValue({ checked: !currentValue.checked });

        this.prevMap.set(currentValue.id || '', !currentValue.checked);
    }
}
