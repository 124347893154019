// eslint-disable-next-line no-shadow
export enum QueryStates {
    INITIAL = 0,
    QUERYING = 1,
    EXPORTING = 2,
    HAS_RESULTS = 3,
    EXPORT_OK = 4,
    ERROR = 5,
}

// eslint-disable-next-line no-shadow
export enum QueryActions {
    INITIAL = 0,
    EXECUTE = 1,
    EXPORT= 2,
}

export interface QueryServiceStatus {
    action: QueryActions;
    state: QueryStates;
}
