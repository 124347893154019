import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TabItemModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/tab-item';
import { TabsModule } from '@rdc-apps/rdc-apex/src/lib/settings/ui/tabs';
import { CompactSavedQueriesMenuComponent } from "apex-shared-ui";
import { RdcButtonDirective, RdcDialogItemDirective, RdcIconButtonDirective, TooltipDirective } from "shared-directives";
import { IconComponent } from "shared-ui";

import { QueryBuilderQuickLauncherComponent } from './query-builder-quick-launcher.component';



@NgModule({
    imports: [
        CommonModule,
        CompactSavedQueriesMenuComponent,
        RdcButtonDirective,
        RouterLink,
        CdkMenuModule,
        IconComponent,
        RdcDialogItemDirective,
        TabItemModule,
        TabsModule,
        TooltipDirective,
        RdcIconButtonDirective,
    ],
    declarations: [ QueryBuilderQuickLauncherComponent ],
    exports: [ QueryBuilderQuickLauncherComponent ],
})
export class QueryBuilderQuickLauncherModule {}
