import { filter, firstValueFrom, Observable, of } from 'rxjs';

import { DialogRef } from '@angular/cdk/dialog';
import { CdkStepper, CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from "@angular/common";
import { Component,  ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SummaryGroupOrderModule } from "@rdc-apps/rdc-apex/src/lib/shared/pipes/summary-group-order";
import { RepoItem } from "@rdc-apps/rdc-shared/src/lib/data-access/models";
import { RdcComponentUtils } from '@rdc-apps/rdc-shared/src/lib/utilities';
import { setUserSettings, UserSettings ,
    getUserDetailsData,
    getUserDetailsLoaded, updateUserDetails, updateUserDetailsSuccess,
    UserDetails
,
    createAirportGroup, createAirportGroupSuccess, getAirportGroupsSuccess, PreferencesGroup,
    updateDefaultPreferences, updateUnitsMeasurementsSuccess, UserPreferences
,
    DataPointsDropdownData,
    DataPointsEntity,
    getDataPoints, getDataPointsDropDownData
,
    autocomplete,
    AutocompleteOption, getAllAutocomplete
} from 'rdc-apex-store';
import { TooltipDirective } from "shared-directives";
import {
    IconComponent,
    PanelComponent,
    SelectAutocompleteModule,
    StepperComponent,
    ValidatedControlComponent
} from "shared-ui";

@Component({
    selector: 'rdc-apps-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: [ './onboarding.component.scss' ],
    imports: [
        CommonModule,
        CdkStepperModule,
        StepperComponent,
        ReactiveFormsModule,
        ValidatedControlComponent,
        IconComponent,
        PanelComponent,
        TooltipDirective,
        SummaryGroupOrderModule,
        SelectAutocompleteModule,
    ]
})
export class OnboardingComponent extends RdcComponentUtils {

    autocompleteResults$: Observable<AutocompleteOption[]> = of([]);
    dataPointsData$!: Observable<DataPointsEntity | null | undefined>;
    userDetails$!: Observable<UserDetails | undefined | null>;
    dataPointsDropDownData$!: Observable<DataPointsDropdownData>;
    userDetailsLoaded$!: Observable<boolean | undefined | null>;

    defaultAirportGroupId!: string | undefined;

    form: FormGroup = this.fb.group({
        details: this.fb.group({
            firstName: [ '', Validators.required ],
            lastName: [ '', Validators.required ],
            organisation: [
                { value: '', disabled: true },
                Validators.required,
            ],
            jobTitle: [ '' ],
        }),
        preferences: this.fb.group({
            homeAirport: [ '' ],
            units: [ 'kilometres', Validators.required ],
            tableOutput: [ 'Commas', Validators.required ],
            currency: [ 'EUR', Validators.required ],
            directionality: [ 'bothWays', Validators.required ],
            reportingLevel: [ 'fullyAllocated', Validators.required ],
        }),
    });

    isLinear = true;
    validationMessages = {
        required: 'This field is required',
        email: 'Email address required',
    };
    showValidation = false;
    showAirportDropdown = true;

    saving = false;

    readonly IMAGE_SRC = '/assets/rdc-apex/images/apex-laptop.svg';
    readonly AIRPORT_TOOLTIP = 'Optionally choose an airport you’d frequently like to filter by in new data queries';

    @ViewChild('cdkStepper') cdkStepper!: CdkStepper;

    constructor(
        public dialogRef: DialogRef<string>,
        private fb: FormBuilder,
        private store$: Store,
        private actions: Actions
    ) {
        super(actions);

        this.dataPointsData$ = this.store$.select(getDataPoints('dataPointState'));
        this.dataPointsDropDownData$ = this.store$.select(getDataPointsDropDownData('dataPointState'));
        this.autocompleteResults$ = this.store$.select(getAllAutocomplete);

        this.userDetailsLoaded$ = this.store$.select(getUserDetailsLoaded);

        this.userDetails$ = this.store$.select(getUserDetailsData('userDetailsState'));

        this.userDetailsLoaded$.pipe(
            filter((userLoaded) => !!userLoaded)
        ).subscribe(() => this.initialiseForm());

        this.onActions([ createAirportGroupSuccess ], (action) => {
            this.defaultAirportGroupId = action.groups.find(({ isDefaultGroup }) => isDefaultGroup)?.groupId;
        });

        this.onActions([ updateUserDetailsSuccess ], () => this.progress());

        this.actions.pipe(ofType(updateUnitsMeasurementsSuccess))
            .subscribe(() => this.progress());

        // for dev/test purposes applies existing home airport in the instance we need to clear the onboarding process
        this.actions
            .pipe(
                ofType(getAirportGroupsSuccess)
            )
            .subscribe(({ groups }) => {
                const defaultAirport = groups.filter((group): boolean => group.name === 'Home airports').at(0);
                this.defaultAirportGroupId = defaultAirport?.groupId;
                this.form.get('preferences.homeAirport')?.patchValue(defaultAirport?.selections.at(0));
            });
    }

    private async initialiseForm(): Promise<void> {
        const userDetails = await firstValueFrom(this.userDetails$);

        this.form.patchValue({ details: {
            ...userDetails,
            organisation: userDetails?.organisation.name,
        },
        });
    }

    get formDetails(): FormGroup {
        return this.form.get('details') as FormGroup;
    }

    get formPreferences(): FormGroup {
        return this.form.get('preferences') as FormGroup;
    }

    onSaveUserDetails(): void {
        if (!this.canContinue('details')) {
            return;
        }

        this.saving = true;

        this.store$.dispatch(updateUserDetails({ updateUserDetails: this.formDetails.value }));
    }

    onSaveUserPreferences(): void {

        if (!this.canContinue('preferences')) {
            return;
        }

        const formValue = this.formPreferences.value;

        // defaults that we don't need them to select now
        const preferences: UserPreferences = {
            distanceUnits: formValue.units,
            currency: formValue.currency,
            tableOutput: formValue.tableOutput,
            governmentTaxes: true,
            airportCharges: true,
            iataCodes: true,
            shouldDisplayNames: false,
        };

        this.saving = true;

        if (this.form.value.preferences.homeAirport) {

            const group: PreferencesGroup = {
                name: 'Home airports',
                selections: [ { ...formValue.homeAirport, code: formValue.homeAirport.icaoCode } ],
                isDefaultGroup: true,
                ...(this.defaultAirportGroupId ? { groupId: this.defaultAirportGroupId } : {}),
            };

            this.store$.dispatch(createAirportGroup({ group }));
        }

        this.store$.dispatch(updateDefaultPreferences({ unitsMeasurements: preferences }));
    }

    private progress(): void {
        this.saving = false;
        this.cdkStepper.next();
    }

    canContinue(formGroupName: string): boolean {
        if (this.form.get(formGroupName)?.invalid) {
            this.showValidation = true;

            return false;
        }

        this.showValidation = false;

        return true;
    }

    onSeeApex(): void {
        this.dialogRef.close();

        this.store$.dispatch(setUserSettings({ userSettings: { userOnboardingCompleted: true } as UserSettings }));
    }

    onAutocomplete(query: string): void {
        this.store$.dispatch(autocomplete({ query, filter: 'airport' }));
    }

    onSelect(item: Partial<RepoItem<unknown>>): void {

        this.formPreferences?.patchValue({
            homeAirport: item,
        });
        this.showAirportDropdown = false;
    }

    onRemove(): void {
        this.formPreferences.get('homeAirport')?.patchValue('');
        this.formPreferences.get('homeAirport')?.markAsTouched();
        this.showAirportDropdown = true;
    }
}
