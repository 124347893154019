import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { QueryBuilderType } from '@rdc-apps/rdc-apex/src/lib/shared/data-access/models';

import { QueryEntity } from './query.models';
import { DataPoint, DataPointsData, DataPointsEntity, DataPointsType } from '../../data-points/+state/data-points.models';


@Injectable({ providedIn: 'root' })
export class QueryDataPointsConversionService {

    createQuery(dataPoints: DataPointsEntity | null | undefined): DataPointsData[] {
        return this.mappedDataPoints(dataPoints);
    }

    loadQueries(queries: QueryEntity[], dataPoints: DataPointsEntity | undefined): Observable<QueryEntity[]> {
        const dataPointsMapped = this.mappedDataPoints(dataPoints);
        const queriesArray: DataPointsType[] = [];

        queries.forEach((query): void => {

            const dataPointCodes = query.queryRequest.dataPoints?.dataPointCodes as string[];
            queriesArray.push(
                dataPointsMapped.reduce((dataPointsGroup, dataPointsData): object =>
                    ({
                        ...dataPointsGroup, [dataPointsData.code]: dataPointsData.dataPointsData
                            .filter((dataPoint): boolean => dataPoint.tabularVisible)
                            .reduce((acc, dataPoint): object =>
                                ({
                                    ...acc, [dataPoint.code]: dataPointCodes?.includes(dataPoint.code),
                                }),{}),
                    }), {})
            );
        });

        const queryEntity = queries.map((query, index): QueryEntity => {

            if (query.studyType !== 'table') {
                return query;
            }

            return {
                ...query,
                queryRequest: {
                    ...query.queryRequest,
                    dataPoints: queriesArray[index],
                },
            };

        });

        return of(queryEntity);
    }

    sendQuery(query: QueryEntity): QueryEntity {
        return this.convertedQuery(query);
    }

    saveQuery(query: QueryEntity): Observable<QueryEntity> {
        return of(query);
    }

    convertedQuery(query: QueryEntity): QueryEntity {

        if (query.studyType !== QueryBuilderType.Table) {
            return {
                ...query,
                queryRequest: {
                    ...query.queryRequest,
                    dataPoints: {
                        dataPointCodes: query.queryRequest.dataPoints.dataPointCodes,
                    },
                },
            };
        }

        const dataPointValues = Object.values(query.queryRequest.dataPoints as DataPointsType || {});
        let dataPointsArray: string[] = [];

        dataPointValues.map((dataPointValue): void => {
            const selectedDataPoints = Object.keys(dataPointValue).filter((value): boolean | null => dataPointValue[value]);

            if (selectedDataPoints.length) {
                dataPointsArray.push(...selectedDataPoints);
            }
        });

        dataPointsArray = [ ...new Set(dataPointsArray) ];

        return {
            ...query,
            queryRequest: {
                ...query.queryRequest,
                dataPoints: {
                    dataPointCodes: dataPointsArray,
                },
            },
        };
    }

    queryConversion(query: QueryEntity): QueryEntity {
        return Object.prototype.hasOwnProperty.call(query.queryRequest.dataPoints, 'dataPointCodes')
            ? query
            : this.convertedQuery(query)
    }

    private mappedDataPoints(dataPoints: DataPointsEntity | null | undefined): DataPointsData[] {
        const dataPointsData = dataPoints?.datapoints;
        const dataPointsGroupsData = dataPoints?.datapointsGroups;

        return (dataPointsGroupsData || []).map((dataPointGroup): DataPointsData => {

            const mappedDataPointsData = (dataPointsData || []).filter((dataPoint): boolean =>
                dataPoint.datapointGroups.map(( { datapointGroupCode }) => datapointGroupCode).includes(dataPointGroup.code)
            );

            return {
                label: dataPointGroup.label,
                code: dataPointGroup.code,
                displayOrder: dataPointGroup.displayOrder,
                dataPointsData: this.sortByDataPointsGroupsDisplayOrder(mappedDataPointsData, dataPointGroup.code),
            };
            // TODO: TEMP FILTER OF PERFORMANCE INDICATORS
        }).filter((dataPointData) => dataPointData.code !== 'performanceIndicators');
    }

    private sortByDataPointsGroupsDisplayOrder(dataPoints: DataPoint[], dataPointGroupCode: string): DataPoint[] {
        return [ ...dataPoints ].slice().sort((a, b): number => {

            const hasPopularGroupA = a.datapointGroups.some((group): boolean => group.datapointGroupCode === 'popular');
            const hasPopularGroupB = b.datapointGroups.some((group): boolean => group.datapointGroupCode === 'popular');

            if (dataPointGroupCode === 'popular') {
                if (hasPopularGroupA && !hasPopularGroupB) {
                    return -1;
                }

                if (!hasPopularGroupA && hasPopularGroupB) {
                    return 1;
                }

                if (hasPopularGroupA && hasPopularGroupB) {
                    const popularObjectA = a.datapointGroups.find((obj): boolean => obj.datapointGroupCode === 'popular');
                    const popularObjectB = b.datapointGroups.find((obj): boolean => obj.datapointGroupCode === 'popular');

                    if (popularObjectA && popularObjectB) {
                        return popularObjectA.datapointDisplayOrder - popularObjectB.datapointDisplayOrder;
                    }
                }
            }

            return a.datapointGroups.slice()
                .sort((groupCodeA, groupCodeB) =>
                    groupCodeA.datapointDisplayOrder - groupCodeB.datapointDisplayOrder)[0].datapointDisplayOrder - b.datapointGroups.slice()
                .sort((groupCodeA, groupCodeB) =>
                    groupCodeA.datapointDisplayOrder - groupCodeB.datapointDisplayOrder)[0].datapointDisplayOrder;
        });
    }

}
