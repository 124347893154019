import { CommonModule, NgOptimizedImage } from "@angular/common";
import { Component, Input } from '@angular/core';

@Component({
    standalone: true,
    selector: 'rdc-apps-empty-state',
    templateUrl: './empty-state.component.html',
    styleUrls: [ './empty-state.component.scss' ],
    imports: [
        CommonModule,
        NgOptimizedImage
    ]
})
export class EmptyStateComponent {

    @Input() title!: string;
    @Input() copy!: string;
    @Input() imageSrc = '/assets/rdc-apex/images/apex-no-data.svg';

}
